import { makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    dbRightParent: {
      width: "18vw",
      maxHeight: "auto",
      backgroundColor: "#fff",
      // position: "fixed",
      right: "0",
      top: "0",
      zIndex: "1",
      fontFamily: "Montserrat",
      padding: "20px",
      [theme.breakpoints.down("sm")]: {
        position: "relative",
        width: "100vw",
        marginBottom: "80px",
      },
    },
    dbleft:{
       flex:1
    },
    greetingsParent: {
      width: "100%",
      backgroundColor: "#FEDD17",
      height: "310px",
      opacity: "0.6",
      boxShadow: "2px 4px 24px 3px rgba(0, 0, 0, 0.1)",
      fontFamily: "Montserrat",
      [theme.breakpoints.down("sm")]: {
        height: "600px",
      },
    },
    filterWrap:{
    width:'100%',
    padding:'0 20px',
    [theme.breakpoints.down("sm")]: {
      width:'100%',
    },
    },
    greetingsTextParent: {
     
      paddingLeft: "25px",
      fontFamily: "Montserrat",
    },
    greetingsHeader: {
      fontSize: "48px",
      fontWeight: "700 !important",
      lineHeight: "59px",
      color: "#000000",
      fontFamily: "Montserrat",
    },
    greetingsBody: {
      fontSize: "16px",
      fontWeight: "500 !important",
      color: "#000000",
      fontFamily: "Montserrat",
    },
    greetingsMiniCardParent: {
      display: "flex",
      justifyContent: "space-evenly",
      width: "100%",
      position: "relative",
      bottom: "50px",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "row",
        // position: "absolute",
        // top: "350px",
        // bottom: "0px",
        bottom: "0px",
        marginTop: "-170px",
        flexWrap: "wrap",
        gap: "15px",
        paddingInline: "15px",
        justifyContent: "center",
        width: "100%",
      },
    },
    greetingsMiniCard: {
      width: "120px",
      height: "125px",
      boxShadow: "2px 4px 24px 3px rgba(0, 0, 0, 0.1) !important",
      borderRadius: "12px !important",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-around",
      [theme.breakpoints.down("sm")]: {
        width: "30%",
        marginBottom: "20px",
      },
    },
    greetingsMiniCardStat: {
      color: "#444445",
      fontWeight: "600",
      fontSize: "18px",
      fontFamily: "Montserrat",
    },
    greetingsMiniCardText: {
      color: "#67748E",
      fontWeight: "700",
      fontSize: "12px",
      fontFamily: "Montserrat",
    },
    dealersSectionParent: {
      width: "100%",

      minHeight: "420px",
      padding: "0 20px",
      marginBottom: "20px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginTop: "80px",
      },
    },
    orderValueChartParent: {
      width: "100% !",
      height: "420px",
      backgroundColor: "#FFFFFF",
      boxShadow: "2px 4px 24px 3px rgba(0, 0, 0, 0.1) !important",
      borderRadius: "12px !important",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        width: "90vw",
      },
    },
    dealersOnboardedBlockParent: {
      // minHeight: "420px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    dealersOnboardedFilter: {
      width: "100%",
      height: "50px",
      backgroundColor: "#FFFFFF",
      boxShadow: "2px 4px 24px 3px rgba(0, 0, 0, 0.1) !important",
      borderRadius: "12px !important",
      padding: "0 10px",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "30px",
        height: "150px",
      },
    },
    dealersOnboardedChartParent: {
      width: "100%",
      height: "360px",
      backgroundColor: "#FFFFFF",
      boxShadow: "2px 4px 24px 3px rgba(0, 0, 0, 0.1) !important",
      borderRadius: "12px !important",
      position: "relative",
    },
    ordersSectionParent: {
      width: "100%",
      minHeight: "450px",
      padding: "0 20px",
      marginBottom: "20px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    ordersChartParent: {
      width: "99.3%",
      height: "450px",
      backgroundColor: "#ffffff",
      borderRadius: "12px !important",
      boxShadow: "2px 4px 24px 3px rgba(0, 0, 0, 0.1) !important",
      position: "relative",
      ['& .recharts-legend-wrapper']:{
        width:'100% !important',
        left:'0 !important',
        top : '-10px !important'
      }
    },
    salesSectionParent: {
      width: "100%",
      minHeight: "420px",
      padding: "0 20px",
      marginBottom: "20px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    salesChartCommonParent: {
      backgroundColor: "#fff",
      width: "100%",
      height: "440px",
      borderRadius: "6px !important",
      boxShadow: "2px 4px 24px 3px rgba(0, 0, 0, 0.1) !important",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        width: "90vw",
      },
    },
    selectUserParent: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    iButtonParent: {
      position: "absolute",
      right: "20px",
      top: "20px",
    },
    chartHeaderParent: {
      padding: "10px 0 10px 30px",
      
    },
    chartHeader: {
      color: "#000000",
      fontFamily: "Montserrat",
      fontWeight: "500",
      fontSize: "20px",
    },
    chartSubHeader: {
      color: "#4F4F4F",
      fontFamily: "Montserrat",
      fontWeight: "400",
      fontSize: "12px",
      marginTop: "-10px",
    },
    orderSortParent: {
      position: "absolute",
      right: "15px",
      top: "15px",
      backgroundColor: "#FFFFFF",
      boxShadow:
        "0px 4px 7px -1px rgba(0, 0, 0, 0.11), 0px 2px 4px -1px rgba(0, 0, 0, 0.07)",
      border: "0.5px solid #9E9E9E",
      display: "flex",
      width: "190px",
      borderRadius: "8px",
      padding: "0 15px",
    },
    orderSortText: {
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: "400",
      color: "#444445",
      width: "90px",
      paddingTop: "7px",
    },
    appDownloadStatsParent: {
      // height: "35%",
      marginTop:'20px'
    },
    appDownloadStatsTitle: {
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: "600",
      color: "#444445",
    },
    appDownloadStatsSelectParent: {
      width: "100%",
      height: "35px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    appDownloadStatsSelectTitle: {
      color: "#165BAA",
      fontFamily: "Montserrat",
      fontSize: "18px",
      fontWeight: "700",
      marginBottom: "0",
    },
    appDownloadStatsSelectSubTitle: {
      color: "#444445",
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: "400",
      marginBottom: "0",
    },
    appDownloadStatsSelect: {
      backgroundColor: "#ffffff",
      boxShadow:
        "0px 4px 7px -1px rgba(0, 0, 0, 0.11), 0px 0px 4px 2px rgba(0, 0, 0, 0.07)",
      borderRadius: "4px",
      height: "100%",
      width: "120px",
    },
    appDownloadStatsChart: {
      backgroundColor: "rgba(26, 212, 237, 0.12)",
      width: "100%",
      height: "230px",
      marginTop: "10px",
      borderRadius: "24px",
    },
    chartDetailHeaderTitle: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      ontWeight: "600",
      fontSize: "14px",
      color: "#828282",
    },
    chartDetailHeaderStat: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "40px",
      color: "#0B1354",
      margin: "-9px 0",
    },
    chartDetailHeaderUpdate: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "14px",
      color: "#4F4F4F",
    },
    quickUpdatesParent: {
      height: "50%",
    
      [theme.breakpoints.down("sm")]: {
        marginTop: "80px",
      },
    },
    quickUpdatesHeader: {
      color: "#444445",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "14px",
      borderBottom: "2px solid #444445",
    },
    quickUpdatesSubHeader: {
      color: "#444445",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "12px",
      padding: "5px 0 2px 0",
    },
    quickUpdatesRadialProgressParent: {
      display: "flex",
      justifyContent: "center",
    },
    quickUpdatesSubHeaderTwo: {
      color: "#444445",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "12px",
      padding: "20px 0 10px 0",
    },
    quickUpdatesRadioParent: {
      color: "#19191D",
      backgroundColor: "#ffffff",
      boxShadow: "2px 4px 24px 3px rgba(0, 0, 0, 0.1)",
      borderRadius: "12px",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      width: "100%",
      marginBottom:'2px'
      
    },
    radioButtonsText: {
      fontFamily: "Inter !important",
      fontWeight: "800",
      fontSize: "14px",
      color: "#19191D",
      ['& .MuiFormControlLabel-label']:{
        fontSize:'14px !important'
      }
    },
    filterText: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "12px",
    },
    filterSelectBoxStyles: {
      height: "30px",
      border: "0.5px solid #979797",
      borderRadius: "6px",
    },
    filterGridParent: {
      height: "100%",
    },
  })
);

export default useStyles;
