import {
  makeStyles,
  createStyles,

} from "@material-ui/core/styles";

export default makeStyles((theme) =>
  createStyles({
    searchBoxParent: {
      // width: "78%",
      display: "flex",
      margin: "20px",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: "10px",
     
    },

    divStyle: {
      width: "100%",
      minHeight: "calc(100vh - 80px) !important",
      backgroundColor: "#F5F5F5",
      padding: "2px 0",
    },
    filterStyle: {
      display: "flex",
      fontFamily: "Montserrat",
      fontWeight: "700",
      fontSize: "14px",
      color: "#444445",
      lineHeight: "45px",
    },
    formSelectBox: {
      width: "204px",
      height: "40px",
     
      borderRadius: "6px",
     marginTop:"5px !important",
      [theme.breakpoints.down("xs")]: {
        width: "100% !important",
      },
    },
    pagination: {
      display: "flex !important",
      justifyContent: "space-evenly !important",
      width: "100% !important",
      gap:'10px',
      paddingTop: "25px !important",
      paddingBottom: "25px !important",
      paddingRight: "85px !important",
      ["@media (max-width: 500px)"]: {
        display: "block !important",
        paddingRight: "0px !important",
      },
    },
    paginationStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap:'4px',
      margin:'0 10px',
      ["@media (max-width: 500px)"]: {
        margin: "15px 0",
      },
    },
    paginationPage: {
      fontFamily: "Montserrat",
      width:"8vw",
      marginLeft:"-23px",
      [theme.breakpoints.down("sm")]: {
        width:"14vw",
        marginLeft:"-27px",


    },
      [theme.breakpoints.down("xs")]: {
        width:"29vw",
        marginLeft:"-30px",
      },

    },
    paginationFont:{
      fontFamily: "Montserrat !important",
      "& .MuiPaginationItem-root":{
        fontFamily: "Montserrat !important",

      },

      ['& > ul']:{
         justifyContent:'center'
      }
    }
  })
);
