import axios from "axios";
import DataService from "../../DataService/DataService";
import { httpBase } from "../../interceptor";

const ordersList = async ({ page, pageCount, search, filter }) => {
  const filterData = () => {
    if (filter === "All") return '';
    else if (filter === "New") return 1;
    else if (filter === "Confirmed") return 2;
    else if (filter === "Inprogress") return 3;
    else if (filter === "Completed") return 4;
    else if (filter === "Delivered") return 5;
    else if (filter === "Canceled") return 6;
  };
  const url = `/order/orders/?${pageCount ? "page_size=" + pageCount + "&" : ""
    }${search ? "search=" + search + "&" : ""}${filter ? "status=" + filterData() + "&" : ""
    }${page ? "page=" + page : ""}`;
  const data = await DataService.get(url);
  return data;
};
const singleOrder = async (id) => {
  const data = await DataService.get(`order/orders/${id}/`);
  return data;
};

const downloadOrderPDF = async (id) => {
  const data = await DataService.get(`technicians/report-pdf/${id}/`);
  return data.data;
};

const advidorList = async ({ page, pageCount, search }) => {
  const url = `/order/getsupportadvisors/?${search ? "search=" + search + "&" : ""
    }${pageCount ? "page_size=" + pageCount + "&" : ""}${page ? "page=" + page : ""
    }`;
  const data = await DataService.get(url);
  return data;
};
const invoice = async ({ invoice_id, order_id }) => {
  try {
    const data = await DataService.get(
      `/order/getinvoice/${invoice_id}/${order_id}/`
    );
    return data;
  } catch (err) {
    console.log(err);
  }
};
const assignToAdvisor = async (value) => {
  const url = `/order/assign_advisors/${value.id}/`;
  const data = await DataService.patch(url, value.data);
  return data;
};

const singleDealerList = async ({ order_id, qry }) => {

  const url = `/order/get-dealers-by-orderitems/${order_id}/${qry.search ? "?search=" + qry.search : ""
    }`;
  const data = await DataService.get(url);
  return data;
};

const multipleDealerList = async ({ order_id, qry }) => {
  const data = await DataService.get(`/order/getdealersbybrand/${order_id}/${qry.search ? "?search=" + qry.search : ""
    }`);
  return data;
};
const assignToDealer = async (value) => {
  const url = `/order/assignallitems/${value.orderId}/`;
  const data = await DataService.patch(url, { dealer_id: value.dealerId });
  return data;
};

const assignMultipleDealer = async (value) => {
  const url = `/order/assign_orderitems/${value.orderId}/`;
  const data = await DataService.patch(url, { dealer_id: value.dealerId });
  return data;
};
const statusChange = async (value) => {
  const url = `order/order-status-change/${value.orderId}/`;
  const data = await DataService.patch(url, { status: value.status });
  return data;
};

const editOrder = async (value) => {
  const { id, ...editData } = value;
  const url = `/order/additem-to-order/${id}/`;
  const data = await DataService.patch(url, editData);
  return data;
};

export {
  ordersList,
  singleOrder,
  advidorList,
  assignToAdvisor,
  assignToDealer,
  statusChange,
  assignMultipleDealer,
  invoice,
  singleDealerList,
  multipleDealerList,
  editOrder,
  downloadOrderPDF
};
