import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../config/config";
import axios from "axios";
import { APP_URLS } from "../../config/api-url";
import DataService from "../../DataService/DataService";
import routes from "../../Routes/Routes";
import { useQuery } from "react-query";
import { getPermissionsList } from "./loginUrls";
import {toast, ToastContainer } from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL;

const permissions_list = () => {
  return useQuery(["permissionsList"], () => getPermissionsList(), {
    staleTime: 30000,
    keepPreviousData: false,
    refetchOnWindowFocus: true,
    poll: 500,
  });
};

const login = (data) => {
  const navigate = useNavigate();
  return useMutation(
    async (payload) => {
      const { email, password } = payload;
      const { data } = await axios.post(API_URL + APP_URLS.login, {
        email: email,
        password: password,
      });
      return data;
    },
    {
      onSuccess: (response) => {
        // navigate(AppRoutes.ADMIN + EndPoint.DEALER_MANAGEMENT);
        if (
          response.data.tokens.group !== "Dealer" &&
          response.data.tokens.group !== "Customer" &&
          response.data.tokens.group !== "Technician" &&
          response.data.tokens.group !== "Dealer Technician"
        ) {
          localStorage.setItem("accessToken", response.data.tokens.access);
          localStorage.setItem("refreshToken", response.data.tokens.refresh);
          localStorage.setItem("userType", response.data.tokens.group);
          localStorage.setItem("userName", response.data.tokens.first_name);
          localStorage.setItem("userId", response.data.tokens.id);
          localStorage.setItem(
            "userPermissions",
            JSON.stringify(response.data.tokens.permissions)
          );
          let filteredRoutes = [];
          let permissions = response.data.tokens.permissions;
          if (permissions) {
            permissions = permissions.map((item) => item.id);
            permissions && permissions.push(0);
            filteredRoutes = routes.filter((item) =>
              permissions.includes(item.id)
            );
          }
          if (filteredRoutes[2]) {
            navigate(AppRoutes.ADMIN + filteredRoutes[2].path);
          }
        } else {
          toast.error("You don't have the permisson to login");
        }
      },
      onError: (error) => {
        return error;
      },
    }
  );
};

const logout = () => {
  const navigate = useNavigate();
  return useMutation(
    async (refreshToken) => {
      const url = `auth/logout/`;
      const data = await DataService.post(url, refreshToken);
      return data;
    },
    {
      onSuccess: (response) => {
        localStorage.clear();
        const access = localStorage.removeItem("accessToken");
      },
      onError: (error) => {},
    }
  );
};

const useLoginQuery = {
  login,
  logout,
  permissions_list,
};

export default useLoginQuery;
