import { Box, Grid, IconButton, Typography } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import React, { useEffect } from "react";

import { AiOutlineClose } from "react-icons/ai";

import OrderManagmentStyle from "./OrderManagmentStyle";
import moment from "moment";

import Controls from "../../common/Controls/Controls";
import orderStyles from "./orderStyles";
import IconButs from "../../common/Controls/IconButs";
import { useState } from "react";
import AssignDealerTechnician from "./AssignDealerTechnician";
import "./order.css";
import { useOrderQuery } from "../../data/orderManegemnet/orderQuery";
import CustomLoader from "../../features/CustomLoader";
import geocodeLocation from "../../utilitis/locationDecode/geocodeUtils";

const AssignDealerTechnicianModal = ({
  open,
  handleClose,
  title,
  orderDetials,
}) => {
  const [advisorOpen, setAdvisorOpen] = useState(false);
  const orderStyle = OrderManagmentStyle();
  const dealerStyle = orderStyles();
  const { data, isLoading, isSuccess } = useOrderQuery.get_order_Detials(
    orderDetials.id
  );

  const [orderId, setOrderId] = useState("");
  const [multipleDelears, setMultipleDEalers] = useState(false);
  const [location, setLocation] = useState("");
  const [dealerId, setDealerId] = useState(null);
  const restrictSingleAssign = () => {
    const dealer =
      data &&
      data.data &&
      data.data.order_item &&
      data.data.order_item.filter((item) => {
        return item.dealer_id !== null;
      });

    return dealer && dealer.length > 0 ? true : false;
  };
  useEffect(() => {
    if (restrictSingleAssign()) {
      setMultipleDEalers(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    const fetchGeocodeData = async () => {
      if (
        data &&
        data.data &&
        data.data.service_details &&
        data.data.service_details.location &&
        data.data.service_details.location.location_string
      ) {
        const address = data.data.service_details.location.location_string;
        setLocation(address);
      } else if (
        data &&
        data.data &&
        data.data.service_details &&
        data.data.service_details.location
      ) {
        const { latitude, longitude } = data.data.service_details.location;
        const address = await geocodeLocation(latitude, longitude);
        setLocation(address);
      }
    };

    fetchGeocodeData();
  }, [isSuccess]);

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <div className={dealerStyle.advisorModalWap}>
          <Box>
            <Box className={orderStyle.assignlinkbox}>
              <Box></Box>
              <Typography className={dealerStyle.assignTitle}>
                {title}
              </Typography>
              <Box className={orderStyle.assignlinkclose}>
                <IconButton onClick={handleClose}>
                  <AiOutlineClose />
                </IconButton>
              </Box>
            </Box>
            <div style={{ width: "100%", padding: "20px", height: "100%" }}>
              {isLoading ? (
                <div className="loader">
                  <CustomLoader />
                </div>
              ) : (
                <Grid container spacing={4}>
                  <Grid xs={12} sm={3}>
                    <Typography className={dealerStyle.label}>
                      Order ID
                    </Typography>
                  </Grid>
                  <Grid xs={12} sm={9}>
                    <Typography className={dealerStyle.viewLabel}>
                      {data && data.data && data.data.order_no}
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={12} sm={9}>
                  <Controls.InputField
                    value={data && data.data && data.data.order_no}
                    className={dealerStyle.inputStyles}
                     disabled={ isLoading ? true:false}
                  /> 
                </Grid>*/}
                  <Grid xs={12} sm={3}>
                    <Typography className={dealerStyle.label}>
                      Customer Name
                    </Typography>
                  </Grid>

                  <Grid xs={12} sm={9}>
                    <Typography className={dealerStyle.viewLabel}>
                      {data &&
                        data.data &&
                        `${data.data.user_id.first_name}${
                          data.data.user_id.last_name
                            ? ` ${data.data.user_id.last_name}`
                            : ""
                        }`}
                    </Typography>
                  </Grid>

                  {/* <Grid item xs={12} sm={9}>
                  <Controls.InputField
                    value={data && data.data && data.data.user_id.first_name}
                    className={dealerStyle.inputStyles}
                     disabled={ isLoading ? true:false}
                  />
                </Grid> */}
                  <Grid xs={12} sm={3}>
                    <Typography className={dealerStyle.label}>
                      Location
                    </Typography>
                  </Grid>

                  <Grid xs={12} sm={9}>
                    <Typography className={dealerStyle.viewLabel}>
                      {location}
                    </Typography>
                  </Grid>

                  {data &&
                    data.data &&
                    data.data.group &&
                    data.data.group.includes("Customer") && (
                      <>
                        <Grid xs={12} sm={3}>
                          <Typography className={dealerStyle.label}>
                            Vehicle Reg.No
                          </Typography>
                        </Grid>
                        <Grid xs={12} sm={9}>
                          <Typography className={dealerStyle.viewLabel}>
                            {data &&
                            data.data &&
                            data.data.customer_vehicle_id &&
                            data.data.customer_vehicle_id.basic_details
                              .register_number
                              ? data.data.customer_vehicle_id.basic_details
                                  .register_number
                              : ""}
                          </Typography>
                        </Grid>

                        <Grid xs={12} sm={3}>
                          <Typography className={dealerStyle.label}>
                            Vehicle Details
                          </Typography>
                        </Grid>
                        <Grid xs={12} sm={9}>
                          <Typography className={dealerStyle.viewLabel}>
                            {data &&
                              data.data &&
                              data.data.vehicle_id &&
                              `${
                                data.data.vehicle_id.make
                                  ? data.data.vehicle_id.make
                                  : ""
                              } ${
                                data.data.vehicle_id.model
                                  ? data.data.vehicle_id.model
                                  : ""
                              } ${
                                data.data.vehicle_id.trim
                                  ? data.data.vehicle_id.trim
                                  : ""
                              }`}
                          </Typography>
                        </Grid>

                        <Grid xs={12} sm={3}>
                          <Typography className={dealerStyle.label}>
                            Vehicle Segment
                          </Typography>
                        </Grid>

                        <Grid xs={12} sm={9}>
                          <Typography className={dealerStyle.viewLabel}>
                            {data &&
                              data.data &&
                              data.data.vehicle_id &&
                              data.data.vehicle_id.category}
                          </Typography>
                        </Grid>

                        <Grid xs={12} sm={3}>
                          <Typography className={dealerStyle.label}>
                            Fuel Type
                          </Typography>
                        </Grid>
                        <Grid xs={12} sm={9}>
                          <Typography className={dealerStyle.viewLabel}>
                            {data &&
                              data.data &&
                              data.data.vehicle_id &&
                              data.data.vehicle_id.fuel_type}
                          </Typography>
                        </Grid>
                      </>
                    )}

                  {data &&
                    data.data &&
                    data.data.service_details &&
                    data.data.service_details.date_slot &&
                    data.data.service_details.time_slot && (
                      <>
                        <Grid xs={12} sm={3}>
                          <Typography className={dealerStyle.label}>
                            Scheduled Date & Time
                          </Typography>
                        </Grid>
                        <Grid xs={12} sm={9}>
                          <Typography className={dealerStyle.viewLabel}>
                            {moment(data.data.service_details.date_slot).format(
                              "D MMMM YYYY"
                            )}{" "}
                            {data.data.service_details.time_slot}
                          </Typography>
                        </Grid>
                      </>
                    )}

                  <Grid xs={12} sm={3}>
                    <Typography className={dealerStyle.label}>
                      Service Method
                    </Typography>
                  </Grid>
                  <Grid xs={12} sm={9}>
                    <Typography className={dealerStyle.viewLabel}>
                      {data &&
                        data.data &&
                        data.data.service_details &&
                        data.data.service_details.service_method}
                    </Typography>
                  </Grid>

                  {/* <Grid item xs={12} sm={9}>
                  <Controls.InputField
                    value="dfgdg"
                    className={dealerStyle.inputStyles}
                    disabled={ isLoading ? true:false}
                  />
                </Grid> */}
                  <Grid xs={12} sm={3}>
                    <Typography className={dealerStyle.label}>
                      Amount
                    </Typography>
                  </Grid>

                  <Grid xs={12} sm={9}>
                    <Typography className={dealerStyle.viewLabel}>
                      INR {data && data.data && data.data.grand_total}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    {/* <Controls.InputField
                    value={data && data.data && data.data.grand_total}
                    className={dealerStyle.inputStyles}
                     disabled={ isLoading ? true:false}
                  /> */}
                    <div className={dealerStyle.assignMultipleDealer}>
                      {data &&
                        data.data &&
                        data.data.order_item[0] &&
                        data.data.order_item[0].dealer_id === null && (
                          <IconButs
                            onClick={() => {
                              setAdvisorOpen(true);
                              setOrderId(orderDetials.id);
                            }}
                            ibtname={"Assign to Dealer"}
                            className={orderStyle.formButtons}
                            disabled={multipleDelears || restrictSingleAssign()}
                          />
                        )}
                      {data &&
                        data.data &&
                        data.data.order_item[0] &&
                        data.data.order_item[0].dealer_id === null && (
                          <div className="multiple-btn">
                            <Controls.radioButton
                              disabled={restrictSingleAssign()}
                              value={multipleDelears}
                              checked={multipleDelears}
                              onClick={() =>
                                setMultipleDEalers(!multipleDelears)
                              }
                            />
                            <div>Assign multiple dealers</div>
                          </div>
                        )}
                    </div>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={
                      data &&
                      data.data &&
                      data.data.order_item[0] &&
                      data.data.order_item[0].dealer_id !== null
                        ? 12
                        : 3
                    }
                  >
                    <Typography
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      className={dealerStyle.label}
                    >
                      <span style={{ width: "60%" }}>Order Items</span>{" "}
                      {data &&
                        data.data &&
                        data.data.order_item[0] &&
                        data.data.order_item[0].dealer_id !== null && (
                          <span
                            style={{
                              width: "40%",
                              // textAlign: "center",
                              display: "inline-block",
                            }}
                          >
                            Dealer
                          </span>
                        )}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={
                      data &&
                      data.data &&
                      data.data.order_item[0] &&
                      data.data.order_item[0].dealer_id !== null
                        ? 12
                        : 9
                    }
                  >
                    <div className={dealerStyle.assignedContainer}>
                      {data &&
                        data.data &&
                        data.data.order_item[0] &&
                        data.data.order_item.map((item, index) => {
                          return (
                            <div style={{ width: "100%" }}>
                              <div style={{ width: "100%" }}>
                                <div
                                  style={{ minWidth: "60%", fontSize: "12px" }}
                                >
                                  <span>.{index + 1}</span>
                                  <p>{item.product_id.title}</p>
                                  <p>{item.product_id.brand_id.name}</p>
                                  <p>
                                    {item.product_id.brand_id.category_id.name}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    color: "#86AFFF",
                                    marginLeft: "1rem",
                                    fontSize: "13px",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {item &&
                                    item.dealer_id &&
                                    item.dealer_id &&
                                    item.dealer_id.first_name}
                                </div>
                                <div
                                  style={{
                                    color: "#86AFFF",
                                    marginLeft: "1rem",
                                    fontSize: "13px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  {item && item && item.shop_name}
                                </div>
                                {multipleDelears &&
                                  (item && item.dealer_id === null ? (
                                    <button
                                      onClick={() => {
                                        setAdvisorOpen(true);
                                        setOrderId(item.id);
                                      }}
                                      style={{ minWidth: "30%" }}
                                    >
                                      Assign dealer
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() => {
                                        setAdvisorOpen(true);
                                        setOrderId(item.id);
                                        setDealerId(item && item.dealer_id);
                                      }}
                                      style={{ minWidth: "30%" }}
                                    >
                                      Re assign dealer
                                    </button>
                                  ))}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </Grid>
                </Grid>
              )}
            </div>
          </Box>
        </div>
      </Modal>
      {advisorOpen && (
        <AssignDealerTechnician
          handleClose={setAdvisorOpen}
          title="Assign Dealer / Technician"
          open={advisorOpen}
          orderId={orderId}
          multipleDelears={multipleDelears}
          dealerId={dealerId}
        />
      )}
    </div>
  );
};

export default AssignDealerTechnicianModal;
