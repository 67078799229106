import {
  makeStyles,
  createStyles,
  createTheme,
} from "@material-ui/core/styles";
const theme = createTheme();
export default makeStyles((theme) =>
  createStyles({
    reAssign: {
      [theme.breakpoints.down("xs")]: {
        width: "70px !important",
      },
    },
    viewlabel: {
      fontWeight: "600",
      fontStyle: "normal",
      fontSize: "12px",
      lineHeight: "24px",
      fontFamily: "Montserrat !important",
    },
    detailviewlabel: {
      fontWeight: "700",
      fontStyle: "normal",
      fontSize: "12px",
      lineHeight: "24px",
      fontFamily: "Montserrat !important",
    },
    viewLabelSmall: {
      fontFamily: `Montserrat`,
      fontStyle: `Normal`,
      fontWeight: `400`,
      fontSize: `14px`,
      color: `#444445`,
      lineHeight: `30px`,
    },
    assignlinkbox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "#FEDD17",
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
    },
    assignlinkclose: {
      border: "1.5px solid #292D32",
      borderRadius: "8px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "2rem",
      height: "2rem",
      margin: "10px",
    },
    assignTitle: {
      fontFamily: "Montserrat !important",
      fontWeight: "700 !important",
      fontSize: "16px !important",
      textAlign: "center !important",
      lineHeight: "24px !important",
    },
    formButtons: {
      fontFamily: "Montserrat !important",
      fontWeight: "700 !important",
      width: "60%",
      height: "40px",
      background: "#FEDD17 !important",
      padding: "12px 20px !important",
      fontSize: "12px !important",
      color: "#000000 !important",
      textTransform: "capitalize !important",
      lineHeight: "24px",
      margin: "20px 0px 20px -14px !important",
      border: "1px solid #FEDD17",
      borderRadius: "6px !important",
      [theme.breakpoints.down("sm")]: {
        width: "100% !important",
      },
    },
    formButtonadd: {
      fontFamily: "Montserrat !important",
      fontWeight: "700 !important",
      width: "100%",
      height: "40px",
      background: "#FEDD17 !important",
      padding: "12px 20px !important",
      fontSize: "12px !important",
      color: "#000000 !important",
      textTransform: "capitalize !important",
      lineHeight: "24px",
      margin: "20px 0px !important",
      border: "1px solid #FEDD17",
      borderRadius: "6px !important",
      [theme.breakpoints.down("sm")]: {
        width: "100% !important",
      },
    },
    formButton: {
      fontFamily: "Montserrat !important",
      fontWeight: "600 !important",
      width: "100%",
      height: "40px",
      color: "#252F40 !important",
      padding: "12px 20px !important",
      fontSize: "12px !important",
      border: "1px solid #FEDD17",
      color: "#000000 !important",
      lineHeight: "24px",
      textTransform: "capitalize !important",
      boxShadow: "6px 6px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",

      ["@media (max-width: 450px)"]: {
        marginTop: "10px",
        width: "100%",
        height: "40px",
        margin: "10px 0 !important",
        fontFamily: "Montserrat !important",
        fontWeight: "400 !important",
        fontStyle: "normal",
        fontSize: "12px !important",
        lineHeight: "24px",
        padding: "12px 20px !important",
        fontSize: "15px !important",
        background: "#ffffff !important",
        border: "1px solid #D9D9D9",
        color: "#000000 !important",
        textTransform: "capitalize !important",
        borderRadius: "6px !important",
        ["@media (max-width: 450px)"]: {
          width: "100%",
        },
      },
    },
    advisorDetials: {
      padding: "15px",
      width: "20rem",
      borderRadius: "10px",
      "& .detials-container": {
        width: "100%",
        height: "auto",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& h2": {
          fontSize: "14px",
          fontWeight: "600",
        },
        "& p": {
          fontSize: "14px",
          fontWeight: "300",
        },
      },
    },
    inputTextBar: {
      width: "100%",
      fontFamily: "Montserrat !important",
      height: "40px",
      borderRadius: "6px",
      "& .MuiOutlinedInput-root": {
        borderRadius: "6px",
        height: "40px",
        "& fieldset": {
          fontFamily: `Montserrat`,
          fontSize: "12px",
        },
        "& input": {
          fontFamily: `Montserrat`,
          fontSize: "12px",
        },
      },
    },
    inputTextArea: {
      width: "100%",
      fontFamily: "Montserrat !important",
      height: "80px",
      borderRadius: "6px",
      resize: "none",
      padding: "15px",
      fontSize: "12px",
      "& .MuiOutlinedInput-root": {
        borderRadius: "6px",
        height: "40px",
        "& fieldset": {
          fontFamily: `Montserrat`,
          fontSize: "12px",
        },
        "& input": {
          fontFamily: `Montserrat`,
          fontSize: "12px",
        },
      },
    },

    imageViewer: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "10px",
      overflow: "hidden",

      "& .image-container": {
        width: "60rem",
        height: "40rem",
        borderRadius: "10px",
        backgroundColor: "white",
      },
      "& .image-section": {
        padding: "15px",
        overflowY: "scroll",
        height: "36rem",
        display: "grid",
        gridTemplateColumns: "auto auto ",
        gap: "15px",

        "& img": {
          width: "100%",
          height: "100%",
        },
      },
    },

    paginationStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      ["@media (max-width: 500px)"]: {
        margin: "10px auto",
        width: "50%",
      },
    },
    paginationPage: {
      marginLeft: "7px",
      fontFamily: "Montserrat",
      ["@media (max-width: 500px)"]: {
        width: "27vw",
        margin: "0px",
      },
    },
    addIcon: {
      cursor: "pointer",
      "& label": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor:'pointer',
        height:'8rem',
        borderRadius:'100%',
        transition:'all .2s ease',
        
        width:'8rem',
        '&:hover':{
          backgroundColor:'#f5f5f5'
        },
        '&:active':{
          backgroundColor:'#ededed'
        },


        '& p':{
          fontSize:'16px',
          fontWeight:'bold',
          marginTop:'10px'
        }
      },
    },
    modalStyle: {
      position: "absolute",
      top: "50%",
      left: "50%",
      fontFamily: "Montserrat",
      transform: "translate(-50%, -50%)",
      width: "900px",
      backgroundColor: "#ffffff",
      boxShadow: 24,
      borderRadius: "10px",
      height: "500px",
      overflow: "hidden scroll",
      scrollbarWidth: "none",
      [theme.breakpoints.down("sm")]: {
        width: "90vw",
        minHeight: "70vh",
      },
    },
  })
);
