import React, { useState } from "react";
import {
  Typography,
  Paper,
  IconButton,
  Box,
  Grid,
  Button,
} from "@material-ui/core";
import IconButsUser from "../../common/Controls/iconButsUser";
import { Link, useSearchParams } from "react-router-dom";
import Sub from "../../common/Subheader/Sub";
import Carousel from "react-material-ui-carousel";
import { TXT_USERMANAGEMENT, TXT_USER_EDIT } from "../../utilitis/header";
import Controls from "../../common/Controls/Controls";
import BackbuttonIcon from "../../utilitis/BackbuttonIcon";
import HeadBar from "../../components/HeadBar/HeadBar";
import ForwardIcon from "../../utilitis/icons/ForwardIcon";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import useUsersQuery from "../../data/user/useUsersQuery";

import CustomLoader from "../../features/CustomLoader";

import makeStyles from "../../sharedFeatures/sharedClasses";
import userStyles from "./userStyles";
import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";

import "../DealerManagement/AddEditDealer/toast.css";
import { FindLink } from "../../sharedFeatures/FindLink";
import axios from "axios";
import OrderDetails from "./OrderDetails";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UserEdit = () => {
  const viewStyle = makeStyles();
  const styles = makeStyles();
  const userClass = userStyles();
  const navigate = useNavigate();
  const url = "AIzaSyCeViu0eoIfgK2TWKWUXCt2p_YWvFbtc7c";
  const API_URL = process.env.REACT_APP_API_URL;
  const [activeChild, setActiveChild] = React.useState(0);

  const [loading, setLoading] = React.useState(false);

  const [value, setValue] = React.useState(0);

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const { id, view } = useParams();

  useEffect(() => {
    if (view) {
      setValue(2);
    }
  }, [view]);

  const { data: usersData } = id ? useUsersQuery.users_details(id) : "";

  const { data: userVehilceData, isLoading: usersVehicleDataLoading } = id
    ? useUsersQuery.users_vehicle_details(id)
    : "";

  const {
    mutateAsync: editUser,
    isSuccess: editUserSuccess,
    isLoading,
  } = useUsersQuery.update_user_details();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [orderLoading, setOrderloading] = useState(false);

  const [orderdetails, setOrderDetails] = useState([]);
  let accessToken = localStorage.getItem("accessToken");
  const userid = usersData && usersData.data && usersData.data.id;
  const getOrderDetails = async () => {
    setOrderloading(true);
    let url = `${API_URL}/order/get-orders-by-userid/${userid}/`;
    try {
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      setOrderloading(false);
      // Handle successful response
      // console.log('Response:', response.data.data);
      setOrderDetails(response.data.data);
    } catch (error) {
      // Handle error
      setOrderloading(false);
      console.error("Error:", error);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 2) {
      getOrderDetails();
    }
  };

  const [firstname, setFirstName] = React.useState("");
  const [lastname, setLastName] = React.useState("");

  const [email, setEmail] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [address_line_one, setAddress_line_one] = React.useState("");
  const [address_line_two, setAddress_line_two] = React.useState(null);

  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [zip_code, setZip_code] = React.useState("");
  const [gst, setGst] = React.useState("");

  const [firstnameError, setFirstNameError] = React.useState("");
  const [lastnameError, setLastNameError] = React.useState("");
  const [latitude, setLatitude] = React.useState("");
  const [longitude, setlongitude] = React.useState("");

  const [emailError, setEmailError] = React.useState("");
  const [locationError, setLocationError] = React.useState("");
  const [address_line_oneError, setAddress_line_oneError] = React.useState("");
  const [address_line_twoError, setAddress_line_twoError] = React.useState("");

  const [cityError, setCityError] = React.useState("");
  const [stateError, setStateError] = React.useState("");
  const [zip_codeError, setZip_codeError] = React.useState("");
  const [gstError, setGstError] = React.useState("");
  React.useEffect(() => {
    if (usersData && usersData.data) {
      setFirstName(usersData.data.first_name);
      setLastName(usersData.data.last_name);

      setEmail(usersData.data.email);
      setLocation(usersData.data.address && usersData.data.address.land_mark);
      setAddress_line_one(
        usersData.data.address && usersData.data.address.addressLine
      );
      setLatitude(usersData.data.address && usersData.data.address.latitude);
      setlongitude(usersData.data.address && usersData.data.address.longitude);

      setAddress_line_two(
        usersData.data.address &&
        usersData.data.address.addressLine2 &&
        usersData.data.address.addressLine2
      );
      setCity(usersData.data.address && usersData.data.address.city);
      setState(usersData.data.address && usersData.data.address.state);
      setZip_code(usersData.data.address && usersData.data.address.pin_code);
      setGst(usersData.data.gst_number);
    }
  }, [usersData]);
  let link = FindLink("/administrator/users");

  const handleClickSubmit = async () => {
    let error = false;
    setFirstName(firstname && firstname.trim());
    setLastName(lastname && lastname.trim());
    setEmail(email && email.trim());

    if (firstname === "" || firstname === null) {
      setFirstNameError("First Name is required");
      error = true;
    } else if (!/^[a-zA-Z\s'.,]+$/i.test(firstname)) {
      setFirstNameError("First Name not valid");
      error = true;
    } else {
      setFirstNameError("");
    }

    if (lastname === "" || lastname === null) {
      setLastNameError("Last Name is required");
      error = true;
    } else if (!/^[a-zA-Z\s'.,]+$/i.test(lastname)) {
      setLastNameError("Last Name not valid");
      error = true;
    } else {
      setLastNameError("");
    }

    if (email === "" || email === null) {
      setEmailError("Email is required");
      error = true;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/i.test(email)) {
      setEmailError("Enter a valid email address");
      error = true;
    } else {
      setEmailError("");
    }

    // if (
    //   gst !== "" &&
    //   !/^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1}$/i.test(
    //     gst
    //   )
    // ) {
    //   setGstError("Invalid GST Number");
    //   error = true;
    // } else {
    //   setGstError("");
    // }

    if (location === "" || location === undefined || location === null) {
      setLocationError("Location is required");
      error = true;
    } else {
      setLocationError("");
    }
    if (
      address_line_one === "" ||
      address_line_one === null ||
      address_line_one === undefined
    ) {
      setAddress_line_oneError("Address is required");
      error = true;
    } else {
      setAddress_line_oneError("");
    }
    if (
      address_line_two === "" ||
      address_line_two === null ||
      address_line_two === undefined
    ) {
      setAddress_line_twoError("Address is required");
      error = true;
    } else {
      setAddress_line_twoError("");
    }
    if (city === "" || city === null || city === undefined) {
      setCityError("City is required");
      error = true;
    } else {
      setCityError("");
    }
    if (state === "" || state === null || state === undefined) {
      setStateError("State is required");
      error = true;
    } else {
      setStateError("");
    }
    if (zip_code === "" || zip_code === null || zip_code === undefined) {
      setZip_codeError("Zip code is required");
      error = true;
    } else {
      setZip_codeError("");
    }
    if (error) {
      return;
    }

    await editUser({
      id: id,
      first_name: firstname,
      last_name: lastname,

      email: email,
      address: {
        addressLine: address_line_one,
        addressLine2: address_line_two,
        latitude: latitude,
        longitude: longitude,
        city: city,
        state: state,
        pin_code: zip_code,
        land_mark: location,
      },
      gst_number: gst,
    }).then((res) => {
      toast.success(
        <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
          User updated successfully.
        </span>, {
        hideProgressBar: true,
        autoClose: 2200,
      }
      );
      navigate(link);
    });
  };
  //aimee

  React.useEffect(() => {
    if (editUserSuccess) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, [editUserSuccess]);

  const BLOCK_SPECIAL_REGEX = /^[0-9a-zA-Z \b]+$/;

  const ALPHA_DASH_WITH_SPACE_REGEX = /^[a-zA-Z\s]+$/;
  const ALPHA_DASH_WITH_SPCIAL = /^[a-zA-Z\s'.,]+$/;

  const alphaWithSpace = (event) => {
    let value = event.target.value;
    if (value !== "" && !ALPHA_DASH_WITH_SPACE_REGEX.test(value)) {
      event.preventDefault();
    }
  };

  const alphaWithspecial = (event) => {
    let value = event.target.value;
    if (value !== "" && !ALPHA_DASH_WITH_SPCIAL.test(value)) {
      event.preventDefault();
    }
  };

  const addressREstriction = (event) => {
    let regix = /^[a-zA-Z\s'.,]+$/;
    let value = event.target.value;
    if (value !== "" && !regix.test(value)) {
      event.preventDefault();
    }
  };

  const preventSpecial = (event) => {
    let value = event.target.value;
    if (value !== "" && !BLOCK_SPECIAL_REGEX.test(value)) {
      event.preventDefault();
    }
  };

  const changeChild = React.useCallback(
    (e) => {
      if (e.key === "ArrowLeft") {
        setActiveChild((a) =>
          a - 1 < 0 ? userVehilceData.data.data.length - 1 : a - 1
        );
      } else if (e.key === "ArrowRight") {
        setActiveChild((a) =>
          a + 1 > userVehilceData.data.data.length - 1 ? 0 : a + 1
        );
      }
    },
    [userVehilceData && userVehilceData.data && userVehilceData.data.data]
  );
  // React.useEffect(() => {
  //   document.addEventListener("keydown", changeChild);

  //   return function cleanup() {
  //     document.removeEventListener("keydown", changeChild);
  //   };
  // });

  const ALPHA_NUMERIC_NO_SPACE = /^[a-zA-Z0-9]*$/;

  return (
    <>
      <HeadBar title={TXT_USERMANAGEMENT} />
      <div className={viewStyle.divStyle}>
        <Paper className={viewStyle.parentContainer} elevation="6">
          <div className={viewStyle.subHeaderParent}>
            <Box sx={{ display: { xs: "none", md: "inline" } }}>
              <Link to={link}>
                <IconButton className={styles.backButtonParent}>
                  <BackbuttonIcon />
                </IconButton>
              </Link>
            </Box>
            <Sub data={TXT_USER_EDIT} />
          </div>
          {!loading ? (
            <>
              <Grid
                container
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Grid item sm={12} md={6}>
                  <Grid container sm={12}>
                    <Grid item xs={12} sm={2} md={3} lg={3}>
                      <Typography className={userClass.viewlabel}>
                        First Name
                      </Typography>
                    </Grid>

                    <Grid item xs={12} md={7}>
                      <Controls.StyledInput
                        value={firstname}
                        placeholder={"First Name"}
                        onChange={(e) => setFirstName(e.target.value)}
                        variant="outlined"
                        className={userClass.inputTextBar}
                        inputProps={{ maxLength: 45 }}
                        onKeyPress={alphaWithspecial}
                        error={firstnameError}
                      />
                    </Grid>

                    <Grid item xs={12} sm={2} md={3} lg={3}>
                      <Typography className={userClass.viewlabel}>
                        Last Name
                      </Typography>
                    </Grid>

                    <Grid item xs={12} md={7}>
                      <Controls.StyledInput
                        value={lastname}
                        placeholder={"Last Name"}
                        onChange={(e) => setLastName(e.target.value)}
                        variant="outlined"
                        className={userClass.inputTextBar}
                        inputProps={{ maxLength: 45 }}
                        onKeyPress={alphaWithspecial}
                        error={lastnameError}
                      />
                    </Grid>

                    <Grid item xs={12} sm={2} md={3} lg={3}>
                      <Typography className={userClass.viewlabel}>
                        Email address
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <Controls.StyledInput
                        value={email}
                        placeholder={"Email address"}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        onKeyPress={(e) => {
                          if (e.code === "Space") e.preventDefault();
                        }}
                        variant="outlined"
                        className={userClass.inputTextBar}
                        type="email"
                        error={emailError}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} md={3} lg={3}>
                      <Typography className={userClass.viewlabel}>
                        GST number
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <Controls.StyledInput
                        variant="outlined"
                        placeholder={"GST Number"}
                        className={userClass.inputTextBar}
                        value={gst}
                        // onChange={(e) => setGst(e.target.value)}
                        onChange={(event) => {
                          const value = event.target.value;
                          if (
                            value !== "" &&
                            !ALPHA_NUMERIC_NO_SPACE.test(value)
                          ) {
                            return;
                          }
                          setGstError("");
                          setGst(event.target.value);
                        }}
                        error={gstError}
                        inputProps={{ maxLength: 15 }}
                      />
                    </Grid>
                    <Grid iitem xs={12} sm={2} md={3} lg={3}>
                      <Typography className={userClass.viewlabel}>
                        Location
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      {/* <Controls.StyledInput
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    className={userClass.inputTextBar}
                  /> */}
                      <Autocomplete
                        className={userClass.geolocation}
                        apiKey={url}
                        style={{
                          borderColor:
                            locationError.length > 0 ? "red" : "gray",
                          borderWidth: 1,
                          borderStyle: "solid",
                        }}
                        options={{
                          types: [
                            "neighborhood",
                            "locality",
                            "sublocality",
                            "postal_code",
                            "point_of_interest",
                            // "ALL",
                          ],
                          componentRestrictions: { country: "in" },
                          fields: [
                            "address_components",
                            "geometry",
                            "icon",
                            "name",
                            "place_id",
                            "formatted_address",
                            // "ALL",
                          ],
                        }}
                        name="location"
                        onPlaceSelected={(place) => {
                          setLocationError("");
                          setLocation(place.formatted_address);
                          const state = place.formatted_address
                            .split(",")
                          [
                            place.formatted_address.split(",").length - 2
                          ].replace(/[0-9]/g, "");

                          const city = place.formatted_address
                            .split(",")
                          [
                            place.formatted_address.split(",").length - 3
                          ].replace(/[0-9]/g, "");
                          const lat = place.geometry.location.lat();
                          const lng = place.geometry.location.lng();
                          setLatitude(lat);
                          setlongitude(lng);
                          setState(state);
                          setCity(city);
                          axios
                            .get(
                              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${place.geometry.location.lat()},${place.geometry.location.lng()}&key=${process.env.REACT_APP_GEOCODE_API_KEY
                              }
                          `
                            )
                            .then((res) => {
                              setZip_code(
                                res.data.results[0].address_components[
                                  res.data.results[0].address_components
                                    .length - 1
                                ].long_name
                              );
                            });
                        }}
                        onKeyPress={preventSpecial}
                        onChange={async (place) => {
                          if (place.formatted_address === undefined) {
                            setLocationError("Invalid Location");
                          }

                          setLocation(place.formatted_address);
                        }}
                        value={location}
                      />
                      {
                        <span
                          style={{
                            color: "red",
                            fontSize: "12px",
                            fontFamily: "Montserrat",
                            display: "block",
                          }}
                        >
                          {locationError}
                        </span>
                      }
                    </Grid>
                    <Grid item xs={12} sm={2} md={3} lg={3}>
                      <Typography className={userClass.viewlabel}>
                        Address
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} spacing={3}>
                      <Grid
                        container
                        xs={12}
                        spacing={isSmall ? 0 : 3}
                        style={{ marginBottom: isSmall ? "5px" : "" }}
                      >
                        <Grid item xs={6}>
                          <Controls.TextArea
                            name="address_line_one"
                            placeholder={"Enter Address Line 1"}
                            type="text"
                            value={address_line_one}
                            height={"75px"}
                            padding={"10px"}
                            maxRows={4}
                            fontSize={"14px"}
                            borderRadius={5}
                            onChange={(e) => {
                              if (
                                /^[A-Za-z0-9\s.,#'&/-]*$/.test(e.target.value)
                              ) {
                                setAddress_line_one(e.target.value);
                              } else {
                                setAddress_line_one(
                                  e.target.value.substring(
                                    0,
                                    e.target.value.length - 1
                                  )
                                );
                                return;
                              }
                            }}
                            className={userClass.addressBar}
                            error={address_line_oneError}
                          />
                          <span
                            style={{
                              color: "red",
                              fontSize: "12px",
                              fontFamily: "Montserrat",
                              display: "block",
                            }}
                          >
                            {address_line_oneError}
                          </span>
                        </Grid>
                        <Grid item xs={6}>
                          <Controls.TextArea
                            className={userClass.addressBar}
                            name="address_line_two"
                            placeholder={"Enter Address Line 2"}
                            height={"75px"}
                            padding={"10px"}
                            borderRadius={5}
                            maxRows={4}
                            fontSize={"14px"}
                            type="text"
                            value={address_line_two}
                            onChange={(e) => {
                              if (
                                /^[A-Za-z0-9\s.,#'&/-]*$/.test(e.target.value)
                              ) {
                                setAddress_line_two(e.target.value);
                              } else {
                                setAddress_line_two(
                                  e.target.value.substring(
                                    0,
                                    e.target.value.length - 1
                                  )
                                );
                                return;
                              }
                            }}
                            error={address_line_twoError}
                          />
                          {
                            <span
                              style={{
                                color: "red",
                                fontSize: "12px",
                                fontFamily: "Montserrat",
                                display: "block",
                              }}
                            >
                              {address_line_twoError}
                            </span>
                          }
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        xs={12}
                        spacing={isSmall ? 0 : 3}
                        style={{ marginBottom: isSmall ? "5px" : "" }}
                      >
                        <Grid item xs={6}>
                          <Controls.StyledInput
                            name="city"
                            placeholder={"City"}
                            type="text"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            className={userClass.addressBar}
                            error={cityError}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Controls.StyledInput
                            name="state"
                            placeholder={"State"}
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                            type="text"
                            className={userClass.addressBar}
                            error={stateError}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={2}
                      md={3}
                      lg={3}
                      style={{ marginTop: "12px" }}
                    >
                      <Typography className={userClass.viewlabel}>
                        Country & Zip Code
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={8}
                      spacing={3}
                      style={{ marginTop: "12px" }}
                    >
                      <Grid
                        container
                        xs={12}
                        spacing={isSmall ? 0 : 3}
                        style={{ marginBottom: isSmall ? "5px" : "" }}
                      >
                        <Grid item xs={6}>
                          <Controls.StyledInput
                            name="country"
                            placeholder="Country"
                            value="India"
                            disabled
                            className={userClass.addressBar}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Controls.StyledInput
                            name="pincode"
                            placeholder={"Pincode"}
                            type="number"
                            value={zip_code}
                            onChange={(e) => setZip_code(e.target.value)}
                            className={userClass.addressBar}
                            error={zip_codeError}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={2} md={3} lg={3}>
                      <Typography className={userClass.viewBtnlabel}>
                        Car Details
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      {value === 0 ? (
                        <div className={userClass.userButtonContainer2}>
                          <div
                            style={{
                              marginBlock: "auto",
                              marginLeft: "10px",
                              fontSize: "12px",
                              color: "#252F40 !important",
                              fontFamily: "Montserrat",
                            }}
                          >
                            Click to View
                          </div>
                          <Button
                            variant="outlined"
                            className={userClass.userButton2}
                            endIcon={<ForwardIcon />}
                            classes={{
                              iconSizeMedium: userClass.iconSizeMedium,
                            }}
                            size="medium"
                            onClick={() => handleChange("_", 0)}
                          ></Button>
                        </div>
                      ) : (
                        <div className={userClass.userButtonContainer}>
                          <div
                            style={{
                              marginBlock: "auto",
                              marginLeft: "10px",
                              fontSize: "12px",
                              color: "#252F40 !important",
                              fontFamily: "Montserrat",
                            }}
                          >
                            Click to View
                          </div>
                          <Button
                            variant="outlined"
                            className={userClass.userButton2}
                            endIcon={<ForwardIcon />}
                            classes={{
                              iconSizeMedium: userClass.iconSizeMedium,
                            }}
                            size="medium"
                            onClick={() => handleChange("_", 0)}
                          ></Button>
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={2} md={3} lg={3}>
                      <Typography className={userClass.viewBtnlabel}>
                        Images
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      {value === 1 ? (
                        <div className={userClass.userButtonContainer2}>
                          <div
                            style={{
                              marginBlock: "auto",
                              marginLeft: "10px",
                              fontSize: "12px",
                              color: "#252F40 !important",
                              fontFamily: "Montserrat",
                            }}
                          >
                            Click to View
                          </div>
                          <Button
                            variant="outlined"
                            className={userClass.userButton2}
                            endIcon={<ForwardIcon />}
                            classes={{
                              iconSizeMedium: userClass.iconSizeMedium,
                            }}
                            size="medium"
                            onClick={() => handleChange("_", 1)}
                          ></Button>
                        </div>
                      ) : (
                        <div className={userClass.userButtonContainer}>
                          <div
                            style={{
                              marginBlock: "auto",
                              marginLeft: "10px",
                              fontSize: "12px",
                              color: "#252F40 !important",
                              fontFamily: "Montserrat",
                            }}
                          >
                            Click to View
                          </div>
                          <Button
                            variant="outlined"
                            className={userClass.userButton2}
                            endIcon={<ForwardIcon />}
                            classes={{
                              iconSizeMedium: userClass.iconSizeMedium,
                            }}
                            size="medium"
                            onClick={() => handleChange("_", 1)}
                          ></Button>
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={2} md={3} lg={3}>
                      <Typography className={userClass.viewBtnlabel}>
                        Order Details
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      {value === 2 ? (
                        <div className={userClass.userButtonContainer2}>
                          <div
                            style={{
                              marginBlock: "auto",
                              marginLeft: "10px",
                              fontSize: "12px",
                              color: "#252F40 !important",
                              fontFamily: "Montserrat",
                            }}
                          >
                            Click to View
                          </div>
                          <Button
                            variant="outlined"
                            className={userClass.userButton2}
                            endIcon={<ForwardIcon />}
                            classes={{
                              iconSizeMedium: userClass.iconSizeMedium,
                            }}
                            size="medium"
                            onClick={() => handleChange("_", 2)}
                          ></Button>
                        </div>
                      ) : (
                        <div className={userClass.userButtonContainer}>
                          <div
                            style={{
                              marginBlock: "auto",
                              marginLeft: "10px",
                              fontSize: "12px",
                              color: "#252F40 !important",
                              fontFamily: "Montserrat",
                            }}
                          >
                            Click to View
                          </div>
                          <Button
                            variant="outlined"
                            className={userClass.userButton2}
                            endIcon={<ForwardIcon />}
                            classes={{
                              iconSizeMedium: userClass.iconSizeMedium,
                            }}
                            size="medium"
                            onClick={() => handleChange("_", 2)}
                          ></Button>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={12} md={6} className={userClass.tabParent}>
                  <Card sx={{ width: "100%" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="simple tabs example"
                      style={{ paddingInline: "30px" }}
                      TabIndicatorProps={{ style: { background: "#FEDD17" } }}
                    >
                      <Tab
                        label="Your Cars"
                        className={userClass.tabLabel}
                        style={{ color: value === 0 ? "#FEDD17" : "black" }}
                        {...a11yProps(0)}
                      />
                      <Tab
                        label="Images"
                        className={userClass.tabLabel}
                        style={{ color: value === 1 ? "#FEDD17" : "black" }}
                        {...a11yProps(1)}
                      />
                      <Tab
                        label="Order Details"
                        className={userClass.tabLabel}
                        style={{ color: value === 2 ? "#FEDD17" : "black" }}
                        {...a11yProps(2)}
                      />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                      <Carousel
                        index={activeChild}
                        navButtonsAlwaysVisible={
                          userVehilceData &&
                          userVehilceData.data &&
                          userVehilceData.data.data.length > 1
                        }
                        autoPlay={false}
                        indicators={false}
                        swipe={true}
                        navButtonsProps={{
                          style: {
                            backgroundColor: "#FFFFFF",
                            borderRadius: 0,
                            color: "black",
                            cursor: "pointer",
                            zIndex: 9999999,
                          },
                        }}
                      >
                        {userVehilceData &&
                          userVehilceData.data &&
                          userVehilceData.data.data.map((_, idx) => {
                            return (
                              <Grid item sm={12} md={12}>
                                <CardMedia
                                  component="img"
                                  height="220px"
                                  objectFit="scale-down"
                                  image={`${BASE_URL}media/models/${_.imagename}`}
                                  alt="Sorry No image !"
                                />

                                <CardContent>
                                  <div className={userClass.cardTitle}>
                                    <div>
                                      <Typography
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontSize: "14px",
                                          fontWeight: "700",
                                          color: "#444445",
                                        }}
                                      >
                                        {_.vehicle_id.make +
                                          " " +
                                          _.vehicle_id.model}
                                      </Typography>
                                      <Typography
                                        align="center"
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontSize: "12px",
                                          fontWeight: "700",
                                          color: "#989898",
                                        }}
                                      >
                                        {_.vehicle_id.fuel_type}
                                      </Typography>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      marginTop: "30px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "170%",
                                      }}
                                    >
                                      <Typography
                                        align="start"
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontSize: "12px",
                                          fontWeight: "600",
                                          color: "#444445",
                                          textAlign: "start",
                                        }}
                                      >
                                        Reg. Year
                                      </Typography>

                                      <Typography
                                        align="start"
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontSize: "14px",
                                          fontWeight: "400",
                                          color: "#989898",
                                          marginTop: "10px",
                                          textAlign: "start",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        {_.basic_details &&
                                          _.basic_details.register_year}
                                      </Typography>
                                    </div>
                                    <div className={userClass.regNoDiv}>
                                      <Typography
                                        align="start"
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontSize: "12px",
                                          fontWeight: "600",
                                          color: "#444445",
                                          textAlign: "start",
                                        }}
                                      >
                                        Reg No
                                      </Typography>
                                      <Typography
                                        align="start"
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontSize: "14px",
                                          fontWeight: "400",
                                          color: "#989898",
                                          marginTop: "10px",
                                        }}
                                      >
                                        {_.basic_details &&
                                          _.basic_details.register_number}
                                      </Typography>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <div>
                                      <Typography
                                        align="start"
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontSize: "12px",
                                          fontWeight: "600",
                                          color: "#444445",
                                        }}
                                      >
                                        Fuel
                                      </Typography>

                                      <Typography
                                        align="start"
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontSize: "14px",
                                          fontWeight: "400",
                                          color: "#989898",
                                          marginTop: "10px",
                                        }}
                                      >
                                        {_.vehicle_id.fuel_type}
                                      </Typography>
                                    </div>
                                    <div>
                                      <Typography
                                        align="start"
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontSize: "12px",
                                          fontWeight: "600",
                                          color: "#444445",
                                        }}
                                      >
                                        Insurance Expiry
                                      </Typography>
                                      <Typography
                                        align="start"
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontSize: "14px",
                                          fontWeight: "400",
                                          color: "#989898",
                                          marginTop: "10px",
                                        }}
                                      >
                                        {_.basic_details &&
                                          _.basic_details.insurance_date}
                                      </Typography>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <div>
                                      <Typography
                                        align="start"
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontSize: "12px",
                                          fontWeight: "600",
                                          color: "#444445",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        PUC Expiry Date
                                      </Typography>

                                      <Typography
                                        align="start"
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontSize: "14px",
                                          fontWeight: "400",
                                          color: "#989898",
                                          marginTop: "10px",
                                        }}
                                      >
                                        {_.basic_details && _.basic_details.puc}
                                      </Typography>
                                    </div>
                                    <div>
                                      <Typography
                                        align="start"
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontSize: "12px",
                                          fontWeight: "600",
                                          color: "#444445",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        PUC Expiry Date
                                      </Typography>
                                      <Typography
                                        align="start"
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontSize: "14px",
                                          fontWeight: "400",
                                          color: "#989898",
                                          marginTop: "10px",
                                        }}
                                      >
                                        {_.basic_details && _.basic_details.puc}{" "}
                                      </Typography>
                                    </div>
                                  </div>
                                </CardContent>
                              </Grid>
                            );
                          })}
                      </Carousel>
                      {userVehilceData &&
                        userVehilceData.data &&
                        userVehilceData.data.data &&
                        userVehilceData.data.data.length === 0 && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <lottie-player
                              src="https://assets8.lottiefiles.com/packages/lf20_agnejizn.json"
                              background="transparent"
                              speed="1"
                              style={{ width: "300px", height: "300px" }}
                              loop
                              autoplay
                            ></lottie-player>
                          </div>
                        )}

                      {usersVehicleDataLoading && <CustomLoader />}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <Grid container>
                        {userVehilceData &&
                          userVehilceData.data &&
                          userVehilceData.data.data &&
                          userVehilceData.data.data.map((_) => {
                            return (
                              <Grid item xs={12} md={6}>
                                <CardMedia
                                  component="img"
                                  width="400px"
                                  image={`${BASE_URL}media/models/${_.imagename}`}
                                  // image={require(`${BASE_URL}media/models/${_.imagename}`)}
                                  alt="Sorry No image !"
                                />
                              </Grid>
                            );
                          })}
                      </Grid>
                    </TabPanel>
                    <TabPanel
                      value={value}
                      index={2}
                      style={{ textAlign: "center" }}
                    >
                      {orderLoading && orderdetails.length === 0 && (
                        <span
                          style={{ fontFamily: "Montserrat", fontSize: "14px" }}
                        >
                          Loading...
                        </span>
                      )}

                      {orderdetails.length === 0 && !orderLoading && (
                        <span
                          style={{ fontFamily: "Montserrat", fontSize: "14px" }}
                        >
                          No orders yet!
                        </span>
                      )}
                      {orderdetails.length !== 0 && (
                        <OrderDetails data={orderdetails} />
                      )}
                    </TabPanel>
                  </Card>
                </Grid>
              </Grid>

              <Grid
                container
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: isSmall ? "20px" : "40px",
                  marginTop: "30px",
                }}
              >
                <Grid item>
                  <div className={viewStyle.formIcon}>
                    <IconButsUser
                      onClick={handleClickSubmit}
                      ibtname="OK"
                      className={userClass.formButtonAdd}
                      variant="outlined"
                    />
                  </div>
                </Grid>
                <Grid item>
                  <div className={viewStyle.formIcon}>
                    <Link to={link}>
                      <IconButsUser
                        ibtname="Cancel"
                        className={userClass.formButtonAdd}
                        variant="outlined"
                      />
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "100px",
              }}
            >
              <lottie-player
                src="https://assets8.lottiefiles.com/packages/lf20_agnejizn.json"
                background="transparent"
                speed="1"
                style={{ width: "300px", height: "300px" }}
                loop
                autoplay
              ></lottie-player>
            </div>
          )}
        </Paper>
      </div>
    </>
  );
};

export default UserEdit;
