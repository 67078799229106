import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import styles from "../../../sharedFeatures/sharedClasses";
import Controls from "../../../common/Controls/Controls";
import { STEPPER_NEXT } from "../../../utilitis/header";
import IconButs from "../../../common/Controls/IconButs";
import dealerStyles from "../dealerStyle";
import Autocomplete from "react-google-autocomplete";
import useAddDealer from "../../../data/dealer/useAddDealer";
import { Link, useSearchParams } from "react-router-dom";
import makeStyles from "../../../sharedFeatures/sharedClasses";
import { FindLink } from "../../../sharedFeatures/FindLink";

const BasicDetails = ({
  handleInputChange,
  values,
  handleNext,

  id,
  handleLocation,
  handleCordinates,
  location,
  setDirty,
  setValues,
}) => {
  const url = "AIzaSyCeViu0eoIfgK2TWKWUXCt2p_YWvFbtc7c";
  const dealerClass = dealerStyles();
  const [userType, setUserType] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPinCode] = useState("");
  const [locality, setLocality] = useState("");
  const [errors, setErrors] = useState(false);
  const [locationErr, setLocationErr] = useState("");

  const ALPHA_NUMERIC_NO_SPACE = /^[0-9]+$/;

  const ALPHA_DASH_REGEX = /^[a-zA-Z\\s]*$/;

  const BLOCK_SPECIAL_REGEX = /^[0-9a-zA-Z \b]+$/;

  const ALPHA_DASH_WITH_SPACE_REGEX = /^[a-zA-Z\s]+$/;

  const customStyle = styles();
  //aimee
  let link = FindLink("/administrator/dealers");

  const typeofUsers = [{ id: "Dealer", name: "Dealer" }];

  const {
    mutate: emailCheck,
    data: emailExistData,
    isSuccess: successMsg,
    isError: errorMsg,
    error: errorData,
  } = useAddDealer.email_exist_not();

  const {
    mutate: phoneCheck,
    data: phoneExistData,
    isSuccess: successphnMsg,
    isError: errorphnMsg,
    error: errorphnData,
  } = useAddDealer.phone_exist_not();

  const phoneExistOrnot = async (e) => {
    await phoneCheck({
      phone: values.phone,
    });
  };

  const emailExistOrnot = async (e) => {
    await emailCheck({
      email: values.email,
    });
  };

  // emailExistData && emailExistData.success === "False"

  // errorData.response.data.success === "True"

  useEffect(() => {
    if (errorData && errorData.response.data.success === "True") {
      setEmail(errorData && errorData.response.data.message);
    } else if (errorphnData && errorphnData.response.data.success === "True") {
      setPhone(errorphnData && errorphnData.response.data.message);
    } else if (
      emailExistData &&
      emailExistData.success === "False" &&
      phoneExistData &&
      phoneExistData.success === "False"
    ) {
      handleNext();
    }
  }, [emailExistData, phoneExistData, errorData, errorphnData]);

  const submitFormData = (e) => {
    let err = undefined;
    e.preventDefault();
    if (!values.user_type) {
      setUserType("User Type is required");
      err = true;
    }
    if (!values.shop_name) {
      setCompanyName("Company name is required");
      err = true;
    }
    if (!values.first_name) {
      setFirstName("First name is required");
      err = true;
    }
    if (!values.last_name) {
      setLastName("Last name is required");
      err = true;
    }

    if (!values.email) {
      setEmail("Email is required");
      err = true;
    }
    if (
      !/^[a-z0-9]([a-z0-9_\-\.]*)@([a-z0-9_\-\.]*)(\.[a-z]{2,4}(\.[a-z]{2}){0,2})$/i.test(
        values.email
      )
    ) {
      setEmail("Enter a valid email address");
      err = true;
    }
    if (!values.phone) {
      setPhone("Phone number is required");
      err = true;
    }
    if (values.phone) {
      if (values.phone.length > 10 || values.phone.length < 10) {
        setPhone("Invalid Phone Number");
        err = true;
      }
    }

    if (!location || location.length === 0) {
      setLocationErr("Enter a valid location");
      err = true;
    }

    if (!values.address_line_one) {
      setAddress("Address is required");
      err = true;
    }

    // if (!/^[^\s][a-zA-Z0-9 .,#;:'-]{1,46}$/i.test(values.address)) {
    //   setAddress("Address not clear");
    //   err = true;
    // }
    if (!values.city) {
      setCity("City is required");
      err = true;
    }
    if (!/^[a-zA-Z ]{1,45}$/i.test(values.city)) {
      setCity("Invalid city");
      err = true;
    }
    if (!values.locality) {
      setLocality("locality is required");
      err = true;
    }

    if (!values.pincode) {
      setPinCode("Enter Pincode");
      err = true;
    }
    if (values.pincode) {
      if (values.pincode.length > 6 || values.pincode.length < 6) {
        setPinCode("Invalid Pincode");
        err = true;
      }
    }

    if (!err) {
      handleValidate();
    }

    // handleNext()
  };

  const handleValidate = () => {
    if (
      userType === "" &&
      firstName === "" &&
      email === "" &&
      phone === "" &&
      pincode === "" &&
      address === "" &&
      city === "" &&
      locationErr === ""
    ) {
      if (id) handleNext();
      if (!id) emailExistOrnot();
      if (!id) phoneExistOrnot();
      // setErrors(false);
    }
  };

  // useEffect(() => {
  //   if (!errors) {
  //     if (
  //       userType === "" &&
  //       firstName === "" &&
  //       email === "" &&
  //       phone === "" &&
  //       pincode === "" &&
  //       address === "" &&
  //       city === "" &&
  //       locationErr === ""
  //     ) {
  //       if (id) handleNext();
  //       if (!id) emailExistOrnot();
  //       if (!id) phoneExistOrnot();
  //       setErrors(false);
  //     }
  //   }
  // }, [errors]);

  document.addEventListener("wheel", function (event) {
    if (document.activeElement.type === "number") {
      document.activeElement.blur();
    }
  });
  const customStylem = makeStyles();
  const handleChange = (event) => {
    // handleInputChange(event);
    setErrors(false);
    let targetName = event.target.name;
    if (targetName == "user_type") {
      if (
        event.target.value === "Dealer" ||
        event.target.value === "Technician"
      ) {
        setUserType("");
      }
    }
    if (targetName === "shop_name") {
      if (event.target.value.length < 120) {
        handleInputChange(event);
        setFirstName("");
      } else {
        setValues({
          ...values,
          shop_name: event.target.value.substring(
            0,
            event.target.value.length - 1
          ),
        });
      }
    }

    if (targetName === "first_name") {
      if (/^[a-z ,.'-]+$/i.test(event.target.value)) {
        if (event.target.value.length < 46) {
          handleInputChange(event);
          setFirstName("");
        }
      } else {
        setValues({
          ...values,
          first_name: event.target.value.substring(
            0,
            event.target.value.length - 1
          ),
        });
      }
    }
    if (targetName === "last_name") {
      if (/^[a-z ,.'-]+$/i.test(event.target.value)) {
        if (event.target.value.length < 46) {
          handleInputChange(event);
          setLastName("");
        }
      } else {
        setValues({
          ...values,
          last_name: event.target.value.substring(
            0,
            event.target.value.length - 1
          ),
        });
      }
    }
    if (targetName === "email") {
      if (event.target.value.length < 60) {
        handleInputChange(event);
        setEmail("");
      } else {
        setValues({
          ...values,
          email: event.target.value.substring(0, event.target.value.length - 1),
        });
      }
    }
    if (targetName === "phone") {
      if (/^[0-9]+$/.test(event.target.value)) {
        if (event.target.value.length < 11) {
          handleInputChange(event);
          setPhone("");
        }
      } else {
        return setValues({
          ...values,
          phone: event.target.value.substring(0, event.target.value.length - 1),
        });

        // return false;
      }
    }
    if (targetName === "address_line_one") {
      if (/^[a-zA-Z0-9 .,-/&_]+$/.test(event.target.value)) {
        handleInputChange(event);
        setAddress("");
      } else {
        setValues({
          ...values,
          address_line_one: event.target.value.substring(
            0,
            event.target.value.length - 1
          ),
        });

        // return false;
      }
    }
    if (targetName === "address_line_two") {
      if (/^[a-zA-Z0-9 .,-/&_]+$/.test(event.target.value)) {
        handleInputChange(event);
        setAddress("");
      } else {
        setValues({
          ...values,
          address_line_two: event.target.value.substring(
            0,
            event.target.value.length - 1
          ),
        });

        // return false;
      }
    }
    if (targetName === "pincode") {
      if (/^[0-9]+$/.test(event.target.value)) {
        if (event.target.value.length < 7) {
          handleInputChange(event);
          setPinCode("");
        }
      } else {
        setValues({
          ...values,
          pincode: event.target.value.substring(
            0,
            event.target.value.length - 1
          ),
        });

        // return false;
      }
    }
    if (targetName === "city") {
      if (BLOCK_SPECIAL_REGEX.test(event.target.value)) {
        handleInputChange(event);
        setCity("");
      } else {
        setValues({
          ...values,
          city: event.target.value.substring(0, event.target.value.length - 1),
        });
        // return false;
      }
    }
    if (targetName === "locality") {
      if (/^[a-zA-Z0-9 .,/&_-]+$/.test(event.target.value)) {
        handleInputChange(event);
        setLocality("");
      } else {
        setValues({
          ...values,
          locality: event.target.value.substring(
            0,
            event.target.value.length - 1
          ),
        });
        // return false;
      }
    }
  };

  const preventSpaceAndSpecialWithAlphaNumeric = (event) => {
    let value = event.target.value;
    if (value !== "" && !ALPHA_NUMERIC_NO_SPACE.test(value)) {
      event.preventDefault();
    }
    if (event.nativeEvent.code === "Space") {
      event.preventDefault();
    }
  };

  const preventSpecial = (event) => {
    let value = event.target.value;
    if (value !== "" && !BLOCK_SPECIAL_REGEX.test(value)) {
      event.preventDefault();
    }
  };

  const alphaWithSpace = (event) => {
    let value = event.target.value;
    if (value !== "" && !ALPHA_DASH_WITH_SPACE_REGEX.test(value)) {
      event.preventDefault();
    }
  };

  return (
    <>
      <Grid container alignItems="center" spacing={1} lg={6}>
        <Grid item xs={12} sm={2} md={5} lg={5}>
          <Typography className={dealerClass.label}>
            Entity Type
            <span className={dealerClass.required}>*</span>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10} md={7} lg={7}>
          <Controls.SelectBox
            className={dealerClass.basicSelectBox}
            name="user_type"
            label="Select Entity type"
            value={values.user_type}
            options={typeofUsers}
            disabled={id ? true : false}
            onChange={(e) => {
              handleInputChange(e);
              setUserType("");
            }}
            error={userType}
          />
        </Grid>
        <Grid item xs={12} sm={2} md={5} lg={5} style={{ marginBottom: "5px" }}>
          <Typography className={dealerClass.label}>
            Company Name
            <span className={dealerClass.required}>*</span>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10} md={7} lg={7}>
          <Controls.InputField
            name="shop_name"
            type="text"
            placeholder="Company Name"
            value={values.shop_name}
            className={dealerClass.stepperFormTextBar}
            onInput={(event) => {
              const value = event.target.value;
              const regex = /^[a-zA-Z0-9 ,-/'#]*$/;
              if (!regex.test(value)) {
                event.target.value = value.replace(/[^a-zA-Z0-9 ,-/'#]/g, "");
              }
            }}
            onChange={(event) => {
              setCompanyName("");
              handleChange(event);
            }}
            error={companyName}
            inputProps={{ maxLength: 120 }}
          />
        </Grid>
        <Grid item xs={12} sm={2} md={5} lg={5} style={{ marginBottom: "5px" }}>
          <Typography className={dealerClass.label}>
            First Name
            <span className={dealerClass.required}>*</span>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10} md={7} lg={7}>
          <Controls.InputField
            name="first_name"
            placeholder="First name"
            type="text"
            value={values.first_name}
            inputProps={{ maxLength: 45 }}
            className={dealerClass.stepperFormTextBar}
            onKeyPress={alphaWithSpace}
            onChange={(e) => {
              handleChange(e);
            }}
            error={firstName}
          />
        </Grid>
        <Grid item xs={12} sm={2} md={5} lg={5} style={{ marginBottom: "5px" }}>
          <Typography className={dealerClass.label}>
            Last Name
            <span className={dealerClass.required}>*</span>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10} md={7} lg={7}>
          <Controls.InputField
            name="last_name"
            placeholder="Last name"
            type="text"
            value={values.last_name}
            inputProps={{ maxLength: 45 }}
            className={dealerClass.stepperFormTextBar}
            onKeyPress={alphaWithSpace}
            onChange={(e) => {
              handleChange(e);
            }}
            error={lastName}
          />
        </Grid>
        <Grid item xs={12} sm={2} md={5} lg={5} style={{ marginBottom: "5px" }}>
          <Typography className={dealerClass.label}>
            Contact Email
            <span className={dealerClass.required}>*</span>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10} md={7} lg={7}>
          <Controls.InputField
            name="email"
            type="text"
            placeholder="example@email.com"
            onInput={(event) => {
              const value = event.target.value;
              const regex = /^[a-zA-Z0-9@._]*$/;
              if (!regex.test(value)) {
                event.target.value = value.replace(/[^a-zA-Z0-9@._]/g, "");
              }
            }}
            value={values.email}
            className={dealerClass.stepperFormTextBar}
            onChange={(e) => {
              handleChange(e);
            }}
            error={email}
            inputProps={{ maxLength: 60 }}
          />
        </Grid>
        <Grid item xs={12} sm={2} md={5} lg={5} style={{ marginBottom: "5px" }}>
          <Typography className={dealerClass.label}>
            Contact Mobile <span className={dealerClass.required}>*</span>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10} md={7} lg={7}>
          <Controls.InputField
            name="phone"
            placeholder="Mobile"
            type="number"
            onKeyPress={preventSpaceAndSpecialWithAlphaNumeric}
            value={values.phone}
            inputProps={{ maxLength: 10 }}
            className={dealerClass.stepperFormTextBar}
            onChange={(e) => {
              handleChange(e);
            }}
            error={phone}
          />
        </Grid>
        <Grid item xs={12} sm={2} md={5} lg={5}>
          <Typography className={dealerClass.label}>
            Location <span className={dealerClass.required}>*</span>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10} md={7} lg={7}>
          <Autocomplete
            className={dealerClass.geolocation}
            apiKey={url}
            style={{
              borderColor: locationErr.length > 0 ? "red" : "gray",
              borderWidth: 1,
              borderStyle: "solid",
            }}
            options={{
              types: ["geocode", "establishment"],
              componentRestrictions: { country: "in" },
              fields: [
                "address_components",
                "geometry",
                "icon",
                "name",
                "place_id",
                "formatted_address",
              ],
            }}
            name="location"
            onPlaceSelected={(place) => {
              let cords = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              };
              setLocationErr("");
              handleLocation(place.formatted_address);
              handleCordinates(cords);
              setDirty(true);
            }}
            // onKeyPress={preventSpecial}
            onChange={(place) => {
              if (place.formatted_address === undefined) {
                setLocationErr("Invalid Location");
              }
              handleLocation(place.formatted_address);
              setDirty(true);
            }}
            value={location}
          />
          {
            <span
              style={{
                color: "red",
                fontSize: "12px",
                fontFamily: "Montserrat",
              }}
            >
              {locationErr}
            </span>
          }
        </Grid>

        <Grid item xs={12} sm={2} md={5} lg={5}>
          <Typography className={dealerClass.label}>
            Address <span className={dealerClass.required}>*</span>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10} md={7} lg={7}>
          <Grid container>
            <Grid
              item
              xs={12}
              spacing={2}
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                marginBottom: "5px",
                gap: "5px",
              }}
            >
              <Controls.InputField
                name="address_line_one"
                placeholder="Address Line 1"
                type="text"
                value={values.address_line_one}
                className={dealerClass.addressBarForm}
                onChange={(e) => {
                  handleChange(e);
                }}
                error={address}
                inputProps={{ maxLength: 200 }}
              />
              <Controls.InputField
                name="address_line_two"
                type="text"
                placeholder="Address Line 2"
                value={values.address_line_two}
                className={dealerClass.addressBarForm}
                onChange={(e) => {
                  handleChange(e);
                }}
                inputProps={{ maxLength: 200 }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                marginBottom: "5px",
              }}
            >
              <Controls.InputField
                name="locality"
                placeholder="Locality"
                type="text"
                value={values.locality}
                className={dealerClass.addressBarForm}
                onChange={(e) => {
                  handleChange(e);
                }}
                inputProps={{ maxLength: 200 }}
                onInput={(event) => {
                  const value = event.target.value;
                  const regex = /^[a-zA-Z0-9 .,/&_-]*$/;
                  if (!regex.test(value)) {
                    event.target.value = value.replace(
                      /[^a-zA-Z0-9 .,/&_-]/g,
                      ""
                    );
                  }
                }}
                error={locality}
              />

              <Controls.InputField
                name="city"
                placeholder="City"
                type="text"
                value={values.city}
                className={dealerClass.addressBarForm}
                onKeyPress={alphaWithSpace}
                onChange={(e) => {
                  const value = e.target.value;
                  if (
                    value !== "" &&
                    !ALPHA_DASH_WITH_SPACE_REGEX.test(value)
                  ) {
                    return;
                  }
                  handleChange(e);
                }}
                error={city}
                inputProps={{ maxLength: 30 }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <Controls.InputField
                name="country"
                placeholder="Country"
                disabled
                value="India"
                className={dealerClass.addressBarForm}
                onChange={handleInputChange}
              />

              <Controls.InputField
                name="pincode"
                type="number"
                placeholder="Pin Code"
                value={values.pincode}
                className={dealerClass.addressBarForm}
                onKeyPress={preventSpaceAndSpecialWithAlphaNumeric}
                onChange={(e) => {
                  handleChange(e);
                }}
                error={pincode}
                inputProps={{ maxLength: 6 }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} sm={12} md={12} xl={12} lg={12}>
        <div className={customStyle.formIconCentered2}>
          <Grid justifyContent="center">
            {/* <Link to={link}> */}
            <IconButs
              onClick={() => window.history.back()}
              ibtname="Cancel"
              className={dealerClass.formButtonAdd}
              variant="outlined"
            />
            {/* </Link> */}
          </Grid>
          <Grid justifyContent="center">
            <IconButs
              ibtname={STEPPER_NEXT}
              className={dealerClass.formButtonAdd}
              variant="outlined"
              onClick={submitFormData}
            />
          </Grid>
        </div>
      </Grid>
    </>
  );
};

export default BasicDetails;
