import { useMutation, useQueryClient } from "react-query";
import { addProduct, deleteProduct, editProduct, productImageAdd, productImgDelete, SelectAddOns } from "./inventoryUrls";

const add_product = () => {
  const queryClient = useQueryClient();
  return useMutation((product) => addProduct(product), {
    onMutate: async (newProduct) => {
      await queryClient.cancelQueries("productList");

      const prev = queryClient.getQueryData("productList");

      return {
        prev,
        newProduct,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("productList");
    },
    onError: (err, _, context) => {
    },
  });
};

const SelectAdd_ons = () => {
  const queryClient = useQueryClient();
  return useMutation((add_ons) => SelectAddOns(add_ons), {
    onMutate: async (add_ons) => {
      await queryClient.cancelQueries("productList");

      const prev = queryClient.getQueryData("productList");

      return {
        prev,
        add_ons,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("productList");
    },
    onError: (err, _, context) => {
    },
  });
};

const addImage = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => productImageAdd(data), {
    onSuccess: async (newProduct) => {
      queryClient.invalidateQueries("productList");;

      return newProduct;

    },
    onSettled: () => {
      queryClient.invalidateQueries("productList");
    },
    onError: (err, _, context) => {
    },
  });
};

const deleteImage = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => productImgDelete(data), {
    onSuccess: async (newProduct) => {
      queryClient.invalidateQueries("productDetails");
      queryClient.invalidateQueries("productList");;

      return newProduct;

    },
    onSettled: () => {
      queryClient.invalidateQueries("productDetails");
      queryClient.invalidateQueries("productList");

    },
    onError: (err, _, context) => {
    },
  });
};


const edit_product = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => editProduct(data), {
    onMutate: async (newData) => {
      await queryClient.cancelQueries("productList");
      const prev = queryClient.getQueryData("productList");

      return {
        prev,
        newData,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("productList");
      queryClient.invalidateQueries("productDetails");

    },
    onError: (err, _, context) => {
      queryClient.setQueryData("productList", context.prev);
    },
  });
};

const delete_Product = () => {
  const queryClient = useQueryClient()
  return useMutation((todoId) => deleteProduct(todoId), {
    onMutate: async (todo) => {
      await queryClient.cancelQueries("productList")
      const prev = queryClient.getQueryData("productList")
      queryClient.setQueryData("productList", (old = []) => {
      })

      // old.results.filter((item)=>item.id!==todo.id) 


      return {
        prev,
        todo
      }

    },
    onError: (err, _, context) => {
      queryClient.setQueryData("productList", context.prev)
    },
    onSettled: (todo) => {
      queryClient.invalidateQueries("productList")
    }
  })
}
const useAddProduct = {
  add_product,
  edit_product,
  SelectAdd_ons,
  delete_Product,
  addImage,
  deleteImage
};

export default useAddProduct;
