import * as React from "react";
import Box from "@material-ui/core/Box";

export default function CustomLoader() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <CircularProgress sx={{ marginLeft: "50%" }} /> */}
      <lottie-player
        src={'https://lottie.host/ae5a909e-20d3-4b29-be06-d3173a1f026b/Tz9bGOnHwU.json'}
        background="transparent"
        speed="1"
        style={{ width: "300px", height: "300px" }}
        loop
        autoplay
      ></lottie-player>
    </Box>
  );
}
