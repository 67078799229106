import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AdminLayout from "./Layout/AdminLayout";
import AuthLayout from "./Layout/Auth";
import ProtectedRoutes from "./Routes/ProtectedRoutes";
import Login from "./pages/Auth/Login";
import ForgetPassword from "./pages/Auth/ForgetPassword";
import ResetPassword from "./pages/Auth/ResetPassword/ResetPassword";
import ScrollToTop from "./common/ScrollToTop";
import { ToastContainer } from "react-toastify";

const App = () => {
  return (
    <>
      <Router>
      <ToastContainer position="top-center" toastStyle={{fontFamily: "Montserrat"}}/>
        <ScrollToTop />
        <Routes>
          <Route path="/auth/*" element={<AuthLayout />} />
          <Route
            path="/administrator/*"
            element={
              <ProtectedRoutes>
                <AdminLayout />
              </ProtectedRoutes>
            }
          />
          <Route exact path="/" element={<Login />} />
          <Route path="/forgetpasword" element={<ForgetPassword />} />
          <Route path="/resetpasword" element={<ResetPassword />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
