import React from "react";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import { useForm } from "../../sharedFeatures/useForm";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import makeStyles from "../../sharedFeatures/sharedClasses";
import IconButs from "../../common/Controls/IconButs";
import { FORMONE_BUTTON_MANAGEMENT } from "../../utilitis/header";
import Controls from "../../common/Controls/Controls";
import salesStyle from "../SalesPersonManagement/salesStyle";
import "../SalesPersonManagement/Sales.css";
import userRoleQuery from "../../data/user role/useUserRoleQuery";
import useAddRole from "../../data/user role/useAddRole";
import CustomLoader from "../../features/CustomLoader";

import { ToastContainer, toast } from "react-toastify";

const initialFValue = {
  id: 0,
  addNewRole: "",
  userRoleManagers: "",
  roles: {},
};

const UserRoleForm = ({ open, handleClose, title, userRoleManagersData }) => {
  const customStyle = makeStyles();
  const salesStyles = salesStyle();

  const status = [
    { id: 1, name: "Active" },
    { id: 2, name: "InActive" },
  ];

  const [roleNameError, setRoleNameError] = React.useState("");
  const [permissionError, setPermissionError] = React.useState("");

  const { data: userRolePermissionsData, isLoading: permissionsLoading } =
    userRoleQuery.getUserRolePermissions();
  const {
    mutateAsync: addRole,
    isLoading: addRoleLoading,
    isSuccess: addRoleSuccess,
  } = useAddRole.add_user_role();

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValue);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (values.addNewRole === "") {
      setRoleNameError("Please enter role name");
      return;
    } else {
      setRoleNameError("");
    }

    const activeRoleNamesList = Object.keys(values.roles).filter(
      (key) => values.roles[key]
    );

    if (activeRoleNamesList.length === 0) {
      setPermissionError("Please select at least one permission");
      return;
    } else {
      setPermissionError("");
    }

    const roleIdList = userRolePermissionsData.data
      .filter((item) => activeRoleNamesList.includes(item.name))
      .map((item) => item.id);

    await addRole({
      user_role: values.addNewRole.trim(),
      menu_id: roleIdList,
    })
      .then((res) => {
        toast.success(
          <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
            Role added successfully
          </span>, {
          hideProgressBar: true,
          autoClose: 2200,
        }
        );
        resetForm();
        handleClose();
      })
      .catch((err) => {
        if (err.response.status !== 403) {
          if (err.response.data && err.response.data.error.error) {
            toast.error(
              <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
                {err.response.data.error.error[0]}
              </span>, {
              hideProgressBar: true,
              autoClose: 2200,
            }
            );
          } else {
            toast.error(
              <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
                Something went wrong, please try after some time
              </span>, {
              hideProgressBar: true,
              autoClose: 2200,
            }
            );
          }

        } else {
          if (err.response.data.detail) {
            toast.warn(err.response.data.detail, {
              hideProgressBar: true,
              autoClose: 2200,
            });
          }

        }

      });
  };

  return (
    <Modal open={open}>
      <div className="modalWrap">
        <Box>
          <div
            className={customStyle.iconStyle}
            style={{
              backgroundColor: "#FEDD17",
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
            }}
          >
            <Typography
              className={salesStyles.assignTitle}
              style={{ margin: "5px 0px" }}
            >
              {title}
            </Typography>
          </div>
          <form onSubmit={handleSubmit}>
            <div className={customStyle.divStyleTwo}>
              <Grid container alignItems="center">

                <>
                  <Grid item xs={12} sm={6} className={customStyle.label}>
                    <Typography className={salesStyles.salesLabel}>
                      Add New Role
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controls.InputField
                      name="addNewRole"
                      value={values.addNewRole}
                      onChange={handleInputChange}
                      error={roleNameError}
                      className={salesStyles.inputTextBar}
                      inputProps={{ maxLength: "25" }}
                      onInput={(event) => {
                        const value = event.target.value;
                        const regex = /^[a-zA-Z0-9 ]*$/;
                        if (!regex.test(value)) {
                          event.target.value = value.replace(
                            /[^a-zA-Z0-9 ]/g,
                            ""
                          );
                        }
                      }}
                    />
                  </Grid>

                  <Grid
                    container
                    spacing={1}
                    alignItems="top"
                    style={{ margin: "20px 0px" }}
                  >
                    <Grid item xs={12}>
                      <Typography className={salesStyles.salesLabel}>
                        Permissions
                      </Typography>
                    </Grid>

                    {userRolePermissionsData &&
                      userRolePermissionsData.data &&
                      userRolePermissionsData.data.map((item, idx) => {
                        return (
                          <Grid item xs={12} sm={6} key={idx}>
                            <Controls.CheckBoxLabel
                              name={item.name}
                              label={item.name}
                              value={item.name}
                              onChange={() =>
                                setValues({
                                  ...values,
                                  roles: {
                                    ...values.roles,
                                    [item.name]: !values.roles[item.name],
                                  },
                                })
                              }
                              checked={values.roles[item.name]}
                            />
                          </Grid>
                        );
                      })}
                  </Grid>

                  {
                    <span
                      style={{
                        color: "red",
                        fontSize: "12px",
                        fontFamily: "Montserrat",
                      }}
                    >
                      {permissionError}
                    </span>
                  }

                  {permissionsLoading && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {" "}
                      <CustomLoader />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} sm={6}>
                        <IconButs
                          ibtname={
                            addRoleLoading ? (
                              <CircularProgress color="black" size={24} />
                            ) : (
                              "Add Role"
                            )
                          }
                          disabled={addRoleLoading ? true : false}
                          type="submit"
                          className={salesStyles.roleAddStyle}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <IconButs
                          onClick={handleClose}
                          ibtname={FORMONE_BUTTON_MANAGEMENT}
                          className={salesStyles.formButton}
                          disabled={addRoleLoading ? true : false}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>

              </Grid>
            </div>
          </form>
        </Box>
      </div>
    </Modal>
  );
};

export default UserRoleForm;
