import React,{useState} from "react";
import Modal from "@material-ui/core/Modal";
import { useForm } from "../../sharedFeatures/useForm";
import { Grid, Typography } from "@material-ui/core";
import makeStyles from "../../sharedFeatures/sharedClasses";
import IconButs from "../../common/Controls/IconButs";
import Controls from "../../common/Controls/Controls";
import { DatePicker } from "antd";
import CalenderIcon from "../../utilitis/icons/CalenderIcon";
import { BsSearch } from "react-icons/bs";
import "./offer.css";
import useAddCouponQuery from "../../data/coupon/useAddCouponQuery";
import { ToastContainer, toast } from "react-toastify";
const { RangePicker } = DatePicker;
const customIcon = <CalenderIcon />;

const initialFValue = {
  couponName: "",
  couponCode: "",
  minimumCartValue: "",
  discountAmount:""
};

const OfferAdd = ({ open, handleClose, title }) => {
  const customStyle = makeStyles();
  const [couponNameError,setCouponNameError] = useState("")
  const [couponCodeError,setcouponCodeError] = useState("")
  const [minimumCartValueError,setminimumCartValueError] = useState("")
  const [discountAmountError,setdiscountAmountError] = useState("")
  // const validate = () => {
  //   let temp = {};
  //   temp.addNewRole = values.addNewRole ? "" : "This Field is Required";
  //   temp.userRoleManagers =
  //     values.userRoleManagers.length != 0 ? "" : "This field is required.";
  //   setErrors({
  //     ...temp,
  //   });
  //   return Object.values(temp).every((x) => x == "");
  // };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValue);

    const {
      mutate: addCoupon,
      isLoading: addCouponLoading,
      isSuccess: addCouponSuccess,
    } = useAddCouponQuery.add_coupon();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (values.couponName === "") {
      setCouponNameError("Coupon name cannot be empty");
    } else {
      setCouponNameError("");
    }
    
    if (values.couponCode === "") {
      setcouponCodeError("Coupon code cannot be empty");
    } else {
      setcouponCodeError("");
    }
    
    if (values.minimumCartValue === "") {
      setminimumCartValueError("Cart value cannot be empty");
    } else {
      setminimumCartValueError("");
    }
    
    if (values.discountAmount === "") {
      setdiscountAmountError("Discount amount cannot be empty");
    } else {
      setdiscountAmountError("");
    }
    
    if (
      values.minimumCartValue !== "" &&
      values.discountAmount !== "" &&
      parseInt(values.discountAmount) >= parseInt(values.minimumCartValue)
    ) {
      setdiscountAmountError("Minimum cart value must be greater than discount amount");
    }

    
    if(values.couponName == "" || values.couponCode == "" || values.minimumCartValue == "" || values.discountAmount == "" ||  parseInt(values.discountAmount) >= parseInt(values.minimumCartValue)){
       return false;
    }
    // if( values.couponCode == "" && values.minimumCartValue == "" && values.discountAmount == ""){
    //   setcouponCodeError("Coupon code cannot be empty")
    //    setminimumCartValueError("Cart value cannot be empty")
    //    setdiscountAmountError("Discount amount cannot be empty")
    //     return false;
    // }

    // if(values.couponCode == ""){
    //   setcouponCodeError("Coupon code cannot be empty")
    //   // return false;
    // } if(values.minimumCartValue == ""){  
    //   setminimumCartValueError("Cart value cannot be empty")
    //   // return false;
    // } if(values.discountAmount == ""){
    //   setdiscountAmountError("Discount amount cannot be empty")
    //   // return false;
    // } if(parseInt(values.discountAmount) >= parseInt(values.minimumCartValue) ){
    //   setdiscountAmountError("minimumCartValue must be greater than discount amount")
    //   return false;
    // }else  {
    
   
    await addCoupon({
      name: values.couponName, 
      coupon_code: values.couponCode,
      min_cart_value:values.minimumCartValue,
      coupon_amount:values.discountAmount
    });

    // if (validate()) {
    // }
  };

  React.useEffect(() => {
    if (addCouponSuccess) {
      toast.success(
        <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
          Coupon added successfully
        </span>
      );
      resetForm();
      handleClose();
    }
  }, [addCouponSuccess]);

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="modalWrap" style={{height:"500px"}}>
        <div
          className={customStyle.iconStyle}
          style={{
            backgroundColor: "#FEDD17",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
          }}
        >
          <h1
            style={{
              fontSize: "16px",
              fontWeight: "700",
              paddingTop: "10px",
            }}
          >
            {title}
          </h1>
        </div>
        <form onSubmit={handleSubmit}>
          <div className={customStyle.divStyleTwo} >
            <Grid container alignItems="center">
              <Grid
                item
                xs={12}
                sm={2}
                md={5}
                lg={5}
                style={{ marginBottom: "5px" }}
              >
                <Typography className={customStyle.addlabel}>
                  Name of the coupon
                    <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={10} md={7} lg={7}>
                <Controls.InputField
                  type="text"
                  className={customStyle.stepperFormTextBar}
                  inputProps={{ maxLength: 100 }}
                  name="couponName"
                  value={values.couponName}
                  placeholder={"Coupon Name"}
                  onInput={(event)=>{
                    event.target.value = event.target.value.replace(/[^a-zA-Z0-9 ]/g,'')
                  }}
                  onChange={handleInputChange}
                   error={couponNameError}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={2}
                md={5}
                lg={5}
                style={{ marginBottom: "5px" }}
              >
                <Typography className={customStyle.addlabel}>
                  Coupon Code
                  <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={10} md={7} lg={7}>
                <Controls.InputField
                  type="text"
                  className={customStyle.stepperFormTextBar}
                  inputProps={{ maxLength: 15 }}
                  name="couponCode"
                  value={values.couponCode}
                  placeholder={"Coupon code"}
                  onInput={(event)=>{
                    event.target.value = event.target.value.replace(/[^a-zA-Z0-9 ]/g,'')
                  }} 
                  onChange={handleInputChange}
                  error={couponCodeError}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={2}
                md={5}
                lg={5}
                style={{ marginBottom: "5px" }}
              >
                <Typography className={customStyle.addlabel}>
                Minimum cart value
                  <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={10} md={7} lg={7}>
              <Controls.InputField
                  type="number"
                  className={customStyle.stepperFormTextBarArea}
                  inputProps={{ maxLength: 10 }}
                  name="minimumCartValue"
                  maxLength={10}
                  placeholder={"Minimum cart amount"}
                  value={values.minimumCartValue}
                  onChange={handleInputChange}
                  error={minimumCartValueError}
                />
              </Grid>
              {/* <Grid
                item
                xs={12}
                sm={2}
                md={5}
                lg={5}
                style={{ marginBottom: "5px" }}
              >
                <Typography className={customStyle.addlabel}>
                 Minimum cart value
                </Typography>
              </Grid>
              <Grid item xs={12} sm={10} md={7} lg={7}>
                <Controls.InputField
                  type="number"
                  className={customStyle.stepperFormTextBarArea}
                  inputProps={{ maxLength: 10 }}
                  name="minimumCartValue"
                  value={values.minimumCartValue}
                  onChange={handleInputChange}
                  error={minimumCartValueError}
                />
              </Grid> */}

             
             

              <Grid
                item
                xs={12}
                sm={2}
                md={5}
                lg={5}
                style={{ marginBottom: "5px" }}
              >
                <Typography className={customStyle.addlabel}>
                  Discount amount
                  <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={10} md={7} lg={7}>
                <Controls.InputField
                  type="number"
                  className={customStyle.stepperFormTextBar}
                  inputProps={{ maxLength: 10 }}
                  name="discountAmount" 
                  placeholder={"Discount amount"}
                  value={values.discountAmount}
                  onChange={handleInputChange}
                  error={discountAmountError}
                />
              </Grid>

        

              <Grid container className={customStyle.formButtonContainer}>
                <Grid item>
                  <div className={customStyle.formIcon}>
                    <IconButs
                    
                      ibtname="Add"
                      className={customStyle.formButtonAdd}
                      variant="outlined"
                      type="submit"
                    />
                  </div>
                </Grid>
                <Grid item>
                  <div className={customStyle.formIcon}>
                    <IconButs
                      ibtname="Cancel"
                      className={customStyle.formButtonAdd}
                      variant="outlined"
                      onClick={handleClose}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default OfferAdd;
