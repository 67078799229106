import React, { useState } from "react";
import InfoIButton from "../../utilitis/icons/InfoIButton";
import { Box, Card, Divider, Grid } from "@material-ui/core";
import useStyles from "./dashboardStyles";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import ChartHeader from "./ChartHeader";
import { DatePicker } from "antd";
import "./DealersOnboardedChart.css";
import { AiOutlineCalendar } from "react-icons/ai";

// const data = [
//   {
//     name: "14",
//     uv: 4000,
//     pv: 2400,
//     amt: 1000,
//   },
//   {
//     name: "15",
//     uv: 3000,
//     pv: 1398,
//     amt: 2210,
//   },
//   {
//     name: "16",
//     uv: 2000,
//     pv: 2800,
//     amt: 3290,
//   },
//   {
//     name: "17",
//     uv: 2780,
//     pv: 3908,
//     amt: 2000,
//   },
//   {
//     name: "18",
//     uv: 1890,
//     pv: 4800,
//     amt: 2181,
//   },
//   {
//     name: "19",
//     uv: 2390,
//     pv: 3800,
//     amt: 1500,
//   },
//   {
//     name: "20",
//     uv: 3490,
//     pv: 4300,
//     amt: 2100,
//   },
// ];



const DealersOnboardedChart = ({data,total=0}) => {
  const dashStyles = useStyles();
  const changeLegendTextColor = (value, entry) => {
    return (
      <span
        style={{
          color: "#000000",
          fontFamily: "Inter",
          fontWeight: "400",
          fontSize: "12px",
        }}
      >
        {value}
      </span>
    );
  };



  return (
    <>
      <Box className={dashStyles.dealersOnboardedBlockParent}>
       
        <Card className={dashStyles.dealersOnboardedChartParent}>
          <Box className={dashStyles.iButtonParent}>
            <InfoIButton />
          </Box>

          <ChartHeader header="Dealers Onboarded" subHeader="Status" />
          <Divider />
          <ResponsiveContainer  width="100%" height="75%">
            <AreaChart
              data={data}
              margin={{
                top: 30,
                right: 30,
                left: 0,
                bottom: 0,
              }}
              width={1000}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis dataKey="name" axisLine={false} />
              <YAxis axisLine={false} />
              <Tooltip cursor={false} />
             
              <Area
                type="monotone"
                dataKey="uv"
                stroke="none"
                fill="#F94144"
                name="Dealer"
              />
           
            </AreaChart>
          </ResponsiveContainer>
        </Card>
      </Box>
    </>
  );
};

export default DealersOnboardedChart;
