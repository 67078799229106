import { GrStatusGoodSmall } from "react-icons/gr";
import DataService from "../../DataService/DataService";

const SalesReports = async ({
  searchQry,
  page,
  perPageItems,
  first_name,
  last_name,
}) => {
  
  const data = await DataService.get(
    `/reports/salesreport/?page=${page}&${
      first_name  ? first_name === 'ALL' ? '' : "first_name=" + first_name + "&" : ""
    }${searchQry ? "search=" + searchQry + "&" : ""}${
      perPageItems ? "page_size=" + perPageItems : ""
    }&status=Active`
  );
  return data.data;
};

//   const getAllReviews = async () => {
//     const url = "/product/review_rating/";
//     const data = await DataService.get(url);
//     return data.data;
//   };

const SalesPersonList = async () => {
  const url = "/reports/salespersonlist/";
  const data = await DataService.get(url);
  return data.data;
};

// const DealersListById = async (id) => {
//   const url = `/sales/dealerlistbysalesperson/${id}/`;
//   const data = await DataService.get(url);
//   return data.data;
// };

const DealersListById = async ({
  searchQry,
  page,
  perPageItems,
  id,
  status,
}) => {
  let url = ``;
  if (searchQry && page && perPageItems && id && status) {
    url = `/sales/dealerlistbysalesperson/${id}/?search=${searchQry}&page_size=${perPageItems}&status=${status}&page=${page}`;
  } else if (searchQry && page && perPageItems && id) {
    url = `/sales/dealerlistbysalesperson/${id}/?search=${searchQry}&page_size=${perPageItems}&page=${page}`;
  } else if (searchQry && page && id && status) {
    url = `/sales/dealerlistbysalesperson/${id}/?search=${searchQry}&status=${status}&page=${page}`;
  } else if (page && perPageItems && id && status) {
    url = `/sales/dealerlistbysalesperson/${id}/?page_size=${perPageItems}&status=${status}&page=${page}`;
  } else if (searchQry && page && id) {
    url = `/sales/dealerlistbysalesperson/${id}/?search=${searchQry}&page=${page}`;
  } else if (searchQry && perPageItems && id) {
    url = `/sales/dealerlistbysalesperson/${id}/?search=${searchQry}&page_size=${perPageItems}&page=${page}`;
  } else if (searchQry && id && status) {
    url = `/sales/dealerlistbysalesperson/${id}/?search=${searchQry}&status=${status}&page=${page}`;
  } else if (page && perPageItems && id) {
    url = `/sales/dealerlistbysalesperson/${id}/?page_size=${perPageItems}&page=${page}`;
  } else if (page && id && status) {
    url = `/sales/dealerlistbysalesperson/${id}/?status=${status}&page=${page}`;
  } else if (perPageItems && id && status) {
    url = `/sales/dealerlistbysalesperson/${id}/?page_size=${perPageItems}&status=${status}&page=${page}`;
  } else if (page) {
    url = `/sales/dealerlistbysalesperson/${id}/?page=${page}`;
  }

  const data = await DataService.get(url);

  return data.data;
};

export { SalesReports, SalesPersonList, DealersListById };
