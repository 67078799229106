import React, { useState } from 'react'
import {
    Paper,
    IconButton,
    Grid
} from "@material-ui/core";
import makeStyles from '../../sharedFeatures/sharedClasses';
import IconButs from '../../common/Controls/IconButs';
import { Link, useSearchParams } from 'react-router-dom';
import Sub from "../../common/Subheader/Sub";
import { FcSettings } from "react-icons/fc";
import { MdNotifications, MdArrowBackIosNew } from "react-icons/md";
import InputField from "../../common/Controls/InputField";
import { BsSearch, BsPersonCircle } from "react-icons/bs";
import {
    TXT_INVENTORYMANAGEMENT,
    ASSIGN_DEALER_TECHNICIAN,
    TXT_INVENTORY_USER_DETAIL
} from "../../utilitis/header";
import Controls from "../../common/Controls/Controls";

const InventoryViewDetails = () => {
    const viewStyle = makeStyles();
    const [search, setSearch] = useState();
    const searchUser = (e) => {
        setSearch(e.target.value);
    };

    //aimee
   const [searchParams] = useSearchParams();
   const page = searchParams.get("page");
   const searchp = searchParams.get("search");
   const itemsno = searchParams.get("itemsno");
   
   let link = "/admin/inventry";
   link = page !== null ? link + `?page=${page}` : link;
   link = search !== null ? link + `&search=${searchp}` : link;
   link = itemsno !== null ? link + `&itemsno=${itemsno}` : link;

    return (

        <div className={viewStyle.divStyle}>

            <header className={viewStyle.headerStyle} style={{ backgroundColor: '#FEDD17' }}>
                <h1>{TXT_INVENTORYMANAGEMENT}</h1>

                <div className={viewStyle.iconStyle}>
                    <IconButton><BsPersonCircle className={viewStyle.iconColor} /></IconButton>Sign In
                    <IconButton><FcSettings className={viewStyle.iconColor} /></IconButton>
                    <IconButton><MdNotifications className={viewStyle.iconColor} /></IconButton>
                </div>

            </header>

            <div className={viewStyle.searchBoxParent}>
                <InputField
                    value={search}
                    placeholder='Type here...'
                    className={viewStyle.searchBar}
                    size='small'
                    name='usersearch'
                    onChange={searchUser}
                    icon={<BsSearch />}
                />
            </div>

            <Paper className={viewStyle.parentContainer} elevation="6">

                <div className={viewStyle.subHeaderParent}>
                    <Link to={link}>
                        <IconButton className={viewStyle.backButtonParent}>
                            <MdArrowBackIosNew className={viewStyle.backButton} />
                        </IconButton>
                    </Link>
                    <Sub data={TXT_INVENTORY_USER_DETAIL} />
                </div>

                <Grid container spacing={1}>
                    <Grid item xs={4} md={6}>
                        Name of the User
                    </Grid>
                    <Grid item xs={4} md={6}>
                        <Controls.InputField
                            placeholder="Jenny Wilson"
                            variant="outlined"
                            className={viewStyle.inputTextBar}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={4} md={6}>
                        User Type
                    </Grid>
                    <Grid item xs={4} md={6}>
                        <Controls.InputField
                            placeholder="Dealer"
                            variant="outlined"
                            className={viewStyle.inputTextBar}
                        />
                    </Grid>
                    <Grid item xs={4} md={6}>
                        Email address
                    </Grid>
                    <Grid item xs={4} md={6}>
                        <Controls.InputField
                            placeholder="sara.cruz@example.com"
                            variant="outlined"
                            className={viewStyle.inputTextBar}
                        />
                    </Grid>
                    <Grid item xs={4} md={6}>
                        Mobile number
                    </Grid>
                    <Grid item xs={4} md={6}>
                        <Controls.InputField
                            placeholder="9605499898"
                            variant="outlined"
                            className={viewStyle.inputTextBar}
                        />
                    </Grid>
                    <Grid item xs={4} md={6}>
                        Shop Category
                    </Grid>
                    <Grid item xs={4} md={6}>
                        <Controls.InputField
                            placeholder="Premium"
                            variant="outlined"
                            className={viewStyle.inputTextBar}
                        />
                    </Grid>
                    <Grid item xs={4} md={6}>
                        Service Category
                    </Grid>
                    <Grid item xs={4} md={6}>
                        <Controls.InputField
                            placeholder="Services"
                            variant="outlined"
                            className={viewStyle.inputTextBar}
                        />
                    </Grid>
                    <Grid item xs={4} md={6}>
                        Facilities
                    </Grid>
                    <Grid item xs={4} md={6}>
                        <Controls.InputField
                            placeholder="Gated, Customer Lounge"
                            variant="outlined"
                            className={viewStyle.inputTextBar}
                        />
                    </Grid>
                    <Grid item xs={4} md={6}>
                        GST Number
                    </Grid>
                    <Grid item xs={4} md={6}>
                        <Controls.InputField
                            placeholder="123456789"
                            variant="outlined"
                            className={viewStyle.inputTextBar}
                        />
                    </Grid>

                    <Grid item xs={4} md={12}>
                        <div className={viewStyle.formIcon}>

                            <Link to={link}>
                                <IconButs
                                    ibtname={ASSIGN_DEALER_TECHNICIAN}
                                    className={viewStyle.formButtonAdd}
                                    variant='outlined'
                                />
                            </Link>

                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </div >
    )
}

export default InventoryViewDetails;
