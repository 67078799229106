import { Box, Grid, Modal, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Controls from "../../common/Controls/Controls";
import IconButs from "../../common/Controls/IconButs";
import Sub from "../../common/Subheader/Sub";
import useAddCategory from "../../data/inventory/useAddCategory";
import useCategoryData from "../../data/inventory/useCategoryData";
import {
  FORMONE_BUTTON_MANAGEMENT,
  FORM_BUTTON_MANAGEMENT,
} from "../../utilitis/header";
import InventoryManagmentStyle from "./InventoryManagmentStyle";
import { ToastContainer, toast } from "react-toastify";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "90%",
  fontFamily: "Montserrat",
  transform: "translate(-50%, -50%)",
  bgcolor: "#ffffff",
  boxShadow: 24,
  borderRadius: "10px",
};

const SpecificationKeys = ({
  openKey,
  handleKeys,
  values,
  setValues,
  handleInputChange,
  catId,
  errors,

  params,
}) => {
  const [open, setOpen] = useState(false);
  const [keysError, setKeysError] = useState("");
  const [oldSpecification, setOldSpecification] = useState("");
  const categoryStyle = InventoryManagmentStyle();
  const handleOpen = () => {
    setOpen(!open);
  };

  const { data: SpecificationById } = params.name
    ? useCategoryData.useSubcategoryList(catId)
    : useCategoryData.useCategoryList(catId);
  const {
    mutateAsync: addCategory,
    data: addCategoryData,
    isSuccess: addCategorySuccess,
  } = useAddCategory.add_cat(params.id);
  const {
    mutateAsync: editCategory,
    data: editCategoryData,
    isSuccess: editCategorySuccess,
  } = useAddCategory.edit_cat(params.name);
  useEffect(() => {
    setOldSpecification(
      SpecificationById &&
      SpecificationById.specification_fields &&
      SpecificationById.specification_fields.SpecificationFields
    );
    // oldSpecification =
    //   SpecificationById &&
    //   SpecificationById.specification_fields
    setValues({
      ...values,
      specification: "",
    });
  }, [SpecificationById]);

  const submitData = async (e) => {
    // console.log(values.specification);
    // return false;
    e.preventDefault();
    let err = false;
    if (!values.specification) {
      setKeysError("Specification is required");
      err = true;
    }
    if (!err) {
      if (catId && oldSpecification !== "") {
        let updateddOldSpecification = oldSpecification;
        if (oldSpecification && oldSpecification.includes('null')) {
          updateddOldSpecification = oldSpecification.replace(/null,/g, '');
        }
        await editCategory({
          id: catId,
          name: SpecificationById && SpecificationById.name,
          usp: SpecificationById && SpecificationById.usp,
          other_charges: SpecificationById && SpecificationById.other_charges,
          type: SpecificationById && SpecificationById.type,
          parent_id:
            params.id !== undefined && params.id !== null
              ? parseInt(params.id)
              : "",
          // specification_fields: {
          //   SpecificationFields: updateddOldSpecification + "," + values.specification,
          // },
          specification_fields: {
            SpecificationFields: updateddOldSpecification !== null
              ? updateddOldSpecification + "," + values.specification
              : values.specification,
          },
        }).then((res) => {
          if (res.status === 200) {
            toast.success('Specification keys added', {
              hideProgressBar: true,
              autoClose: 2200,
            });
          }


        })
          .catch((err) => {

            if (err && err.response && err.response.status !== 403) {

              toast.error("Something went wrong", {
                hideProgressBar: true,
                autoClose: 2200,
              });
            }
          });
      } else {
        await addCategory({
          name: SpecificationById && SpecificationById.name,
          usp: SpecificationById && SpecificationById.usp,
          other_charges: SpecificationById && SpecificationById.other_charges,
          type: SpecificationById && SpecificationById.type,
          parent_id:
            params.id !== undefined && params.id !== null
              ? parseInt(params.id)
              : "",
          // specification_fields: {
          //   SpecificationFields: values.specification,
          // },
        });
      }
      handleKeys();
    }
  };


  return (
    <div>
      <Modal open={openKey}>
        <Box className={categoryStyle.specifickeyContainer}>
          <Box
            style={{
              backgroundColor: "#FEDD17",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ marginTop: "5px" }}>
              <Sub data={"Specification Keys"} />
            </Box>{" "}
          </Box>
          <Box sx={{ width: "100%", padding: "10px" }}>
            <Grid container>
              {open ? (
                <>
                  <Grid item xs={12} sm={4}>
                    <Typography className={categoryStyle.categoryLabel}>
                      Specification Keys
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Controls.TextArea
                      className={categoryStyle.formtTextArea}
                      disabled="true"
                      placeholder={'Specification Keys'}
                      value={oldSpecification}
                    />
                  </Grid>
                </>
              ) : null}

              <Grid item xs={12}>
                <Box>
                  <IconButs
                    onClick={handleOpen}
                    ibtname={"View"}
                    className={categoryStyle.formButtonAdd}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography className={categoryStyle.categoryLabel}>
                  New Specification Keys
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Controls.TextArea
                  name="specification"
                  className={categoryStyle.formtTextArea}
                  value={values.specification}
                  onChange={handleInputChange}
                  placeholder={'New Specification Keys'}
                  error={keysError}
                  maxLength="250"
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <Box
                  sx={{
                    display: { xs: "inline", sm: "flex" },
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IconButs
                    ibtname={FORM_BUTTON_MANAGEMENT}
                    className={categoryStyle.formButtonAdd}
                    onClick={submitData}
                  />
                </Box>
              </Grid>
              <Grid xs={12} sm={6}>
                <Box
                  sx={{
                    display: { xs: "inline", sm: "flex" },
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IconButs
                    ibtname={FORMONE_BUTTON_MANAGEMENT}
                    className={categoryStyle.cancelButton}
                    onClick={handleKeys}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default SpecificationKeys;
