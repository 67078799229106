import React, { useEffect, useState } from "react";
import { Grid, Box, Button, Typography, MenuItem } from "@material-ui/core";
import dealerStyles from "../dealerStyle";
import Controls from "../../../common/Controls/Controls";
import IconButs from "../../../common/Controls/IconButs";
import {
  STEPPER_NEXT,
  STEPPER_BACK,
  DEALER_APPROVE,
} from "../../../utilitis/header";
import useDealersQuery from "../../../data/dealer/useDealersQuery";
import ScrollToTop from "../../../common/ScrollToTop";
import { Select } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import useMediaQuery from "@mui/material/useMediaQuery";
import {toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AdvancedDetails = ({
  handleInputChange,
  id,
  values,
  setValues,
  handleBack,
  submitData,
  approve,
  status,
  activeStep,
}) => {
  const dealerClass = dealerStyles();
  const [shopType, setShopType] = useState("");
  const [shopCategory, setShopCategory] = useState("");
  const [serviceCategoryErr, setServiceCategoryErr] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [category, setCategory] = useState([]);
  const [gstnumber, setgstNumber] = useState("");
  const [gstTreatmentErr, setGstTreatmentErr] = useState("");
  const [facilities, setFacilities] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [holderName, setHolderName] = useState("");
  const [branch, setBranch] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [checked, setChecked] = React.useState([]);
  const [brandId, setBrandId] = useState("");
  const [allTradingTypes, setAllTradingTypes] = useState([]);
  const [allServicesTypes, setAllServicesTypes] = useState([]);
  const [fecilitiesDatas, setFecilitiesData] = useState([]);
  const navigate = useNavigate();
  const ALPHA_NUMERIC_DASH_REGEX_TWO = /^[^\s][a-zA-Z\s]+[^\s]$/;

  const ALPHA_NUMERIC_NO_SPACE = /^[a-zA-Z0-9]*$/;

  const ALPHA_NUMERIC_NUMBER = /^[0-9]+$/;

  const ALPHA_DASH_REGEX = /^[a-zA-Z\\s]*$/;

  const ALPHA_DASH_WITH_SPACE_REGEX = /^[a-zA-Z\s]*$/;

  const BLOCK_SPECIAL_REGEX = /^[0-9a-zA-Z \b]+$/;

  const BLOCK_SPACE = /^\S*$/;

  const [Error, setError] = React.useState(false);
  let error = false;

  const {
    data: servicesType,
    error: serviceError,
    isError: serviceIsError,
  } = useDealersQuery.get_Service_Type();

  const {
    data: bothTypeData,
    error: typeError,
    isError: typeIsError,
  } = useDealersQuery.get_brands_services_by_cat(values.service_category);
  const {
    data: fecilitiesData,
    error: fecilityError,
    isError: fecilityIsError,
  } = useDealersQuery.get_fecilities();

  const {
    data: tradingAndServicesType,
    error: tradingError,
    isError: tradingIsError,
    refetch: refetchTypes,
  } = useDealersQuery.get_trading_and_services();
  useEffect(() => {
    if (
      (serviceError &&
        serviceError.response &&
        serviceError.response.data &&
        serviceError.response.data.detail) ||
      (typeError &&
        typeError.response &&
        typeError.response.data &&
        typeError.response.data.detail) ||
      (fecilityError &&
        fecilityError.response &&
        fecilityError.response.data &&
        fecilityError.response.data.detail) ||
      (tradingError &&
        tradingError.response &&
        tradingError.response.data &&
        tradingError.response.data.detail)
    ) {
      navigate("/administrator/dealers");
    }
  }, [serviceIsError, typeIsError, fecilityIsError, tradingIsError]);
  const isMobile = useMediaQuery("(max-width: 600px)");

  useEffect(() => {
    if (
      fecilitiesData !== undefined ||
      (fecilitiesData && fecilitiesData.length !== 0)
    ) {
      setFecilitiesData(fecilitiesData);
    }
  }, [fecilitiesData]);

  useEffect(() => {
    if (tradingAndServicesType !== undefined) {
      let trades = tradingAndServicesType.filter((item) => item.type === 1);
      let services = tradingAndServicesType.filter((item) => item.type === 2);
      setAllTradingTypes(trades);
      setAllServicesTypes(services);
    }
  }, [tradingAndServicesType]);

  const [brandID, setBrandID] = useState(null);

  const {
    data: brand,
    refetch,
    isLoading: brandLoading,
    isRefetching: brandRefetching,
  } = checked ? useDealersQuery.get_Brand_By_Category(brandID) : "";

  useEffect(() => {
    if (brandID) {
      refetch();
    }
  }, [brandID]);

  useEffect(() => {
    refetchTypes();
  }, [activeStep]);

  useEffect(() => {
    if (values.brandLoaded && values.brandLoaded.length > 0) {
      let allItems = [...values.brandLoaded];
      let allCategories = allItems.map((item) => item.category_id);
      allCategories = allCategories.filter(
        (value, index, self) =>
          index ===
          self.findIndex((t) => t.id === value.id && t.name === value.name)
      );
      allCategories.forEach((item) => {
        setBrandID(item.id);
      });
      let allData = allCategories.map((item) => {
        return {
          id: item.id,
          name: item.name,
          data: allItems.filter((item2) => item2.category_id.id === item.id),
          brands: [],
        };
      });

      values.myData = allData;
      setValues({ ...values, myData: allData, dirty: true });
    }
  }, [values.brandLoaded, bothTypeData, allTradingTypes]);

  const handleChange = (event, newValue, key) => {
    setServiceCategoryErr("");
    setError(false);
    error = false;

    setBrandID(newValue.id);

    let newItem = [...values.myData];
    newItem[key] = {
      id: newValue.id,
      name: newValue.name,
      data: [],
      brand: [],
    };

    setValues({ ...values, myData: newItem, dirty: true });
  };

  useEffect(() => {
    if (brand !== undefined && brand.length > 0) {
      let newItem = [...values.myData];
      let currentItem = newItem.findIndex(
        (item) => item.id === brand[0].category_id.id
      );
      if (currentItem > -1) {
        newItem[currentItem].brand = brand;
        setValues({ ...values, myData: newItem, dirty: true });
      }
    }
  }, [brand, values.brandLoaded]);

  const increaseArray = () => {
    let newItem = [...values.myData];
    newItem.push({});
    setValues({ ...values, myData: newItem, dirty: true });
  };

  const decreaseArray = (key) => {
    let newItem = [...values.myData];
    newItem.splice(key, 1);
    setValues({ ...values, myData: newItem, dirty: true });
  };

  const [serviceData, setServiceData] = React.useState([]);
  const [servicesError, setServicesError] = React.useState("");

  useEffect(() => {
    if (id) {
      let items =
        values.serviceObjects &&
        values.serviceObjects.length > 0 &&
        values.serviceObjects.map((it) => it);
      if (items && serviceData.lenght > 0) {
        setServiceData([...serviceData, ...items]);
      } else if (items) {
        setServiceData([...items]);
      }

      let names =
        values.serviceObjects &&
        values.serviceObjects.length > 0 &&
        values.serviceObjects.map((item) => item && item.name);
      if (values.serviceObjects && values.serviceObjects.length > 0) {
        setValues({ ...values, serviceName: [...names] });
      }
    }
  }, []);

  //       fecilitiesData.map((item) => item && item);
  //     if (names) {
  //       setValues({ ...values, facilities: [...names] });
  //     }
  //   }
  // }, []);

  useEffect(() => {
    if (id && serviceData && serviceData.length > 0) {
      let idList = serviceData.filter(
        (v, i, a) =>
          a.findIndex((v2) => JSON.stringify(v) === JSON.stringify(v2)) === i
      );

      idList =
        idList &&
        idList.filter((item) => values.serviceName.includes(item && item.name));

      idList = idList.filter(function (element) {
        return element !== undefined;
      });

      idList = idList.map((item) =>
        item.category_brand ? item.category_brand[0].id : item.id
      );

      setValues({ ...values, servicesList: idList, dirty: true });
    }
  }, [serviceData]);

  const handleMultipleSelect = async (event, obj) => {
    setServicesError("");
    setBrandId("");
    setError(false);
    error = false;

    const {
      target: { value },
    } = event;
    setValues({
      ...values,
      serviceName: typeof value === "string" ? value.split(",") : value,
      dirty: true,
    });
    const newServiceData =
      (await servicesType) &&
      servicesType.filter((item) => {
        return value.length > 0 ? item.id === obj.props.id : false;
      });
    if (value.length > 0) {
      if (serviceData && serviceData.length > 0) {
        setServiceData([...serviceData, newServiceData[0]]);
      } else {
        setServiceData([newServiceData[0]]);
      }
    } else {
      setServiceData([]);
    }
  };

  const handleFacilitiesSelect = async (event, obj) => {
    setFacilities("");
    setError(false);
    error = false;
    const {
      target: { value },
    } = event;
    setValues({
      ...values,
      facilities: typeof value === "string" ? value.split(",") : value,
      dirty: true,
    });
  };

  useEffect(() => {
    if (!id) {
      let idList = serviceData.map(
        (item) => item && item.category_brand[0] && item.category_brand[0].id
      );
      idList = idList.filter(
        (v, i, a) =>
          a.findIndex((v2) => JSON.stringify(v) === JSON.stringify(v2)) === i
      );

      idList = idList.filter(function (element) {
        return element !== undefined;
      });

      setValues({ ...values, servicesList: idList, dirty: true });
      // console.log(serviceData);
    }
  }, [serviceData]);
  // console.log(values);
  const handleBrand = (event, key) => {
    setBrandId("");
    setServicesError("");
    setError(true);
    error = false;

    let newBrand = [...event.target.value];

    let allItems = [...values.myData];
    allItems[key].data = newBrand;

    setValues({ ...values, myData: allItems, dirty: true });

    let allData = allItems.map((item) => item.data);
    allData = allData.flat(1);
    allData = allData.filter(function (element) {
      return element !== undefined;
    });
    setValues({ ...values, brand_id: allData, dirty: true });
  };

  function clearError() {
    setShopType("");
    setShopCategory("");
    setServiceCategoryErr("");
    setServicesError("");
    setgstNumber("");
    setAccountNumber("");
    setBankName("");
    setHolderName("");
    setBranch("");
  }

  const typeofshop = [
    { id: 1, name: "A+" },
    { id: 2, name: "A" },
    { id: 3, name: "B+" },
    { id: 4, name: "B" },
    { id: 5, name: "C" },
  ];

  const shopcategory = [
    { id: 1, name: "Premium workshop" },
    { id: 2, name: "Standard vehicle workshop" },
    { id: 3, name: "Small workshop" },
  ];

  const serviceCategories = [
    { id: 1, name: "Trading" },
    { id: 2, name: "Services" },
    { id: 3, name: "Services & Trading" },
  ];

  const gstTreatments = [
    { id: 1, name: "Registered Business - Regular" },
    // { id: 2, name: "Registered Business - Composition" },
    { id: 3, name: "Unregistered Business" },
    // { id: 4, name: "Overseas" },
    // { id: 5, name: "Special Economic Zone" },
    // { id: 6, name: "Deemed Export" },
    // { id: 7, name: "Tax Deductor" },
    // { id: 8, name: "SEZ Developer" },
  ];

  useEffect(() => {
    if (error) {
      setError(true);
    } else {
      setError(false);
    }
  }, [error]);

  const submitFormData = (e) => {
    clearError();
    setError(false);

    let allItems = [...values.myData];
    let brand_id = allItems.map((item) => item.data);
    brand_id = brand_id.flat(1);
    brand_id = brand_id.filter(function (element) {
      return element !== undefined;
    });

    values.brand_id = brand_id;

    if (!values.shop_type) {
      setShopType("Shop Type is required");
      setError(true);
      error = true;
    }
    if (!values.service_category) {
      setServiceCategoryErr("Service Category is required");
      setError(true);
      error = true;
    }
    if (!values.shop_category) {
      setShopCategory("Shop Category is required");
      setError(true);
      error = true;
    }

    if (
      !values.facilities ||
      (values.facilities && values.facilities.length === 0)
    ) {
      setFacilities("Facilities is required");
      setError(true);
      error = true;
    }
    if (
      !values.gst_treatment ||
      (values.gst_treatment && values.gst_treatment === "")
    ) {
      setGstTreatmentErr("GST Treatment is required");
      setError(true);
      error = true;
    }
    if (
      (values.gstnumber &&
        values.gstnumber.length > 0 &&
        values.gstnumber.length > 15) ||
      (values.gstnumber &&
        values.gstnumber.length > 0 &&
        values.gstnumber.length < 15)
    ) {
      setgstNumber("GST Number is invalid");
      setError(true);
      error = true;
    }
    if (
      values.gst_treatment &&
      values.gst_treatment !== 3 &&
      !values.gstnumber
    ) {
      setgstNumber("GST Number is required");
      setError(true);
      error = true;
    }
    if (
      values.gstnumber &&
      values.gstnumber.length > 0 &&
      !/\d{2}[A-Za-z]{5}\d{4}[A-Za-z]{1}[A-Za-z\d]{1}[Zz]{1}[A-Za-z\d]{1}/.test(
        values.gstnumber
      )
    ) {
      setgstNumber("GST Number is invalid.");
      setError(true);
      error = true;
    }
    if (
      values.service_category !== 2 &&
      (brand_id === undefined || (brand_id && !brand_id.length))
    ) {
      setBrandId("Product/Brand is required");
      setError(true);
      error = true;
    }
    if (
      (Number(values.service_category) !== 1 && !values.servicesList) ||
      (Number(values.service_category) !== 1 &&
        values.servicesList &&
        values.servicesList.length === 0)
    ) {
      setServicesError("Services is required");
      setError(true);
      error = true;
    }
    if (
      !values.account_number ||
      (values.account_number && values.account_number.length === 0)
    ) {
      setAccountNumber("Account Number is required");
      setError(true);
    }
    if (
      (values.account_number && values.account_number.length < 9) ||
      (values.account_number && values.account_number.length > 17)
    ) {
      setAccountNumber("Maximum 17 digits allowed");
      setError(true);
      error = true;
    }
    if (!values.account_name) {
      setHolderName("Account name is required");
      setError(true);
      error = true;
    }
    if (!ALPHA_DASH_WITH_SPACE_REGEX.test(values.account_name)) {
      setHolderName("Invalid Account Holder Name");
      setError(true);
      error = true;
    }
    if (!values.bank_name) {
      setBankName("Bank name is required");
      setError(true);
      error = true;
    }

    if (!ALPHA_DASH_WITH_SPACE_REGEX.test(values.bank_name)) {
      setBankName("Incorrect Bank Name");
      setError(true);
      error = true;
    }

    if (!ALPHA_DASH_WITH_SPACE_REGEX.test(values.branch)) {
      setBranch("Invalid Branch");
      setError(true);
      error = true;
    }
    if (!values.ifsc) {
      setIfsc("IFSC is required");
      setError(true);
      error = true;
    }
    if (!/^[a-zA-Z]{4}0[a-zA-Z0-9]*$/.test(values.ifsc)) {
      setIfsc(
        "Please specify a valid IFSC CODE, expected 4 alphabets followed by the number 0 and 6 - 10 numbers/alphabets."
      );
      setError(true);
      error = true;
    } else if (
      shopType === "" &&
      shopCategory === "" &&
      serviceCategoryErr === "" &&
      servicesError === "" &&
      brandId === "" &&
      gstnumber === "" &&
      facilities === "" &&
      accountNumber === "" &&
      bankName === "" &&
      holderName === "" &&
      branch === "" &&
      ifsc === "" &&
      companyName === "" &&
      companyWebsite === "" &&
      gstTreatmentErr === "" &&
      error === false
    ) {
      let dat =
        brand_id &&
        brand_id.filter(function (element) {
          return element !== undefined;
        });
      dat = dat.map((item) => item.category_id && item.category_id.name);
      let tradingNames = [...new Set(dat)];
      values.category = [...tradingNames, ...values.serviceName];

      submitData();
    }
  };

  const detailsChange = (event) => {
    setError(false);
    error = false;
    if (event.target.name === "shop_type") {
      setShopType("");
    } else if (event.target.name === "service_category") {
      setServiceCategoryErr("");
    } else if (event.target.name === "shop_category") {
      setShopCategory("");
    } else if (event.target.name === "facilities") {
      setFacilities("");
    } else if (event.target.name === "gstnumber") {
      setgstNumber("");
    } else if (event.target.name === "brand") {
      setBrandId("");
    } else if (event.target.name === "account_number") {
      setAccountNumber("");
    } else if (event.target.name === "account_name") {
      setHolderName("");
    } else if (event.target.name === "bank_name") {
      setBankName("");
    } else if (event.target.name === "branch") {
      setBranch("");
    } else if (event.target.name === "ifsc") {
      setIfsc("");
    } else if (event.target.name === "shop_name") {
      setCompanyName("");
    } else if (event.target.name === "website") {
      setCompanyWebsite("");
    } else if (event.target.name === "gst_treatment") {
      setGstTreatmentErr("");
    }
    handleInputChange(event);
  };

  const userType = localStorage.getItem("userType");

  function checkIfNumber(event) {
    const regex = new RegExp(
      /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
    );

    return !event.key.match(regex) && event.preventDefault();
  }

  const preventSpaceAndSpecial = (event) => {
    let value = event.target.value;
    if (value !== "" && !ALPHA_DASH_REGEX.test(value)) {
      event.preventDefault();
    }
    if (event.nativeEvent.code === "Space") {
      event.preventDefault();
    }
  };

  const preventSpaceAndSpecialWithAlphaNumeric = (event) => {
    let value = event.target.value;
    if (value !== "" && !ALPHA_NUMERIC_NO_SPACE.test(value)) {
      event.preventDefault();
    }
    if (event.nativeEvent.code === "Space") {
      event.preventDefault();
    }
  };

  const preventSpecial = (event) => {
    let value = event.target.value;
    if (value !== "" && !BLOCK_SPECIAL_REGEX.test(value)) {
      event.preventDefault();
    }
  };

  const preventCharWithLimit = (event) => {
    let value = event.target.value;
    if (value !== "" && value.length > 17) {
      event.preventDefault();
    }
  };

  const preventAphaDashWithSpace = (event) => {
    let value = event.target.value;
    if (value !== "" && !ALPHA_DASH_WITH_SPACE_REGEX.test(value)) {
      event.preventDefault();
    }
  };

  let old = new Set(values.myData.map(({ id }) => id));
  let unique = bothTypeData && bothTypeData.filter(({ id }) => !old.has(id));

  let old2 = new Set(values.myData.map(({ id }) => id));
  let unique2 =
    allTradingTypes && allTradingTypes.filter(({ id }) => !old.has(id));

  const sortedFecilitiesData = () => {
    const fecilitiesDat = fecilitiesData && fecilitiesData;
    const index =
      fecilitiesDat &&
      fecilitiesDat.findIndex((item) => item.name === "No facilities");

    if (index > 0) {
      const element = fecilitiesDat.splice(index, 1)[0];
      fecilitiesDat.unshift(element);
      return fecilitiesDat;
    } else return fecilitiesDat;
  };

  return (
    <>
      <ScrollToTop />
      <Grid container spacing={{ xs: 0, md: 1 }}>
        <Grid
          item
          xs={12}
          md={2}
          sm={12}
          lg={2}
          xl={2}
          style={{ marginTop: "10px" }}
        >
          <Typography className={dealerClass.label}>
            Type Of Shop <span className={dealerClass.required}>*</span>
          </Typography>
        </Grid>
        <Grid item xs={12} md={10} sm={12} lg={4} xl={4}>
          <Controls.SelectBox
            className={dealerClass.basicSelectBox}
            name="shop_type"
            label="Type Of Shop"
            value={values.shop_type}
            options={typeofshop}
            onChange={(e) => detailsChange(e)}
            error={shopType}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={2}
          sm={12}
          lg={2}
          xl={2}
          style={{ marginTop: "10px" }}
        >
          <Typography className={dealerClass.label}>
            Shop Category
            <span className={dealerClass.required}>*</span>
          </Typography>
        </Grid>
        <Grid item xs={12} md={10} sm={12} lg={4} xl={4}>
          <Controls.SelectBox
            className={dealerClass.basicSelectBox}
            name="shop_category"
            label="Shop Category"
            value={values.shop_category}
            options={shopcategory}
            onChange={detailsChange}
            error={shopCategory}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={2}
          sm={12}
          lg={2}
          xl={2}
          style={{ marginTop: "5px" }}
        >
          <Typography className={dealerClass.label}>
            GST Treatment
            <span className={dealerClass.required}>*</span>
          </Typography>
        </Grid>
        <Grid item xs={12} md={10} sm={12} lg={4} xl={4}>
          <Controls.SelectBox
            className={dealerClass.basicSelectBox}
            name="gst_treatment"
            label="GST Treatment"
            value={values.gst_treatment}
            options={gstTreatments}
            onChange={detailsChange}
            error={gstTreatmentErr}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={2}
          sm={12}
          lg={2}
          xl={2}
          style={{ marginTop: "5px" }}
        >
          <Typography className={dealerClass.label}>Website</Typography>
        </Grid>
        <Grid item xs={12} md={10} sm={12} lg={4} xl={4}>
          <Controls.InputField
            name="website"
            type="text"
            placeholder="Website"
            value={values.website}
            className={dealerClass.stepperFormTextBar}
            onChange={(event) => {
              const value = event.target.value;
              if (value !== "" && !BLOCK_SPACE.test(value)) {
                return;
              }
              setCompanyWebsite("");
              detailsChange(event);
            }}
            error={companyWebsite}
            inputProps={{ maxLength: 45 }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={2}
          sm={12}
          lg={2}
          xl={2}
          style={{ marginTop: "5px" }}
        >
          <Typography className={dealerClass.label}>GST Number</Typography>
        </Grid>
        <Grid item xs={12} md={10} sm={12} lg={4} xl={4}>
          <Controls.InputField
            name="gstnumber"
            type="text"
            placeholder="GST Number"
            value={values.gstnumber}
            className={dealerClass.stepperFormTextBar}
            onChange={(event) => {
              const value = event.target.value;
              if (value !== "" && !ALPHA_NUMERIC_NO_SPACE.test(value)) {
                return;
              }
              setgstNumber("");
              detailsChange(event);
            }}
            error={gstnumber}
            inputProps={{ maxLength: 15 }}
          />
        </Grid>

        <Grid item xs={12} md={2} sm={12} lg={2} xl={2}>
          <Typography className={dealerClass.label}>
            Facilities <span className={dealerClass.required}>*</span>
          </Typography>
        </Grid>
        <Grid item xs={12} md={10} sm={12} lg={4} xl={4}>
          <Controls.MultipleSelectCheckmarks
            className={dealerClass.multipleSelect}
            name="facilities"
            options={sortedFecilitiesData() && sortedFecilitiesData()}
            value={values && values.facilities}
            onChange={handleFacilitiesSelect}
            error={facilities}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={2}
          sm={12}
          lg={2}
          xl={2}
          style={{ marginTop: "5px" }}
        >
          <Typography className={dealerClass.label}>
            Service Category
            <span className={dealerClass.required}>*</span>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={10}
          sm={12}
          lg={4}
          xl={4}
          style={{ marginTop: { sm: "8px", md: "15px" } }}
        >
          <Controls.SelectBox
            className={dealerClass.basicSelectBox}
            label="Service Category"
            name="service_category"
            value={values.service_category}
            options={serviceCategories}
            onChange={detailsChange}
            error={serviceCategoryErr}
          />
        </Grid>

        <Grid container>
          {(values.service_category === 1 || values.service_category === 3) && (
            <>
              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                <Typography className={dealerClass.label}>
                  Product Type <span className={dealerClass.required}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                {values.service_category === 1 && (
                  <Box className={dealerClass.brand}>
                    {bothTypeData ? (
                      <>
                        <Box
                          sx={{
                            borderBottom: 1,
                            borderColor: "divider",
                          }}
                        >
                          {values.myData.map((c, idx) => {
                            return (
                              <Grid
                                key={idx}
                                container
                                className={dealerClass.tradingContainer}
                              >
                                <Grid
                                  item
                                  xl={3}
                                  lg={3}
                                  sm={7}
                                  xs={7}
                                  className={dealerClass.tradingGrid}
                                >
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      {" "}
                                      Select Type
                                    </InputLabel>{" "}
                                    <Select
                                      labelId="demo-simple-select-label"
                                      label="Select Type"
                                      value={
                                        values.myData && values.myData[idx]
                                      }
                                      renderValue={(selected) => selected.name}
                                      sx={{
                                        fontFamily: "Montserrat",
                                        fontSize: "14px",
                                      }}
                                      MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            "& .MuiMenuItem-root": {
                                              padding: 2,
                                              fontFamily: "Montserrat",
                                              fontSize: "14px",
                                            },
                                          },
                                        },
                                      }}
                                      onChange={(e) =>
                                        handleChange(e, e.target.value, idx)
                                      }
                                    >
                                      {unique &&
                                        unique.map((item, i) => {
                                          return (
                                            <MenuItem key={i} value={item}>
                                              {item.name}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                {isMobile && (
                                  <Grid
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Grid>
                                      {idx !== 0 &&
                                        values.myData.length > 1 && (
                                          <Button
                                            className={dealerClass.addRemoveBtn}
                                            onClick={() => decreaseArray(idx)}
                                          >
                                            <RemoveCircleOutlineIcon />
                                          </Button>
                                        )}
                                    </Grid>
                                    <Grid>
                                      {idx === values.myData.length - 1 &&
                                        values.myData[
                                          values.myData.length - 1
                                        ] &&
                                        values.myData[values.myData.length - 1]
                                          .id !== undefined &&
                                        values.myData[values.myData.length - 1]
                                          .data.length !== 0 &&
                                        values.myData[values.myData.length - 1]
                                          .data.length !== undefined && (
                                          <Button
                                            className={dealerClass.addRemoveBtn}
                                            onClick={() => increaseArray()}
                                          >
                                            <AddIcon />
                                          </Button>
                                        )}
                                    </Grid>
                                  </Grid>
                                )}
                                <Grid
                                  item
                                  xl={6}
                                  lg={6}
                                  sm={12}
                                  xs={12}
                                  style={{
                                    marginTop: "8px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Controls.MultipleTwo
                                    className={dealerClass.multipleSelect}
                                    name="brand"
                                    value={values.myData[idx].data || []}
                                    options={
                                      values.myData[idx] &&
                                      values.myData[idx].brand &&
                                      values.myData[idx].brand.length > 0
                                        ? values.myData[idx].brand
                                        : []
                                    }
                                    onClick={() =>
                                      setBrandID(values.myData[idx].id)
                                    }
                                    myData={values.myData}
                                    num={idx}
                                    onChange={handleBrand}
                                    loading={brandLoading || brandRefetching}
                                  />
                                </Grid>
                                <Grid
                                  className={dealerClass.addRemoveButtonGroup}
                                >
                                  {idx !== 0 && values.myData.length > 1 && (
                                    <Button onClick={() => decreaseArray(idx)}>
                                      <RemoveCircleOutlineIcon />
                                    </Button>
                                  )}
                                  {idx === values.myData.length - 1 &&
                                    values.myData[values.myData.length - 1] &&
                                    values.myData[values.myData.length - 1]
                                      .id !== undefined &&
                                    values.myData[values.myData.length - 1].data
                                      .length !== 0 &&
                                    values.myData[values.myData.length - 1].data
                                      .length !== undefined && (
                                      <Button onClick={() => increaseArray()}>
                                        <AddIcon />
                                      </Button>
                                    )}
                                </Grid>
                              </Grid>
                            );
                          })}
                        </Box>
                      </>
                    ) : (
                      ""
                    )}
                  </Box>
                )}

                {values.service_category === 3 && (
                  <Box className={dealerClass.brand}>
                    {allTradingTypes ? (
                      <>
                        <Box
                          sx={{
                            borderBottom: 1,
                            borderColor: "divider",
                          }}
                        >
                          {values.myData.map((c, idx) => {
                            return (
                              <Grid key={idx} container>
                                <Grid
                                  item
                                  xl={3}
                                  lg={3}
                                  sm={7}
                                  xs={7}
                                  className={dealerClass.tradingGrid}
                                >
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Select Type
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      label="Select Type"
                                      value={
                                        values.myData && values.myData[idx]
                                      }
                                      renderValue={(selected) => selected.name}
                                      sx={{
                                        fontFamily: "Montserrat",
                                        fontSize: "14px",
                                      }}
                                      onChange={(e) =>
                                        handleChange(e, e.target.value, idx)
                                      }
                                      MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            "& .MuiMenuItem-root": {
                                              padding: 2,
                                              fontFamily: "Montserrat",
                                              fontSize: "14px",
                                            },
                                          },
                                        },
                                      }}
                                    >
                                      {unique2 &&
                                        unique2
                                          .filter((item) => item)
                                          .map((item, i) => {
                                            return (
                                              <MenuItem key={i} value={item}>
                                                {item.name}
                                              </MenuItem>
                                            );
                                          })}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                {isMobile && (
                                  <Grid
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Grid>
                                      {idx !== 0 &&
                                        values.myData.length > 1 && (
                                          <Button
                                            className={dealerClass.addRemoveBtn}
                                            onClick={() => decreaseArray(idx)}
                                          >
                                            <RemoveCircleOutlineIcon />
                                          </Button>
                                        )}
                                    </Grid>
                                    <Grid>
                                      {idx === values.myData.length - 1 &&
                                        values.myData[
                                          values.myData.length - 1
                                        ] &&
                                        values.myData[values.myData.length - 1]
                                          .id !== undefined &&
                                        values.myData[values.myData.length - 1]
                                          .data.length !== 0 &&
                                        values.myData[values.myData.length - 1]
                                          .data.length !== undefined && (
                                          <Button
                                            className={dealerClass.addRemoveBtn}
                                            onClick={() => increaseArray()}
                                          >
                                            <AddIcon />
                                          </Button>
                                        )}
                                    </Grid>
                                  </Grid>
                                )}
                                <Grid
                                  item
                                  xl={6}
                                  lg={6}
                                  sm={12}
                                  xs={12}
                                  style={{
                                    marginTop: "8px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  <Controls.MultipleTwo
                                    className={dealerClass.multipleSelect}
                                    name="brand"
                                    value={values.myData[idx].data || []}
                                    options={
                                      values.myData[idx] &&
                                      values.myData[idx].brand &&
                                      values.myData[idx].brand.length > 0
                                        ? values.myData[idx].brand
                                        : []
                                    }
                                    onClick={() =>
                                      setBrandID(values.myData[idx].id)
                                    }
                                    myData={values.myData}
                                    num={idx}
                                    onChange={handleBrand}
                                    loading={brandLoading || brandRefetching}
                                  />
                                </Grid>
                                <Grid
                                  className={dealerClass.addRemoveButtonGroup}
                                >
                                  {idx !== 0 && values.myData.length > 1 && (
                                    <Button
                                      onClick={() => decreaseArray(idx)}
                                      sx={{
                                        fontFamily: "Montserrat !important",
                                      }}
                                    >
                                      <RemoveCircleOutlineIcon />
                                    </Button>
                                  )}
                                  {idx === values.myData.length - 1 &&
                                    values.myData[values.myData.length - 1] &&
                                    values.myData[values.myData.length - 1]
                                      .id !== undefined &&
                                    values.myData[values.myData.length - 1].data
                                      .length !== 0 &&
                                    values.myData[values.myData.length - 1].data
                                      .length !== undefined && (
                                      <Button
                                        onClick={() => increaseArray()}
                                        sx={{
                                          fontFamily: "Montserrat !important",
                                        }}
                                      >
                                        <AddIcon />
                                      </Button>
                                    )}
                                </Grid>
                              </Grid>
                            );
                          })}
                        </Box>
                      </>
                    ) : (
                      ""
                    )}
                  </Box>
                )}
                {!isMobile && (
                  <p
                    style={{
                      color: "red",
                      transitionDuration: "initial",
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                    }}
                  >
                    {brandId}
                  </p>
                )}
              </Grid>
              {isMobile && (
                <p
                  style={{
                    color: "red",
                    transitionDuration: "initial",
                    marginTop: "10px",
                    fontFamily: "Montserrat",
                    fontSize: "12px",
                  }}
                >
                  {brandId}
                </p>
              )}
            </>
          )}
        </Grid>

        <Grid container alignItems="center">
          {(values.service_category === 2 || values.service_category === 3) && (
            <>
              <Grid item xs={12} md={2} sm={12} lg={2} xl={2}>
                <Typography className={dealerClass.label}>
                  Services <span className={dealerClass.required}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={10} sm={12} lg={4} xl={4}>
                <Controls.MultipleSelectCheckmarks
                  className={
                    values.serviceName && values.serviceName.length > 0
                      ? dealerClass.multipleSelect
                      : dealerClass.basicSelectBox
                  }
                  name="services"
                  value={values.serviceName}
                  options={
                    values.service_category === 2
                      ? bothTypeData
                      : allServicesTypes
                  }
                  onChange={handleMultipleSelect}
                  error={servicesError}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} md={2} lg={2} sm={12} xl={2}>
            <Typography className={dealerClass.label}>
              A/c Number <span className={dealerClass.required}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={10} lg={4} sm={9} xl={4}>
            <Controls.InputField
              name="account_number"
              type="number"
              placeholder="Account Number"
              value={values.account_number}
              className={dealerClass.stepperFormTextBar}
              onKeyPress={preventCharWithLimit}
              onChange={(event) => {
                const value = event.target.value;
                if (value !== "" && !ALPHA_NUMERIC_NUMBER.test(value)) {
                  return;
                } else if (value.length > 17) {
                  return;
                } else {
                  detailsChange(event);
                }
              }}
              error={accountNumber}
            />
          </Grid>
          <Grid item xs={12} md={2} lg={2} sm={12} xl={2}>
            <Typography className={dealerClass.label}>
              A/c Holder Name<span className={dealerClass.required}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={10} lg={4} sm={12} xl={4}>
            <Controls.InputField
              name="account_name"
              placeholder="Account Holder Name"
              type="text"
              value={values.account_name}
              className={dealerClass.stepperFormTextBar}
              onKeyPress={preventAphaDashWithSpace}
              onChange={(event) => {
                const value = event.target.value;
                if (value !== "" && !ALPHA_DASH_WITH_SPACE_REGEX.test(value)) {
                  return;
                }
                setHolderName("");
                detailsChange(event);
              }}
              error={holderName}
              inputProps={{ maxLength: 45 }}
            />
          </Grid>
          <Grid item xs={12} md={2} lg={2} sm={12} xl={2}>
            <Typography className={dealerClass.label}>
              Bank Name <span className={dealerClass.required}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={10} lg={4} sm={12} xl={4}>
            <Controls.InputField
              name="bank_name"
              placeholder="Bank Name"
              type="text"
              value={values.bank_name}
              className={dealerClass.stepperFormTextBar}
              onKeyPress={preventAphaDashWithSpace}
              onChange={(event) => {
                const value = event.target.value;
                if (value !== "" && !ALPHA_DASH_WITH_SPACE_REGEX.test(value)) {
                  return;
                } else {
                  detailsChange(event);
                }
              }}
              error={bankName}
              inputProps={{ maxLength: 45 }}
            />
          </Grid>
          <Grid item xs={12} md={2} lg={2} sm={12} xl={2}>
            <Typography className={dealerClass.label}>Branch</Typography>
          </Grid>
          <Grid item xs={12} md={10} lg={4} sm={12} xl={4}>
            <Controls.InputField
              type="text"
              name="branch"
              placeholder="Branch"
              value={values.branch}
              className={dealerClass.stepperFormTextBar}
              onKeyPress={preventAphaDashWithSpace}
              onChange={(event) => {
                const value = event.target.value;
                if (value !== "" && !ALPHA_DASH_WITH_SPACE_REGEX.test(value)) {
                  return;
                }
                setBranch("");
                detailsChange(event);
              }}
              inputProps={{ maxLength: 45 }}
            />
          </Grid>
          <Grid item xs={12} md={2} lg={2} sm={12} xl={2}>
            <Typography className={dealerClass.ifscLabel}>
              IFSC <span className={dealerClass.required}>*</span>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={10}
            lg={4}
            sm={12}
            xl={4}
            className={dealerClass.ifscOverlay}
          >
            <Controls.InputField
              type="text"
              name="ifsc"
              placeholder="IFSC"
              value={values.ifsc}
              className={dealerClass.stepperFormTextBar}
              onChange={(event) => {
                setIfsc("");
                detailsChange(event);
              }}
              error={ifsc}
              inputProps={{ maxLength: 15 }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
        style={{ marginTop: "15px" }}
      >
        {/* <div className={customStyle.formIcon}> */}
        <Grid
          item
          xs={12}
          sm={12}
          md={status === "Pending" ? 4 : 6}
          lg={status === "Pending" ? 4 : 6}
          xl={status === "Pending" ? 4 : 6}
        >
          <Grid container justifyContent="center">
            <IconButs
              ibtname={STEPPER_BACK}
              className={dealerClass.formButtonAdd}
              variant="outlined"
              onClick={handleBack}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Grid container justifyContent="center">
            <IconButs
              ibtname={STEPPER_NEXT}
              className={dealerClass.formButtonAdd}
              variant="outlined"
              onClick={submitFormData}
            />
          </Grid>
        </Grid>
        {status === "Pending" &&
        (userType === "Super Admin" || userType === "Admin") ? (
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Grid container justifyContent="center">
              {status === "Pending" ? (
                <IconButs
                  ibtname={DEALER_APPROVE}
                  className={dealerClass.formButtonAdd}
                  variant="outlined"
                  onClick={approve}
                />
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        {/* </div> */}
      </Grid>
    </>
  );
};

export default AdvancedDetails;
