import React, { useState } from "react";
import { IconButton } from "@material-ui/core";
import { FcSettings } from "react-icons/fc";
import { MdNotifications } from "react-icons/md";
import { Grid, AppBar } from "@material-ui/core";
import headerStyles from "../HeadBar/headerStyles";
import { Box } from "@material-ui/core";
import KwikfixSmallLogo from "../../utilitis/icons/KwikfixSmallLogo";
import BellIcon from "../../utilitis/icons/BellIcon";
import AppIcon from "../../utilitis/icons/AppIcon";
import SidebarDrawer from "./SidebarDrawer";
import ComigSoonDialog from "../ComingSoonDialog";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HiUserCircle } from "react-icons/hi";
import BackbuttonIcon from "../../utilitis/BackbuttonIcon";
import dealerStyles from "../../../src/pages/DealerManagement/dealerStyle";

const HeadBar = ({ title }) => {
  const headerClass = headerStyles();
  const dealerClass = dealerStyles();

  const { pathname } = window.location;

  const params = useParams();

  const dealerConditions = ["dealeradd", "dealeredit", "dealerDetails"];
  const userConditions = ["addUser", "usersEdit", "usersDetails"];
  const categoryConditions = ["categoryDetails"];
  const brandCondition = ["addbrand", "editBrand", "brandDetails"];
  const productCondition = ["addProduct", "editProduct", "ProductDetails"];
  const orderCondition = ["assignorderdetails", "asigntoemergency"];
  const salesCondition = ["salesDealerTechnicianDetails"];
  const subCatCondition = ["category/"];

  const vehicleCondition = ["vehicleAdd", "vehicleEdit", "vehicleDetails"];

  const dealers = dealerConditions.some((el) => pathname.includes(el));

  const users = userConditions.some((el) => pathname.includes(el));

  const category = categoryConditions.some((el) => pathname.includes(el));

  const brand = brandCondition.some((el) => pathname.includes(el));

  const product = productCondition.some((el) => pathname.includes(el));

  const order = orderCondition.some((el) => pathname.includes(el));

  const sales = salesCondition.some((el) => pathname.includes(el));

  const vehicle = vehicleCondition.some((el) => pathname.includes(el));

  const subcat = subCatCondition.some((el) => pathname.includes(el));

  const [handleComingSoon, setHandleComingSoon] = useState(false);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const userType = localStorage.getItem("userType");
  const userName = localStorage.getItem("userName");


  const toggleDrawer = (anchor) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(anchor);
  };

  const navigate = useNavigate()

  return (
    <>
      <div className={headerClass.headbar}>
        <AppBar position="sticky" color="default">
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <header className={headerClass.headerStyle}>
              {params.id != undefined && params.name != undefined && subcat ? (
                <>
                  <Link to="#" onClick={()=>navigate(-1)}>
                    <IconButton style={{ marginBottom: "7px" }}>
                      <BackbuttonIcon />
                    </IconButton>
                  </Link>
                </>
              ) : null}

              <Grid className={headerClass.grow}>
                <h1 className={headerClass.title}>{title}</h1>
              </Grid>
              <div className={headerClass.iconStyle}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <HiUserCircle size="25px" color="#67748E" />
                  <p className={headerClass.userType} title={userType}>{userName+' - '+userType}</p>
                </Box>
                <IconButton>
                  <FcSettings
                    onClick={() => setHandleComingSoon(true)}
                    className={headerClass.iconColor}
                  />
                </IconButton>
                <IconButton>
                  <MdNotifications
                    onClick={() => setHandleComingSoon(true)}
                    className={headerClass.iconColor}
                  />
                </IconButton>
              </div>
            </header>
          </Box>
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            <header className={headerClass.mobileHeaderStyle}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",

                  height: "0px",
                  marginTop: "-25px",
                  // marginRight:"13px",
                  // marginLeft:"-9px"
                }}
              >
              
                  <Link onClick={()=>navigate(-1)} to={'#'}>
                    <IconButton className={dealerClass.backtoDealer}>
                      <BackbuttonIcon />
                    </IconButton>
                  </Link>
                


                {/* <BackButtonSmall /> */}
                <KwikfixSmallLogo />
              </Box>
              {/* onClick={toggleDrawer(true)} */}
              <h6
                style={{
                  color: "#000000",
                  fontFamily: "Montserrat",
                  fontSize: "12px",
                  fontWeight: "700",
                  lineHeight: "16px",
                  fontStyle: "normal",
                  letterSpacing: "-0.192px",
                }}
              >
                {title}
              </h6>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "55px",
                }}
              >
                <Box
                  sx={{ marginTop: "4px" }}
                  onClick={() => setHandleComingSoon(true)}
                  className={headerClass.iconColor}
                >
                  {" "}
                  <BellIcon size="18px" color="#000" />
                </Box>
                <Box sx={{ cursor: "pointer" }} onClick={toggleDrawer(true)}>
                  <AppIcon />
                </Box>
              </Box>
            </header>
          </Box>
        </AppBar>
      </div>
      {drawerOpen ? (
        <SidebarDrawer drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
      ) : (
        ""
      )}
      {handleComingSoon ? (
        <ComigSoonDialog
          open={handleComingSoon}
          setOpen={setHandleComingSoon}
          title="Coming Soon"
          content="This feature is coming soon"
          handleClose={() => setHandleComingSoon(false)}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default HeadBar;
