import React from "react";
import { Grid, Paper, IconButton } from "@material-ui/core";
import { MdArrowBackIosNew } from "react-icons/md";
import Controls from "../../../common/Controls/Controls";
import Sub from "../../../common/Subheader/Sub";
import makeStyles from "../../../sharedFeatures/sharedClasses";
import "react-toastify/dist/ReactToastify.css";
import IconButs from "../../../common/Controls/IconButs";
import { Link } from "react-router-dom";
import { STEPPER_NEXT, TXT_PRODUCT_DETAIL } from "../../../utilitis/header";

const BasicInformation = ({ handleInputChange, handleNext }) => {
  // const notify = () => toast.success("Sucessfully Added!");
  // const { id } = useParams();

  const customStyle = makeStyles();

  const brand = [
    { id: 1, name: "BMW" },
    { id: 2, name: "APOLLO" },
  ];

  return (
    <>
      <Paper className={customStyle.parentContainer} elevation="6">
        <div className={customStyle.subHeaderParent}>
          <Link to="/admin/product">
            <IconButton className={customStyle.backButtonParent}>
              <MdArrowBackIosNew className={customStyle.backButton} />
            </IconButton>
          </Link>
          <Sub data={TXT_PRODUCT_DETAIL} />
        </div>

        <Grid container spacing={1}>
          <Grid item sm={12} md={12} xs={12}>
            <Grid container alignItems="center">
              <Grid item xs={2} md={2}>
                Brand
              </Grid>
              <Grid item xs={6} md={4}>
                <Controls.SelectBox
                  className={customStyle.formSelectBox}
                  name="brand_id"
                  // value={values.brand_id}
                  options={brand}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={2} md={2}>
                Series
              </Grid>
              <Grid item xs={6} md={4}>
                <Controls.InputField
                  name="series"
                  className={customStyle.formTextBar}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={2} md={2}>
                Item Code
              </Grid>
              <Grid item xs={6} md={4}>
                <Controls.InputField
                  name="item_code"
                  className={customStyle.formTextBar}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={2} md={2}>
                Model
              </Grid>
              <Grid item xs={6} md={4}>
                <Controls.InputField
                  name="model"
                  className={customStyle.formTextBar}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={2} md={2}>
                MRP
              </Grid>
              <Grid item xs={6} md={4}>
                <Controls.InputField
                  name="mrp"
                  placeholder="INR 123456.00"
                  className={customStyle.formTextBar}
                />
              </Grid>
              <Grid item xs={2} md={2}>
                RCP
              </Grid>
              <Grid item xs={6} md={4}>
                <Controls.InputField
                  name="rcp"
                  placeholder="INR 123456.00"
                  className={customStyle.formTextBar}
                />
              </Grid>
              <Grid item xs={2} md={2}>
                Dealer Price
              </Grid>
              <Grid item xs={6} md={4}>
                <Controls.InputField
                  name="dealer_landing_price"
                  placeholder="INR 123456.00"
                  className={customStyle.formTextBar}
                />
              </Grid>
              <Grid item xs={2} md={2}>
                Customer Price
              </Grid>
              <Grid item xs={6} md={4}>
                <Controls.InputField
                  name="customer_landing_price"
                  placeholder="INR 123456.00"
                  className={customStyle.formTextBar}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item sm={12} md={12}>
            <Grid container alignItems="center">
              <Grid item xs={2} md={2}>
                GST
              </Grid>
              <Grid item xs={6} md={4}>
                <Controls.InputField
                  name="gst_perc"
                  className={customStyle.formTextBar}
                />
              </Grid>
              <Grid item xs={2} md={2}>
                Margin
              </Grid>
              <Grid item xs={6} md={4}>
                <Controls.InputField
                  name="margin"
                  className={customStyle.formTextBar}
                />
              </Grid>
              <Grid item xs={2} md={2}>
                Description
              </Grid>
              <Grid item xs={6} md={4}>
                <Controls.TextArea
                  name="description"
                  className={customStyle.formTextBar}
                />
              </Grid>
              <Grid item xs={2} md={2}>
                Warranty Details
              </Grid>
              <Grid item xs={6} md={4}>
                <Controls.TextArea
                  name="warranty"
                  className={customStyle.formTextBar}
                />
              </Grid>
              <Grid item xs={2} md={2}>
                Status
              </Grid>
              <Grid item xs={6} md={4}>
                <Controls.InputField
                  name="status"
                  className={customStyle.formTextBar}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4} md={12}>
            <div className={customStyle.formIcon}>
              {/* <Link to='/admin/product'> */}
              <IconButs
                ibtname={STEPPER_NEXT}
                className={customStyle.formButtonAdd}
                variant="outlined"
                onClick={handleNext}
                // onClick={submitData}
              />
              {/* </Link> */}
            </div>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default BasicInformation;
