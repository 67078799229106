import {
  makeStyles,
  createStyles,
  createTheme,
} from "@material-ui/core/styles";
import { height } from "@mui/system";
const theme = createTheme();

export default makeStyles((theme) =>
  createStyles({
    parentContainer: {
      maxWidth: "1500px",
      minHeight: "auto",
      marginLeft: "3vw",
      marginRight: "3vw",
      padding: "3vw",
      marginTop: "50px",
    },

    dmSalesButtonOne: {
      fontFamily: "Montserrat",
      fontWeight: "700",
      background: "#FEDD17",
      lineHeight: "22px",
      letterSpacing: "1px",
      padding: "10px 8px",
      width: "252px",
      height: "40px",
      fontSize: "14px",
      color: "#252F40",
      textTransform: "capitalize",
      borderRadius: "8px",
      justifyContent: "space-evenly",
      "&:hover": {
        background: "#FEDD17 !important",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        justifyContent: "center !important",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "10px !important",
      },
    },
    boxStyle: {
      position: "absolute",
      top: "50%",
      left: "50%",
      fontFamily: "Montserrat",
      transform: "translate(-50%, -50%)",
      background: "#ffffff",
      boxShadow: 24,
      borderRadius: "10px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        overflowY: "scroll !important",
        height: "90vh",
      },
      [theme.breakpoints.down("xs")]: {
        width: "90vw",
        overflowY: "scroll !important",
        height: "90vh",
      },
    },
    subHeader: {
      fontFamily: "Montserrat !important",
      fontWeight: "700 !important",
      fontSize: "16px",
      fontStyle: "normal",
      color: "#252F40",
      padding: "6px 0 0 10px",
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },

    searchBoxParent: {
      display: "flex",
      margin: "25px",
      justifyContent: "space-between",
      alignItems: "center",
    },
    label: {
      padding: "20px",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "12px",
      lineHeight: "24px",
      [theme.breakpoints.down("sm")]: {
        padding: "8px",
      },
    },
    categoryLabel: {
      // used in add brand
      padding: "20px",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "14px",
      lineHeight: "24px",
      [theme.breakpoints.down("sm")]: {
        padding: "8px",
      },
    },
    formTextBar: {
      // also used in salesdealerTechincian details
      "& input[type=number]": {
        "-moz-appearance": "textfield !important",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none !important",
        margin: "0px !important",
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none !important",
        margin: "0px !important",
      },

      "& .MuiInputBase-root": {
        fontFamily: "Montserrat !important",
      },
      //   margin: "5px 0px !important",
      // width: "361px !important",
      // margin: "20px 0px !important",

      // "& .MuiOutlinedInput-root": {
      //   height: "50px !important",
      //   fontFamily: "Montserrat !important",
      //   fontStyle: "normal !important",
      //   fontWeight: "400 !important",
      //   fontSize: "14px !important",
      //   lineHeight: "24px !important",
      //   color: "#444445 !important",
      //   borderRadius: "6px !important",

      // },
      "& input": {
        fontFamily: "Montserrat !important",
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "14px !important",
        lineHeight: "24px !important",
        color: "#444445 !important",
        borderRadius: "6px !important",
      },
      width: "100%",
      margin: "20px 0",
      height: "50px !important",

      [theme.breakpoints.down("xs")]: {
        margin: "0px",
        width: "96% !important",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100% !important",
      },
    },
    cartegoryFormDetail: {
      "& input[type=number]": {
        "-moz-appearance": "textfield !important",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none !important",
        margin: "0px !important",
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none !important",
        margin: "0px !important",
      },
      //   margin: "5px 0px !important",
      // width: "361px !important",
      // margin: "20px 0px !important",

      // "& .MuiOutlinedInput-root": {
      //   height: "50px !important",
      //   fontFamily: "Montserrat !important",
      //   fontStyle: "normal !important",
      //   fontWeight: "400 !important",
      //   fontSize: "14px !important",
      //   lineHeight: "24px !important",
      //   color: "#444445 !important",
      //   borderRadius: "6px !important",

      // },
      "& input": {
        fontFamily: "Montserrat !important",
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "12px !important",
        lineHeight: "24px !important",
        color: "#444445 !important",
        borderRadius: "6px !important",
      },
      width: "100%",
      margin: "20px 0",
      height: "50px !important",

      [theme.breakpoints.down("xs")]: {
        margin: "0px",
        width: "96% !important",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100% !important",
      },
    },
    formTextBar2: {
      // "& input[type=number]": {
      //   "-moz-appearance": "textfield",
      // },
      // "& input[type=number]::-webkit-outer-spin-button": {
      //   "-webkit-appearance": "none",
      //   margin: 0,
      // },
      // "& input[type=number]::-webkit-inner-spin-button": {
      //   "-webkit-appearance": "none",
      //   margin: 0,
      // },
      // "& .MuiOutlinedInput-root": {
      //   height: "50px !important",
      //   fontFamily: "Montserrat !important",
      //   fontStyle: "normal !important",
      //   fontWeight: "400 !important",
      //   fontSize: "14px !important",
      //   lineHeight: "24px !important",
      //   color: "#444445 !important",
      //   borderRadius: "6px !important",
      // },
      "& input": {
        fontFamily: "Montserrat !important",
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "12px !important",
        lineHeight: "24px !important",
        color: "#444445 !important",
        borderRadius: "6px !important",
      },
      //hide input arrows
      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
        {
          "-webkit-appearance": "none !important",
          margin: "0px !important",
        },
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
      // "& .MuiOutlinedInput-inputAdornedEnd": {
      //   fontSize: "12px !important",
      //   fontFamily: "Montserrat !important",
      //   fontStyle: "normal !important",
      //   lineHeight: "24px !important",
      //   fontWeight: "400 !important",
      // },
      minHeight: "50px important!",
      height: "50px",
      width: "21vw",
      // margin: "20px 0",
      [theme.breakpoints.down("sm")]: {
        margin: "0px",
        width: "100%",
      },
    },
    formTextBar3: {
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& input": {
        fontFamily: "Montserrat !important",
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "12px !important",
        lineHeight: "24px !important",
        color: "#444445 !important",
        borderRadius: "6px !important",
      },

      width: "91%",
      height: "50px",
      // margin: "20px 0",
      [theme.breakpoints.down("sm")]: {
        margin: "0px",
        width: "100%",
      },
    },
    basicSelectBox: {
      width: "100%",
      height: "50px",
      fontSize: "14px !important",
      fontFamily: "Montserrat !important",

      borderRadius: "8px !important",
      marginBottom: "10px",
      [theme.breakpoints.down("sm")]: {
        width: "96% !important",
      },
      [theme.breakpoints.down("xs")]: {
        margin: "0px",
        width: "100% !important",
      },
    },
    textarea2: {
      width: "21vw !important",
      padding: "10px !important",
      fontFamily: "Montserrat !important",
      fontSize: "12px !important",
      // borderRadius: `10px !important`,
      // marginTop: "10px !important",
      border: "1px solid #D9D9D9 !important",
      borderRadius: "6px !important",
      // marginBottom: "23px !important",
      resize:'none',
      maxHeight:'10rem',
      overflowY:'scroll',
      [theme.breakpoints.down("sm")]: {
        width: "100% !important",
      },

      // ["@media (max-width: 960px)"]: {
      //   width: "100% !important",
      //   // marginBottom: "20px"
      // },
      // ["@media (max-width: 450px)"]: {
      //   marginBottom: "-5px !important",
      // },
    },
    textArea: {
      width: "100%",
      padding: "10px",
      borderRadius: `10px`,
      fontFamily: "Montserrat !important",
      fontSize: "14px !important",
      border: "1px solid #D9D9D9 !important",
      borderRadius: "6px !important",
      marginTop: "10px",
      minHeight: "50px !important",
      [theme.breakpoints.down("sm")]: {
        margin: "0px",
        width: "96% !important",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100% !important",
      },
    },
    textArea: {
      width: "100%",
      padding: "10px",
      borderRadius: `10px`,
      fontFamily: "Montserrat !important",
      fontSize: "14px !important",
      border: "1px solid #D9D9D9 !important",
      borderRadius: "6px !important",
      marginTop: "10px",
      minHeight: "50px !important",
      [theme.breakpoints.down("sm")]: {
        margin: "0px",
        width: "96% !important",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100% !important",
      },
    },
    formtTextArea: {
      width: "100%",
      padding: "10px",
      borderRadius: `10px`,
      fontFamily: "Montserrat !important",
      fontSize: "12px !important",
      border: "1px solid #D9D9D9 !important",
      borderRadius: "6px !important",
      marginTop: "10px",
      minHeight: "50px !important",
      height: "50px",
      [theme.breakpoints.down("sm")]: {
        margin: "0px",
        width: "96% !important",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100% !important",
      },
    },
    Inventory_formtTextArea: {
      // width: "100%",
      width: "21vw",
      padding: "10px",
      borderRadius: `10px`,
      fontFamily: "Montserrat !important",
      fontSize: "12px !important",
      border: "1px solid #D9D9D9 !important",
      borderRadius: "6px !important",
      marginTop: "10px",
      minHeight: "50px !important",
      height: "50px",
      [theme.breakpoints.down("sm")]: {
        margin: "0px",
        width: "96% !important",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100% !important",
      },
    },
    detailsFormtTextArea: {
      width: "100%",
      padding: "10px",
      borderRadius: `10px`,
      fontFamily: "Montserrat !important",
      fontSize: "12px !important",
      border: "1px solid #D9D9D9 !important",
      borderRadius: "6px !important",
      marginTop: "10px",
      minHeight: "50px !important",
      height: "50px !important",
      [theme.breakpoints.down("sm")]: {
        margin: "0px",
        width: "96% !important",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100% !important",
      },
    },
    formButtonAdd: {
      fontFamily: "Montserrat !important",
      fontWeight: "600 !important",
      background: "#FEDD17 !important",
      fontSize: "18px !important",
      color: "#252F40 !important",
      width: "21vw",
      height: "40px",
      textTransform: "capitalize !important",
      boxShadow: "6px 6px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",
      border: "0 !important",
      margin: "30px 30px",
      "&:hover": {
        backgroundColor: "yellow !important",
        boxShadow: "none",
      },

      [theme.breakpoints.down("sm")]: {
        width: "96% !important",
        margin: "30px 0px",
      },
      [theme.breakpoints.down("xs")]: {
        margin: "10px 0px",
        width: "100% !important",
      },
    },
    formButtonAdd2: {
      fontFamily: "Montserrat !important",
      fontWeight: "600 !important",
      background: "#FEDD17 !important",
      fontSize: "18px !important",
      color: "#252F40 !important",
      width: "100%",
      height: "40px",
      textTransform: "capitalize !important",
      boxShadow: "6px 6px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",
      border: "0 !important",
      margin: "30px 0px",
      "&:hover": {
        backgroundColor: "yellow !important",
        boxShadow: "none",
      },

      [theme.breakpoints.down("sm")]: {
        margin: "10px 18px",
        width: "90%",
        marginBottom: "10px !important",
      },
    },
    uploadInventory: {
      fontFamily: "Montserrat !important",
      fontWeight: "600 !important",
      background: "#FEDD17 !important",
      fontSize: "18px !important",
      color: "#252F40 !important",
      width: "100%",
      height: "40px",
      textTransform: "capitalize !important",
      boxShadow: "6px 6px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",
      border: "0 !important",
      margin: "30px 0px",
      display: "flex",
      justifyContent: "space-evenly",
      "&:hover": {
        backgroundColor: "yellow !important",
        boxShadow: "none",
      },

      [theme.breakpoints.down("sm")]: {
        margin: "10px 18px",
        width: "90%",
        marginBottom: "10px !important",
        fontSize: "14px !important",
      },
    },
    formButton: {
      fontFamily: "Montserrat !important",
      fontWeight: "600 !important",
      width: "21vw",
      height: "40px",
      color: "#252F40 !important",
      padding: "12px 20px !important",
      fontSize: "12px !important",
      // background: "#ffffff !important",
      border: "1px solid #FEDD17",
      color: "#000000 !important",
      lineHeight: "24px",
      textTransform: "capitalize !important",
      boxShadow: "6px 6px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",
      margin: "30px 30px",

      [theme.breakpoints.down("sm")]: {
        width: "96% !important",
        margin: "30px 0px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100% !important",
      },
    },
    cancelButton: {
      fontFamily: "Montserrat !important",
      fontWeight: "600 !important", // used in add brand
      width: "21vw",
      height: "40px",
      color: "#252F40 !important",
      padding: "12px 20px !important",
      fontSize: "18px !important", // changed from 12 to 18px
      // background: "#ffffff !important",
      border: "1px solid #FEDD17",
      color: "#000000 !important",
      lineHeight: "24px",
      textTransform: "capitalize !important",
      boxShadow: "6px 6px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",
      margin: "30px 0px",

      [theme.breakpoints.down("sm")]: {
        width: "96% !important",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100% !important",
      },
    },
    imageButton: {
      fontFamily: "Montserrat !important",
      fontWeight: "600 !important", // used in add brand
      width: "21vw",
      height: "40px",
      color: "#252F40 !important",
      padding: "12px 20px !important",
      fontSize: "14px !important", // changed from 12 to 18px
      // background: "#ffffff !important",
      border: "1px solid #FEDD17",
      color: "#000000 !important",
      lineHeight: "24px",
      textTransform: "capitalize !important",
      boxShadow: "6px 6px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",
      margin: "30px 0px",

      [theme.breakpoints.down("sm")]: {
        width: "96% !important",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100% !important",
      },
    },
    formAdd: {
      fontFamily: "Montserrat !important",
      fontWeight: "600 !important",
      background: "#FEDD17 !important",
      fontSize: "14px !important",
      color: "#252F40 !important",
      width: "27vw",
      height: "40px",
      textTransform: "capitalize !important",
      boxShadow: "6px 6px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",
      border: "0 !important",
      margin: "10px 0px",
      "&:hover": {
        backgroundColor: "yellow !important",
        boxShadow: "none",
      },

      [theme.breakpoints.down("sm")]: {
        margin: "10px 0px",
        width: "100%",
        marginBottom: "20px !important",
      },
    },
    // docStyle: {
    //   display: "flex",
    //   alignItems: "center",
    //   justifyContent: 'center'
    // },
    // formIcon: {
    // width: "100%",
    // padding: "20px 0",
    //   display: "flex",
    //   justifyContent: "center",
    //   alignItems: "center",
    //   marginLeft: "30px",
    // },
    searchBar: {
      borderRadius: "8px !important",
      // width: "310px",
      backgroundColor: "white",

      "& .MuiOutlinedInput-root": {
        fontFamily: "Montserrat !important",
      },
      "& .MuiInputBase-input": {
        fontFamily: "Montserrat !important",
        fontSize: "14px !important",
      },
      [theme.breakpoints.down("sm")]: {
        // marginTop: "-20px !important",
        "& .MuiFormControl-root MuiTextField-root": {
          width: "100% !important",
        },
        width: "100%",
      },
    },
    formSelectBox: {
      width: "204px",
      backgroundColor: "white",
      borderRadius: "12px",
      height: "40px",

      "& .MuiInputLabel-outlined": {
        zIndex: "100 !important",
      },
      [theme.breakpoints.down("sm")]: {
        // marginTop: "-20px !important",
        width: "100%",
      },
    },
    formSelectBox2: {
      width: "204px",
      borderRadius: "12px",
      height: "40px",
      marginTop: "5px !important",
      "& .MuiInputLabel-outlined": {
        zIndex: "100 !important",
      },
      [theme.breakpoints.down("sm")]: {
        // marginTop: "-20px !important",
        width: "100%",
      },
    },
    viewlabel: {
      fontWeight: "700 !important",
      fontStyle: "normal !important",
      fontSize: "14px !important",
      fontFamily: "Montserrat !important",
      lineHeight: "24px !important",
      color: "#444445 !important",
      width: "100% !important",
      [theme.breakpoints.down("sm")]: {
        // marginTop: "-20px !important",
        textAlign: "start !important",
      },
    },

    parentPaper: {
      margin: "0px 30px !important",
      borderRadius: "8px !important",
    },
    // excelData:{
    //   display:"flex",
    //   [theme.breakpoints.up("sm")]: {
    //     justify
    //   },
    // },

    paginationNext: {
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        justifyContent: "center",
      },
    },
    formStyle: {
      [theme.breakpoints.down("xs")]: {
        margin: "0px 18px !important",
      },
    },
    chechBox: {
      "& .MuiFormControlLabel-label": {
        fontWeight: "700 !important",
        fontStyle: "normal !important",
        fontSize: "14px !important",
        fontFamily: "Montserrat !important",
      },
    },
    specifickeyContainer: {
      position: "absolute",
      top: "50%",
      left: "50%",
      backgroundColor: "#fff",
      fontFamily: "Montserrat",
      transform: "translate(-50%, -50%)",
      bgcolor: "#ffffff",
      boxShadow: 24,
      borderRadius: "10px",
      [theme.breakpoints.down("xs")]: {
        width: "90%",
      },
    },
  })
);
