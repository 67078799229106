import React, { useState, useEffect } from "react";
import useTable from "../../sharedFeatures/useTable";
import {
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Box,
} from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import HeadCells from "../../common/Headcells";
import {
  TXT_VEHICLEMANAGEMENT,
  INVENTORY_MODAL_HEADER,
  ADD_VEHICLE,
} from "../../utilitis/header";
import makeStyles from "../../sharedFeatures/sharedClasses";
import { AiOutlineEye } from "react-icons/ai";
import HeadBar from "../../components/HeadBar/HeadBar";
import useVehicleList from "../../data/vehicle/useVehicleList";
import Controls from "../../common/Controls/Controls";
import PageCounter from "../../common/PageCounter";
import { Pagination } from "@material-ui/lab";
import usePagination from "../../common/Controls/usePagination";
import PageDropDown from "../../common/Controls/PageDropDown";
import DialogBox from "../../common/Controls/DialogBox";
import { useNavigate } from "react-router-dom";
import vehicleStyles from "./VehicleStyle";
import CustomLoader from "../../features/CustomLoader";
import NavigateLink from "../../common/Controls/NavigateLink";

const Vehicle = () => {
  const styles = makeStyles();
  const vehicleStyle = vehicleStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [openDel, setOpenDel] = useState(false);

  // const [trySearch, setTrySearch] = useState("");
  // const [currentpageNo, setcurrentpageNo] = useState(1);
  // const [pageItems, setPageItems] = useState(10);

  //aimee
  // const [searchParams] = useSearchParams();
  const searchParams = new URLSearchParams(window.location.search);
  const searchp = searchParams.get("search");
  const itemsno = searchParams.get("itemsno");
  // const searchParams = new URLSearchParams(window.location.search);
  let page = searchParams.get("page");
  const [trySearch, setTrySearch] = useState(searchp !== null ? searchp : "");
  const [pageItems, setPageItems] = useState(itemsno !== null ? itemsno : 10);
  const [currentpageNo, setcurrentpageNo] = useState(1);
  const query = {
    searchQry: trySearch,
    page: page !== null ? page : currentpageNo, //aimee
    perPageItems: pageItems,
  };

  React.useEffect(() => {
    if (currentpageNo > maxPage) {
      setcurrentpageNo(maxPage);
    }
  }, [pageItems]);
  

  const { data, isError, isLoading, isFetching,refetch } = useVehicleList(query);
  const { totalCount } = PageCounter(data && data.count);
  useEffect(()=>{
    refetch()
  },[])
  const { currentData, currentPage, maxPage, setSearchParams } = usePagination(
    data !== undefined && data,
    pageItems
  );

  useEffect(() => {
    setcurrentpageNo(1);
  }, [trySearch, pageItems]);
  useEffect(() => {
    setcurrentpageNo(currentPage);
  }, [currentPage]);

  const handlePageItems = (e) => {
    searchParams.set("page", "1");
    setSearchParams(searchParams);
    setPageItems(e.target.value);
  };

  const { TblContainer, TblHead } = useTable(HeadCells.userVehicle, totalCount);

  useEffect(() => {
    setcurrentpageNo(1);
  }, [trySearch]);

  const [excelModal, setExcelModal] = useState(false);

  const excelDataModalClose = () => {
    setExcelModal(false);
  };
  // let serialNo = pageItems * (page === null ? slNo : Number(page) - 1);

  let vehicleTable =
    data &&
    data.data &&
    data.data.map((item, key) => {
      return item.vehicle_spec;
    });

  let vehicleSpec =
    vehicleTable &&
    vehicleTable.map((item, key) => {
      return item;
    });

  let vehicleId =
    vehicleSpec &&
    vehicleSpec.map((item, key) => {
      return item[0].vehicle_id;
    });

  const addVehicle = () => {
    navigate("/administrator/vehicle/vehicleAdd");
  };
  return (
    <>
      <HeadBar title={TXT_VEHICLEMANAGEMENT} />
      <div className={styles.listdivStyle}>
        <div className={vehicleStyle.searchBoxParent}>
          <Controls.StyledSearch
            placeholder="Search"
            className={styles.searchBar}
            size="small"
            name="usersearch"
            value={trySearch}
            onChange={(e) => {
              searchParams.set("page", "1");
              setSearchParams(searchParams);
              setTrySearch(e.target.value);
            }}
            icon={<BsSearch />}
          />
          {/* <IconButs
            ibtname={ADD_VEHICLE}
            icon={<AiOutlinePlus />}
            className={styles.salesButton}
            onClick={addVehicle}
          /> */}
        </div>

        <Box className={styles.tableBox}>
          <TblContainer>
            <TblHead />
            <TableBody>
              {(isLoading || isFetching) && (
                <TableRow>
                  <TableCell colSpan={8}>
                    <CustomLoader />
                  </TableCell>
                </TableRow>
              )}
              {!isLoading &&
                !isFetching &&
                vehicleId &&
                vehicleId.map((item, key) => {
                  // serialNo++;
                  return (
                    <TableRow>
                      <TableCell align="center">{item.id}</TableCell>
                      <TableCell align="center">{item.make}</TableCell>
                      <TableCell align="center">{item.model}</TableCell>
                      <TableCell align="center">{item.trim}</TableCell>
                      <TableCell align="center">{item.fuel_type}</TableCell>
                      <TableCell align="center">{item.category}</TableCell>
                      <TableCell align="center">Active</TableCell>
                      <TableCell align="center">
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          {/* <Link
                            to={`/administrator/vehicle/vehicleDetails/${item.id}`}
                          >
                            <IconButton>
                              <AiOutlineEye className="user-icons" />
                            </IconButton>
                          </Link>
                          {/* <Link
                            // to={`/administrator/vehicle/vehicleEdit/${item.id}`}
                            to={{
                              pathname: `/administrator/vehicle/vehicleEdit/${item.id}`,
                              search: '?myParam=myValue',
                            }}
                          </Link> */}
                          <NavigateLink
                            url={`/administrator/vehicle/vehicleDetails/${item.id}`}
                            styleclass={styles.linkColor}
                            currentPage={currentPage}
                            trySearch={trySearch}
                            pageItems={pageItems}
                            itemid={item.id}
                            display={
                              <IconButton>
                                <AiOutlineEye className="user-icons" />
                              </IconButton>
                            }
                          />

                          {/* <Link
                            to={`/administrator/vehicle/vehicleEdit/${item.id}`}
// =======
//                           </Link>
//                           <Link
//                             // to={`/administrator/vehicle/vehicleEdit/${item.id}`}
//                             to={{
//                               pathname: `/administrator/vehicle/vehicleEdit/${item.id}`,
//                               search: '?myParam=myValue',
//                             }}
// >>>>>>> 78671b57cd0b8b3d4e1339bacd27f076255b1629
                          >
                            <IconButton>
                              <AiOutlineEdit className="user-icons" />
                            </IconButton>
                          </Link> */}
                          {/* <IconButton onClick={() => setOpenDel(true)}>
                          </Link> */}

                          {/* <NavigateLink
                          url={`/administrator/vehicle/vehicleEdit/${item.id}`}
                          styleclass={styles.linkColor}
                          currentPage={currentPage}
                          trySearch={trySearch}
                          pageItems={pageItems}
                          itemid={item.id}
                          display={<IconButton>
                            <AiOutlineEdit className="user-icons" />
                          </IconButton>}
                        /> */}

                          {/* <IconButton onClick={() => setOpenDel(true)}>
                            <AiOutlineDelete className="user-icons" />
                          </IconButton>  */}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </TblContainer>
        </Box>

        {((vehicleId && vehicleId.length === 0) || isError) && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "100px",
            }}
          >
            <lottie-player
              src="https://assets8.lottiefiles.com/packages/lf20_agnejizn.json"
              background="transparent"
              speed="1"
              style={{ width: "300px", height: "300px" }}
              loop
              autoplay
            ></lottie-player>
          </div>
        )}

        {excelModal ? (
          <Controls.ExcelData
            open={excelModal}
            // onClick={handleClick}
            onModalClose={excelDataModalClose}
            title={INVENTORY_MODAL_HEADER}
            // readExcel={readExcel}
            // fileType={fileType}
            // message={message}
            // requestId={requestId}
          />
        ) : null}

        <div className="pagination">
          <div className="paginationStyle">
            <PageDropDown
              value={pageItems}
              style={{ width: "90px", height: "34px", borderRadius: "55px" }}
              handlePageItems={handlePageItems}
              items={[
                { key: 1, value: 10 },
                { key: 2, value: 20 },
                { key: 2, value: 30 },
              ]}
            />
            <span className="paginationPage">Per Page</span>
          </div>

          <Pagination
            count={maxPage}
            className={styles.paginationCompStyle}
            page={Number(page) || currentPage}
            onChange={currentData}
            variant="outlined"
          />
        </div>
      </div>
      {openDel ? (
        <DialogBox
          open={openDel}
          setOpen={setOpenDel}
          content={"Are you sure you want to delete this Vehicle ?"}
          handleClose={() => setOpenDel(false)}
          handleRemove={() => setOpenDel(false)}
          userId={1}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Vehicle;
