import {
  Box,
  Grid,
  IconButton,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import makeStyles from "../../sharedFeatures/sharedClasses";
import React, { useState } from "react";
import Controls from "../../common/Controls/Controls";
import { BsSearch } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import BackbuttonIcon from "../../utilitis/BackbuttonIcon";
import HeadBar from "../../components/HeadBar/HeadBar";
import useTable from "../../sharedFeatures/useTable";
import HeadCells from "../../common/Headcells";
import PageDropDown from "../../common/Controls/PageDropDown";
import { Pagination } from "@material-ui/lab";
import financeStyles from "../FinanceManagement/financeStyles";
import DropDown from "../../common/Controls/ListingDropdown";
import reportQuery from "../../data/report/reportQuery";
import usePagination from "../../common/Controls/usePagination";
import CustomLoader from "../../features/CustomLoader";
import { useLocation } from "react-router-dom";
import "./report.css";
const ReportView = () => {
  const styles = makeStyles();
  const location = useLocation();
  const passedData = location.state && location.state.someData;
  const financeStyle = financeStyles();
  const [currentpageNo, setcurrentpageNo] = useState(1);

  const searchParams = new URLSearchParams(window.location.search);
  const searchp = searchParams.get("search");
  const filterByp = searchParams.get("filter");

  const itemsno = searchParams.get("pageItems");
  let page = searchParams.get("page");
  const [trySearch, setTrySearch] = useState(searchp !== null ? searchp : "");
  const [pageItems, setPageItems] = useState(itemsno !== null ? itemsno : 10);
  const [filterBy, setFilterBy] = useState(filterByp !== null ? filterByp : '');
  const [toggle, setToggle] = useState(false);
  const params = useParams();

  let parentPage = searchParams.get("parentpage") !== 'null' ? searchParams.get("parentpage") : 1;
  let parentSearch = searchParams.get("parentsearch");
  let parentpageItems = searchParams.get("parentpageItems");
  let slno = searchParams.get("slno") || 0;

  const qry = {
    id: params.id,
    searchQry: trySearch,
    status: filterBy == "All" ? "" : filterBy,
    // last_name: "",
    page: page !== null ? page : currentpageNo,
    perPageItems: pageItems,
  };

  // const location = useLocation();
  const qryParams = (location.state && location.state.qry) || null;
  const {
    data: reportData,
    isLoading: reportLoading,
    isFetching,
    isError
  } = reportQuery.getDealersListById(qry);
  const { TblContainer, TblHead } = useTable(HeadCells.reportsDealers);
  const { currentData, currentPage, maxPage, slNo,setSlNo, setSearchParams } =
    usePagination(reportData !== undefined && reportData, pageItems);

 

  React.useEffect(() => {
    if (currentpageNo > maxPage) {
      setcurrentpageNo(maxPage);
    }
  }, [pageItems]);

  React.useEffect(() => {
    setcurrentpageNo(currentpageNo);
  }, [currentpageNo]);
  const dropData = [
    {
      key: "All",
      value: "All",
    },
    {
      key: "Pending",
      value: "Pending",
    },
    {
      key: "Active",
      value: "Active",
    },
    {
      key: "Inactive",
      value: "Inactive",
    },
  ];

  const handlePageItems = (e) => {
    setSearchParams(searchParams);
    setPageItems(e.target.value);
 
  };
  const items = reportData && reportData.data && reportData.data.length;
  const pageNo = page===null ? 0 : Number(page)-1
  let serialNo = pageItems * pageNo;


  const handleFilterBySalesPerson = (e) => {
    searchParams.set("filter", e.target.value);
    setFilterBy(e.target.value);
    const newQueryString = searchParams.toString();
    const newUrl = `${window.location.pathname}?${newQueryString}`;
    window.history.replaceState({}, "", newUrl);
  };


  return (
    <>
      <HeadBar title={"Reports"} />
      <div className={styles.divStyle}>
        <Grid
          item
          xs={12}
          sm={4}
          style={{ width: "100%", height: "100%", padding: "40px 3vw" }}
        >
          <Controls.StyledSearch
            placeholder="Search"
            className={styles.searchBar}
            name="usersearch"
            value={trySearch}
            onChange={(e) => {
              searchParams.set("page", "1");
              setSearchParams(searchParams);
              setTrySearch(e.target.value);
              setSlNo(0)

              searchParams.set("search", e.target.value);
              const newQueryString = searchParams.toString();
              const newUrl = `${window.location.pathname}?${newQueryString}`;
              window.history.replaceState({}, "", newUrl);

            }}
            icon={<BsSearch style={{ marginLeft: "5px" }} />}
          />
        </Grid>
        <Paper className={styles.parentContainerReport}>
          <div
            className={styles.searchBoxParentReport}
            style={{ width: "100%", margin: "10px 0 !important" }}
          >
            <div
              className={styles.subHeaderParent}
              style={{
                width: "100%",
                padding: "0",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="r-head-container">
                <Box >
                  <Link to={`/administrator/Reporting?page=${parentPage}&search=${parentSearch}&pageItems=${parentpageItems}`}>
                    <IconButton className={styles.backButtonParent}>
                      <BackbuttonIcon />
                    </IconButton>
                  </Link>
                </Box>
                <Typography className={styles.subHeader}>
                  {params.name}
                </Typography>
              </div>
              <Grid item xs={12} sm={4}>
                <Grid container alignItems="center">
                  <Grid item xs={3}>
                    <Typography className={styles.viewlabel}>
                      Filter By
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <DropDown
                      style={{ width: "100%" }}
                      value={filterBy}
                      handlecreateNewItem={handleFilterBySalesPerson}
                      items={dropData}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className={styles.reportTbHead}>
            <Box className={styles.tableBox}>
              <TblContainer>
                <TblHead />
                {reportLoading || isFetching ? (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <CustomLoader />
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableBody>
                    {reportData &&
                      reportData.data &&
                      reportData.data.map((item) => {
                        serialNo++;
                        return (
                          <TableRow>
                            <TableCell align="center">{serialNo}</TableCell>
                            <TableCell align="center">
                              {item.first_name} {item.last_name}
                            </TableCell>
                            <TableCell align="center">{item.status}</TableCell>
                            <TableCell align="center">
                              <Link
                                to={`/administrator/dealerDetails/${item.id}`}
                                className={styles.linkColor}
                                // onClick={() => handlePassword(item.id)}
                              >
                                View
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      
                  </TableBody>
                )}
                
              </TblContainer>
              {((reportData && reportData.data.length === 0) ||
          isError ||
          (reportData &&
            reportData.data.length ===
              0)) && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "100px",
            }}
          >
            <lottie-player
              src="https://assets8.lottiefiles.com/packages/lf20_agnejizn.json"
              background="transparent"
              speed="1"
              style={{ width: "300px", height: "300px" }}
              loop
              autoplay
            ></lottie-player>
          </div>
        )}
            </Box>
         
          </div>

          <Box className={financeStyle.pagination}>
            <Box className={financeStyle.paginationStyle}>
              <PageDropDown
                value={pageItems}
                style={{ width: "90px", borderRadius: "55px", height: "34px" }}
                items={[
                  { key: 1, value: 10 },
                  { key: 2, value: 20 },
                  { key: 3, value: 30 },
                ]}
                handlePageItems={handlePageItems}
              />
              <span
                className={financeStyle.paginationPage}
                style={{ marginLeft: "13px" }}
              >
                Per Page
              </span>
            </Box>
            <Pagination
              count={maxPage}
              className={financeStyle.paginationFont}
              page={Number(page) || currentpageNo}
              onChange={currentData}
              variant="outlined"
            />
          </Box>
        </Paper>
      </div>
    </>
  );
};

export default ReportView;
