import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import { Grid } from "@material-ui/core";
import makeStyles from "../../sharedFeatures/sharedClasses";
import IconButs from "../../common/Controls/IconButs";
import useTable from "../../sharedFeatures/useTable";
import { AiOutlineClose } from "react-icons/ai";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Link } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import InputField from "../../common/Controls/InputField";
import OrderManagmentStyle from "./OrderManagmentStyle";
import Controls from "../../common/Controls/Controls";
import HeadCells from "../../common/Headcells";
import InventoryManagmentStyle from "../InventoryManagement/InventoryManagmentStyle";
import "./order.css";
import useDealersQuery from "../../data/dealer/useDealersQuery";
import useAdvisor from "../../data/orderManegemnet/orderChangeQuery";
import CustomLoader from "../../features/CustomLoader";
import { toast, ToastContainer } from "react-toastify";
import { useOrderQuery } from "../../data/orderManegemnet/orderQuery";
import useDebounce from "../../utilitis/debounceSearch/useDebounce";

const AssignDealerTechnician = ({
  open,
  handleClose,
  title,
  orderId,
  multipleDelears,
  dealerId,
}) => {
  const customStyle = makeStyles();
  const orderStyle = OrderManagmentStyle();
  const inventory = InventoryManagmentStyle();
  const [search, setSearch] = useState("");
  const formData = new FormData();
  const searchUser = (e) => {
    setSearch(e.target.value);
  };

  const debounceSearch = useDebounce(search, 500);
  const qry = {
    pageCount: 20,
    search: debounceSearch,
  };

  const { TblContainer, TblHead } = useTable(HeadCells.assignDealerHead, 10);

  // const { data: dealerList, isLoading } = useDealersQuery.dealers_list({qry});
  const {
    data: dealerList,
    isLoading,
    isError,
    isFetching
  } = !multipleDelears
      ? useOrderQuery.get_single_dealer_list({
        order_id: orderId,
        qry,
      })
      : useOrderQuery.get_multiple_dealer_list({ order_id: orderId, qry });

  const { mutateAsync: assignDealerSubmit, isLoading: assginLoading } =
    useAdvisor.assignDealer();
  const {
    mutateAsync: assignMultipleDealerSubmit,
    isLoading: assginMultipleLoading,
  } = useAdvisor.useAssignMultipleDealer();

  const handleAssignDealer = (id) => {
    formData.append("dealer_id", id);
    assignDealerSubmit({
      dealerId: id,
      orderId: orderId,
    })
      .then((response) => {
        toast.success(
          <span style={{ fontFamily: "Montserrat" }}>
            Dealer assigned successfully.
          </span>, {
          hideProgressBar: true,
          autoClose: 2200,
          icon: false,
          style: {
            backgroundColor: 'green',
            color: 'white',
          },
        }
        );

        handleClose(false);
      })
      .catch((error) => {
        toast.error(
          <span style={{ fontFamily: "Montserrat" }}>
            Something went wrong. Please try again
          </span>, {
          hideProgressBar: true,
          autoClose: 2200,
        }
        );
      });
  };
  const handleAssignMultipleDealer = (id) => {
    formData.append("dealer_id", id);
    assignMultipleDealerSubmit({
      dealerId: id,
      orderId: orderId,
    })
      .then((response) => {
        toast.success(
          dealerId !== null ? (
            <span style={{ fontFamily: "Montserrat" }}>
              Dealer re-assigned successfully.
            </span>, {
              hideProgressBar: true,
              autoClose: 2200,
            }
          ) : (
            <span style={{ fontFamily: "Montserrat" }}>
              Dealer assigned successfully.
            </span>, {
              hideProgressBar: true,
              autoClose: 2200,
            }
          )
        );
        handleClose(false);
      })
      .catch((error) => {
        toast.error(
          <span style={{ fontFamily: "Montserrat" }}>
            Something went wrong. Please try again
          </span>, {
          hideProgressBar: true,
          autoClose: 2200,
        });
      });
  };

  const [checked, setChecked] = useState(true);

  const handleChecked = () => {
    setChecked(!checked);
  };

  return (
    <Modal open={open} onClose={() => handleClose(false)}>
      <div className="modalWrap">
        {/* <Box className={orderStyle.modalStyle}> */}
        <div>
          <Box className={orderStyle.assignlinkbox}>
            <Box></Box>
            <Typography className={orderStyle.assignTitle}>{title}</Typography>
            <Box className={orderStyle.assignlinkclose}>
              <IconButton onClick={() => handleClose(false)}>
                <AiOutlineClose />
              </IconButton>
            </Box>
          </Box>

          <div className={customStyle.searchBoxParent}>
            <InputField
              value={search}
              placeholder="Search Dealers"
              className={inventory.searchBar}
              size="small"
              name="usersearch"
              onChange={searchUser}
              icon={<BsSearch />}
            />
          </div>
          <Box className={customStyle.tableBox}>
            <TblContainer>
              <Table sx={{ minWidth: 750 }} aria-label="simple table">
                <TblHead />

                <TableBody>
                  {((dealerList && dealerList.data.data.length === 0) ||
                    isError) && (
                      <TableRow>
                        <TableCell colSpan={8}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "100px",
                            }}
                          >
                            <lottie-player
                              src="https://assets8.lottiefiles.com/packages/lf20_agnejizn.json"
                              background="transparent"
                              speed="1"
                              style={{ width: "300px", height: "300px" }}
                              loop
                              autoplay
                            ></lottie-player>
                          </div>
                          <div style={{ width: "100%", textAlign: "center" }}>
                            No matching dealers found!!
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  {isLoading || assginLoading || isFetching || assginMultipleLoading ? (
                    <TableRow>
                      <TableCell colSpan={8}>
                        <CustomLoader />
                      </TableCell>
                    </TableRow>
                  ) : (
                    dealerList &&
                    dealerList.data &&
                    dealerList.data.data &&
                    dealerList.data.data.map((item, index) => (
                      <TableRow key={index}>

                        {/* <TableCell component="th" scope="row" align="center">
                          {
                            <>
                              <Controls.radioButton
                                value={"radio"}
                                checked={item.status === "Active" && true}
                                onChange={handleChecked}
                              />
                            </>
                          }
                        </TableCell> */}
                        <TableCell align="center">{item.id}</TableCell>
                        <TableCell align="center">{item.shop_details[0].shop_name}</TableCell>


                        <TableCell align="center">{`${item.first_name} ${item.last_name !== undefined ? item.last_name : ""
                          }`}</TableCell>
                        <TableCell align="center">{item.phone} </TableCell>

                        <TableCell align="center">
                          {item.shop_details[0].address.location}
                        </TableCell>
                        {/* <TableCell align="center">
                          {item.groups[0].name}
                        </TableCell> */}
                        <TableCell align="center">
                          {item.distance ? item.distance + "km" : "-"}{" "}
                        </TableCell>

                        <TableCell align="center">
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "5px",
                              alignItems: "center",
                            }}
                          >
                            <Link
                              onClick={() => {
                                if (!multipleDelears) {
                                  handleAssignDealer(item.id);
                                } else {
                                  handleAssignMultipleDealer(item.id);
                                }
                              }}
                              className={customStyle.linkColor}
                            >
                              Assign
                            </Link>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TblContainer>
          </Box>
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: "30px",
              gap: "20px",
            }}
          >
            <Grid item>
              <div className={customStyle.formIcon}>
                <IconButs
                  ibtname="Cancel"
                  className={customStyle.formButtonAdd}
                  variant="outlined"
                  onClick={() => handleClose(false)}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        {/* </Box> */}
      </div>
    </Modal>
  );
};

export default AssignDealerTechnician;
