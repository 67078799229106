import React, { useState, useEffect } from "react";
import { Avatar, Box, Card, Grid } from "@material-ui/core";
import useStyles from "./dashboardStyles";
import dashboard_doodle_svg from "../Dashboard/Images/dashboard_greeting_doodles.svg";
import MiniCard from "./MiniCard";
import userIcon from "../Dashboard/Icons/Users.svg";
import salesIcon from "../Dashboard/Icons/SalesPerson.svg";
import orderIcon from "../Dashboard/Icons/Orders.svg";
import dealerIcon from "../Dashboard/Icons/Dealer.svg";
import inventoryIcon from "../Dashboard/Icons/Inventory.svg";
import paymentIcon from "../Dashboard/Icons/Payment.svg";
import { useCountData } from "../../data/dashboard/dashboardQuery";
import { DatePicker } from "antd";
import { AiOutlineCalendar } from "react-icons/ai";
import moment from "moment";
const Greetings = ({ setFilter, filter }) => {
  const dashStyles = useStyles();
  const [greetings, setGreetings] = useState("");

  const { data } = useCountData({
    filter_type: filter.time,
    date_range:
      filter.start_date !== "" &&
      filter.start_date !== undefined &&
      filter.end_date !== "" &&
      filter.end_date !== undefined
        ? `${filter.start_date},${filter.end_date}`
        : "",
  });
  const salesPerson =
    data &&
    data.data &&
    data.data.data &&
    data.data.data.group_users.filter((e) => {
      return e.name === "Sales Executive";
    }).length;
  const dealer =
    data &&
    data.data &&
    data.data.data &&
    data.data.data.group_users
      .map((e) => {
        if (e.name === "Dealer Technician" || e.name === "Dealer") {
          return e.count;
        }
      })
      .filter((e) => e !== undefined)
      .reduce((e, i) => e + i, 0);
  useEffect(() => {
    var today = new Date();
    var curHr = today.getHours();

    if (curHr < 12) {
      setGreetings("Good Morning");
    } else if (curHr >= 12 && curHr <= 17) {
      setGreetings("Good Afternoon");
    } else {
      setGreetings("Good Evening");
    }
  }, []);
  const disabledDate = (current) => {
    const today = moment();
    const maxDate = today.clone().subtract(1, "years").startOf("day");
    return current && (current > today.endOf("day") || current < maxDate);
  };
  return (
    <div>
      <Box className={dashStyles.greetingsParent}>
        <Avatar
          alt="Greeting Image Doodles"
          src={dashboard_doodle_svg}
          sx={{
            width: "100%",
            height: "250px",
            position: "absolute",
            top: "0",
          }}
          variant="square"
        />
        <Box className={dashStyles.greetingsTextParent}>
          <h2 className={dashStyles.greetingsHeader}>{greetings}!</h2>
          <p className={dashStyles.greetingsBody}>
            You are doing great today. Let’s see <br />
            What do we have today...
          </p>
        </Box>
        <div className={dashStyles.filterWrap}>
          <Card className={dashStyles.dealersOnboardedFilter}>
            <Grid
              container
              alignItems="center"
              className={dashStyles.filterGridParent}
            >
              <Grid item md={4}>
                <span className={dashStyles.filterText}>
                  Filter By{"   "}
                  <select
                    className={dashStyles.filterSelectBoxStyles}
                    value={filter.time}
                    onChange={(e) => {
                      setFilter({
                        time: e.target.value,
                        start_date: "",
                        end_date: "",
                      });
                    }}
                  >
                    <option value="">Select options</option>
                    <option value="today">Today</option>
                    <option defaultChecked value="last_seven_days">
                      Last 7 Days
                    </option>
                    <option value="last_month">Last Month</option>
                    <option value="last_three_month">Last 3 Months</option>
                    <option value="last_year">1 year</option>
                  </select>
                </span>
              </Grid>
              <Grid container md={8} alignItems="center">
                <span className={dashStyles.filterText}>Filter By Date</span>

                <Grid item md={4} sx={{ paddingRight: "5px" }}>
                  <DatePicker
                    onChange={(_, date) => {
                      setFilter({ ...filter, start_date: date, time: "" });
                    }}
                    // disabled={filter.time !== ""}
                    placeholder="YYYY-MM-DD"
                    suffixIcon={<AiOutlineCalendar size="18px" />}
                    className="dealerFilter"
                    style={{ border: "1px solid #979797", borderRadius: "6px" }}
                    format={"YYYY-MM-DD"}
                    disabledDate={disabledDate}
                    value={
                      filter.start_date !== ""
                        ? moment(filter.start_date)
                        : null
                    }
                  />
                </Grid>
                <Grid item md={4} sx={{ paddingLeft: "5px" }}>
                  <DatePicker
                    onChange={(_, date) => {
                      setFilter({ ...filter, end_date: date, time: "" });
                    }}
                    // disabled={filter.time !== "" }
                    placeholder="YYYY-MM-DD"
                    suffixIcon={<AiOutlineCalendar size="18px" />}
                    className="dealerFilter"
                    style={{ border: "1px solid #979797", borderRadius: "6px" }}
                    disabledDate={disabledDate}
                    disabled={!filter.start_date}
                    value={
                      filter.end_date !== "" ? moment(filter.end_date) : null
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </div>
      </Box>

      <Box className={dashStyles.greetingsMiniCardParent}>
        <MiniCard
          icon={userIcon}
          stat={
            data &&
            data.data &&
            data.data.data &&
            data.data.data.users &&
            data.data.data.users.count
          }
          text="Users"
        />
        <MiniCard icon={salesIcon} stat={salesPerson} text="Sales Person" />
        <MiniCard
          icon={orderIcon}
          stat={
            data &&
            data.data &&
            data.data.data &&
            data.data.data.orders &&
            data.data.data.orders.count
          }
          text="Orders"
        />
        <MiniCard icon={dealerIcon} stat={dealer} text="Dealer/Mechanic" />
        <MiniCard
          icon={inventoryIcon}
          stat={
            data &&
            data.data &&
            data.data.data &&
            data.data.data.inventory &&
            data.data.data.inventory.count
          }
          text="Inventory"
        />
        <MiniCard
          icon={paymentIcon}
          stat={
            data &&
            data.data &&
            data.data.data &&
            data.data.data.payments &&
            data.data.data.payments.total === null
              ? 0
              : data &&
                data.data &&
                data.data.data &&
                data.data.data.payments &&
                data.data.data.payments.total
          }
          text="Payment"
        />
      </Box>
    </div>
  );
};

export default Greetings;
