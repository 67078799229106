import React from "react";
import { Paper, IconButton, Grid, Typography, Box } from "@material-ui/core";
import makeStyles from "../../sharedFeatures/sharedClasses";
import Sub from "../../common/Subheader/Sub";
import {
  TXT_CATEGORY,
  TXT_CATEGORY_DETAIL,
  ASSIGN_DEALER_TECHNICIAN,
  TXT_SUB_CATEGORY,
  TXT_SUB_CATEGORY_DETAIL,
} from "../../utilitis/header";
import {
  Link,
  useNavigate,
  useNavigation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import IconButs from "../../common/Controls/IconButs";
import Controls from "../../common/Controls/Controls";
import useCategoryData from "../../data/inventory/useCategoryData";
import dealerStyles from "../DealerManagement/dealerStyle";
import HeadBar from "../../components/HeadBar/HeadBar";
import BackbuttonIcon from "../../utilitis/BackbuttonIcon";
import categoryBrandStyles from "./InventoryManagmentStyle";

const CategoryDetails = () => {
  const viewStyle = makeStyles();
  const dealerClass = dealerStyles();
  const categoryStyle = categoryBrandStyles();

  const categoryType = [
    { id: 1, name: "Product" },
    { id: 2, name: "Service" },
  ];

  //aimee
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");
  const search = searchParams.get("search");
  const itemsno = searchParams.get("itemsno");
 const navigate =useNavigate()
  let link = "/administrator/category";
  link = page !== null ? link + `?page=${page}` : link;
  link = search !== null ? link + `&search=${search}` : link;
  link = itemsno !== null ? link + `&itemsno=${itemsno}` : link;

  const { id } = useParams();
  const { data: categoryData } = isNaN(id)
    ? ""
    : useParams().name !== undefined && useParams().name !== null
    ? useCategoryData.useSubcategoryList(id)
    : useCategoryData.useCategoryList(id);
  if (categoryData)
    return (
      <>
        <div className={dealerClass.divStyle}>
          <HeadBar
            title={
              useParams().name !== undefined && useParams().name !== null
                ? TXT_SUB_CATEGORY
                : TXT_CATEGORY
            }
          />

          <Paper className={dealerClass.parentContainer} elevation="6">
            <div className={viewStyle.subHeaderParent}>
              <Box sx={{ display: { xs: "none", md: "inline" } }}>
                <div
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <IconButton className={viewStyle.backButtonParent}>
                    <BackbuttonIcon />
                  </IconButton>
                </div>
              </Box>
              <Sub
                data={
                  useParams().name !== undefined && useParams().name !== null
                    ? TXT_SUB_CATEGORY_DETAIL
                    : TXT_CATEGORY_DETAIL
                }
              />
            </div>
            <div className={categoryStyle.formStyle}>
              <Grid
                container
                alignItems="center"
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12} md={4}>
                  <Typography className={categoryStyle.label}>
                    {useParams().name !== undefined && useParams().name !== null
                      ? "Name of the Sub Category"
                      : "Name of the Category"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.TextArea
                    disabled={"true"}
                    className={categoryStyle.detailsFormtTextArea}
                    name="name"
                   
                    placeholder={'Name'}
                    value={categoryData && categoryData.name}
                    // disabled
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography className={categoryStyle.label}>
                    Other Charges
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.TextArea
                    disabled={"true"}
                    className={categoryStyle.detailsFormtTextArea}
                    name="other_charges"
                    placeholder={"Nil"}
                    value={categoryData && categoryData.other_charges}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography className={categoryStyle.label}>USP</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.TextArea
                    disabled={"true"}
                    className={categoryStyle.formtTextArea}
                    name="usp"
                    placeholder={'USP'}
                    value={categoryData && categoryData.usp}
                  />
                </Grid>
                {categoryData && (
                  <>
                    <Grid item xs={12} md={4}>
                      <Typography className={categoryStyle.label}>
                        Type
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box sx={{ marginTop: { xs: "0px", sm: "20px" } }}>
                        {/* <Controls.SelectBox
  
                        disabled={"true"}

  className={categoryStyle.basicSelectBox}
                        name="type"
                        label="Category Type"
                        value={categoryData && categoryData.type}
                        options={categoryType}
                        disabled={true}
                      /> */}
                        <Controls.StyledInput
                          disabled={"true"}
                          className={categoryStyle.cartegoryFormDetail}
                          name="type"
                          placeholder={'Type'}
                          value={
                            categoryData && categoryData.type == 1
                              ? "Product"
                              : "Service"
                          }
                        />
                      </Box>
                    </Grid>
                  </>
                )}
                <Grid item md={4}></Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ marginTop: { xs: "20px" } }}>
                    {/* 
                      useParams().name !== undefined &&
                      useParams().name !== null
                        ? `/administrator/category/${
                            categoryData && categoryData.parent_id.id
                          }/${useParams().name}`
                        : "/administrator/category/"
                     */}
                    <Link to="#" onClick={()=>navigate(-1)}>
                      <IconButs
                        // type="submit"
                        ibtname={ASSIGN_DEALER_TECHNICIAN}
                        className={categoryStyle.formAdd}
                        variant="outlined"
                      />
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </div>
      </>
    );
};

export default CategoryDetails;
