import React, { useState, useEffect } from "react";
import useTable from "../../sharedFeatures/useTable";
import {
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Box,
  Grid,
} from "@material-ui/core";
import HeadCells from "../../common/Headcells";
import {
  AiOutlineEye,
  AiOutlinePlus,
  AiOutlineEdit,
  AiOutlineDelete,
} from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import makeStyles from "../../sharedFeatures/sharedClasses";
import DialogBox from "../../common/Controls/DialogBox";
import useAddDealer from "../../data/dealer/useAddDealer";
import {
  TXT_DEALERMANAGEMENT,
  ICON_BUTTON_DEALERMANAGEMENT,
} from "../../utilitis/header";
import IconButs from "../../common/Controls/IconButs";
import { Link, useNavigate, useParams } from "react-router-dom";

import Controls from "../../common/Controls/Controls";
import HeadBar from "../../components/HeadBar/HeadBar";
import useDealersQuery from "../../data/dealer/useDealersQuery";
import { PAGE_SIZE } from "../../utilitis/variable";
import { useForm } from "../../sharedFeatures/useForm";
import CustomLoader from "../../features/CustomLoader";
import PageCounter from "../../common/PageCounter";
import listStyles from "./listStyle";
import { makeStyles as customStyles } from "@material-ui/core/styles";
import "./Responsivetable.css";
import DropDown from "../../common/Controls/ListingDropdown";
import { DatePicker } from "antd";
import { Pagination } from "@material-ui/lab";
import usePagination from "../../common/Controls/usePagination";
import "./Dealermanagement.css";
import PageDropDown from "../../common/Controls/PageDropDown";
import CalenderIcon from "../../utilitis/icons/CalenderIcon";
import "../../common/Controls/PaginationStyle.css";
import flitersearch from "../../images/filtersearch.svg";
import { toast, ToastContainer } from "react-toastify";
import "./AddEditDealer/toast.css";
import NavigateLink from "../../common/Controls/NavigateLink";

const notifyDelete = () =>
  toast.success(
    <span style={{ fontFamily: "Montserrat" }}>
      Dealer / Technician deleted successfully.
    </span>, {
    hideProgressBar: true,
    autoClose: 2200,
  }
  );

const { RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";

const tabStyles = customStyles({
  table: {
    minWidth: 650,
  },
});
const customIcon = <CalenderIcon />;
const fieldStyles = {
  container: {
    display: "flex",
  },
};

const DealerManagement = ({ val, handleChange }) => {
  const customStyle = listStyles();
  const moment = require("moment");

  const [online, setOnline] = useState(true);

  const id = useParams().id;

  //aimee
  // const [searchParams] = useSearchParams();
  const searchParams = new URLSearchParams(window.location.search);
  const searchp = searchParams.get("search");
  const itemsno = searchParams.get("itemsno");
  const page = searchParams.get("page");
  const selected = searchParams.get("selected");
  const from = searchParams.get("from");
  const to = searchParams.get("to");
  const navigate = useNavigate();

  const [trySearch, setTrySearch] = useState(searchp !== null ? searchp : "");
  const [pageItems, setPageItems] = useState(itemsno !== null ? itemsno : 10);
  const [currentpageNo, setcurrentpageNo] = useState(page !== null ? page : 1);

  const [select, setSelect] = useState(selected !== null ? selected : "");
  // const [trySearch, setTrySearch] = useState("");
  // const [pageItems, setPageItems] = useState(10);
  // const [currentpageNo, setcurrentpageNo] = useState(1);

  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState();
  const [dates, setDates] = useState([]);
  const [fromDate, setFromDate] = useState(from !== null ? from : "2000/01/01");
  const [toDate, setToDate] = useState(to !== null ? to : "2000/01/02");

  const onChangeDateRange = (dates, datesString) => {
    setFromDate(datesString[0]);
    setToDate(datesString[1]);

    searchParams.set("page", "1");
    setSearchParams(searchParams);
  };

  const qry = {
    searchQry: trySearch,
    page: trySearch ? currentpageNo : page !== null ? page : currentpageNo, //aimee
    selected: select,
    perPageItems: pageItems,
    from_date:
      from !== null
        ? moment(from).format("YYYY-MM-DD")
        : fromDate === "2000/01/01" || fromDate === "2000/01/02"
          ? ""
          : moment(fromDate).format("YYYY-MM-DD"),
    to_date:
      to !== null
        ? moment(to).format("YYYY-MM-DD")
        : fromDate === "2000/01/01" || fromDate === "2000/01/02"
          ? ""
          : moment(toDate).format("YYYY-MM-DD"),
    created_by: id !== undefined ? id : "",
  };

  const {
    data: dealersList,
    isLoading,
    error,
    refetch,
    isFetching,
    isError,

  } = useDealersQuery.dealers_list(qry);
  useEffect(() => {
    refetch()
  }, [])

  const { totalCount } = PageCounter(dealersList && dealersList.count);

  const { TblContainer, TblHead } = useTable(HeadCells.dealerHead, totalCount);
  
  const { mutateAsync: remove, data: deletedData } =
    useAddDealer.delete_Dealer();
  const {
    currentData,
    currentPage,
    maxPage,
    slNo,
    setSearchParams,
    setCurrentPage,
  } = usePagination(dealersList !== undefined && dealersList, pageItems);

  const handlePageItems = (e) => {
    searchParams.set("page", "1");
    setSearchParams(searchParams);
    setPageItems(e.target.value);
  };

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (navigator.onLine) {
      setOnline(true);
    } else {
      setOnline(false);
    }
  }, [navigator.onLine]);

  useEffect(() => {
    setCurrentPage(page !== null ? Number(page) : 1);
  }, [trySearch, select, fromDate, toDate, page]);

  const clearFilter = () => {
    setDates([]);
    setFromDate("2000/01/01");
    setToDate("2000/01/02");
    setSelect("");
    setTrySearch("");
    setcurrentpageNo(1);
    setPageItems(10);
    navigate("/administrator/dealers");
  };

  useEffect(() => {
    setcurrentpageNo(currentPage);
  }, [currentPage]);

  //if last page and perpage changes go to first page
  useEffect(() => {
    if (currentpageNo > maxPage) {
      setcurrentpageNo(maxPage);
    }
  }, [pageItems]);

  const styles = makeStyles();

  const handleCreateNewItem = (e) => {
    setSelect(e.target.value);
    searchParams.set("page", "1");

    setSearchParams(searchParams);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = (id) => {
    setOpen(true);
    setUserId(id);
  };

  const handleRemove = () => {
    if (userId) {
      remove(userId)
        .then((res) => {
          if (res.status === 200) {
            if (
              res.data.message ===
              "Dealer/Technician has active orders pending!"
            ) {
              toast.error(res.data.message, {
                hideProgressBar: true,
                autoClose: 2200,
              });
              setOpen(false);
            } else {
              toast.success("Dealer/Technician deleted successfully", {
                hideProgressBar: true,
                autoClose: 2200,
              });
              setOpen(false);
            }
          }
        })
        .catch((err) => {
          if (err && err.response && err.response.status !== 403) {
            toast.error("Can't delete Dealer/Technician", {
              hideProgressBar: true,
              autoClose: 2200,
            });
          }

          setOpen(false);
        });
    }
  };

  const { handleMessageBox, resetMessage } = useForm();

  useEffect(() => {
    if (deletedData != undefined) {
      handleMessageBox(
        deletedData && deletedData.data && deletedData.data.message
      );
    }

    setTimeout(() => {
      resetMessage();
    }, [1500]);
  }, [deletedData]);

  let serialNo = PAGE_SIZE * slNo;
  return (
    <>
      <HeadBar title={id ? 'On borded dealers' : TXT_DEALERMANAGEMENT} />
      <div className={styles.listdivStyle}>
        <Box className={styles.dmSearchBoxParent}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={6} md={2} lg={2}>
              <Controls.FullWidthSearch
                placeholder="Search"
                className={styles.searchBar}
                name="usersearch"
                value={trySearch}
                onChange={(e) => {
                  setTrySearch(e.target.value);
                  searchParams.set("page", "1");
                  setSearchParams(searchParams);
                }}
                icon={
                  <BsSearch style={{ marginLeft: "5px", color: "#67748E" }} />
                }
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={4}>
              <div style={{ position: "relative" }}>
                <div className="date-picker">{customIcon}</div>
                <h4 className="datepicker__title"> From </h4>
                <h4 className="datepicker__to_title">To</h4>
                <RangePicker
                  placeholder={["dd-mm-yy", "dd-mm-yy"]}
                  suffixIcon={customIcon}
                  allowClear={true}
                  allowEmpty={true}
                  style={{
                    height: "2.7rem",
                    width: "100%",
                    fontFamily: "Montserrat !important",
                    borderRadius: 10,
                    zIndex: 0,
                  }}
                  onChange={onChangeDateRange}
                  defaultValue={[]}
                  value={
                    fromDate === "2000/01/01"
                      ? ["", ""]
                      : [
                        moment(fromDate, dateFormat),
                        moment(toDate, dateFormat),
                      ]
                  }
                />
              </div>
            </Grid>
            <Grid item xs={7} sm={6} md={3} lg={2}>
              <Grid container alignItems="center">
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: { sm: "inline", md: "none" } }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box>
                        <img src={flitersearch} />
                      </Box>
                      <Box
                        sx={{
                          width: "50px",
                          marginLeft: "4px",
                          marginRight: "6px",
                        }}
                      >
                        Filter By
                      </Box>
                    </Box>
                  </Box>
                  <DropDown
                    style={{ width: "100%" }}
                    value={select}
                    handlecreateNewItem={handleCreateNewItem}
                    items={[
                      { key: 1, value: "All" },
                      { key: 2, value: "Active" },
                      { key: 3, value: "Inactive" },
                      { key: 4, value: "Pending" },
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5} sm={6} md={1} lg={1} onClick={clearFilter}>
              <Box sx={{ display: { sm: "inline", md: "none" } }}>
                <IconButs
                  ibtname={"Clear"}
                  fullWidth
                  className={customStyle.dmSalesButtonOne}
                />
              </Box>
              <Box sx={{ display: { xs: "none", md: "inline" } }}>
                <IconButs
                  ibtname={"Clear"}
                  fullWidth
                  className={customStyle.dmSalesButtonOne}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Link to={`/administrator/dealeradd/`}>
                <Box sx={{ display: { sm: "inline", md: "none" } }}>
                  <IconButs
                    ibtname={ICON_BUTTON_DEALERMANAGEMENT}
                    fullWidth
                    className={customStyle.dmSalesButtonOne}
                  />
                </Box>
                <Box sx={{ display: { xs: "none", md: "inline" } }}>
                  <IconButs
                    ibtname={ICON_BUTTON_DEALERMANAGEMENT}
                    icon={<AiOutlinePlus />}
                    fullWidth
                    className={customStyle.dmSalesButtonOne}
                  />
                </Box>
              </Link>
            </Grid>
          </Grid>
        </Box>

        <Box className={styles.tableBox}>
          <TblContainer>
            <TblHead />

            {online && (isLoading || isFetching) ? (
              <TableRow>
                <TableCell colSpan={8}>
                  <CustomLoader />
                </TableCell>
              </TableRow>
            ) : (
              <TableBody>
                {dealersList &&
                  dealersList.data.map((item, key) => {
                    serialNo++;
                    return (
                      <TableRow key={key}>
                        <TableCell align="center">
                          {/* <<<<<<< HEAD
                          {(item.shop_details[0] !== undefined && item.shop_details[0].shop_name !== null)  ? item.shop_details[0].shop_name : "--shop--"}
                          {console.log("hello", item.shop_details[0])}
======= */}
                          {item.shop_details[0] &&
                            item.shop_details[0].shop_name
                            ? item.shop_details[0].shop_name
                            : ""}
                        </TableCell>
                        <TableCell align="center">
                          {item.first_name.charAt(0).toUpperCase() +
                            item.first_name.slice(1)}{" "}
                          {item.last_name}
                        </TableCell>
                        <TableCell align="center">{item.email}</TableCell>
                        <TableCell align="center">{item.phone}</TableCell>
                        <TableCell align="center">
                          {item.shop_details[0] &&
                            item.shop_details[0].address &&
                            item.shop_details[0].address.locality}
                          ,{" "}
                          {item.shop_details[0] &&
                            item.shop_details[0].address &&
                            item.shop_details[0].address.city}
                        </TableCell>
                        <TableCell align="center">
                          {item.created_by.first_name}{" "}
                          {item.created_by.last_name}
                        </TableCell>
                        <TableCell align="center">
                          {moment(item.created_at).format("DD/MM/YY")}
                        </TableCell>
                        <TableCell align="center">
                          {item.groups[0].name}
                        </TableCell>
                        <TableCell align="center">{item.status}</TableCell>
                        <TableCell align="center">
                          <Box
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            {/* <Link
                              to={`/administrator/dealerDetails/${item.id}`}
                            >
                              <IconButton>
                                <AiOutlineEye className="user-icons" />
                              </IconButton>
                            </Link> */}

                            <NavigateLink
                              url={`/administrator/dealerDetails/${item.id}`}
                              styleclass={styles.linkColor}
                              currentPage={currentPage}
                              trySearch={trySearch}
                              pageItems={pageItems}
                              selected={select}
                              itemid={item.id}
                              from={fromDate}
                              to={toDate}
                              display={
                                <IconButton>
                                  <AiOutlineEye className="user-icons" />
                                </IconButton>
                              }
                            />

                            {/* <Link to={`/administrator/dealeredit/${item.id}`}>
                              <IconButton>
                                <AiOutlineEdit className="user-icons" />
                              </IconButton>
                            </Link> */}
                            <NavigateLink
                              url={`/administrator/dealeredit/${item.id}`}
                              styleclass={styles.linkColor}
                              currentPage={currentPage}
                              trySearch={trySearch}
                              selected={select}
                              pageItems={pageItems}
                              itemid={item.id}
                              from={fromDate}
                              to={toDate}
                              display={
                                <IconButton>
                                  <AiOutlineEdit className="user-icons" />
                                </IconButton>
                              }
                            />

                            <IconButton
                              onClick={() => handleClickOpen(item.id)}
                            >
                              <AiOutlineDelete className="user-icons" />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            )}
          </TblContainer>
        </Box>

        {((dealersList && dealersList.data.length === 0) || isError) && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "100px",
            }}
          >
            <lottie-player
              src="https://assets8.lottiefiles.com/packages/lf20_agnejizn.json"
              background="transparent"
              speed="1"
              style={{ width: "300px", height: "300px" }}
              loop
              autoplay
            ></lottie-player>
          </div>
        )}

        {/* {!online && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "100px",
            }}
          >
            <lottie-player
              src="https://assets2.lottiefiles.com/packages/lf20_Zuwmxh.json"
              background="transparent"
              speed="1"
              style={{ width: "300px", height: "300px" }}
              loop
              autoplay
            ></lottie-player>
          </div>
        )} */}

        <div className="pagination">
          <div className="paginationStyle">
            <PageDropDown
              value={pageItems}
              style={{ width: "90px", height: "34px" }}
              handlePageItems={handlePageItems}
              items={[
                { key: 1, value: 10 },
                { key: 2, value: 20 },
                { key: 2, value: 30 },
              ]}
            />
            <span className="paginationPage">Per Page</span>
          </div>

          <Pagination
            count={maxPage}
            className={styles.paginationCompStyle}
            page={currentPage}
            onChange={currentData}
            variant="outlined"
          />
        </div>

        {open ? (
          <DialogBox
            open={open}
            setOpen={setOpen}
            content={
              <span>
                Are you sure you want to delete this Dealer/Technician?
              </span>
            }
            handleClose={handleClose}
            userId={userId}
            handleRemove={handleRemove}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default DealerManagement;
