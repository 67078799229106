import React, { useEffect, useState } from "react";
import {
    Modal, IconButton, Grid, Box, Typography, CircularProgress, TextField,
    InputAdornment,
    Card,
    CardContent
} from "@material-ui/core";
import { AiOutlineClose } from "react-icons/ai";
import OrderManagmentStyle from "../Assign_Office_Advisor/OrderManagmentStyle";
import salesStyle from "../SalesPersonManagement/salesStyle";
import { useNavigate, useParams } from "react-router-dom";
import Controls from "../../common/Controls/Controls";
import IconButs from "../../common/Controls/IconButs";
import useAddProduct from "../../data/inventory/useAddProduct";
import makeStyles from "../../sharedFeatures/sharedClasses";
import {
    FORMONE_BUTTON_MANAGEMENT,
} from "../../utilitis/header";
import categoryBrandStyles from "./InventoryManagmentStyle";
import productQuery from "../../data/inventory/useProductList";
import useList from "../../data/inventory/useList";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InventoryImage from "./inventoryImage";
import { FindLink } from "../../sharedFeatures/FindLink";
import { BsSearch } from "react-icons/bs";
import sample_img from '../../images/sample_add_ons.png'
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { yellow } from "@material-ui/core/colors";
import closeSquare from '../../images/close_square.svg'
import Checkbox from '@mui/material/Checkbox';
import { debounce } from "lodash";
import Search_Icon from '../../images/search_icon_add_ons.svg'

const EditInventoryProduct = (props) => {
    const { open, setOpen, heading, id } = props.EditProductModalProps

    const orderStyle = OrderManagmentStyle();
    const salesStyles = salesStyle();

    const customStyle = makeStyles();

    const categoryStyle = categoryBrandStyles();
    const [imageOpen, setImageOPen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [categoryList, setCategoryList] = useState([]);
    const [subCategoriesList, setSubCategoriesList] = useState([]);
    const [brandsList, setBrandsList] = useState([]);
    const [specificationFileds, setSpecificationFields] = useState([]);
    const [brand_id, setBrandID] = useState(null);
    const [series, setSeries] = useState("");
    const [item_code, setItemCode] = useState("");
    const [model, setModel] = useState("");
    const [mrp, setMrp] = useState(null);
    const [rcp, setRcp] = useState(null);
    const [dealer_landing_price, setDealerLandingPrice] = useState(null);
    const [customer_landing_price, setCustomerLandingPrice] = useState(null);
    const [gst_perc, setGstPerc] = useState(null);
    const [description, setDescription] = useState("");
    const [features, setFeatures] = useState('');
    const [service_items, setServiceItems] = useState('');
    const [specifications, setSpecifications] = useState('');
    const [warranty, setWarranty] = useState("");
    const [vehicle_category, setVehicleCategory] = useState("");
    const [vehicle_segment, setVehicleSegment] = useState(null);
    const [hsn_code, setHscCode] = useState("");
    const [advantages, setAdvantages] = useState("");
    const [title, setTitle] = useState("");
    const [status, setStatus] = useState("");
    const [zohoId, setZohoId] = useState("");
    const [margin, setMargin] = useState("");
    const [timeForService, setTimeForSErvices] = useState("");
    const [categoryError, setCategoryError] = useState("");
    const [subCategoryError, setSubCategoryError] = useState("");
    const [brandError, setBrandError] = useState("");
    const [itemCodeErr, setItemCodeErr] = useState("");
    const [hsnCodeErr, setHsnCodeErr] = useState("");
    const [mrpError, setMrpError] = useState("");
    const [dealerPriceError, setDealerPriceError] = useState("");
    const [timeForServiceErr, setTimeForServiceErr] = useState("");
    const [customerPriceError, setCustomerPriceError] = useState("");
    const [rcpError, setRcpError] = useState("");
    const [specsError, setSpecError] = useState({});
    const [warrentError, setWarrentError] = useState({});
    const [producttype, setProductType] = useState("");
    const [warrantyField, setWarrantyField] = useState({});
    const [productImages, setProductImages] = useState([]);
    const [imageData, setImageData] = useState([]);
    const [deletedImages, setDeletedImages] = useState([]);
    const [vcatErr, setVcatErr] = useState("");
    const [seriesErr, setSeriesErr] = useState("");

    const [vsecErr, setVsectErr] = useState("");
    const [gstErr, setgstErr] = useState("");
    const formData = new FormData();

    const navigate = useNavigate();

    let link = FindLink(`/administrator/inventoryProduct`);

    const vehicleCategoryList = [
        { id: 'All', name: 'All' },
        { id: "Premium Large", name: "Premium Large" },
        { id: "Premium Medium", name: "Premium Medium" },
        { id: "Medium Car", name: "Medium Car" },
        { id: "Small Car", name: "Small Car" },
        { id: "Large Car", name: "Large Car" },
        { id: "Medium Sedan", name: "Medium Sedan" },
        { id: "Medium SUV", name: "Medium SUV" },
        { id: "Medium MUV", name: "Medium MUV" },
        { id: "Small MUV", name: "Small MUV" },
    ];

    const vehicleSegmentList = [
        { id: 1, name: "Indian" },
        { id: 2, name: "Imported" },
    ];

    const notifyEdit = () =>
        toast.success(
            <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
                Product updated successfully.
            </span>, {
            hideProgressBar: true,
            autoClose: 2200,
        }
        );

    const qry = {
        searchQry: "",
        page: 0,
        perPageItem: 500,
    };

    const { data: productDetails, isSuccess: detailsFetched, refetch } =
        productQuery.ProductDetails(id)
    // console.log("checkdetails", productDetails)

    const { mutateAsync: editProduct, isSuccess: productEditSuccess, isLoading: invLoading } =
        useAddProduct.edit_product();

    const { mutateAsync: SelectAddOns } = useAddProduct.SelectAdd_ons(); //select add-ons

    const { mutateAsync: addImage, isLoading: imageLoading } = useAddProduct.addImage();
    const { mutateAsync: deleteImg, } = useAddProduct.deleteImage();


    const { data: catList, refetch: catRefetch } = useList.cat_List_SelectBox(qry);

    const { data: subCatList, isSuccess: subCatListSuccess, refetch: subcatRefetch } =
        useList.subcat_list_by_category("", selectedCategory);

    const { data: brandList, isSuccess: brandsListSuccess, refetch: brandRefetch } =
        useList.brand_by_category(
            selectedSubCategory ? selectedSubCategory : selectedCategory
        );

    useEffect(() => {
        refetch()
    }, [])

    useEffect(() => {
        if (catList && catList && catList.length > 0) {
            setCategoryList([...catList]);
        }
    }, [catList]);

    useEffect(() => {
        if (subCatList && subCatList.data && subCatList.data.length > 0) {
            setSubCategoriesList([...subCatList.data]);
        } else {
            setSubCategoriesList([]);
        }
    }, [subCatList, subCatListSuccess]);

    useEffect(() => {
        if (brandList && brandList.data && brandList.data.length > 0) {
            setBrandsList([...brandList.data]);
            setBrandID(null);
        } else {
            setBrandsList([]);
            setBrandID(null);
        }
    }, [brandList, brandsListSuccess]);

    useEffect(() => {
        if (selectedCategory) {
            let selected = categoryList.find((item) => item.id === selectedCategory);
            let fields = [];

            if (selected && selected.specification_fields) {
                fields = selected.specification_fields["SpecificationFields"] || [];
            }

            if (fields.length > 0) {
                fields = fields.split(",");
                setSpecificationFields(
                    fields.reduce(function (obj, v) {
                        obj[v] = "";
                        return obj;
                    }, {})
                );
            }

        }
    }, [selectedCategory]);

    //Select add-ons
    const [searchText, setSearchText] = useState('');
    const [results, setResults] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [searchQuery, setSearchQuery] = useState({ searchQry: '' });

    const {
        data: productListAddons,
        isLoading: addOnsisLoading,
        isFetching: addOnsisFetching,
        refetch: ProductSelectAddonsRefetch,
    } = productQuery.ProductList(searchQuery);

    useEffect(() => {
        setSelectedItems(productDetails?.addons)
    }, [productDetails?.addons])

    // Update search query and refetch data on search text change
    useEffect(() => {
        const debouncedFetch = debounce(() => {
            if (searchText === '') {
                setResults([]);
            } else {
                setSearchQuery({ searchQry: searchText });
                ProductSelectAddonsRefetch()
                setResults(productListAddons?.data);
            }
        }, 500);

        debouncedFetch();

        return () => {
            debouncedFetch.cancel();
        };
    }, [searchText, productListAddons]);

    // Add or remove item from selected list
    const toggleSelectItem = (item) => {
        setSelectedItems((prevSelected) => {
            if (prevSelected?.some((selected) => selected?.id === item?.id)) {
                return prevSelected?.filter((selected) => selected?.id !== item?.id);
            } else {
                return [...prevSelected, item];
            }
        });
    };

    // Remove item from selected items
    const handleRemoveSelectedItem = (id) => {
        setSelectedItems((prevSelected) =>
            prevSelected?.filter((item) => item.id !== id)
        );
    };

    const submitData = async (e) => {
        e.preventDefault();

        let err = false;

        if (!selectedCategory) {
            err = true;
            setCategoryError("Category is required");
        }

        if (hsn_code.length === 0 && !hsn_code) {
            err = true;
            setHsnCodeErr("HSN code is required");
        } else {
            err = false;
        }

        if (subCategoriesList.length > 0 && !selectedSubCategory) {
            err = true;
            setSubCategoryError("Sub Category is required");
        }

        if (item_code.length === 0 || !item_code) {
            err = true;
            setItemCodeErr("Item code is required");
        }

        if (hsn_code.length === 0 || !hsn_code) {
            err = true;
            setHsnCodeErr("HSN code is required");
        }

        if (!brand_id || brand_id === "") {
            err = true;
            setBrandError("Brand is required");
        }

        if (!vehicle_category || vehicle_category === "") {
            err = true;
            setVcatErr("Vehicle Category is required");
        }

        if (!vehicle_segment || vehicle_segment === "") {
            err = true;
            setVsectErr("Vehicle segment is required");
        }

        if (!gst_perc || gst_perc === "") {
            err = true;
            setgstErr("GST percentage is required");
        }

        if (
            !hsn_code ||
            hsn_code === "" ||
            hsn_code.length < 4 || // Minimum length is 4
            hsn_code.length > 8 || // Maximum length is 8
            !/^\d+$/.test(hsn_code)
        ) {
            err = true;
            setHsnCodeErr("HSN Code must contain between 4 and 8 digits");
        }

        if ((!mrp || mrp === 0) && (!rcp || rcp === 0)) {
            err = true;
            setMrpError("MRP / RCP is required");
            setRcpError("MRP / RCP is required");
        }

        if (!series || series === "") {
            err = true;
            setSeriesErr("Series is required");
        }

        if (!dealer_landing_price || dealer_landing_price === 0) {
            err = true;
            setDealerPriceError("Dealer Price is required");
        }

        if (!customer_landing_price || customer_landing_price === 0) {
            err = true;
            setCustomerPriceError("Customer Price is required");
        }

        Object.keys(specificationFileds).forEach((key) => {
            if (specificationFileds[key] === "" || !specificationFileds[key]) {
                err = true;
                setSpecError((prev) => ({
                    ...prev,
                    [key]: "This field is required",
                }));
            }
        });

        if (err) {
            return;
        }

        try {
            const res = await editProduct({
                id: id,
                series: series,
                item_code: item_code,
                model: model,
                mrp: mrp === "" ? 0 : parseFloat(mrp),
                rcp: rcp === "" ? 0 : parseFloat(rcp),
                dealer_landing_price: dealer_landing_price && parseFloat(dealer_landing_price),
                customer_landing_price: customer_landing_price && parseFloat(customer_landing_price),
                gst_perc: gst_perc && parseFloat(gst_perc),
                margin: (margin === null || margin === '' || margin < 0) ? 0 : parseInt(margin),
                description: description,
                features: { features: features },
                service_items: { 0: service_items },
                specifications: specificationFileds,
                warranty: warrantyField,
                vehicle_category: vehicle_category,
                vehicle_segment: vehicle_segment,
                hsn_code: hsn_code,
                advantages: advantages,
                title: productDetails?.title,
                brand_id: brand_id,
            });

            if (res.status === 200) {
                SelectAddOns({
                    target_product_id: id,
                    addons_product_id: selectedItems?.map((o) => o?.addons_product_id ? o?.addons_product_id?.id : o?.id)
                })
                if (producttype !== "service") {
                    Object.keys(imageData).forEach((key, i) => {
                        formData.append(`image${i + 1}`, imageData[key]);
                    });
                    await addImage({ id: id, image: formData });
                    await deleteImg({ id: id, image: deletedImages });
                }

                toast.success("Product updated successfully", {
                    hideProgressBar: true,
                    autoClose: 2200,
                });
                refetch();
                // navigate(link);
                setOpen(false)
            }
        } catch (err) {
            if (err.response) {
                const { status, data } = err.response;
                if (status !== 500) {
                    if (data.detail) {
                        toast.warn(data.detail, {
                            hideProgressBar: true,
                            autoClose: 2200,
                        });
                    } else if (data.item_code) {
                        toast.warn(data.item_code[0], {
                            hideProgressBar: true,
                            autoClose: 2200,
                        });
                    }
                } else {
                    toast.error("Something went wrong", {
                        hideProgressBar: true,
                        autoClose: 2200,
                    });
                }
            }
        }
    };


    // useEffect(() => {
    //   if (productEditSuccess) {
    //     notifyEdit();
    //     handleClose();
    //   }
    // }, [productEditSuccess]);



    const handleClose = () => {
        // navigate(AppRoutes.ADMIN + EndPoint.INVENTORY_PRODUCT);
        // navigate(link);
        setOpen(false)
    };

    const handleCategory = (e) => {
        setCategoryError("");
        setSelectedCategory(e.target.value);
    };

    const handleSubCategory = (e) => {
        setSubCategoryError("");
        setSelectedSubCategory(e.target.value);
    };

    const handleBrand = (e) => {
        setBrandError("");
        setBrandID(e.target.value);
    };

    useEffect(() => {
        if (detailsFetched) {
            if (
                productDetails &&
                productDetails.brand_id &&
                productDetails.brand_id.category_id &&
                productDetails.brand_id.category_id.parent_id
            ) {
                setSelectedCategory(productDetails.brand_id.category_id.parent_id.id);
            } else if (
                productDetails &&
                productDetails.brand_id &&
                productDetails.brand_id.category_id
            ) {
                setSelectedCategory(productDetails.brand_id.category_id.id);
            }

            if (
                productDetails &&
                productDetails.brand_id &&
                productDetails.brand_id.category_id &&
                productDetails.brand_id.category_id.parent_id
            ) {
                setSelectedSubCategory(productDetails.brand_id.category_id.id);
            }

            if (productDetails && productDetails.brand_id) {
                setBrandID(productDetails.brand_id.id);
            }

            if (productDetails && productDetails.vehicle_category) {
                setVehicleCategory(productDetails.vehicle_category);
            }

            if (productDetails && productDetails.vehicle_segment) {
                setVehicleSegment(productDetails.vehicle_segment);
            }

            if (productDetails && productDetails.series) {
                setSeries(productDetails.series);
            }

            if (productDetails && productDetails.item_code) {
                setItemCode(productDetails.item_code);
            }

            if (productDetails && productDetails.model) {
                setModel(productDetails.model);
            }

            if (productDetails && productDetails.hsn_code) {
                setHscCode(productDetails.hsn_code);
            }

            if (productDetails && productDetails.images) {
                setProductImages(productDetails.images);

            }
            if (productDetails && productDetails.mrp) {
                setMrp(productDetails.mrp);
            }

            if (productDetails && productDetails.rcp) {
                setRcp(productDetails.rcp);
            }

            if (productDetails && productDetails.dealer_landing_price) {
                setDealerLandingPrice(productDetails.dealer_landing_price);
            }

            if (productDetails && productDetails.customer_landing_price) {
                setCustomerLandingPrice(productDetails.customer_landing_price);
            }

            if (productDetails && productDetails.gst_perc) {
                setGstPerc(productDetails.gst_perc);
            }

            if (productDetails && productDetails.advantages) {
                setAdvantages(productDetails.advantages);
            }

            if (productDetails && productDetails.description) {
                setDescription(productDetails.description);
            }

            if (productDetails && productDetails.specifications) {
                setSpecificationFields(productDetails.specifications);
            }

            if (productDetails && productDetails.warranty) {
                setWarrantyField(productDetails.warranty);
            }

            if (
                productDetails &&
                productDetails.features && productDetails.features
            ) {
                setFeatures(productDetails.features.features);
            }
            if (productDetails && productDetails.service_items && productDetails.service_items[0]) {
                setServiceItems(productDetails.service_items[0]);
            }
            if (productDetails && productDetails.status) {
                setStatus(productDetails && productDetails.status === true
                    ? "Active"
                    : "Inactive",);
            }
            if (productDetails && productDetails.title) {
                setTitle(productDetails.title);
            }
            if (productDetails && productDetails.zoho_item_id) {
                setZohoId(productDetails.zoho_item_id);
            }
            if (productDetails && productDetails.margin) {
                setMargin(productDetails.margin);
            }
            if (productDetails && productDetails.margin) {
                setTimeForSErvices(productDetails.margin);
            }

            if (productDetails && productDetails.warranty) {
                setWarranty(productDetails.warranty);
            }

            if (
                productDetails &&
                productDetails.brand_id &&
                productDetails.brand_id.category_id &&
                productDetails.brand_id.category_id.type === 1
            ) {
                setProductType(
                    'Product'
                );
            } else if (
                productDetails &&
                productDetails.brand_id &&
                productDetails.brand_id.category_id &&
                productDetails.brand_id.category_id.type === 2
            ) {
                setProductType(
                    'Services'
                );
            }
        }
    }, [detailsFetched, categoryList, subCatList, brandList]);

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >

            <div className="modalWrap">
                <div>
                    <Box>
                        <Box className={orderStyle.assignlinkbox}>
                            <Box></Box>
                            <Typography className={salesStyles.assignTitle}>
                                {heading}
                            </Typography>
                            <Box className={orderStyle.assignlinkclose}>
                                <IconButton onClick={() => setOpen(false)}>
                                    <AiOutlineClose />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                </div>
                <div>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        Category
                                        <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Controls.EditSelectBox
                                        className={categoryStyle.formTextBar2}
                                        name="inventoryType"
                                        disabled
                                        label="Select Category"
                                        value={selectedCategory}
                                        options={categoryList}
                                        onChange={(e) => handleCategory(e)}
                                        error={categoryError}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        {subCategoriesList.length > 0 && (
                            <Grid item xs={12} md={6}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} sm={4}>
                                        <Typography className={categoryStyle.label}>
                                            Sub Category
                                            <span style={{ color: "red" }}>*</span>

                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <Controls.EditSelectBox
                                            className={categoryStyle.formTextBar2}
                                            name="inventoryType"
                                            label="Select Sub Category"
                                            disabled
                                            value={selectedSubCategory}
                                            options={subCategoriesList}
                                            onChange={(e) => handleSubCategory(e)}
                                            error={subCategoryError}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        {producttype !== "Service" && (
                            <Grid item xs={12} md={6}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} sm={4}>
                                        <Typography className={categoryStyle.label}>
                                            Brand
                                            <span style={{ color: "red" }}>*</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <Controls.EditSelectBox
                                            className={categoryStyle.formTextBar2}
                                            name="inventoryType"
                                            label="Select Brand"
                                            disabled
                                            options={brandsList}
                                            value={brand_id}
                                            onChange={(e) => handleBrand(e)}
                                            error={brandError}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        Vehicle Category
                                        <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Controls.EditSelectBox
                                        className={categoryStyle.formTextBar2}
                                        name="inventoryType"
                                        label={"Select Vehicle Category"}
                                        value={vehicle_category}
                                        options={vehicleCategoryList}
                                        onChange={(e) => {
                                            setVehicleCategory(e.target.value);
                                            setVcatErr("");
                                        }}
                                        error={vcatErr}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        {producttype !== "Services" && (
                            <Grid item xs={12} md={6}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} sm={4}>
                                        <Typography className={categoryStyle.label}>
                                            Vehicle Segment
                                            <span style={{ color: "red" }}>*</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <Controls.EditSelectBox
                                            className={categoryStyle.formTextBar2}
                                            name="inventoryType"
                                            label="Select Vehicle Segment"
                                            value={vehicle_segment}
                                            options={vehicleSegmentList}
                                            onChange={(e) => {
                                                setVehicleSegment(e.target.value);
                                                setVsectErr("");
                                            }}
                                            error={vsecErr}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        Item Code
                                        <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Controls.InputField
                                        className={categoryStyle.formTextBar2}
                                        name="itemcode"
                                        placeholder="Item Code"
                                        value={item_code}
                                        onChange={(e) => {
                                            setItemCode(e.target.value);
                                            setItemCodeErr("");
                                        }}
                                        type="text"
                                        error={itemCodeErr}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        {producttype !== "Services" && (
                            <>
                                {" "}
                                <Grid item xs={12} md={6}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} sm={4}>
                                            <Typography className={categoryStyle.label}>
                                                Series
                                                <span style={{ color: "red" }}>*</span>

                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <Controls.InputField
                                                className={categoryStyle.formTextBar2}
                                                name="series"
                                                placeholder="Series"
                                                value={series}
                                                onChange={(e) => setSeries(e.target.value)}
                                                type="text"
                                                error={seriesErr}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} sm={4}>
                                            <Typography className={categoryStyle.label}>
                                                Model
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <Controls.InputField
                                                className={categoryStyle.formTextBar2}
                                                name="model"
                                                placeholder="Model"
                                                value={model}
                                                onChange={(e) => setModel(e.target.value)}
                                                type="text"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        Title
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Controls.InputField
                                        className={categoryStyle.formTextBar2}
                                        name="title"
                                        placeholder="Title"
                                        value={title}
                                        // onChange={(e) => setModel(e.target.value)}
                                        type="text"
                                        disabled={true}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        Status
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Controls.InputField
                                        className={categoryStyle.formTextBar2}
                                        name="status"
                                        placeholder="Status"
                                        value={status}
                                        onChange={(e) => setModel(e.target.value)}
                                        type="text"
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        Zoho id
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.InputField
                        className={categoryStyle.formTextBar2}
                        name="zohoId"
                        placeholder="Zoho id"
                        value={zohoId}
                        onChange={(e) => setModel(e.target.value)}
                        type="text"
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid> */}

                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        HSN Code
                                        <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Controls.InputField
                                        className={categoryStyle.formTextBar2}
                                        name="hsn_code"
                                        placeholder="HSN Code"
                                        value={hsn_code}
                                        onChange={(e) => {
                                            const value = e.target.value.replace(/\D/g, '');
                                            setHsnCodeErr("");
                                            setHscCode(value);
                                        }}
                                        type="text"
                                        error={hsnCodeErr}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        GST Perc
                                        <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Controls.InputField
                                        className={categoryStyle.formTextBar2}
                                        name="gst_perc"
                                        placeholder="GST Perc"
                                        value={gst_perc}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const regex = /^[0-9]*\.?[0-9]*$/;
                                            if (regex.test(value)) {
                                                setGstPerc(value)
                                                setgstErr("");
                                            }
                                        }}
                                        type="text"
                                        error={gstErr}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        MRP
                                        <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Controls.InputField
                                        className={categoryStyle.formTextBar2}
                                        name="mrp"
                                        inputProps={{ min: 0 }}
                                        placeholder="Eg. 1234"
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const regex = /^[0-9]*\.?[0-9]*$/;
                                            if (regex.test(value)) {
                                                setMrp(value);
                                            }
                                            if (value != 0) {
                                                setMrpError("");
                                                setRcpError("");
                                            }
                                        }}
                                        type="text"
                                        value={mrp}
                                        error={mrpError}
                                        onKeyDown={() => setMrpError("")}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        RCP
                                        <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Controls.InputField
                                        className={categoryStyle.formTextBar2}
                                        name="RCP"
                                        inputProps={{ min: 0 }}
                                        placeholder="Eg. 1234"
                                        error={rcpError}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const regex = /^[0-9]*\.?[0-9]*$/;
                                            if (regex.test(value)) {
                                                setRcp(value);
                                            }
                                            if (value != 0) {
                                                setRcpError("");
                                                setMrpError("");
                                            }
                                        }}
                                        type="text"
                                        value={rcp}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        Customer Price
                                        <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Controls.InputField
                                        className={categoryStyle.formTextBar2}
                                        name="customer_landing_price"
                                        placeholder="Eg. 1234"
                                        value={customer_landing_price}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const regex = /^[0-9]*\.?[0-9]*$/;
                                            if (regex.test(value)) {
                                                setCustomerLandingPrice(value);
                                            }
                                        }
                                        }
                                        type="text"
                                        error={customerPriceError}
                                        onKeyDown={() => setCustomerPriceError("")}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        Dealer Price
                                        <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Controls.InputField
                                        className={categoryStyle.formTextBar2}
                                        name="dealer_landing_price"
                                        placeholder="Eg. 1234"
                                        value={dealer_landing_price}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const regex = /^[0-9]*\.?[0-9]*$/;
                                            if (regex.test(value)) {
                                                setDealerLandingPrice(value);
                                            }
                                        }
                                        }
                                        type="text"
                                        error={dealerPriceError}
                                        onKeyDown={() => setDealerPriceError("")}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        Margin
                                        {/* <span style={{ color: "red" }}>*</span> */}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Controls.InputField
                                        className={categoryStyle.formTextBar2}
                                        name="margin"
                                        placeholder="Margin"
                                        value={margin}
                                        onChange={(e) => setMargin(e.target.value)}
                                        type="number"
                                    // error={dealerPriceError}
                                    // onKeyDown={() => setm("")}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        Description
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Controls.TextArea
                                        className={categoryStyle.Inventory_formtTextArea}
                                        name="description"
                                        value={description}
                                        placeholder="Eg. Advanced safety, excellent handling, brilliant traction and improved stability on all conditions."
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        Features
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Controls.TextArea
                                        className={categoryStyle.Inventory_formtTextArea}
                                        name="features"
                                        value={features}
                                        placeholder="Eg. TR414 Tubeless Car Tyre Valve Stem Cap Good Quality"
                                        onChange={(e) => setFeatures(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        Advantages
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Controls.TextArea
                                        className={categoryStyle.Inventory_formtTextArea}
                                        name="advantages"
                                        placeholder="Eg. durability, excellent braking, superior performance"
                                        value={advantages}
                                        onChange={(e) => setAdvantages(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        {producttype === "Services" && (
                            <>
                                {/* <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            Time for services
                            <span style={{ color: "red" }}>*</span>

                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.InputField
                            className={categoryStyle.formTextBar2}
                            name="timeForServices"
                            placeholder="Time for services"
                            value={timeForService}
                            onChange={(e) => setTimeForSErvices(e.target.value)}
                            type="text"
                            error={timeForServiceErr}
                            onKeyDown={() => setTimeForServiceErr("")}
                          />
                        </Grid>
                      </Grid>
                    </Grid> */}
                                <Grid item xs={12} md={6}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} sm={4}>
                                            <Typography className={categoryStyle.label}>
                                                Services items
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <Controls.TextArea
                                                className={categoryStyle.Inventory_formtTextArea}
                                                name="servicesItems"
                                                value={service_items}
                                                placeholder="Services Items"
                                                onChange={(e) => setServiceItems(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            Specifications
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.TextArea
                            className={categoryStyle.textarea2}
                            name="specifications"
                            value={specifications}
                            label="Specifications"
                            onChange={(e) => setSpecifications(e.target.value)}
                          />
                        </Grid>
                      </Grid>
                    </Grid> */}
                            </>
                        )}
                        {/* {Object.keys(warranty).length > 0 &&
                  Object.keys(warranty).map((item, idx) => {
                    return (
                      <Grid key={idx} item xs={12} md={6}>
                        <Grid container alignItems="center">
                          <Grid item xs={12} sm={4}>
                            <Typography className={categoryStyle.label}>
                              {`Free ${item}`}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Controls.InputField
                              className={categoryStyle.formTextBar2}
                              name={item}
                              placeholder={`Enter ${item}`}
                              value={warrantyField[item]}
                              onChange={(e) =>
                                setWarrantyField({
                                  ...warrantyField,
                                  [item]: e.target.value,
                                })
                              }
                              type="text"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })} */}

                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        Free warranty
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Controls.InputField
                                        className={categoryStyle.formTextBar2}
                                        name={'warranty'}
                                        placeholder={`Enter warranty`}
                                        value={warrantyField.warranty}
                                        onChange={(e) =>
                                            setWarrantyField({
                                                ...warrantyField,
                                                warranty: e.target.value,
                                            })
                                        }
                                        type="text"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        {Object.keys(specificationFileds).length > 0 &&
                            Object.keys(specificationFileds).map((item, idx) => {
                                return (
                                    <Grid key={idx} item xs={12} md={6}>
                                        <Grid container alignItems="center">
                                            <Grid item xs={12} sm={4}>
                                                <Typography className={categoryStyle.label}>
                                                    {item}
                                                    <span style={{ color: "red" }}>*</span>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={8}>
                                                <Controls.InputField
                                                    className={categoryStyle.formTextBar2}
                                                    name={item}
                                                    placeholder={`Enter ${item}`}
                                                    value={specificationFileds[item]}
                                                    inputProps={{ maxLength: 500 }}
                                                    error={specsError[item]}
                                                    onChange={(e) => {
                                                        setSpecificationFields({
                                                            ...specificationFileds,
                                                            [item]: e.target.value,
                                                        })
                                                        setSpecError({
                                                            ...specsError, [item]: ''
                                                        })
                                                    }
                                                    }
                                                    type="text"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        {producttype !== "Services" && <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        Images
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <IconButs
                                        ibtname={"Edit image"}
                                        className={categoryStyle.imageButton}
                                        onClick={() => setImageOPen(true)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>}

                        <Grid item xs={12} md={12} style={{ marginRight: "30px" }}>
                            <Grid container alignItems="flex-start">
                                <Grid item xs={12} sm={2}>
                                    <Typography className={categoryStyle.label}>
                                        Select Add-ons
                                    </Typography>
                                </Grid>
                                {/* Search Bar and Results Section */}
                                <Grid
                                    item
                                    xs={12}
                                    sm={10}
                                >
                                    <TextField
                                        placeholder="Select Add-ons"
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        // onChange={(e) => handleSearch(e)}
                                        variant="outlined"
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                addOnsisLoading || addOnsisFetching ? <CircularProgress position="end" style={{ marginRight: "5px" }} size={24} /> :
                                                    <InputAdornment position="end">
                                                        <img src={Search_Icon} style={{ marginRight: "5px" }} />
                                                    </InputAdornment>
                                            ),
                                        }}
                                    />

                                    {/* Results Cards */}
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            maxHeight: results?.length > 5 ? '400px' : 'auto',
                                            overflowY: results?.length > 5 ? 'auto' : 'visible',
                                            '&::-webkit-scrollbar': {
                                                width: '4px',
                                                height: '40px',
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                                borderRadius: '4px 0px 0px 0px',
                                                backgroundColor: '#444445',
                                                opacity: 0,
                                            },
                                            '&::-webkit-scrollbar-track': {
                                                backgroundColor: 'transparent',
                                                gap: '0px',
                                            },
                                        }}>
                                        {results?.map((item) => (
                                            <Box
                                                key={item.id}
                                                sx={{
                                                    width: '100%',
                                                    // height: 60,
                                                    border: "1px solid #D2D6DA",
                                                    // borderRadius: "5px",
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    // marginBottom: "10px",
                                                }}
                                            >
                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: 3, padding: "8px" }}>
                                                    <Typography style={{ fontSize: '14px' }}>{item?.title}</Typography>
                                                    <Typography style={{ fontSize: '12px', color: "#989898" }}>{`₹ ${item?.mrp}`}</Typography>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", gap: 5 }}>
                                                        <Typography style={{ fontSize: '13px', color: "#989898" }}>{item?.brand_id?.category_id?.parent_id?.name}</Typography>
                                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                                            <Box
                                                                component="span"
                                                                sx={{
                                                                    display: 'inline-block',
                                                                    width: '6px',
                                                                    height: '6px',
                                                                    backgroundColor: "#E5E5E5",
                                                                    borderRadius: '50%',
                                                                }}
                                                            />
                                                            <Typography style={{ fontSize: '13px', color: "#989898" }}>{item?.brand_id?.category_id?.name}</Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>

                                                <Checkbox
                                                    checked={selectedItems?.some((selected) => selected?.id === item?.id)}
                                                    onChange={() => toggleSelectItem(item)}
                                                    sx={{
                                                        color: "#444445",
                                                        '&.Mui-checked': {
                                                            color: yellow[600],
                                                        },
                                                    }}
                                                />
                                            </Box>
                                        ))}
                                    </Box>

                                    {/* Selected Items Section */}
                                    {selectedItems && selectedItems?.length > 0 &&
                                        <Grid item xs={12} style={{ marginTop: searchText ? "20px" : "0px" }}>
                                            <Typography style={{ fontSize: '14px', color: "#989898" }}>Selected Items</Typography>
                                            <Grid container spacing={2} mt={1}>
                                                {selectedItems?.map((item) => (
                                                    <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                padding: '5px 2px',
                                                                position: 'relative',
                                                                border: '1px solid #ccc',
                                                                borderRadius: '8px',
                                                                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                                                                width: '100%',
                                                                height: '100%',
                                                                minWidth: '100px',
                                                            }}
                                                        >
                                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: 3, padding: "8px" }}>
                                                                <Typography style={{ fontSize: '14px' }}>{item?.addons_product_id ? item?.addons_product_id?.title : item?.title}</Typography>
                                                                <Typography style={{ fontSize: '12px', color: "#989898" }}>{`₹ ${item?.addons_product_id ? item?.addons_product_id?.mrp : item?.mrp}`}</Typography>
                                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", gap: 5 }}>
                                                                    <Typography style={{ fontSize: '13px', color: "#989898" }}>{item?.addons_product_id ? item?.addons_product_id?.brand_id?.category_id?.parent_id?.name : item?.brand_id?.category_id?.parent_id?.name}</Typography>
                                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                                                        <Box
                                                                            component="span"
                                                                            sx={{
                                                                                display: 'inline-block',
                                                                                width: '6px',
                                                                                height: '6px',
                                                                                backgroundColor: "#E5E5E5",
                                                                                borderRadius: '50%',
                                                                            }}
                                                                        />
                                                                        <Typography style={{ fontSize: '13px', color: "#989898" }}>{item?.addons_product_id ? item?.addons_product_id?.brand_id?.category_id?.name : item?.brand_id?.category_id?.name}</Typography>
                                                                    </Box>
                                                                </Box>
                                                                <img
                                                                    src={closeSquare}
                                                                    onClick={() => handleRemoveSelectedItem(item.id)}
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: "5px",
                                                                        right: 5,
                                                                        cursor: "pointer"
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container>
                                <Grid xs={12} sm={6}>
                                    <Box
                                        sx={{
                                            display: { xs: "inline", sm: "flex" },
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <IconButs
                                            ibtname={invLoading || imageLoading ? <CircularProgress size={24} /> : "Update"}
                                            className={categoryStyle.formButtonAdd}
                                            onClick={submitData}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Box
                                        sx={{
                                            display: { xs: "inline", sm: "flex" },
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <IconButs
                                            onClick={handleClose}
                                            ibtname={FORMONE_BUTTON_MANAGEMENT}
                                            className={categoryStyle.cancelButton}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <InventoryImage
                    open={imageOpen}
                    setOpen={setImageOPen}
                    data={productImages}
                    setProductImages={setProductImages}
                    setDeletedImages={setDeletedImages}
                    imageData={setImageData}
                    newItem={false}
                />
            </div>
        </Modal>
    )
}

export default EditInventoryProduct