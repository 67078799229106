import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
// import "./PaginationStyle.css";

function usePagination(data, itemsPerPage) {
  const [searchParams,setSearchParams] = useSearchParams();
  const page = searchParams.get("page");
  const pageInitial = page !== null ? parseInt(page, 10) : 1;
  const [currentPage, setCurrentPage] = useState(pageInitial);
  useEffect(()=>{
    if(page){
      
      setCurrentPage(Number(page))
    }else{
      setCurrentPage(pageInitial)
    }
  },[page])
 
  const [slNo, setSlNo] = useState(0);

  const maxPage = Math.ceil(data && data.count / itemsPerPage);

  const currentData = (event, value) => {
    setCurrentPage(value);

    searchParams.set("page", value);
    const newQueryString = searchParams.toString();
    const newUrl = `${window.location.pathname}?${newQueryString}`;
    window.history.replaceState({}, "", newUrl);

    setSlNo(value - 1);
  };

  return {
    currentData,
    currentPage,
    maxPage,
    slNo,
    setSlNo,
    setSearchParams,
    setCurrentPage
    

  };
}

export default usePagination;
