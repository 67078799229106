import React, { useState, useEffect } from "react";
import useTable from "../../sharedFeatures/useTable";
import {
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Box,
} from "@material-ui/core";
import { Link, useSearchParams } from "react-router-dom";
import HeadCells from "../../common/Headcells";
import { AiOutlineEye, AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import makeStyles from "../../sharedFeatures/sharedClasses";
import { TXT_USERMANAGEMENT } from "../../utilitis/header";
import HeadBar from "../../components/HeadBar/HeadBar";
import Controls from "../../common/Controls/Controls";
import { Pagination } from "@material-ui/lab";
import PageDropDown from "../../common/Controls/PageDropDown";
import CustomLoader from "../../features/CustomLoader";
import PageCounter from "../../common/PageCounter";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import DialogBox from "../../common/Controls/DialogBox";
import NavigateLink from "../../common/Controls/NavigateLink";
import useUsersQuery from "../../data/user/useUsersQuery";
import usePagination from "../../common/Controls/usePagination";

import "../DealerManagement/AddEditDealer/toast.css";

const notifyDelete = () =>
  toast.success(
    <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
      User deleted successfully.
    </span>, {
    hideProgressBar: true,
    autoClose: 2200,
  }
  );

const UserManagement = () => {
  //aimee
  // const [searchParams] = useSearchParams();
  const searchParams = new URLSearchParams(window.location.search);
  const searchp = searchParams.get("search");
  const itemsno = searchParams.get("itemsno");
  const page = searchParams.get("page");
  const [trySearch, setTrySearch] = useState(searchp !== null ? searchp : "");
  const [pageItems, setPageItems] = useState(itemsno !== null ? itemsno : 10);
  const [currentpageNo, setcurrentpageNo] = useState(page !== null ? page : 1);

  const [openDelete, setOpenDelete] = useState(false);
  const [userId, setUserId] = useState();
  // const navigate = useNavigate();

  const qry = {
    searchQry: trySearch,
    page: page !== null ? page : currentpageNo,
    perPageItems: pageItems,
  };

  useEffect(() => {
    setcurrentpageNo(1);
  }, [trySearch, pageItems]);

  const {
    data: usersList,
    isLoading,
    isError,
    isFetching,
    refetch
  } = useUsersQuery.users_list(qry);
  useEffect(() => {
    refetch()
  }, [])
  const { isSuccess: editUserSuccess } = useUsersQuery.update_user_details();

  const { mutateAsync: remove } = useUsersQuery.delete_customer();

  const styles = makeStyles();

  const { currentData, currentPage, maxPage, setSearchParams } = usePagination(
    usersList !== undefined && usersList,
    pageItems,

  );

  useEffect(() => {
    setcurrentpageNo(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setcurrentpageNo(1);
  }, [trySearch]);

  //if last page and perpage changes go to first page
  useEffect(() => {
    if (currentpageNo > maxPage) {
      setcurrentpageNo(maxPage);
    }
  }, [pageItems]);

  const { totalCount } = PageCounter(usersList && usersList.count);

  const handlePageItems = (e) => {
    searchParams.set("page", "1");
    setSearchParams(searchParams);
    setPageItems(e.target.value);
  };

  const { TblContainer, TblHead } = useTable(HeadCells.userHead, totalCount);

  //aimee
  // const handleToCar = (e, id) => {
  //   e.preventDefault();
  //   navigate(
  //     "/administrator/usersDetails/" +
  //       id +
  //       "?page=" +
  //       currentPage +
  //       "&search=" +
  //       trySearch +
  //       "&itemsno=" +
  //       pageItems
  //   );
  // };

  // const handleToOrder = (e, id) => {
  //   e.preventDefault();
  //   navigate(
  //     "/administrator/usersDetails/" +
  //       id +
  //       "/2" +
  //       "?page=" +
  //       currentPage +
  //       "&search=" +
  //       trySearch +
  //       "&itemsno=" +
  //       pageItems
  //   );
  // };

  // //aimee
  // const handleEye = (e, id) => {
  //   e.preventDefault();
  //   navigate(
  //     "/administrator/usersEdit/" +
  //       id +
  //       "/2" +
  //       "?page=" +
  //       currentPage +
  //       "&search=" +
  //       trySearch +
  //       "&itemsno=" +
  //       pageItems
  //   );
  // };

  // const handleEdit = (e, id) => {
  //   e.preventDefault();
  //   navigate(
  //     "/administrator/usersDetails/" +
  //       id +
  //       "/2" +
  //       "?page=" +
  //       currentPage +
  //       "&search=" +
  //       trySearch +
  //       "&itemsno=" +
  //       pageItems
  //   );
  // };

  React.useEffect(() => {
    if (editUserSuccess) {
      toast.success("User updated successfully", {
        hideProgressBar: true,
        autoClose: 2200,
      });
    }
  }, [editUserSuccess]);

  const handleClose = () => {
    setOpenDelete(false);
  };

  const handleClickOpen = (id) => {
    setOpenDelete(true);
    setUserId(id);
  };

  const handleRemove = async () => {
    if (userId)
      remove(userId)
        .then((res) => {
          if (res && res.data && res.data.message) {
            toast.warning(
              <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
                {res && res.data && res.data.message
                }
              </span>, {
              hideProgressBar: true,
              autoClose: 2200,
            }
            );
            setOpenDelete(false);

          } else {
            notifyDelete();
            setOpenDelete(false);
          }

        })
        .catch((err) => {

          if (err.response.status !== 403) {
            toast.error("Can 't delete Customer", {
              hideProgressBar: true,
              autoClose: 2200,
            });
          } else {
            if (err.response.data.detail) {
              toast.warn(err.response.data.detail, {
                hideProgressBar: true,
                autoClose: 2200,
              });
            }
          }
          setOpenDelete(false);
        });
  };

  return (
    <>
      <HeadBar title={TXT_USERMANAGEMENT} />
      <div className={styles.listdivStyle}>
        <div className={styles.searchBoxParent}>
          <Controls.StyledSearch
            placeholder="Search"
            className={styles.searchBar}
            value={trySearch}
            onChange={(e) => {
              searchParams.set("page", "1");
              setSearchParams(searchParams);
              setTrySearch(e.target.value);
            }}
            name="usersearch"
            icon={<BsSearch style={{ color: "#67748E" }} />}
          />
        </div>
        <Box className={styles.tableBox}>
          <TblContainer>
            <TblHead />
            <TableBody>
              {(isLoading || isFetching) && (
                <TableRow>
                  <TableCell colSpan={8}>
                    <CustomLoader />
                  </TableCell>
                </TableRow>
              )}
              {!isLoading &&
                !isFetching &&
                usersList &&
                usersList.data &&
                usersList.data.map((item) => {
                  return (
                    <TableRow>
                      <TableCell
                        align="center"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {item.first_name + " " + item.last_name}
                      </TableCell>
                      <TableCell align="center">{item.email}</TableCell>
                      <TableCell align="center">{item.phone}</TableCell>
                      <TableCell
                        align="center"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {item.address && item.address.city}
                      </TableCell>
                      <TableCell align="center">
                        {/* <Link
                          to=""
                          className={styles.linkColor}
                          onClick={(e) => handleToCar(e, item.id)}
                        >
                          View
                        </Link> */}
                        <NavigateLink
                          url={`/administrator/usersDetails/${item.id}`}
                          styleclass={styles.linkColor}
                          currentPage={currentPage}
                          trySearch={trySearch}
                          pageItems={pageItems}
                          itemid={item.id}
                          display="view"
                        />
                      </TableCell>
                      <TableCell align="center">
                        {/* <Link
                          to=""
                          className={styles.linkColor}
                          onClick={(e) => handleToOrder(e, item.id)}
                        >
                          View
                        </Link> */}
                        <NavigateLink
                          url={`/administrator/usersDetails/${item.id}/2`}
                          styleclass={styles.linkColor}
                          currentPage={currentPage}
                          trySearch={trySearch}
                          pageItems={pageItems}
                          itemid={item.id}
                          display="view"
                        />
                      </TableCell>
                      <TableCell align="center">{item.status}</TableCell>
                      <TableCell align="center">
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          {/* <Link to="" onClick={(e) => handleEye(e, item.id)}>
                            <IconButton>
                              <AiOutlineEye className="user-icons" />
                            </IconButton>
                          </Link> */}

                          <NavigateLink
                            url={`/administrator/usersDetails/${item.id}/2`}
                            styleclass={styles.linkColor}
                            currentPage={currentPage}
                            trySearch={trySearch}
                            pageItems={pageItems}
                            itemid={item.id}
                            display={
                              <IconButton>
                                <AiOutlineEye className="user-icons" />
                              </IconButton>
                            }
                          />

                          {/* <Link to="" onClick={(e) => handleEdit(e, item.id)}>
                            <IconButton>
                              <AiOutlineEdit className="user-icons" />
                            </IconButton>
                          </Link> */}

                          <NavigateLink
                            url={`/administrator/usersEdit/${item.id}/`}
                            styleclass={styles.linkColor}
                            currentPage={currentPage}
                            trySearch={trySearch}
                            pageItems={pageItems}
                            itemid={item.id}
                            display={
                              <IconButton>
                                <AiOutlineEdit className="user-icons" />
                              </IconButton>
                            }
                          />

                          {item.status !== "Inactive" && (
                            <IconButton
                              onClick={() => handleClickOpen(item.id)}
                            >
                              <AiOutlineDelete className="user-icons" />
                            </IconButton>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </TblContainer>
        </Box>
        {((usersList && usersList.data.length === 0) || isError) && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "100px",
            }}
          >
            <lottie-player
              src="https://assets8.lottiefiles.com/packages/lf20_agnejizn.json"
              background="transparent"
              speed="1"
              style={{ width: "300px", height: "300px" }}
              loop
              autoplay
            ></lottie-player>
          </div>
        )}
        <div className="pagination">
          <div className="paginationStyle">
            <PageDropDown
              value={pageItems}
              style={{ width: "90px", height: "34px", borderRadius: "55px" }}
              handlePageItems={handlePageItems}
              items={[
                { key: 1, value: 10 },
                { key: 2, value: 20 },
                { key: 2, value: 30 },
              ]}
            />
            <span className="paginationPage">Per Page</span>
          </div>

          <Pagination
            count={maxPage}
            className={styles.paginationCompStyle}
            page={currentPage}
            onChange={currentData}
            variant="outlined"
          />
        </div>
      </div>
      {openDelete ? (
        <DialogBox
          open={openDelete}
          setOpen={setOpenDelete}
          content={<span>Are you sure you want to delete this User?</span>}
          handleClose={handleClose}
          userId={userId}
          handleRemove={handleRemove}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default UserManagement;
