import React, { useState, useEffect } from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import "react-pro-sidebar/dist/css/styles.css";
import "./Sidebar.css";
import { NavLink, useLocation } from "react-router-dom";
import routes from "../Routes/Routes";
import UseAppName from "./UseAppname/UseAppName";
import UseSmallAppName from "./UseAppname/UseSmallAppName";

import useLoginQuery from "../data/auth/useLoginQuery";

const createLinks = (routes) => {
  const location = useLocation();

  return (
    <Menu>
      {routes.map((route, key) =>
        route.collapse === true && route.submenu !== true ? (
          <MenuItem
            key={route.name}
            icon={route.icon}
            title={route.name}
            style={{
              color: route.path
                .toLowerCase()
                .includes(location.pathname.split("/")[2].slice(0, 5))
                ? "#FDC727"
                : "",
              border: route.path
                .toLowerCase()
                .includes(location.pathname.split("/")[2].slice(0, 5))
                ? "1px solid rgba(233, 65, 65, 0.13)"
                : "",
              borderRadius: route.path
                .toLowerCase()
                .includes(location.pathname.split("/")[2].slice(0, 5))
                ? "12px"
                : "",
              boxShadow: route.path
                .toLowerCase()
                .includes(location.pathname.split("/")[2].slice(0, 5))
                ? "2px 4px 24px 3px rgba(0, 0, 0, 0.1)"
                : "",
              backgroundColor: route.path
                .toLowerCase()
                .includes(location.pathname.split("/")[2].slice(0, 5))
                ? "#FFFFFF"
                : "",
            }}
          >
            <NavLink
              to={route.layout + route.path}
              
              className={({ isActive }) =>
                isActive ||
                route.path
                  .toLowerCase()
                  .includes(location.pathname.split("/")[2].slice(0, 5))
                  ? "active"
                  : "link"
              }
            >
              {route.name}
            </NavLink>
          </MenuItem>
        ) : route.collapse === true && route.submenu === true ? (
          <SubMenu
            key={route.name}
            icon={route.icon}
            title={route.name}
            className="link"

            style={{
              color: route.path
                .toLowerCase()
                .includes(location.pathname.split("/")[2].slice(0, 5))
                ? "#FDC727"
                : "",
              border: route.path
                .toLowerCase()
                .includes(location.pathname.split("/")[2].slice(0, 5))
                ? "1px solid rgba(233, 65, 65, 0.13)"
                : "",
              borderRadius: route.path
                .toLowerCase()
                .includes(location.pathname.split("/")[2].slice(0, 5))
                ? "12px"
                : "",
              boxShadow: route.path
                .toLowerCase()
                .includes(location.pathname.split("/")[2].slice(0, 5))
                ? "2px 4px 24px 3px rgba(0, 0, 0, 0.1)"
                : "",
              backgroundColor: route.path
                .toLowerCase()
                .includes(location.pathname.split("/")[2].slice(0, 5))
                ? "#FFFFFF"
                : "",
            }}
          >
            {route &&
              route.views.map((subItem, key) => {
                {
                  return (
                    <MenuItem
                      key={subItem.name}
                      icon={subItem.icon}
                      title={subItem.name}
                      className="subinmenu"
                      style={{
                        borderBottom:
                          location.pathname === subItem.layout + subItem.path
                            ? "1px solid #E94141"
                            : "",
                      }}
                    >
                      {
                        
                      

                      <NavLink
                        to={subItem.layout + subItem.path}
                        style={{ marginLeft:"20px" }}
                        className={({ isActive }) =>
                          isActive ? "active" : "link"
                        }
                      >
                        {subItem.name}
                      </NavLink>
                      }
                    </MenuItem>
                  );
                }
              })}
          </SubMenu>
        ) : null
      )}
    </Menu>
  );
};
const Sidebar = () => {
  const { isSuccess: permissionsSuccess, refetch: permissionsFetch } =
    useLoginQuery.permissions_list();

  const [newRoutes, setNewRoutes] = useState([]);

  useEffect(() => {
    permissionsFetch();
  }, []);

  useEffect(() => {
    let permissions = JSON.parse(localStorage.getItem("userPermissions"));
    let filteredRoutes = [];
    if (permissions) {
      permissions = permissions.map((item) => item.id);
      permissions && permissions.push(0);
      filteredRoutes = routes.filter((item) => permissions.includes(item.id));
    }
    setNewRoutes([...filteredRoutes]);
  }, [permissionsSuccess]);

  const [collapsed, setCollapsed] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const styles = {
    menuIcon: {
      float: "right",
      margin: "10px",
      marginTop: "25px",
    },
  };

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const onClickMenuIcon = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    width < 1300 && onClickMenuIcon();
  }, [width]);

  return (
    <>
      <ProSidebar collapsed={collapsed} collapsedWidth="100px">
        <div className="sidebar-header">
          <SidebarHeader>
            {collapsed ? <UseSmallAppName /> : <UseAppName />}
            <div
              className="icon"
              style={styles.menuIcon}
              onClick={onClickMenuIcon}
            >
              {collapsed ? (
                <AiOutlineDoubleRight
                  style={{ cursor: "pointer", marginLeft: "15px" }}
                  fontSize="large"
                />
              ) : (
                <AiOutlineDoubleLeft
                  style={{ cursor: "pointer" }}
                  fontSize="large"
                />
              )}
            </div>
          </SidebarHeader>
        </div>
        <div className="scrollbarStyle">
          <SidebarContent>{createLinks(newRoutes)}</SidebarContent>
        </div>
      </ProSidebar>
    </>
  );
};

export default Sidebar;
