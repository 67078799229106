import { makeStyles, createStyles } from "@material-ui/core/styles";
import { ThermostatSharp } from "@mui/icons-material";
import { borderRadius } from "@mui/system";
import { BsSlack } from "react-icons/bs";

const drawerWidth = 240;

export default makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      overflowX: "auto",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
   
    tableBox: {
      overflowX: "scroll",
margin : ' 0 20px',
      overflowY: "auto",
      [theme.breakpoints.down(1500)]: {
        backgroundColor: "#fff",
        overflowX: "scroll",
        marginLeft: "15px",
        borderRadius: "12px",
      },
    },
    parentContainerOne: {
      position: "relative",
      width: "100%",
      minHeight: "auto",
    },
    rectangle: {
      width: "22rem",
      height: "4rem",
      padding: "10px",
      marginLeft: "8px",
      marginBottom: "8px",
      backgroundColor: "#feece8",
      border: "1px solid red",
    },
    modalStyle: {
      position: "absolute",
      top: "50%",
      left: "50%",
      fontFamily: "Montserrat",
      transform: "translate(-50%, -50%)",
      width: 900,
      bgcolor: "#ffffff",
      boxShadow: 24,
      p: 4,
      borderRadius: "10px",
    },
    h1: {
      fontSize: "1rem",
      textAlign: "center",
      margin: "0  auto",
    },
    parentContainer: {
      maxWidth: "1500px",
      minHeight: "auto",
      marginLeft: "3vw",
      marginRight: "3vw",
      padding: "3vw",
      marginTop: "50px",
      [theme.breakpoints.down("sm")]: {
        borderRadius: "12px",
        boxShadow: "0px 1px 28px 10px rgba(0, 0, 0, 0.05)",
      },
    },
    parentContainerReport:{
      maxWidth: "95%",
      minHeight: "auto",
      marginLeft: "3vw",
      marginRight: "3vw",
      padding: "2vw",
      marginTop: "0",
      [theme.breakpoints.down("sm")]: {
        borderRadius: "12px",
        boxShadow: "0px 1px 20px 10px rgba(0, 0, 0, 0.05)",
      },
    },
    reportTbHead:{
      boxShadow: "0px 1px 15px 10px rgba(0, 0, 0, 0.05)",
      padding:'20px 0'
    },
    iconStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    closeBtn:{
        width:'100%',
        display:'flex',
        justifyContent:'end'
    },
    parentPaperContainer: {
      position: "absolute",
      padding: theme.spacing(1),
      overflowY: "hidden",
      overflowX: "hidden",
      minHeight: "auto",
      marginLeft: "3vw",
      marginRight: "3vw",
      paddingLeft: "3vw",
      width: "78%",
    },
    iconButton: {
      display: "flex",
      justifyContent: "space-evenly",
      width: "120px",
      paddingLeft: "40px",
    },
    iconButtonClose: {
      position: "absolute",
      top: "20px",
      right: "20px",
    },

    searchBar: {
      width: "310px",
      fontSize: "14px",
      fontFamily: "Montserrat",
      borderRadius: "20px",
      [theme.breakpoints.down("xs")]: {
        width: "100% !important",
      },
    },

    selectOns_searchBar: {
      width: "310px",
      fontSize: "14px",
      fontFamily: "Montserrat",
      borderRadius: "3px",
      [theme.breakpoints.down("xs")]: {
        width: "100% !important",
      },
    },

    locationfield: {
      width: "310px",
      backgroundColor: "white",
      borderRadius: "20px",
      ["@media (max-width: 1500px)"]: {
        width: "200px",
      },
    },

    /////IMPORT EXCEL DATA

    excelData: {
      display: "flex",
    },

    modalHeader: {
      textAlign: "center",
      fontSize: "30px !important",
      letterSpacing: "0.08em",
      color: "#26608c",
    },
    modalSubHeader: {
      textAlign: "center",
      letterSpacing: "0.08em",
      fontS: "normal",
      fontWeight: "100",
      fontSize: "14px",
      lineHeight: "21px",
      color: "red",
    },
    modalBodyText: {
      textAlign: "center",
      letterSpacing: "0.08em",
      fontS: "normal",
      fontWeight: "100",
      fontSize: "14px",
      lineHeight: "21px",
    },
    inBox: {
      width: "80%",
      height: "50%",
      backgroundColor: "#ffffff",
      position: "absolute",
      left: "65px",
      bottom: "85px",
      borderRadius: "16px",
      boxShadow: "9px 9px 50px rgba(0, 0, 0, 0.12)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      border: "1px dotted #26608c",
    },
    uploadIcon: {
      fontSize: "125px",
      color: "#26608c",
    },
    uploadStyle: {
      position: "relative",
      left: "210px",
      top: "290px",
    },

    /////// TABLE
    tableCell: {
      display: "flex",
      justifyContent: "space-evenly !important",
      flexDirection: "row !important",
    },
    link: {
      display: "flex",
      justifyContent: "space-evenly !important",
      alignItems: "center",
      ["@media (max-width: 460px)"]: {
        gap: "10px",
      },
    },

    dropZoneOverlay: {},

    ////// HEADER PART

    linkColor: {
      color: "#F39800",
    },
    linkColorOne: {
      color: "#34D834",
    },
    linkColorTwo: {
      color: "#86AFFF",
      ["@media (max-width: 500px)"]: {
        padding: "0px 2vw",
      },
    },
    linkColorThree: {
      color: "#E94141",
    },
    headerStyle: {
      color: "#252F40",
      position: "sticky",
      fontFamily: "Montserrat",
      fontWeight: "700",
      fontSize: "16px",
      lineHeight: "20px",
      letterSpacing: "0.07em",
      top: "0",
      width: "auto",
      height: "60px",
      display: "flex",
      padding: "0 40px",
      alignItems: "center",
      justifyContent: "space-between",
      zIndex: "100",
    },
    headerStyleOne: {
      color: "#252F40",
      width: "100%",
      height: "60px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: "#FEDD17",
    },
    headerStyleTwo: {
      color: "#252F40",
      width: "100%",
      height: "60px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#FEDD17",
    },

    listdivStyle: {
      width: "100%",
      minHeight: "calc(100vh - 80px) !important",
      backgroundColor: "#f5f5f5",
      padding: "2px 0",
      
      flexDirection:'row',
      ["@media (max-width: 960px)"]: {
        flexDirection:'column',
      },
    },
    dashListdivStyle: {
      width: "100%",
      minHeight: "calc(100vh - 80px) !important",
      backgroundColor: "#f5f5f5",
      padding: "2px 0",
 display:'flex',
      flexDirection:'row',
      ["@media (max-width: 960px)"]: {
        flexDirection:'column',
      },
    },
    divStyle: {
      width: "100%",
      minHeight: "calc(100vh - 80px) !important",
      backgroundColor: "#f5f5f5",
      padding: "2px 0",
      margin:'0 10px'
    },

    divViewStyle: {
      width: "100vw",
      height: "100%",
    },

    divStyleOne: {
      width: "100%",
      height: "100%",
      paddingInline: "50px",
      paddingBottom: "50px",
      paddingTop: "20px",
    },

    divStyleTwo: {
      width: "100%",
      height: "100%",
      paddingInline: "50px",
      paddingTop: "20px",
      ["@media (max-width: 960px)"]: {
        paddingInline: "10px",
      },
    },

    filterStyle: {
      display: "flex",
      fontFamily: "Montserrat",
      fontWeight: "700",
      fontSize: "14px",
      color: "#444445",
      lineHeight: "45px",
    },
    docStyle: {
      display: "flex",
      alignItems: "center",
    },

    input: {
      width: "100%",
      margin: "10px 0px",
      "& .MuiInputBase-root": {
        fontFamily: "Montserrat",
        height: "45px",
        fontSize: "14px",
      },
    },

    ////// FORMS
    inputTextBar: {
      width: "420px",
      fontFamily: "Montserrat !important",
      "& .MuiOutlinedInput-root": {
        borderRadius: "6px",
        "& fieldset": {
          fontFamily: `Montserrat`,
          fontSize: "14px",
        },
        "& input": {
          fontFamily: `Montserrat`,
          fontSize: "14px",
        },
      },
      marginBottom: "20px",
      ["@media (max-width: 1500px)"]: {
        width: "300px",
      },
    },
    formTextBar: {
      margin: "20px 0",
      color: "red",

      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      width: "361px !important",
      height: "40px",
      marginBottom: "30px !important",

      ["@media (max-width: 960px)"]: {
        width: "350px",
      },
    },
    formTextArea: {
      width: "350px",
      height: "100px !important",
      margin: "10px 0 !important",
    },

    inputSelectBox: {
      width: "400px",
      border: "3px solid #FEDD17 ",
    },
    docSelect: {
      marginBottom: "10px",
    },
    formSelectBox: {
      width: "300px",
      marginBottom: "20px",
      backgroundColor: "white",
      borderRadius: "6px",
    },
    formSelectBoxOne: {
      width: "200px",
      border: "2.5px solid #34D834 !important",
      ["@media (max-width: 1500px)"]: {
        width: "100px",
      },
    },
    formIcon: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "30px",
      ["@media (max-width: 460px)"]: {
        marginBottom: "0px",
      },
    },

    formIconCentered: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "30px",
      marginBottom: "30px",
    },
    formIconCentered2: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      rowGap:'1rem',
      columnGap:'2rem',
      alignItems: "center",
      marginTop: "30px",
      marginBottom: "30px",
      ["@media (max-width: 960px)"]: {
        flexDirection:'column'
      },
    },

    bankDeatilCard: {
      width: "350px",
    },
    forms: {
      fontFamily: "Montserrat !important",
      fontWeight: "600 !important",
      padding: "12px 20px !important",
      fontSize: "15px !important",
      width: "350px !important ",
      marginTop: "30px",
      width: "361px",
      height: "40px",
      background: "#fff !important",
      border: "2px solid #FEDD17",
      textTransform: "capitalize !important",
      boxShadow: "4px 4px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",
    },

    formButtons: {
      fontFamily: "Montserrat !important",
      fontWeight: "700 !important",
      width: "197px",
      height: "40px",
      background: "#FEDD17 !important",
      padding: "12px 20px !important",
      fontSize: "12px !important",
      color: "#000000 !important",
      textTransform: "capitalize !important",
      lineHeight: "24px",
      margin: "20px 0 !important",
      border: "1px solid #FEDD17",
      borderRadius: "6px !important",
      ["@media (max-width: 600px)"]: {
        width: "289px",
      },
    },

    formButtonAdd: {
      fontFamily: "Montserrat !important",
      fontWeight: "600 !important",
      background: "#FEDD17 !important",
      fontSize: "18px !important",
      color: "#252F40 !important",
      width: "300px",
      height: "40px",
      textTransform: "capitalize !important",
      boxShadow: "6px 6px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",
      border: "0 !important",
      "&:hover": {
        backgroundColor: "yellow !important",
        boxShadow: "none",
      },
      ["@media (max-width: 600px)"]: {
        width: "300px",
      },
      [theme.breakpoints.down("sm")]: {
        margin: "10px 0px",
        width: "85vw",
      },
    },
    formButtonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      gap: "40px",
      marginTop: "20px",
      ["@media (max-width: 460px)"]: {
        gap: "0px",
      },
    },
    formButton: {
      fontFamily: "Montserrat !important",
      fontWeight: "600 !important",
      width: "320px",
      // height: "40px",
      color: "#252F40 !important",
      padding: "12px 20px !important",
      fontSize: "12px !important",
      border: "1px solid #FEDD17",
      color: "#000000 !important",
      lineHeight: "24px",
      textTransform: "capitalize !important",
      boxShadow: "6px 6px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",

      ["@media (max-width: 450px)"]: {
        marginTop: "10px",
        marginBottom: "-10px",
        width: "94%",
        height: "40px",
        margin: "10px 0 !important",
        fontFamily: "Montserrat !important",
        fontWeight: "400 !important",
        fontStyle: "normal",
        fontSize: "12px !important",
        lineHeight: "24px",
        padding: "12px 20px !important",
        fontSize: "15px !important",
        background: "#ffffff !important",
        border: "1px solid #D9D9D9",
        color: "#000000 !important",
        textTransform: "capitalize !important",
        borderRadius: "6px !important",
      },
    },
    viewDetailsContainer: {
      padding: "20px",
      fontFamily: "Montserrat !important",
      width: "70vw",
      paddingLeft: "200px",
      margin: "25px",
    },
    carDetailsContainer: {
      padding: "20px",
      fontFamily: "Montserrat !important",
      width: "70vw",
      paddingLeft: "400px",
      margin: "25px",
      height: "100px",
    },

    inventoryFormAddealer: {
      fontFamily: "Montserrat !important",
      fontWeight: "400 !important",
      background: "#04A777 !important",
      padding: "12px 20px !important",
      fontSize: "15px !important",
      color: "#FFFFFF !important",
      textTransform: "capitalize !important",
      boxShadow: "4px 4px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",
      transition: "0.2s",
      "&:hover": {
        background: "#fff !important",
        border: "2px solid #04A777",
        color: "#04A777 !important",
        boxShadow:
          "0 0 10px #04A777 0 0 10px #04A777 0 0 20px #04A777 0 0 40px #04A777 0 0 60px #04A777",
      },
    },

    docParent: {
      position: "relative",
      display: "flex",
      margin: "45px",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: "10px",
    },
    subHeaderParent: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      marginRight: "28px",
      marginBottom: "24px",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        textAlign: "center",
      },
    },
    headerParentOne: {
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      marginLeft: "10px",
      marginTop: "40px",
    },
    cardMedia: {
      padding: "5px",
      marginTop: "15px",
      marginLeft: "10px",
    },
    subHeader: {
      fontFamily: "Montserrat !important",
      fontWeight: "700",
      fontSize: "16px",
      fontStyle: "normal",
      color: "#252F40",
      padding: "6px 0 0 10px",
    },
    formSubHeader: {
      marginTop: "50px !important",
      fontWeight: 700,
      textTransform: "none",
    },
    salesButton: {
      fontFamily: "Montserrat !important",
      fontWeight: "700 !important",
      background: "#FEDD17 !important",
      width: "250px !important",
      fontSize: "14px !important",
      color: "#252F40 !important",
      textTransform: "capitalize !important",
      borderRadius: "8px !important",
      justifyContent: "space-evenly",
      marginRight: "15px",
      [theme.breakpoints.down("sm")]: {
        marginRight: "0px !important",
        width: "100% !important",
        justifyContent: "center !important",
      },
    },
    dmSalesButton: {
      fontFamily: "Montserrat",
      fontWeight: "700",
      background: "#FFD000",
      letterSpacing: "1px",
      padding: "10px 8px",
      width: "232px",
      height: "40px",
      fontSize: "18px",
      color: "#252F40",
      textTransform: "capitalize",
      borderRadius: "8px",
    },
    dmDocButton: {
      fontFamily: "Montserrat !important",
      fontWeight: "600 !important",
      padding: "8px 15px !important",
      width: "199px",
      height: "40px",
      fontSize: "14px !important",
      color: "#444445 !important",
      textTransform: "capitalize !important",
      borderRadius: "6px !important",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
      },
      [theme.breakpoints.down("md")]: {
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
      },
      [theme.breakpoints.down("lg")]: {
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
      },
    },
    dmDocButtonOne: {
      fontFamily: "Montserrat !important",
      fontWeight: "600 !important",
      padding: "8px 15px !important",
      width: "199px",
      height: "40px",
      marginTop: "10px",
      fontSize: "14px !important",
      color: "#444445 !important",
      textTransform: "capitalize !important",
      borderRadius: "6px !important",
      "&:hover": {
        color: " #FFFFFF !important",
        background: "#E94141 !important",
      },
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
      },
      [theme.breakpoints.down("md")]: {
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
      },
      [theme.breakpoints.down("lg")]: {
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
      },
    },
    searchBoxParent: {
      display: "flex",
      margin: "20px",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: "10px",
    },

    searchBoxParentReport:{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: "10px",
    },
    aeSearchBoxParent: {
      display: "flex",
      margin: "20px",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: "25px",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0px",
      },
    },
    searchBoxParentOne: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "wrap",
      marginBottom: "50px",
    },
    dmSearchBoxParent: {
      width: "100%",
      display: "flex",
      justifyContent: "space-evenly",
      padding: "20px 40px",
      boxSizing: "border-box",

      ["@media (max-width: 395px)"]: {
        padding: "20px 23px",
      },
      ["@media (max-width: 375px)"]: {
        padding: "20px 12px",
      },
    },
    dmSearchBoxParentOne: {
      width: "78%",
      display: "flex",
      margin: "35px",
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(2),
    },
    dmSearchBoxParentTwo: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      [theme.breakpoints.down("md")]: {
        paddingRight: "1vw",
        flexWrap: "wrap",
      },
    },
    backButton: {
      color: "#292D32",
      width: "20px",
      height: "20px",
      border: "1.5px solid #292D32",
      borderRadius: "10px",
      margin: "10px",
    },
    detailHeading: {
      fontWeight: "800",
      fontSize: "14px",
      lineHeight: "21px",
      color: "#444445",
      letterSpacing: "0.08em",
    },
    detailValues: {
      fontSize: "14px",
      lineHeight: "21px",
      color: "#444445",
      letterSpacing: "0.08em",
    },
    viewItems: {
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "21px",
      color: "#444445",
      letterSpacing: "0.08em",
    },
    listHeading: {
      width: "200px",
      fontStyle: "normal",
      fontWeight: "1000",
      fontSize: "14px",
      lineHeight: "21px",
      color: "#444445",
      letterSpacing: "0.08em",
    },
    listValues: {
      width: "200px",
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "21px",
      color: "#444445",
      letterSpacing: "0.08em",
    },
    fileUploadButton: {
      position: "absolute !important",
      fontFamily: "Poppins !important",
      fontWeight: "600 !important",
      right: "55px !important",
      top: "115px",
      height: "40px !important",
      background: "#5A5DF6 !important",
      fontSize: "15px",
      color: "#FFFFFF !important",
      width: "150px",
      boxShadow: "4px 4px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",
    },

    // Icon for upload documents
    addImageIcon: {
      "& svg": {
        fontSize: 25,
      },
      padding: "10px",
    },
    //Style of paper in Dealers add
    dealerPaper: {
      height: "550px",
      width: "70vw",
      overflowY: "scroll",
      overFlowX: "hidden",
      marginBottom: "80px",
      marginLeft: "80px",
      marginTop: "30px",
    },
    grid: {
      padding: "20px",
      marginTop: "60px",
      flexWrap: "wrap",
    },

    //DealerDetails Page
    detailGrid: {
      width: "100%",
      margin: "0px",
    },
    detailPaper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      background: theme.palette.success.light,
    },
    viewlabel: {
      fontWeight: "600",
      fontStyle: "normal",
      fontSize: "12px",
      lineHeight: "24px",
      color: "#444445",
      fontFamily: "Montserrat !important",
    },
    addlabel: {
      fontWeight: "600",
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "24px",
      color: "#444445",
      fontFamily: "Montserrat !important",
    },
    docTitle: {
      color: "#000000",
      fontFamily: "Montserrat !important",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "14px !important",
      textAlign: "center",
    },
    reportingStyle: {
      width: "100%",
      minHeight: "calc(100vh - 80px) !important",
      backgroundColor: "#f5f5f5",
      padding: "15px 15px",
      overflowY: "hidden",
    },
    reportingStylediv: {
      padding: "30vh 14vw",
      width: "100%",
      minHeight: "calc(100vh - 80px) !important",
      backgroundColor: "#f5f5f5",
      padding: "2px 0"
    },
    paginationCompStyle: {
      "& .MuiPaginationItem-root": {
        fontFamily: "Montserrat !important",
      },
    },
    basicSelectBox: {
      width: "100% !important",
      height: "50px !important",
      marginBottom: "15px !important",
      borderRadius: "6px !important",
      ["@media (max-width: 960px)"]: {
        width: "100% !important",
        marginBottom: "20px !important",
      },
      ["@media (max-width: 820px)"]: {
        width: "100% !important",
        marginBottom: "20px !important",
      },
      ["@media (max-width: 450px)"]: {
        borderRadius: "8px !important",
        width: "100% !important",
        marginBottom: "0px !important",
      },
    },
    stepperFormTextBarArea: {
      "& ::placeholder": {
        fontSize: "14px !important",
        fontFamily: "Montserrat !important",
      },
      "& .MuiInputBase-input": {
        height: "10px !important",
      },
      "& input[type=number]": {
        "-moz-appearance": "textfield !important",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none !important",
        margin: "0px !important",
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none !important",
        margin: "0px !important",
      },
      "MuiInputBase-input MuiOutlinedInput-input": {
        height: "10px !important",
      },
      "& input": {
        fontFamily: `Montserrat !important`,
        fontStyle: `Normal !important`,
        fontWeight: `400 !important`,
        fontSize: `14px !important`,
        color: `#444445 !important`,
        lineHeight: `30px !important`,
      },
      width: "100% !important",
      height: "90px !important",
      margin: "10px 0 !important",
      fontFamily: "Montserrat !important",
      fontStyle: "normal !important",
      fontWeight: "400 !important",
      fontSize: "14px !important",
      lineHeight: "24px !important",
      color: "#444445 !important",
      borderRadius: "6px !important",
      marginBottom: "20px !important",
      ["@media (max-width: 960px)"]: {
        margin: "0px !important",
        marginBottom: "20px !important",
      },
      ["@media (max-width: 820px)"]: {
        width: "100% !important",
        margin: "0px !important",
        marginBottom: "20px !important",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100% !important",
        height: "50px !important",
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px !important",
        },
        "& input": {
          fontSize: "12px !important",
        },
      },
      ["@media (max-width: 450px)"]: {
        borderRadius: "8px !important",
        width: "100% !important",
        height: "50px !important",
        marginBottom: "0px !important",
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px !important",
        },
      },
      ["@media (max-width: 400px)"]: {
        borderRadius: "8px !important",
        width: "100% !important",
        height: "50px !important",
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px !important",
        },
      },
      ["@media (max-width: 360px)"]: {
        borderRadius: "8px !important",
        width: "100% !important",
        height: "50px !important",
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px !important",
        },
      },
    },
    stepperFormTextBar: {
      "& ::placeholder": {
        fontSize: "14px !important",
        fontFamily: "Montserrat !important",
      },
      "& .MuiInputBase-input": {
        height: "10px !important",
      },
      "& input[type=number]": {
        "-moz-appearance": "textfield !important",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none !important",
        margin: "0px !important",
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none !important",
        margin: "0px !important",
      },
      "MuiInputBase-input MuiOutlinedInput-input": {
        height: "10px !important",
      },
      "& input": {
        fontFamily: `Montserrat !important`,
        fontStyle: `Normal !important`,
        fontWeight: `400 !important`,
        fontSize: `14px !important`,
        color: `#444445 !important`,
        lineHeight: `30px !important`,
      },
      "& fieldset": {
        borderRadius: `10px`,
        innerWidth: "18vw",
        height: "56px",
        color: "#67748E",
        fontWeight: "lighter",
        fontSize: "12px",
        fontStyle: "Normal",
      },
      width: "100% !important",
      margin: "10px 0 !important",
      fontFamily: "Montserrat !important",
      fontStyle: "normal !important",
      fontWeight: "400 !important",
      fontSize: "14px !important",
      lineHeight: "24px !important",
      color: "#444445 !important",
      borderRadius: "6px !important",
      marginBottom: "20px !important",
      ["@media (max-width: 960px)"]: {
        margin: "0px !important",
        marginBottom: "20px !important",
      },
      ["@media (max-width: 820px)"]: {
        width: "100% !important",
        margin: "0px !important",
        marginBottom: "20px !important",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100% !important",
        height: "50px !important",
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px !important",
        },
        "& input": {
          fontSize: "12px !important",
        },
      },
      ["@media (max-width: 450px)"]: {
        borderRadius: "8px !important",
        width: "100% !important",
        height: "50px !important",
        marginBottom: "0px !important",
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px !important",
        },
      },
      ["@media (max-width: 400px)"]: {
        borderRadius: "8px !important",
        width: "100% !important",
        height: "50px !important",
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px !important",
        },
      },
      ["@media (max-width: 360px)"]: {
        borderRadius: "8px !important",
        width: "100% !important",
        height: "50px !important",
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px !important",
        },
      },
    },
    offerAddContainer: {
      position: "absolute",
      top: "50%",
      left: "50%",
      height: "80%",
      overflow: "auto",
      fontFamily: "Montserrat",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#fff",
      boxShadow: 24,
      borderRadius: "12px",
      ["@media (max-width: 960px)"]: {
        width: "90%",
        height: "100%",
      },
    },
  })
);
