import React from "react";
import { FiPower, FiBox } from "react-icons/fi";
import { FaKey, FaUserEdit } from "react-icons/fa";
import { TbDiscount2 } from "react-icons/tb";
import { HiTruck, HiClipboardList } from "react-icons/hi";
import {
  IoPersonCircleOutline,
  IoWallet,
  IoBagHandle,
  IoPeopleSharp,
} from "react-icons/io5";
import { RiVoiceRecognitionFill } from "react-icons/ri";
import { MdDashboard } from "react-icons/md";
import { RiSettingsFill } from "react-icons/ri";
import { BiCategoryAlt } from "react-icons/bi";
import { TbBrandBootstrap } from "react-icons/tb";
import { GrProductHunt } from "react-icons/gr";
import { AppRoutes, EndPoint } from "../config/config";

import {
  Dashboard,
  UserManagement,
  DealerManagement,
  DealersViewDocuments,
  FinanceManagement,
  InventoryViewDetails,
  InventryManagement,
  OfferManagement,
  ReviewManagement,
  SalesDealerTechnicianDetails,
  SalesDetails,
  SalesPersonDetails,
  SalesPersonManagement,
  UserCarDetails,
  UserCredentialManagement,
  UserDetails,
  UserEdit,
  UserOrderDetails,
  UserRoleManagement,
  VehicleManagement,
  VehicleDetails,
  VehicleAdd,
  VehicleEdit,
  VehicleProductDetails,
  AssignOrderDetails,
  DealerBankDetails,
  DealerDetails,
  DealerInventoryDetail,
  DealerTechnicianOnboardered,
  Login,
  Logout,
  Reporting,
  AddDealer,
  Category,
  CategoryDetails,
  Brand,
  AddBrand,
  BrandDetails,
  Product,
  BasicInformation,
  AssignOfficeAdvisor,
  AssignDealer,
  AssignEmergency,
} from "../pages/index";
import Bouncer from "./Bouncer";
import Error from "../pages/ErrorPage/Error";
import AddInventory from "../pages/InventoryManagement/AddInventory";
import EditInventory from "../pages/InventoryManagement/EditInventory";
import ProductViewDetails from "../pages/InventoryManagement/ProductViewDetails";
import DealerOrTechnicianView from "../pages/InventoryManagement/DealerOrTechnicianView";
import Orders from "../pages/Assign_Office_Advisor/Orders";
import AssignToAdviser from "../pages/Assign_Office_Advisor/AssignToAdviser";
import WalletPage from "../pages/WalletManagement/WalletPage";
import ReportView from "../pages/Reporting/ReportView";
import SubCategory from "../pages/InventoryManagement/SubCategory";

// import useLoginQuery from "../data/auth/useLoginQuery";

// const { data: permissionsList } = useLoginQuery.permissions_list();

// console.log(permissionsList);

var routes = [
  {
    collapse: false,
    path: "*",
    layout: AppRoutes.ADMIN,
    name: "ErrorPage",
    state: "errorpage",
    show: false,
    icon: <MdDashboard fontSize="large" />,
    component: Error,
    id: 0,
  },
  {
    collapse: false,
    path: "*",
    layout: AppRoutes.AUTH,
    name: "Error Page",
    state: "errorpage",
    show: false,
    icon: <MdDashboard fontSize="large" />,
    component: Error,
    id: 0,
  },
  {
    collapse: true,
    path: EndPoint.DASH,
    layout: AppRoutes.ADMIN,
    name: "Dashboard",
    state: "dashboard",
    show: true,
    icon: <MdDashboard fontSize="large" />,
    component: Dashboard,
    id: 1,
  },

  {
    collapse: true,
    path: EndPoint.USER_MANAGEMENT,
    layout: AppRoutes.ADMIN,
    name: "Customer Management",
    view: "user",
    state: "users",
    key: "usermanagement",
    show: false,
    icon: <IoPeopleSharp fontSize="large" />,
    component: UserManagement,
    id: 2,
  },
  {
    collapse: false,
    path: "usersDetails/:id",
    layout: "/administrator",
    name: "User Details",
    state: "userdetails",
    show: true,
    component: UserDetails,
    id: 2,
  },
  {
    collapse: false,
    path: "usersEdit/:id",
    layout: "/administrator",
    name: "User Edit",
    state: "userEdit",
    show: true,
    component: UserEdit,
    id: 2,
  },
  {
    collapse: false,
    path: "usersDetails/:id/:view",
    layout: "/administrator",
    name: "User Details",
    state: "userdetails",
    show: true,
    component: UserDetails,
    id: 2,
  },
  {
    collapse: false,
    path: EndPoint.USER_CAR_DETAILS,
    layout: AppRoutes.ADMIN,
    name: "USER CAR DETAILS",
    state: "usercardetails",
    show: true,
    component: UserCarDetails,
    id: 2,
  },
  {
    collapse: false,
    path: EndPoint.USER_ORDER_DETAILS,
    layout: AppRoutes.ADMIN,
    name: "USER ORDER DETAILS",
    state: "userorderdetails",
    show: true,
    component: UserOrderDetails,
    id: 2,
  },

  {
    collapse: true,
    path: EndPoint.DEALER_MANAGEMENT,
    layout: AppRoutes.ADMIN,
    name: "Dealer/Mechanic Management",
    state: "dealers",
    key: "dealermanagement",
    show: true,
    icon: <RiSettingsFill fontSize="large" />,
    component: DealerManagement,
    id: 3,
  },

  {
    collapse: false,
    path: "dealerDetails/:id",
    layout: AppRoutes.ADMIN,
    name: "DEALER/MECHANIC MANAGEMENT",
    state: "dealers",
    show: true,
    component: DealerDetails,
    id: 3,
  },

  {
    collapse: false,
    path: "dealeredit/:id",
    layout: AppRoutes.ADMIN,
    name: "DEALER/MECHANIC MANAGEMENT",
    state: "dealers",
    show: true,
    component: AddDealer,
    id: 3,
  },
  {
    collapse: false,
    path: "dealeradd",
    layout: AppRoutes.ADMIN,
    name: "DEALER/Add Dealers",
    state: "dealers",
    show: true,
    component: AddDealer,
    id: 3,
  },
  {
    collapse: false,
    path: "dealerDetails/dealerInventoryDetails/:id",
    layout: AppRoutes.ADMIN,
    name: "Inventory Details",
    state: "dealers",
    show: true,
    component: DealerInventoryDetail,
    id: 3,
  },
  {
    collapse: false,
    path: EndPoint.DEALER_TECH_ONBOARD_DETAILS,
    layout: AppRoutes.ADMIN,
    name: "Technician Onboardered Details",
    state: "dealers",
    show: true,
    component: DealerTechnicianOnboardered,
    id: 3,
  },
  {
    collapse: false,
    path: "dealerDetails/dealerBankDetails/:id",
    layout: AppRoutes.ADMIN,
    name: "Technician Onboardered Details",
    state: "dealers",
    show: true,
    component: DealerBankDetails,
    id: 3,
  },
  {
    collapse: false,
    path: "dealerDetails/dealersViewDocuments/:id",
    layout: AppRoutes.ADMIN,
    name: "Dealers View Documents",
    state: "dealers",
    show: true,
    component: DealersViewDocuments,
    id: 3,
  },


  {
    collapse: true,
    path: EndPoint.SALES_MANAGEMENT,
    layout: AppRoutes.ADMIN,
    name: "Salesperson Management",
    state: "sale",
    key: "salesmanagement",
    show: false,
    icon: <IoBagHandle fontSize="large" />,
    component: SalesPersonManagement,
    id: 4,
  },
  {
    collapse: false,
    path: EndPoint.SALES_USER_DETAILS,
    layout: AppRoutes.ADMIN,
    name: "SALES USER DETAILS",
    state: "salesuserdetails",
    show: true,
    component: SalesDetails,
    id: 4,
  },
  {
    collapse: false,
    path: EndPoint.SALES_DEALER_TECHNICIAN_DETAILS,
    layout: AppRoutes.ADMIN,
    name: "SALES DEALER TECHNICIAN DETAILS",
    state: "salesdealertechniciandetails",
    show: true,
    component: SalesDealerTechnicianDetails,
    id: 4,
  },
  
  {
    collapse: false,
    path: EndPoint.SALES_PERSON_DETAILS,
    layout: AppRoutes.ADMIN,
    name: "SALESPERSON DETAILS",
    state: "salespersondetails",
    show: true,
    component: SalesPersonDetails,
    id: 4,
  },
  {
    collapse: false,
    path: "salespersondetails/onBordedDealers/:id",
    layout: AppRoutes.ADMIN,
    name: "Onborded Dealers",
    state: "salespersondetails",
    show: true,
    component: DealerManagement,
    id: 4,
  },
  {
    collapse: false,
    path: EndPoint.CATEGORY_LIST,
    layout: AppRoutes.ADMIN,
    state: "category",
    key: "category",
    show: true,
    component: Category,
    id: 5,
  },
 
  {
    collapse: false,
    path: "category/:id/:name",
    layout: AppRoutes.ADMIN,
    state: "category",
    key: "category",
    show: true,
    component: SubCategory,
    id: 5,
  },
  {
    collapse: false,
    path: "category/categoryDetails/:id",
    layout: AppRoutes.ADMIN,
    name: "Category Details",
    state: "categorydetails",
    show: true,
    component: CategoryDetails,
    id: 5,
  },
  {
    collapse: false,
    path: "category/categoryDetails/:id/:name",
    layout: AppRoutes.ADMIN,
    name: "Category Details",
    state: "categorydetails",
    show: true,
    component: CategoryDetails,
    id: 5,
  },
  {
    collapse: false,
    path: EndPoint.BRAND_LIST,
    layout: AppRoutes.ADMIN,
    state: "brand",
    key: "brand",
    show: true,
    component: Brand,
    id: 5,
  },
  {
    collapse: false,
    path: EndPoint.ADD_BRAND,
    layout: AppRoutes.ADMIN,
    name: "Add Brand",
    state: "addbrand",
    show: true,
    component: AddBrand,
    id: 5,
  },
  {
    collapse: false,
    path: "editBrand/:id",
    layout: AppRoutes.ADMIN,
    name: "Edit Brand",
    state: "editbrand",
    show: true,
    component: AddBrand,
    id: 5,
  },

  {
    collapse: false,
    path: "brandDetails/:id",
    layout: AppRoutes.ADMIN,
    name: "Brand Details",
    state: "branddetails",
    show: true,
    component: BrandDetails,
    id: 5,
  },
  {
    collapse: false,
    path: EndPoint.INVENTORY_PRODUCT,
    layout: AppRoutes.ADMIN,
    state: "product",
    key: "product",
    show: true,
    component: InventryManagement,
    id: 5,
  },
  {
    collapse: false,
    path: EndPoint.INVENTORY_ADD_PRODUCT,
    layout: AppRoutes.ADMIN,
    state: "product",
    key: "product",
    show: true,
    component: AddInventory,
    id: 5,
  },
  {
    collapse: false,
    path: EndPoint.INVENTORY_EDIT_PRODUCT,
    layout: AppRoutes.ADMIN,
    state: "product",
    key: "product",
    show: true,
    component: EditInventory,
    id: 5,
  },
  {
    collapse: false,
    path: "editProduct/:id",
    layout: AppRoutes.ADMIN,
    name: "Edit Product",
    state: "editProduct",
    show: true,
    component: AddInventory,
    id: 5,
  },
  {
    collapse: false,
    path: "viewProductDetails/:id",
    layout: AppRoutes.ADMIN,
    name: "View Product",
    state: "viewProductDetails",
    show: true,
    component: ProductViewDetails,
    id: 5,
  },
  {
    collapse: false,
    path: "dealerOrTechnician/:id",
    layout: AppRoutes.ADMIN,
    name: "dealer Techician",
    state: "dealerTechnicianDetails",
    show: true,
    component: DealerOrTechnicianView,
    id: 5,
  },
  {
    collapse: false,
    path: "/addProduct",
    layout: AppRoutes.ADMIN,
    name: "Add Product",
    state: "addproduct",
    show: true,
    component: BasicInformation,
    id: 5,
  },
  // {
  //   collapse: true,
  //   path: EndPoint.INVENTORY_MANAGEMENT,
  //   layout: AppRoutes.ADMIN,
  //   name: "Inventory Management",
  //   // view: "user",
  //   state: "inventry",
  //   key: "inventrymanagement",
  //   show: true,
  //   icon: <FaKey fontSize="large" />,
  //   component: InventryManagement,
  // },
  {
    collapse: true,
    path: EndPoint.INVENTORY_MANAGEMENT,
    layout: AppRoutes.ADMIN,
    name: "Inventory Management",
    state: "inventry",
    key: "inventrymanagement",
    submenu: true,
    show: false,
    icon: <FaKey fontSize="large" />,
    component: InventryManagement,
    id: 5,
    views: [
      {
        collapse: false,
        path: EndPoint.CATEGORY_LIST,
        layout: AppRoutes.ADMIN,
        name: "Category",
        state: "category",
        key: "category",
        show: true,
        icon: <BiCategoryAlt fontSize="large" />,
        component: Category,
        id: 5,
      },
      {
        collapse: false,
        path: EndPoint.BRAND_LIST,
        layout: AppRoutes.ADMIN,
        name: "Brand",
        state: "brand",
        key: "brand",
        show: true,
        icon: <TbBrandBootstrap fontSize="large" />,
        component: Brand,
        id: 5,
      },

      {
        collapse: false,
        path: EndPoint.INVENTORY_PRODUCT,
        layout: AppRoutes.ADMIN,
        name: "Product",
        state: "product",
        key: "product",
        show: true,
        icon: <GrProductHunt fontSize="large" />,
        component: Product,
        id: 5,
      },
    ],
  },

  {
    collapse: false,
    path: EndPoint.INVENTORY_VIEW_DETAILS,
    layout: AppRoutes.ADMIN,
    name: "INVENTORY VIEW DETAILS ",
    state: "inventryviewdetails",
    show: true,
    component: InventoryViewDetails,
    id: 5,
  },
  {
    collapse: false,
    path: "/asigntooficeadvisor",
    layout: AppRoutes.ADMIN,
    name: "Assign to Office Advisor",
    state: "asign to ofice advisor",
    key: "asign to ofice advisor",
    show: true,
    component: AssignOfficeAdvisor,
    id: 6,
  },
  {
    collapse: false,
    path: "/asigntoadvisor/:id",
    layout: AppRoutes.ADMIN,
    name: "Assign to Advisor",
    state: "asign to advisor",
    key: "asign to advisor",
    show: true,
    component: AssignToAdviser,
    id: 6,
  },
  {
    collapse: false,
    path: "/reasigntoadvisor/:id/:adid",
    layout: AppRoutes.ADMIN,
    name: " Re assign to Advisor",
    state: "re asign to advisor",
    key: "re asign to advisor",
    show: true,
    component: AssignToAdviser,
    id: 6,
  },
  {
    collapse: false,
    path: EndPoint.ORDER_DETAILS,
    layout: AppRoutes.ADMIN,
    name: "ASSIGN ADVISOR ORDER DETAILS ",
    state: "assignorderdetails",
    show: true,
    component: AssignOrderDetails,
    id: 6,
  },
  {
    collapse: false,
    path: "/asigntodealer",
    layout: AppRoutes.ADMIN,
    name: "Assign to Dealer",
    state: "asign to dealer",
    key: "asign to dealer",
    show: true,
    component: AssignDealer,
    id: 6,
  },
  {
    collapse: false,
    path: "/asigntoemergency",
    layout: AppRoutes.ADMIN,
    name: "Assign to Emergency",
    state: "asign to emergency",
    key: "asign to emergency",
    show: true,
    component: AssignEmergency,
    id: 6,
  },

  {
    collapse: false,
    path: "/orders",
    layout: AppRoutes.ADMIN,
    name: "Orders",
    state: "order",
    key: "order",
    show: true,
    component:Orders,
    id: 6,
  },
  
  {
    collapse: false,
    path: "/asigntooficeadvisor",
    layout: AppRoutes.ADMIN,
    name: "Assign to Office Advisor",
    state: "asign to ofice advisor",
    key: "asign to ofice advisor",
    show: true,
    component: AssignOfficeAdvisor,
    id: 6,
  },
  {
    collapse: false,
    path: "/asigntodealer",
    layout: AppRoutes.ADMIN,
    name: "Assign to Dealer / Technician",
    state: "asign to dealer",
    key: "asign to dealer",
    show: true,
    component: AssignDealer,
    id: 6,
  },

  {
    collapse: true,
    path: EndPoint.ORDER_MANAGEMENT,
    layout: AppRoutes.ADMIN,
    name: "Order Management",
    state: "ordermanagement",
    key: "ordermanagement",
    submenu: true,
    show: false,
    icon: <FiBox fontSize="large" />,
    id: 6,
    component:Orders,
    views: [
      {
        collapse: false,
        path: EndPoint.ORDERS,
        layout: AppRoutes.ADMIN,
        name: "Orders",
        state: "order",
        key: "order",
        show: true,
        component:Orders,
        id: 6,
      },
      {
        collapse: false,
        path: "/asigntoemergency",
        layout: AppRoutes.ADMIN,
        name: "Add Emergency Request",
        state: "asign to emergency",
        key: "asign to emergency",
        show: true,
        component: AssignEmergency,
        id: 6,
      }
    ],
  },
  
  {
    collapse: true,
    path: EndPoint.USERCREDENTIAL_MANAGEMENT,
    layout: AppRoutes.ADMIN,
    name: "User Credential Management",
    state: "usercredential",
    show: false,
    icon: <FaUserEdit fontSize="large" />,
    component: UserCredentialManagement,
    id: 7,
  },
  {
    collapse: true,
    path: EndPoint.USER_ROLE_MANAGEMENT,
    layout: AppRoutes.ADMIN,
    name: "User Role Management",
    state: "userrole",
    show: false,
    icon: <IoPersonCircleOutline fontSize="large" />,
    component: UserRoleManagement,
    id: 8,
  },
  {
    collapse: true,
    path: EndPoint.OFFER_MANAGEMENT,
    layout: AppRoutes.ADMIN,
    name: "Coupon Management",
    state: "offer",
    show: false,
    icon: <TbDiscount2 fontSize="large" />,
    component: OfferManagement,
    id: 9,
  },
  {
    collapse: true,
    path: EndPoint.REVIEW_MANAGEMENT,
    layout: AppRoutes.ADMIN,
    name: "Review Management",
    state: "review",
    show: false,
    icon: <RiVoiceRecognitionFill fontSize="large" />,
    component: ReviewManagement,
    id: 10,
  },
  {
    collapse: true,
    path: EndPoint.FINANCE_MANAGEMENT,
    layout: AppRoutes.ADMIN,
    name: "Finance Management",
    state: "finance",
    show: false,
    icon: <IoWallet fontSize="large" />,
    component: FinanceManagement,
    id: 11,
  },
  {
    collapse: true,
    path: EndPoint.WALLET_MANAGEMENT,
    layout: AppRoutes.ADMIN,
    name: "Wallet Management",
    state: "wallet",
    show: false,
    icon: <IoWallet fontSize="large" />,
    component: WalletPage,
    id: 13,
  },
  {
    collapse: true,
    path: EndPoint.VEHICLE_MANAGEMENT,
    layout: AppRoutes.ADMIN,
    name: "Vehicle Management",
    state: "vehicle",
    show: false,
    icon: <HiTruck fontSize="large" />,
    component: VehicleManagement,
    id: 12,
  },
  {
    collapse: false,
    path: "/vehicle/vehicleDetails/vehicleProductDetails",
    layout: AppRoutes.ADMIN,
    name: "Vehicle Product Details",
    state: "vehicleproductdetails",
    show: true,
    component: VehicleProductDetails,
    id: 11,
  },
  {
    collapse: false,
    path: "/vehicle/vehicleDetails/:id",
    layout: AppRoutes.ADMIN,
    name: "Vehicle Details",
    state: "vehicledetails",
    show: true,
    component: VehicleDetails,
    id: 11,
  },
  {
    collapse: false,
    path: "/vehicle/vehicleAdd/",
    layout: AppRoutes.ADMIN,
    name: "Add Vehicle",
    state: "addvehicle",
    show: true,
    component: VehicleAdd,
    id: 11,
  },
  {
    collapse: false,
    path: "/vehicle/vehicleEdit/:id",
    layout: AppRoutes.ADMIN,
    name: "Edit Vehicle",
    state: "editvehicle",
    show: true,
    component: VehicleEdit,
    id: 11,
  },
  {
    collapse: true,
    path: EndPoint.REPORTING,
    layout: AppRoutes.ADMIN,
    name: "Reporting",
    state: "reporting",
    show: false,
    icon: <HiClipboardList fontSize="large" />,
    component: Reporting,
    id: 14,
  },
  {
    collapse: false,
    path: "usersDetails/:id/:view",
    layout: "/administrator",
    name: "User Details",
    state: "userdetails",
    show: true,
    component: ReportView,
    id: 14,
  },
  {
    collapse: false,
    path: EndPoint.REPORTING_VIEW,
    layout: AppRoutes.ADMIN,
    name: "Reporting View",
    state: "reporting_view",
    show: true,
    
    component: ReportView,
    id: 14,
  },
 
  {
    collapse: false,
    path: EndPoint.LOGIN,
    layout: AppRoutes.AUTH,
    name: "Login",
    state: "login",
    show: false,
    icon: <FiPower fontSize="large" />,
    component: Login,
    id: 0,
  },
  {
    collapse: false,
    path: EndPoint.FORGET_PASSWORD,
    layout: AppRoutes.AUTH,
    name: "Login",
    state: "login",
    show: false,
    icon: <FiPower fontSize="large" />,
    component: Login,
    id: 0,
  },
  {
    collapse: true,
    path: EndPoint.LOGOUT,
    layout: AppRoutes.AUTH,
    name: "Logout",
    state: "logout",
    show: true,
    icon: <FiPower fontSize="large" />,
    component: Logout,
    id: 0,
  },
  {
    collapse: false,
    path: "/",
    layout: AppRoutes.ADMIN,
    name: "Bouncer",
    state: "bouncer",
    show: false,
    icon: <FiPower fontSize="large" />,
    component: Bouncer,
    id: 0,
  },
];

if (process.env.REACT_APP_SHOW_MENU) {
  var routes = [
    {
      collapse: false,
      path: "*",
      layout: AppRoutes.ADMIN,
      name: "ErrorPage",
      state: "errorpage",
      show: false,
      icon: <MdDashboard fontSize="large" />,
      component: Error,
      id: 0,
    },
    {
      collapse: false,
      path: "*",
      layout: AppRoutes.AUTH,
      name: "Error Page",
      state: "errorpage",
      show: false,
      icon: <MdDashboard fontSize="large" />,
      component: Error,
      id: 0,
    },
    {
      collapse: true,
      path: EndPoint.SALES_MANAGEMENT,
      layout: AppRoutes.ADMIN,
      name: "Salesperson Management",
      state: "sale",
      key: "salesmanagement",
      show: false,
      icon: <IoBagHandle fontSize="large" />,
      component: SalesPersonManagement,
      id: 4,
    },
    {
      collapse: false,
      path: EndPoint.SALES_USER_DETAILS,
      layout: AppRoutes.ADMIN,
      name: "SALES USER DETAILS",
      state: "salesuserdetails",
      show: true,
      component: SalesDetails,
      id: 4,
    },
    {
      collapse: false,
      path: EndPoint.SALES_DEALER_TECHNICIAN_DETAILS,
      layout: AppRoutes.ADMIN,
      name: "SALES DEALER TECHNICIAN DETAILS",
      state: "salesdealertechniciandetails",
      show: true,
      component: SalesDealerTechnicianDetails,
      id: 4,
    },
    
    {
      collapse: false,
      path: EndPoint.SALES_PERSON_DETAILS,
      layout: AppRoutes.ADMIN,
      name: "SALESPERSON DETAILS",
      state: "salespersondetails",
      show: true,
      component: SalesPersonDetails,
      id: 4,
    },
    {
      collapse: false,
      path: "salespersondetails/onBordedDealers/:id",
      layout: AppRoutes.ADMIN,
      name: "Onborded Dealers",
      state: "salespersondetails",
      show: true,
      component: DealerManagement,
      id: 4,
    },
    {
      collapse: true,
      path: EndPoint.USER_ROLE_MANAGEMENT,
      layout: AppRoutes.ADMIN,
      name: "User Role Management",
      state: "userrole",
      show: false,
      icon: <IoPersonCircleOutline fontSize="large" />,
      component: UserRoleManagement,
      id: 8,
    },
    {
      collapse: true,
      path: EndPoint.DEALER_MANAGEMENT,
      layout: AppRoutes.ADMIN,
      name: "Dealer/Mechanic Management",
      state: "dealers",
      key: "dealermanagement",
      show: true,
      icon: <RiSettingsFill fontSize="large" />,
      component: DealerManagement,
      id: 3,
    },
  
    {
      collapse: false,
      path: "dealerDetails/:id",
      layout: AppRoutes.ADMIN,
      name: "DEALER/MECHANIC MANAGEMENT",
      state: "dealers",
      show: true,
      component: DealerDetails,
      id: 3,
    },
  
    {
      collapse: false,
      path: "dealeredit/:id",
      layout: AppRoutes.ADMIN,
      name: "DEALER/MECHANIC MANAGEMENT",
      state: "dealers",
      show: true,
      component: AddDealer,
      id: 3,
    },
    {
      collapse: false,
      path: "dealeradd",
      layout: AppRoutes.ADMIN,
      name: "DEALER/Add Dealers",
      state: "dealers",
      show: true,
      component: AddDealer,
      id: 3,
    },
    {
      collapse: false,
      path: "dealerDetails/dealerInventoryDetails/:id",
      layout: AppRoutes.ADMIN,
      name: "Inventory Details",
      state: "dealers",
      show: true,
      component: DealerInventoryDetail,
      id: 3,
    },
    {
      collapse: false,
      path: EndPoint.DEALER_TECH_ONBOARD_DETAILS,
      layout: AppRoutes.ADMIN,
      name: "Technician Onboardered Details",
      state: "dealers",
      show: true,
      component: DealerTechnicianOnboardered,
      id: 3,
    },
    {
      collapse: false,
      path: "dealerDetails/dealerBankDetails/:id",
      layout: AppRoutes.ADMIN,
      name: "Technician Onboardered Details",
      state: "dealers",
      show: true,
      component: DealerBankDetails,
      id: 3,
    },
    {
      collapse: false,
      path: "/asigntooficeadvisor",
      layout: AppRoutes.ADMIN,
      name: "Assign to Office Advisor",
      state: "asign to ofice advisor",
      key: "asign to ofice advisor",
      show: true,
      component: AssignOfficeAdvisor,
      id: 6,
    },
    {
      collapse: false,
      path: "/asigntoadvisor/:id",
      layout: AppRoutes.ADMIN,
      name: "Assign to Advisor",
      state: "asign to advisor",
      key: "asign to advisor",
      show: true,
      component: AssignToAdviser,
      id: 6,
    },
    {
      collapse: false,
      path: "/reasigntoadvisor/:id/:adid",
      layout: AppRoutes.ADMIN,
      name: " Re assign to Advisor",
      state: "re asign to advisor",
      key: "re asign to advisor",
      show: true,
      component: AssignToAdviser,
      id: 6,
    },
    {
      collapse: false,
      path: EndPoint.ORDER_DETAILS,
      layout: AppRoutes.ADMIN,
      name: "ASSIGN ADVISOR ORDER DETAILS ",
      state: "assignorderdetails",
      show: true,
      component: AssignOrderDetails,
      id: 6,
    },
    {
      collapse: false,
      path: "/asigntodealer",
      layout: AppRoutes.ADMIN,
      name: "Assign to Dealer",
      state: "asign to dealer",
      key: "asign to dealer",
      show: true,
      component: AssignDealer,
      id: 6,
    },
    {
      collapse: false,
      path: "dealerDetails/dealersViewDocuments/:id",
      layout: AppRoutes.ADMIN,
      name: "Dealers View Documents",
      state: "dealers",
      show: true,
      component: DealersViewDocuments,
      id: 3,
    },
    {
      collapse: false,
      path: "/orders",
      layout: AppRoutes.ADMIN,
      name: "Orders",
      state: "order",
      key: "order",
      show: true,
      component:Orders,
      id: 6,
    },
    
    {
      collapse: false,
      path: "/asigntooficeadvisor",
      layout: AppRoutes.ADMIN,
      name: "Assign to Office Advisor",
      state: "asign to ofice advisor",
      key: "asign to ofice advisor",
      show: true,
      component: AssignOfficeAdvisor,
      id: 6,
    },
    {
      collapse: false,
      path: "/asigntodealer",
      layout: AppRoutes.ADMIN,
      name: "Assign to Dealer / Technician",
      state: "asign to dealer",
      key: "asign to dealer",
      show: true,
      component: AssignDealer,
      id: 6,
    },
  
    {
      collapse: true,
      path: EndPoint.ORDER_MANAGEMENT,
      layout: AppRoutes.ADMIN,
      name: "Order Management",
      state: "ordermanagement",
      key: "ordermanagement",
      submenu: true,
      show: false,
      icon: <FiBox fontSize="large" />,
      id: 6,
      component:Orders,
      views: [
        {
          collapse: false,
          path: EndPoint.ORDERS,
          layout: AppRoutes.ADMIN,
          name: "Orders",
          state: "order",
          key: "order",
          show: true,
          component:Orders,
          id: 6,
        },
        {
          collapse: false,
          path: "/asigntoemergency",
          layout: AppRoutes.ADMIN,
          name: "Add Emergency Request",
          state: "asign to emergency",
          key: "asign to emergency",
          show: true,
          component: AssignEmergency,
          id: 6,
        }
      ],
    },
    {
      collapse: true,
      path: EndPoint.USERCREDENTIAL_MANAGEMENT,
      layout: AppRoutes.ADMIN,
      name: "User Credential Management",
      state: "usercredential",
      show: false,
      icon: <FaUserEdit fontSize="large" />,
      component: UserCredentialManagement,
      id: 7,
    },
    // {
    //   collapse: true,
    //   path: EndPoint.REVIEW_MANAGEMENT,
    //   layout: AppRoutes.ADMIN,
    //   name: "Review Management",
    //   state: "review",
    //   show: false,
    //   icon: <RiVoiceRecognitionFill fontSize="large" />,
    //   component: ReviewManagement,
    //   id: 10,
    // },
    {
      collapse: true,
      path: EndPoint.OFFER_MANAGEMENT,
      layout: AppRoutes.ADMIN,
      name: "Coupon Management",
      state: "offer",
      show: false,
      icon: <TbDiscount2 fontSize="large" />,
      component: OfferManagement,
      id: 9,
    },
    {
      collapse: true,
      path: EndPoint.REVIEW_MANAGEMENT,
      layout: AppRoutes.ADMIN,
      name: "Review Management",
      state: "review",
      show: false,
      icon: <RiVoiceRecognitionFill fontSize="large" />,
      component: ReviewManagement,
      id: 10,
    },
    {
      collapse: true,
      path: EndPoint.WALLET_MANAGEMENT,
      layout: AppRoutes.ADMIN,
      name: "Wallet Management",
      state: "wallet",
      show: false,
      icon: <IoWallet fontSize="large" />,
      component: WalletPage,
      id: 13,
    },
    {
      collapse: true,
      path: EndPoint.VEHICLE_MANAGEMENT,
      layout: AppRoutes.ADMIN,
      name: "Vehicle Management",
      state: "vehicle",
      show: false,
      icon: <HiTruck fontSize="large" />,
      component: VehicleManagement,
      id: 12,
    },
    {
      collapse: false,
      path: "/vehicle/vehicleDetails/vehicleProductDetails",
      layout: AppRoutes.ADMIN,
      name: "Vehicle Product Details",
      state: "vehicleproductdetails",
      show: true,
      component: VehicleProductDetails,
      id: 11,
    },
    {
      collapse: false,
      path: "/vehicle/vehicleDetails/:id",
      layout: AppRoutes.ADMIN,
      name: "Vehicle Details",
      state: "vehicledetails",
      show: true,
      component: VehicleDetails,
      id: 11,
    },
    {
      collapse: false,
      path: "/vehicle/vehicleAdd/",
      layout: AppRoutes.ADMIN,
      name: "Add Vehicle",
      state: "addvehicle",
      show: true,
      component: VehicleAdd,
      id: 11,
    },
    {
      collapse: false,
      path: "/vehicle/vehicleEdit/:id",
      layout: AppRoutes.ADMIN,
      name: "Edit Vehicle",
      state: "editvehicle",
      show: true,
      component: VehicleEdit,
      id: 11,
    },
    {
      collapse: true,
      path: EndPoint.LOGOUT,
      layout: AppRoutes.AUTH,
      name: "Logout",
      state: "logout",
      show: true,
      icon: <FiPower fontSize="large" />,
      component: Logout,
      id: 0,
    },
    {
      collapse: false,
      path: EndPoint.LOGIN,
      layout: AppRoutes.AUTH,
      name: "Login",
      state: "login",
      show: false,
      icon: <FiPower fontSize="large" />,
      component: Login,
      id: 0,
    },
    {
      collapse: false,
      path: EndPoint.FORGET_PASSWORD,
      layout: AppRoutes.AUTH,
      name: "Login",
      state: "login",
      show: false,
      icon: <FiPower fontSize="large" />,
      component: Login,
      id: 0,
    },
    {
      collapse: false,
      path: "/",
      layout: AppRoutes.ADMIN,
      name: "Bouncer",
      state: "bouncer",
      show: false,
      icon: <FiPower fontSize="large" />,
      component: Bouncer,
      id: 0,
    },
  ]  
}

export default routes;
