import React, { useState, useEffect } from "react";
import {
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Grid,
  Box,
} from "@material-ui/core";
import { TXT_BRAND, ICON_BUTTON_BRAND } from "../../utilitis/header";
import useTable from "../../sharedFeatures/useTable";
import IconButs from "../../common/Controls/IconButs";
import HeadCells from "../../common/Headcells";
import { AiOutlinePlus, AiOutlineEye, AiOutlineEdit } from "react-icons/ai";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";

import { Link, useNavigate } from "react-router-dom";
import HeadBar from "../../components/HeadBar/HeadBar";
import makeStyles from "../../sharedFeatures/sharedClasses";
import categoryBrandStyles from "./InventoryManagmentStyle";
import { BsSearch } from "react-icons/bs";
import { useForm } from "../../sharedFeatures/useForm";
import useList from "../../data/inventory/useList";
import CustomLoader from "../../features/CustomLoader";
import { PAGE_SIZE } from "../../utilitis/variable";

import useAddBrand from "../../data/inventory/useAddBrand";
import Controls from "../../common/Controls/Controls";
import { Pagination } from "@material-ui/lab";
import usePagination from "../../common/Controls/usePagination";
import DropDown from "../../common/Controls/ListingDropdown";
import PageCounter from "../../common/PageCounter";
import { AppRoutes, EndPoint } from "../../config/config";
import financeStyles from "../FinanceManagement/financeStyles";
import NavigateLink from "../../common/Controls/NavigateLink";
import { ToastContainer, toast } from "react-toastify";

const Brand = () => {
  const navigate = useNavigate();
  const styles = makeStyles();
  const customStyle = categoryBrandStyles();
  const financeStyle = financeStyles();

  //aimee
  // const [searchParams] = useSearchParams();
  const searchParams = new URLSearchParams(window.location.search);
  const searchp = searchParams.get("search");
  const itemsno = searchParams.get("itemsno");
  const page = searchParams.get("page");
  const [search, setSearch] = useState(searchp !== null ? searchp : "");
  const [pageItem, setPageItems] = useState(itemsno !== null ? itemsno : 10);
  const [currentpageNo, setcurrentpageNo] = useState(page !== null ? page : 1);

  // const [search, setSearch] = useState("");
  // const [pageItem, setPageItems] = useState(10);
  // const [currentpageNo, setcurrentpageNo] = useState(1);

  const [select, setSelect] = useState("");
  const [deleteOpen, setDeleteOpen] = useState();
  const [userId, setUserId] = useState("");

  const qry = {
    searchQry: search,
    page: page !== null ? page : currentpageNo, //aimee
    perPageItem: pageItem,
  };

  const {
    data: brandList,
    error,
    isLoading,
    isError,
    isFetching,
    refetch
  } = useList.brand_list(qry);
  const { totalCount } = PageCounter(brandList && brandList.count);


  useEffect(() => {
    refetch()
  }, [])

  const { currentData, currentPage, maxPage, slNo, setSearchParams } = usePagination(
    brandList !== undefined && brandList,
    pageItem
  );
  const handlecreateNewItem = (e) => {
    setPageItems(e.target.value);
  };
  const { TblContainer, TblHead } = useTable(HeadCells.brandHead, totalCount);
  useEffect(() => {
    setcurrentpageNo(1);
  }, [search]);

  useEffect(() => {
    setcurrentpageNo(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (currentpageNo > maxPage) {
      setcurrentpageNo(maxPage);
    }
  }, [pageItem]);
  const initialValues = {
    category_id: "",
    name: "",
    features: "",
    advantages: "",
    country_of_origin: "",
    usp: "",
    status: true,
  };

  const {
    handleOpen,
    open,
    setOpen,
    handleMessageBox,
    handleCheckBox,
    values,
    setValues,
    handleInputChange,
    errors,
    setErrors,
    messageOpen,
    messageContent,
  } = useForm(initialValues);

  let serialNo = PAGE_SIZE * slNo;

  const handleNavigate = () => {
    navigate(AppRoutes.ADMIN + EndPoint.ADD_BRAND);
  };

  const { mutateAsync: remove } = useAddBrand.del_brand();

  const { mutateAsync: toggleActiveBrand } = useAddBrand.toggleActive();

  const handleDelete = async (value) => {
    if (value)
      await remove(value)
        .then((res) => {
          if (res && res.status === 200) {


            toast.success("Brand deactivated", {
              hideProgressBar: true,
              autoClose: 2200,
            });
          }
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.detail
          ) {
            toast.warn(err.response.data.detail, {
              hideProgressBar: true,
              autoClose: 2200,
            });
          } else {
            toast.error("Something went wrong", {
              hideProgressBar: true,
              autoClose: 2200,
            });
          }
        });
  };

  const toggleActive = async (value) => {
    if (value)
      remove(value)
        .then((res) => {
          if (res && res.status === 200) {


            toast.success("Brand activated", {
              hideProgressBar: true,
              autoClose: 2200,
            });
          }
        })
        .catch((err) => {
          if (err &&
            err.response &&
            err.response.data &&
            err.response.data.detail) {
            toast.warn(err.response.data.detail, {
              hideProgressBar: true,
              autoClose: 2200,
            });
          } else {
            toast.error("Something went wrong", {
              hideProgressBar: true,
              autoClose: 2200,
            });
          }
        });
  };

  return (
    <>
      <HeadBar title={TXT_BRAND} />
      <div className={styles.divStyle}>
        <div className={customStyle.searchBoxParent}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Controls.FullWidthSearch
                placeholder="Search"
                className={styles.searchBar}
                name="usersearch"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                  searchParams.set("page", "1");
                  setSearchParams(searchParams);
                }}
                icon={
                  <BsSearch style={{ marginLeft: "5px", color: "#67748E" }} />
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={9}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "start", md: "end" },
                }}
              >
                {/* <Link to={`/administrator/addBrand/`}> */}
                <IconButs
                  ibtname={ICON_BUTTON_BRAND}
                  icon={<AiOutlinePlus />}
                  className={customStyle.dmSalesButtonOne}
                  onClick={handleNavigate}
                />
                {/* </Link> */}
              </Box>
            </Grid>
          </Grid>
        </div>

        <div className={styles.tableBox}>
          <section>
            {messageOpen ? (
              <>
                <div>
                  <Controls.MessageBox message={messageContent} />
                </div>
              </>
            ) : (
              ""
            )}
            <TblContainer>
              <TblHead />

              {isLoading || isFetching ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={8}>
                      <CustomLoader />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <>
                  {brandList &&
                    brandList.data.map((item, key) => {
                      serialNo++;
                      return (
                        <TableRow key={key}>
                          <TableCell
                            align="center"
                            style={{
                              fontFamily: "Montserrat",
                              fontSize: "12px",
                              fontWeight: "500",
                            }}
                          >
                            {item.name}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontFamily: "Montserrat",
                              fontSize: "12px",
                              fontWeight: "500",
                            }}
                          >
                            {item.usp}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontFamily: "Montserrat",
                              fontSize: "12px",
                              fontWeight: "500",
                            }}
                          >
                            {item.features}
                          </TableCell>
                          {/* <TableCell align="center">
                              {item.category_id === 1 ? "Product" : "Service"}
                            </TableCell> */}
                          <TableCell
                            align="center"
                            style={{
                              fontFamily: "Montserrat",
                              fontSize: "12px",
                              fontWeight: "500",
                            }}
                          >
                            {item.advantages}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontFamily: "Montserrat",
                              fontSize: "12px",
                              fontWeight: "500",
                              width: "11vw",
                            }}
                          >
                            {item.country_of_origin}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontFamily: "Montserrat",
                              fontSize: "12px",
                              fontWeight: "500",
                            }}
                          >
                            {item.status ? "Active" : "Inactive"}
                          </TableCell>
                          <TableCell align="center">
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {/* <Link
                                to={`/administrator/brandDetails/${item.id}`}
                              >
                                <IconButton>
                                  <AiOutlineEye className="user-icons" />
                                </IconButton>
                              </Link> */}

                              <NavigateLink
                                url={`/administrator/brandDetails/${item.id}`}
                                styleclass={styles.linkColor}
                                currentPage={currentPage}
                                trySearch={search}
                                pageItems={pageItem}
                                itemid={item.id}
                                display={
                                  <IconButton>
                                    <AiOutlineEye className="user-icons" />
                                  </IconButton>
                                }
                              />

                              {/* <Link to={`/administrator/editBrand/${item.id}`}>
                                <IconButton>
                                  <AiOutlineEdit className="user-icons" />
                                </IconButton>
                              </Link> */}

                              <NavigateLink
                                url={`/administrator/editBrand/${item.id}`}
                                styleclass={styles.linkColor}
                                currentPage={currentPage}
                                trySearch={search}
                                pageItems={pageItem}
                                itemid={item.id}
                                display={
                                  <IconButton>
                                    <AiOutlineEdit className="user-icons" />
                                  </IconButton>
                                }
                              />

                              <IconButton>
                                {item.status ? (
                                  <BsToggleOn
                                    onClick={() => handleDelete(item.id)}
                                    className="user-icons"
                                  />
                                ) : (
                                  <BsToggleOff
                                    onClick={() => toggleActive(item.id)}
                                    className="user-icons"
                                  />
                                )}
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </>
              )}
            </TblContainer>
          </section>
        </div>

        {((brandList && brandList.data.length === 0) || isError) && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "100px",
            }}
          >
            <lottie-player
              src="https://assets8.lottiefiles.com/packages/lf20_agnejizn.json"
              background="transparent"
              speed="1"
              style={{ width: "300px", height: "300px" }}
              loop
              autoplay
            ></lottie-player>
          </div>
        )}

        <div className={financeStyle.pagination}>
          <div className={financeStyle.paginationStyle}>
            <DropDown
              value={pageItem}
              handlecreateNewItem={handlecreateNewItem}
              style={{ width: "90px", borderRadius: "55px", height: "34px" }}
              items={[
                { key: 1, value: 10 },
                { key: 2, value: 20 },
                { key: 3, value: 30 },
              ]}
            />
            <span className={financeStyle.paginationPage}>Per Page</span>
          </div>
          <div className={customStyle.paginationNext}>
            <Pagination
              count={maxPage}
              page={currentPage}
              onChange={currentData}
              variant="outlined"
              className={financeStyle.paginationFont}
            />
          </div>
        </div>
        {/* {deleteOpen ? (
          <DialogBox
            open={deleteOpen}
            setOpen={setDeleteOpen}
            handleClose={handleDeleteClose}
            handleRemove={handleRemove}
            content="Are you sure you want to delete this Brand?"
            userId={userId}
          />
        ) : null} */}
      </div>
    </>
  );
};

export default Brand;
