const dealerTechnicalHead = [
  { id: "name", label: "Name" },
  { id: "email", label: "Email" },
  { id: "phone", label: "Phone" },
  { id: "location", label: "Location" },
  { id: "user_type", label: "User Type" },
];

const userHead = [
  // { id: 'sl_no', label: 'Sl.No' },
  { id: "name", label: "Name" },
  { id: "email", label: "Email" },
  { id: "mobile", label: "Mobile" },
  { id: "location", label: "Location" },
  { id: "cardetails", label: "Car Details" },
  { id: "orderdetail", label: "Order Detail" },
  { id: "status", label: "Status" },
  { id: "action", label: "Action" },
];

const assignDealerHead = [
  // { id: "status", label: "Status" },
  { id: "user_id", label: "Id" },
  { id: "company_name", label: "Company Name" },
  { id: "name", label: "Name" },
  { id: "contact_no", label: "Contact No" },
  // { id: "user_type", label: "User Type" },
  { id: "location", label: "Location" },
  { id: "distance", label: "Distance From Customer Location" },
  { id: "action", label: "Action" },
];

const categoryHead = [
  { id: "sl_no", label: "Sl.No" },
  { id: "name", label: "Name" },
  { id: "other_charges", label: "Other Charges" },
  { id: "cat_type", label: "Type of Category" },
  { id: "usp", label: "USP" },
  { id: "action", label: "Action" },
];

const brandHead = [
  { id: "name", label: "Name" },
  { id: "usp", label: "USP" },
  { id: "features", label: "Features" },
  // { id: "category_id", label: "Type of Category" },
  { id: "advantage", label: "Advantages" },
  { id: "origin_country", label: "Country Of Origin" },
  { id: "status", label: "Status" },
  { id: "action", label: "Action" },
];

const productHead = [
  { id: "sl_no", label: "Sl.No" },
  { id: "item_code", label: "Item Code" },
  { id: "model", label: "Model" },
  { id: "gst_perc	", label: "GST" },
  { id: "margin", label: "Margin" },
  { id: "dealer_landing_price", label: "Dealer Price" },
];

const addProductHead = [
  { id: "sl_no", label: "Sl.No" },
  { id: "brand_id", label: "Brand id" },
  { id: "item_code", label: "Item Code" },
  { id: "model", label: "Model" },
  { id: "gst_perc	", label: "GST" },
  { id: "margin", label: "Margin" },
  { id: "dealer_landing_price", label: "Dealer Price" },
];

const dealerHead = [
  // { id: 'sl_no', label: 'Sl.No' },
  // { id: 'id', label: 'ID' },
  { id: "company_name", label: "Company Name" },
  { id: "name", label: "Contact Name" },
  { id: "email", label: "Email" },
  { id: "phone", label: "Mobile" },
  { id: "location", label: "Location" },
  { id: "onboardby", label: "On Boarded By" },
  { id: "onboard", label: "On Boarded Date" },
  { id: "sales_person", label: "User Type" },
  { id: "status", label: "Status" },
  { id: "action", label: "Action" },
];

const dealerTechDetails = [
  { id: "sl_no", label: "Sl.No" },
  { id: "name", label: "Name" },
  { id: "email", label: "Email" },
  { id: "phone", label: "Phone" },
  { id: "address", label: "Address" },
  { id: "products", label: "Products" },
  { id: "status", label: "Status" },
  { id: "comment", label: "Comment" },
];

const salesHead = [
  { id: "sl_no", label: "Sl.No" },
  { id: "name", label: "Name" },
  { id: "email", label: "Email" },
  { id: "mobile", label: "Mobile" },
  { id: "location", label: "Location" },
  { id: "dealers", label: "Dealers/Technician On-Boarded" },
  { id: "followUp", label: "Last Follow-Up" },
  { id: "status", label: "Status" },
  { id: "action", label: "Action" },
];

const salesUserDetails = [
  { id: "name", label: "Name" },
  { id: "email", label: "Email" },
  { id: "mobile", label: "Mobile" },
  { id: "location", label: "Location" },
  { id: "address", label: "Address" },
  { id: "visitingcard", label: "Visiting Card" },
  { id: "status", label: "Status" },
  { id: "action", label: "Action" },
];

const inventryHead = [
  { id: "inventory", label: "Item Code" },
  { id: "inventory_type", label: "Series" },
  { id: "inventory_name", label: "Model" },
  { id: "brand", label: "Brand" },
  { id: "category", label: "Category" },
  { id: "sub_catogery", label: "Subcategory" },
  { id: "title", label: "Title" },
  { id: "vehicle_category", label: "Vehicle category" },

  { id: "hsn", label: "HSN" },
  { id: "zoho_id", label: "Zoho id" },
  { id: "last_updated", label: "Last updated" },
  { id: "updated_by", label: "Updated by" },
  { id: "status", label: "Status" },
  { id: "action", label: "Action" },
];

const userCredentialHead = [
  // { id: 'sl_no', label: 'Sl.No' },
  { id: "name", label: "Name" },
  { id: "userRole", label: "User Role" },
  { id: "phone", label: "Phone" },
  { id: "email", label: "Email" },
  // { id: "username", label: "Username" },
  { id: "password", label: "Password" },
  { id: "status", label: "Status" },
  { id: "action", label: "Action" },
];

const userRole = [
  // { id: 'sl_no', label: 'Sl.No' },
  { id: "userRole", label: "User Role" },
  { id: "permission", label: "Permissions" },
  // { id: "status", label: "Status" },
  { id: "action", label: "Action" },
];

const userOffer = [
  { id: "couponName", label: "Coupon Name" },
  { id: "couponCode", label: "Coupon Code" },
  { id: "minimumCartValue", label: "Minimum Cart Value" },
  { id: "discountAmount", label: "Discount Amount" },
  { id: "action", label: "Action" },
];

const userReview = [
  // { id: 'sl_no', label: 'Sl.No' },
  { id: "reviewer", label: "Reviewer" },
  { id: "orderid", label: "Order Id" },
  { id: "dateandtime", label: "Date and Time" },
  { id: "comments", label: "Comments" },
  { id: "starrating", label: "Star Rating" },
  // { id: "action", label: "Action" },
];

const userFinance = [
  // { id: 'sl_no', label: 'Sl.No' },
  { id: "orderid", label: "Order ID" },
  { id: "customer", label: "Customer" },
  { id: "techinician", label: "Techinician" },
  { id: "totalpayment", label: "Total Payment" },
  { id: "commission", label: "Commission" },
  // { id: "paymentstatus", label: "Payment Status" },
  // { id: "commissionstatus", label: "Commission Status" },
  { id: "invoice", label: "Invoice" },
  { id: "action", label: "Action" },
];

const userVehicle = [
  { id: "sl_no", label: "Vehicle ID" },
  { id: "make", label: "Car Brand" },
  { id: "model", label: "Car Model" },
  { id: "trim", label: "Car Variant" },
  { id: "fuel_type", label: "Fuel Type" },
  { id: "catogery", label: "Catogery" },
  { id: "status", label: "Car Status" },
  { id: "action", label: "Action" },
];

const asignToOficeAdvisor = [
  { id: "orderId", label: "Order ID" },
  { id: "customer", label: "Customer" },
  { id: "order status", label: "Order Status" },
  { id: "payment status", label: "Payment Status" },
  { id: "advisor asigned", label: "Advisor Assigned" },
  { id: "created at", label: "Created at" },
  { id: "action", label: "Action" },
];

const asignToDealer = [
  { id: "orderID", label: "Order ID" },
  { id: "customer", label: "Customer" },
  { id: "order status", label: "Order Status" },
  { id: "payment status", label: "Payment Status" },
  { id: "dealer assigned", label: "Dealer Assigned" },
  { id: "completion time", label: "Completion Time" },
  { id: "timer", label: "Timer" },
  { id: "linked orders", label: "Linked Orders" },
  { id: "action", label: "Action" },
];

const asignToEmergency = [
  { id: "sl_no", label: "Sl.No" },
  { id: "orderId", label: "Order Id" },
  { id: "customer", label: "Customer" },
  { id: "order status", label: "Order Status" },
  { id: "payment status", label: "Payment Status" },
  { id: "advisor asigned", label: "Advisor Asigned" },
  { id: "compleated time", label: "Completed Time" },
  { id: "linked orders", label: "Linked Orders" },
  { id: "action", label: "Action" },
];
const asignToAdvisorHead = [{ id: "advisorname", label: "Advisor name" }];

const addInventoryProduct = [
  { id: "Dealer Name", label: "Dealer Name" },
  { id: "Brand", label: "Brand" },
  { id: "Location", label: "Location" },
  { id: "status", label: "Status" },
  { id: "action", label: "Action" },
];

const walletManagement = [
  { id: "Customer Name", label: "Customer Name" },
  {id: "Contact Number" , label : "Contact Number"},
  { id: "Wallet Amount", label: "Wallet Amount" },
];

const reports = [
  { id: "no", label: "SL No" },
  { id: "Salesperson", label: "Salesperson" },
  { id: "Dealers Onboarded", label: "Dealers Onboarded" },
  { id: "Dealers Approved", label: "Dealers Approved" },
  { id: "Dealers Pending For Approval", label: "Dealers Pending For Approval" },
  { id: "Action", label: "Action" },
];


const reportsDealers = [
  { id: "no", label: "SL No" },
  { id: "Dealer Name", label: "Dealer Name" },
  { id: "Status", label: "Status" },
  { id: "Action", label: "Action" },
]

const orderItems = [
  { id: "Code", label: "Code" },
  { id: "Title", label: "Title" },
  {id: "Category", label: "Category"},
  { id: "Sub Category", label: "Sub Category" },
  { id: "Brand", label: "Brand" },
  { id: "Series", label: "Series" },
  { id: "Model", label: "Model" },
  { id: "Price", label: "Price (INR)" },
  { id: "Quantity", label: "Quantity" },
  { id: "Discount", label: "Discount (INR)" },
  { id: "GST", label: "GST (INR)" },
  { id: "Total", label: "Total (INR)" },
]

const additionalItems = [
  { id: "Item", label: "Item" },
  { id: "Quantity", label: "Quantity" },
  { id: "Price", label: "Price (INR)" },
  { id: "Details", label: "Details" },
  { id: "Voice Clip", label: "Voice Clip" },
]

const HeadCells = {
  asignToAdvisorHead,
  userHead,
  salesHead,
  salesUserDetails,
  userCredentialHead,
  userRole,
  userOffer,
  userReview,
  userFinance,
  userVehicle,
  userHead,
  salesHead,
  dealerHead,
  dealerTechDetails,
  inventryHead,
  asignToOficeAdvisor,
  asignToDealer,
  asignToEmergency,
  categoryHead,
  brandHead,
  productHead,
  addProductHead,
  addInventoryProduct,
  assignDealerHead,
  dealerTechnicalHead,
  walletManagement,
  reports,
  reportsDealers,
  orderItems,
  additionalItems
};

export default HeadCells;
