import React, { useState, useEffect } from "react";
import { Paper, IconButton, Grid, Box } from "@material-ui/core";
import makeStyles from "../../sharedFeatures/sharedClasses";
import Sub from "../../common/Subheader/Sub";
import {
  TXT_BRAND_DETAIL,
  ASSIGN_DEALER_TECHNICIAN,
  TXT_BRAND,
} from "../../utilitis/header";
import { Link, useParams, useSearchParams } from "react-router-dom";
import IconButs from "../../common/Controls/IconButs";
import Controls from "../../common/Controls/Controls";
import useList from "../../data/inventory/useList";
import useBrandData from "../../data/inventory/useBrandData";
import BackbuttonIcon from "../../utilitis/BackbuttonIcon";
import HeadBar from "../../components/HeadBar/HeadBar";
import categoryBrandStyles from "./InventoryManagmentStyle";

const BrandDetails = () => {
  const viewStyle = makeStyles();
  const { data: selectCatList } = useList.cat_List_SelectBox();
  const { id } = useParams();
  const { data: brandData } = isNaN(id) ? "" : useBrandData(id);
  const categoryStyle = categoryBrandStyles();
  const [category, setCategory] = useState([]);
  const [subcategory, setSubcategory] = useState([]);
  const [optsel, setOptsel] = useState("");
  const [catsel, setCatsel] = useState("");

   //aimee
   const [searchParams] = useSearchParams();
   const page = searchParams.get("page");
   const search = searchParams.get("search");
   const itemsno = searchParams.get("itemsno");
   
   let link = "/administrator/brand";
   link = page !== null ? link + `?page=${page}` : link;
   link = search !== null ? link + `&search=${search}` : link;
   link = itemsno !== null ? link + `&itemsno=${itemsno}` : link;
 
  useEffect(() => {
    
    if (brandData) {
          
      setCategory([]);
      selectCatList &&
        selectCatList.map((item, id) => {
          if(brandData.category_id.parent_id !== null){
          // console.log(item.parent_id," = ", brandData.category_id.parent_id)
          if (item.parent_id === brandData.category_id.parent_id.id) { //aimee           
            setSubcategory((subcategory) => [...subcategory, item]);
            setOptsel(item.parent_id);

          }}
          if (item.parent_id === null) {
            setCategory((category) => [...category, item]);

            if (item.id === brandData.category_id.id) {
              setCatsel(item.id);

            }
          }
        });
    }
  }, [brandData]);

  return (
    <>
      <HeadBar title={TXT_BRAND} />
      <div className={viewStyle.divStyle}>
        <Paper className={categoryStyle.parentContainer} elevation="6">
          <div className={viewStyle.subHeaderParent}>
            <Box sx={{ display: { xs: "none", md: "inline" } }}>
              <Link to={link}>
                <IconButton className={viewStyle.backButtonParent}>
                  <BackbuttonIcon />
                </IconButton>
              </Link>
            </Box>

            <Sub data={TXT_BRAND_DETAIL} />
          </div>

          <Grid
            container
            alignItems="center"
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            
          

            {brandData && (
              <>
                <Grid item xs={12} md={4} className={categoryStyle.label}>
                  Category
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.SelectBox
                    disabled={"true"}
                    className={categoryStyle.basicSelectBox}
                    name="category_id"
                    label="Category Type"
                    options={category}
                    value={
                      brandData && brandData.category_id.parent_id
                        ? optsel
                        : catsel
                    }
                  />
                </Grid>
              </>
            )}
            

            {brandData && brandData.category_id.parent_id && (
              <>
                <Grid item xs={12} md={4} className={categoryStyle.label}>
                  Sub Category
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.SelectBox
                    disabled={"true"}
                    className={categoryStyle.basicSelectBox}
                    name="sub_category_id"
                    label="Category Type"
                    options={subcategory}
                    value={
                      brandData &&
                      brandData.category_id &&
                      brandData.category_id.id
                    }
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} md={4} className={categoryStyle.label}>
              Name of the Brand
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.StyledInput
                disabled={"true"}
                className={categoryStyle.formTextBar}
                name="name"
                placeholder={'Name'}
                value={brandData && brandData.name}
              />
            </Grid>
            <Grid item xs={12} md={4} className={categoryStyle.label}>
              USP
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.TextArea
                disabled={"true"}
                className={categoryStyle.textArea}
                name="usp"
                placeholder={'USP'}
                value={brandData && brandData.usp}
              />
            </Grid>
            <Grid item xs={12} md={4} className={categoryStyle.label}>
              Features
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.TextArea
                disabled={"true"}
                className={categoryStyle.textArea}
                name="features"
                placeholder={'Features'}
                value={brandData && brandData.features}
              />
            </Grid>
            <Grid item xs={12} md={4} className={categoryStyle.label}>
              Advantages
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.TextArea
                disabled={"true"}
                className={categoryStyle.textArea}
                name="advantages"
                placeholder={'Advantages'}
                value={brandData && brandData.advantages}
              />
            </Grid>
            <Grid item xs={12} md={4} className={categoryStyle.label}>
              Country of Origin
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.StyledInput
                disabled={"true"}
                className={categoryStyle.formTextBar}
                name="country_of_origin"
                placeholder={'Country of Origin'}
                value={brandData && brandData.country_of_origin}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{display:{xs:"inline",sm:"flex"},justifyContent:"center",alignItems:"center"}}>
              <Link to={link}>
                <IconButs
                  ibtname={ASSIGN_DEALER_TECHNICIAN}
                  className={viewStyle.formButtonAdd}
                  variant="outlined"
                />
              </Link>
            </Box>
          </Grid>
        </Paper>
      </div>
    </>
  );
};

export default BrandDetails;
