import { useMutation, useQueryClient } from "react-query";
import { editOrder } from "./orderUrls";

const edit_order = () => {
  const queryClient = useQueryClient();

  return useMutation((data) => editOrder(data), {
    onMutate: async (newData) => {
      await queryClient.cancelQueries("getAdvisorList");
      const prev = queryClient.getQueryData("getAdvisorList");

      return {
        prev,
        newData,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("getAdvisorList");
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("getAdvisorList", context.prev);
    },
  });
};

const useAddOrder = {
  edit_order
};

export default useAddOrder;
