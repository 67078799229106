import { useQuery } from "react-query";
import { couponDetails } from "./couponUrls";

const getCouponsList = (qry) => {
  return useQuery(["couponslist", qry], () => couponDetails(qry), {
    // staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: true,
    
  });
};



const useCouponQuery = {
    getCouponsList,
};

export default useCouponQuery;
