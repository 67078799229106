import React, { useEffect, useState } from "react";
import useTable from "../../sharedFeatures/useTable";
import {
  TableBody,
  TableRow,
  TableCell,
  Box,
  Grid,
 
} from "@material-ui/core";
import { BsSearch } from "react-icons/bs";
import HeadCells from "../../common/Headcells";
import { TXT_USERROLEMANAGEMENT } from "../../utilitis/header";
import { ICON_BUTTON_USERROLEMANAGEMENT } from "../../utilitis/header";
import IconButs from "../../common/Controls/IconButs";
import makeStyles from "../../sharedFeatures/sharedClasses";
import UserRoleForm from "./UserRoleAdd";
import UserRoleEdit from "./UserRoleEdit";
import UserRoleDetails from "./RoleDetails";
import { AiOutlinePlus } from "react-icons/ai";
import { Link, useSearchParams } from "react-router-dom";
import HeadBar from "../../components/HeadBar/HeadBar";
import { useForm } from "../../sharedFeatures/useForm";
import Controls from "../../common/Controls/Controls";
import { Pagination } from "@material-ui/lab";
import userRoleQuery from "../../data/user role/useUserRoleQuery";
import PageDropDown from "../../common/Controls/PageDropDown";
import CustomLoader from "../../features/CustomLoader";
import usePagination from "../../common/Controls/usePagination";
import PageCounter from "../../common/PageCounter";
import { ToastContainer, toast } from "react-toastify";

import "../DealerManagement/AddEditDealer/toast.css";

const UserRole = () => {
  const userRoleManagers = [
    {
      id: "1",
      name: "Active",
    },
    {
      id: "2",
      name: "InActive",
    },
  ];
  const searchParams = new URLSearchParams(window.location.search);

  let page = searchParams.get("page");
  const [trySearch, setTrySearch] = useState("");
  const [pageItems, setPageItems] = useState(10);
  const [currentpageNo, setcurrentpageNo] = useState(page !== null ? page : 1);
  
  const [editOpen, setEditOpen] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [selectedID, setSelectedID] = useState(null);

  const handleEditOpen = (id) => {
    setSelectedID(id);
    setEditOpen(true);
  };

  const handleDetailsOpen = (id) => {
    setSelectedID(id);
    setDetailsOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleDetailsClose = () => {
    setDetailsOpen(false);
  };

  const styles = makeStyles();

  const { handleOpen, open } = useForm();
  useEffect(()=>{
    setcurrentpageNo(1)
  },[trySearch,pageItems])

  const qry = {
    searchQry: trySearch,
    page:page !== null ? page : currentpageNo,
    
    perPageItems: pageItems,
  };

  const { data: userRoleData, isLoading: rolesLoading ,isFetching,refetch} =
    userRoleQuery.getUserRolesList(qry);
    useEffect(()=>{
      refetch()
    },[])
  const { totalCount } = PageCounter(14);

  const { TblContainer, TblHead } = useTable(HeadCells.userRole, totalCount);

  const { currentData, currentPage, maxPage ,setSearchParams} = usePagination(
    userRoleData !== undefined && userRoleData,
    pageItems
  );

  const handlePageItems = (e) => {
    searchParams.set('page','1')
    setSearchParams(searchParams)
    setPageItems(e.target.value);
  };

  //if last page and perpage changes go to first page
  React.useEffect(() => {
    if (currentpageNo > maxPage) {
      setcurrentpageNo(maxPage);
    }
  }, [pageItems]);

  React.useEffect(() => {
    setcurrentpageNo(currentPage);
  }, [currentPage]);



  return (
    <>
      <HeadBar title={TXT_USERROLEMANAGEMENT} />
      <div className={styles.listdivStyle}>
        <div className={styles.searchBoxParent}>
          <Grid
            container
            spacing={2}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid
              item
              xs={12}
              md={5}
              style={{ display: "flex", justifyContent: "start" }}
            >
              <Controls.StyledSearch
                placeholder="Search"
                className={styles.searchBar}
                name="usersearch"
                value={trySearch}
                onChange={(e) =>{ 
                  searchParams.set('page','1')
                  setSearchParams(searchParams)
                  setTrySearch(e.target.value)
                }}
                icon={<BsSearch  />}
              />
            </Grid>
            {/* <Grid item xs={12} md={3}>
              <div className={styles.excelData}>
                <IconButs
                  ibtname={ICON_BUTTON_USERROLEMANAGEMENT}
                  icon={<AiOutlinePlus />}
                  className={styles.salesButton}
                  onClick={handleOpen}
                />
              </div>
            </Grid> */}
          </Grid>
        </div>

        <Box className={styles.tableBox}>
          <TblContainer>
            <TblHead />
            {rolesLoading || isFetching ? (
              <TableRow>
                <TableCell colSpan={8}>
                  <CustomLoader />
                </TableCell>
              </TableRow>
            ):
            <TableBody>
              {userRoleData &&
                userRoleData.data &&
                userRoleData.data.map((item, idx) => {
                  return (
                    <TableRow key={idx}>
                      <TableCell align="center">
                        {item.group_id && item.group_id.name
                          ? item.group_id.name
                          : "--"}
                      </TableCell>
                      <TableCell align="center">
                        <Link
                          to=""
                          className={styles.linkColor}
                          onClick={() => handleDetailsOpen(item.id)}
                          style={{ cursor: "pointer" }}
                        >
                          View
                        </Link>
                      </TableCell>
                      {/* <TableCell align="center">Active</TableCell> */}

                      <TableCell align="center">
                        <div className={styles.link}>
                          <div>
                            <span
                              onClick={() => handleEditOpen(item.id)}
                              className={styles.linkColor}
                              style={{ cursor: "pointer" }}
                            >
                              Edit
                            </span>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            }
          </TblContainer>
        </Box>

        <div className="pagination">
          <div className="paginationStyle">
            <PageDropDown
              value={pageItems}
              style={{ width: "90px", height: "34px", borderRadius: "55px" }}
              handlePageItems={handlePageItems}
              items={[
                { key: 1, value: 10 },
                { key: 2, value: 20 },
                { key: 2, value: 30 },
              ]}
            />
            <span className="paginationPage">Per Page</span>
          </div>

          <Pagination
            count={maxPage}
            className={styles.paginationCompStyle}
            page={ Number(page)|| currentPage}
            onChange={currentData}
            variant="outlined"
          />
        </div>

        {open ? (
          <UserRoleForm
            open={open}
            handleClose={handleOpen}
            title="Add Role"
            userRoleManagersData={userRoleManagers}
          />
        ) : null}

        {editOpen ? (
          <UserRoleEdit
            open={editOpen}
            handleClose={handleEditClose}
            title="Edit Role"
            userRoleManagersData={userRoleManagers}
            id={selectedID}
          />
        ) : null}

        {detailsOpen ? (
          <UserRoleDetails
            open={detailsOpen}
            handleClose={handleDetailsClose}
            title="Permissions"
            id={selectedID}
          />
        ) : null}
      </div>

    
    </>
  );
};

export default UserRole;
