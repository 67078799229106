import React, { useState } from "react";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  TableContainer,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { PAGE_SIZE } from "../utilitis/variable";
const useStyles = makeStyles((theme, state) => ({
  root: {
    height: "auto",
    width: "100vw",
    fontFamily: "Montserrat !important",
    "& .MuiTableCell-root": {
      padding: "16px 5px !important",
    },
  },
  tableHead: {
    height: "66px",
    // padding: "10px",
    ["@media (max-width: 1500px)"]: {
      height: "100px",
    },
  },
  table: {
    position: "relative",
    backgroundColor: "#FFFFFF",
    marginLeft: "auto",
    marginRight: "auto",
    "&": {
      // width: "96%",
      // [theme.breakpoints.down('md')]: {
      //   width: "40%",
      // },
    },
    "& thead th": {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "14px",
      color: "#8392AB",
      lineHeight: "17px",
      
      // textAlign: "center",
      borderBottom: "1px solid #F5F5F5",
      
    },
    "& tbody td": {
      fontFamily: "Montserrat",
      fontWeight: "500",
      fontSize: "12px",
      borderBottom: "1px solid #E8E8E8",
    },
    "& tbody": {
      backgroundColor: "#FFFFFF",
    },
    "& .MuiTableCell-root": {
    
      [theme.breakpoints.down("sm")]: {
        padding: "16px 3vw !important",
      },
    },
  },
  pageNtn: {
    margin: theme.spacing(2.5),
    marginTop: theme.spacing(5),
    width: "100%",
    position: "relative",
  },
  "& .MuiTableCell-root": {
    padding: "16px 7px !important",
  },
}));

export default function useTable(headCells, totalCount,align="center") {
  const classes = useStyles();
  const [pageNo, setPageNo] = useState(1);
  const [slNo, setSlNo] = useState(0);
  const handleChange = (event, value) => {
    setPageNo(value);
    setSlNo(value - 1);
  };
  const TblContainer = (props) => (
    <Table component={Paper}   elevation={0} className={classes.table}>
      {props.children}
    </Table>
  );
  const TblHead = (props) => (
    <TableHead >
      <TableRow  className={classes.tableHead} >
        {headCells.map((headCell) => (
          <TableCell key={headCell.id}  align="center" >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
  const TblPagination = () => (
    <Pagination
      count={Math.ceil(totalCount / PAGE_SIZE)}
      page={pageNo}
      onChange={handleChange}
      className={classes.pagentn}
    />
  );
  return {
    TblContainer,
    TblHead,
    TblPagination,
    pageNo,
    slNo,
  };
}
