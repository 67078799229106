import { useQuery } from "react-query";
import {
  getDealerlist,
  getDealerDetail,
  getProductType,
  getServicetype,
  getBrandByCategory,
  getInputListForImage,
  getBothByCat,
  getTradingAndServices,
  getDealerTechnicianData,
  getFecilites,
} from "./dealerUrls";

//get the listing of dealers

const dealers_list = (qry) => {
  return useQuery(["dealersList", qry], () => getDealerlist(qry), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: true,
    poll: 500,
  });
};

//get the detail view of dealer

const dealers_data = (qry) => {
  return useQuery(["dealersList", qry], () => getDealerDetail(qry), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const get_Trading_Type = () => {
  // get the list of trading (product list) on the basis of id ,Id of trading : 1,  Id of services: 2 ,
  return useQuery(["getProducttype"], () => getProductType(), {
    // enabled: !!serviceId,
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const get_Service_Type = () => {
  // get the list of services (product list) on the basis of id ,Id of trading : 1,  Id of services: 2 ,
  return useQuery(["getServicetype"], () => getServicetype(), {
    // enabled: !!serviceId,
    
    staleTime: 30000,
    retry:false,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const get_brands_services_by_cat = (catID) => {
  return useQuery(["getBothByCat", catID], () => getBothByCat(catID), {
    enabled: !!catID,
    retry:false,
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const get_dealer_technician_by_dealer = (dealerID) => {
  return useQuery(
    ["getDealerTech", dealerID],
    () => getDealerTechnicianData(dealerID),
    {
      enabled: !!dealerID,
      staleTime: 30000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

const get_trading_and_services = () => {
  return useQuery(["getTradingAndServices"], () => getTradingAndServices(), {
    // enabled: !!catID,
    staleTime: 30000,
    keepPreviousData: true,
    retry:false,
    refetchOnWindowFocus: false,
  });
};
const get_fecilities = () => {
  return useQuery(["getFecilitied"], () => getFecilites(), {
    // enabled: !!catID,
    
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

// const get_both_productType = (serviceId) => {     // get the list of both services and trading
//   return useQuery(['getProducttype', serviceId], () => getallcategory(),{
//     enabled: !!serviceId,
//     staleTime: 30000,
//     keepPreviousData: true,
//     refetchOnWindowFocus: false,
//   })
// }

const get_Brand_By_Category = (brandId) => {
  // get the list of both services and trading
  return useQuery(
    ["getProducttype", brandId],
    () => getBrandByCategory(brandId),
    {
      enabled: !!brandId,
      staleTime: 30000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

//*************Get Dynamic Input Fields for Document Page  from here************
const get_Input_List = () => {
  return useQuery(
    ["getInputListForImageUpload"],
    () => getInputListForImage(),
    {
      staleTime: 30000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

const useDealersQuery = {
  dealers_list,
  dealers_data,
  get_Trading_Type,
  // get_both_productType,
  get_Brand_By_Category,
  get_Input_List,
  get_Service_Type,
  get_brands_services_by_cat,
  get_trading_and_services,
  get_dealer_technician_by_dealer,
  get_fecilities
};

export default useDealersQuery;
