import React from "react";
import { Box } from "@mui/system";
import useStyles from "./dashboardStyles";
import { IconButton } from "@material-ui/core";

const ChartHeader = ({ header, subHeader, icon }) => {
  const dashStyles = useStyles();
  return (
    <>
      <Box className={dashStyles.chartHeaderParent}>
        <h3 className={dashStyles.chartHeader}>{header}</h3>
        <Box  sx={{ display: "flex", alignItems: "center" }}>
          {icon && (
            <IconButton disabled sx={{ marginTop: "-17px" }}>
              {icon}
            </IconButton>
          )}
          <h6 className={dashStyles.chartSubHeader}>{subHeader}</h6>
        </Box>
      </Box>
    </>
  );
};

export default ChartHeader;
