import {
  makeStyles,
  createStyles,
  createTheme,
} from "@material-ui/core/styles";
const theme = createTheme();

export default makeStyles((theme) =>
  createStyles({
    viewlabel: {
      fontWeight: "600",
      fontStyle: "normal",
      fontSize: "12px",
      lineHeight: "24px",
      color: "#444445",
      fontFamily: "Montserrat !important",
      marginTop: "10px",
    },
    viewBtnlabel: {
      fontWeight: "600",
      fontStyle: "normal",
      fontSize: "12px",
      lineHeight: "24px",
      color: "#444445",
      fontFamily: "Montserrat !important",
      marginTop: "30px",
      ["@media (max-width: 412px)"]: {
        marginTop: "20px !important",
      },
    },
    tabLabel: {
      textTransform: "none",
      fontFamily: "Montserrat",
      fontSize: "14px",
    },
    inputTextBar: {
      width: "320px",
      fontFamily: "Montserrat !important",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          fontFamily: `Montserrat`,
          fontSize: "12px",
        },
        "& input": {
          fontFamily: `Montserrat`,
          fontSize: "12px",
        },
      },
      marginBottom: "20px",
      ["@media (max-width: 1500px)"]: {
        width: "100% !important",
      },
      ["@media (max-width: 400px)"]: {
        width: "94% !important",
      },
    },
    userButton: {
      position: "relative !important",
      display: "flex !important",
      justifyContent: "space-between !important",
      fontFamily: "Montserrat !important",
      fontWeight: "480 !important",
      background: "#FEDD17 !important",
      borderColor: "red !important",
      fontSize: "12px !important",
      border: "1px solid yellow !important",
      color: "#252F40 !important",
      width: "320px !important",
      height: "40px !important",
      textTransform: "capitalize !important",
      lineHeight: "15px !important",
      letterSpacing: "-0.387234px !important",
      boxShadow:
        "0px 4px 6px -1px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.07) !important",
      border: "0 !important",
      borderRadius: "8px !important",
      marginTop: "20px !important",
      ["@media (max-width: 412px)"]: {
        width: "340px !important",
        marginTop: "0 !important",
      },
    },
    userButton2: {
      fontFamily: "Montserrat !important",
      fontWeight: "480 !important",
      background: "#FEDD17 !important",
      borderColor: "red !important",
      fontSize: "12px !important",
      border: "1px solid yellow !important",
      color: "#252F40 !important",
      width: "120px !important",
      height: "40px !important",
      textTransform: "capitalize !important",
      lineHeight: "15px !important",
      letterSpacing: "-0.387234px !important",
      boxShadow:
        "0px 4px 6px -1px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.07) !important",
      border: "0 !important",
      borderRadius: "8px !important",
      ["@media (max-width: 412px)"]: {
        width: "120px !important",
      },
    },
    userButtonContainer: {
      backgroundColor: "#fff",
      border: "1px solid #FEDD17",
      borderRadius: "6px",
      marginTop: "20px",
      width: "320px",
      display: "flex",
      justifyContent: "space-between",
      ["@media (max-width: 482px)"]: {
        marginTop: "0px !important",
        width: "380px !important",
      },
      ["@media (max-width: 420px)"]: {
        marginTop: "0px !important",
        width: "320px !important",
      },
    },
    geolocation: {
      width: "320px !important",
      height: "50px !important",
      fontFamily: "Montserrat !important",
      fontStyle: "normal !important",
      fontWeight: "400 !important",
      fontSize: "12px !important",
      lineHeight: "24px !important",
      color: "black !important",
      padding: "18.5px 14px !important",
      margin: "2px 0 !important",
      marginBottom: "20px !important",
      borderRadius: "6px !important",
      ["@media (max-width: 960px)"]: {
        width: "100% !important",
        margin: "0px  !important",
        marginBottom: "20px !important",
      },
      ["@media (max-width: 820px)"]: {
        width: "100% !important",
        margin: "0px !important",
        marginBottom: "20px !important",
      },
      ["@media (max-width: 450px)"]: {
        borderRadius: "8px !important",
        width: "100% !important",
        marginBottom: "0px !important",
      },
      ["@media (max-width: 400px)"]: {
        borderRadius: "8px !important",
        width: "94% !important",
      },
      ["@media (max-width: 360px)"]: {
        borderRadius: "8px !important",
        width: "94% !important",
      },
    },
    userButtonContainer2: {
      backgroundColor: "#FFE861",
      border: "1px solid #FEDD17",
      borderRadius: "6px",
      marginTop: "20px",
      width: "320px",
      display: "flex",
      justifyContent: "space-between",
      ["@media (max-width: 482px)"]: {
        marginTop: "0px !important",
        width: "380px !important",
      },
      ["@media (max-width: 420px)"]: {
        marginTop: "0px !important",
        width: "320px !important",
      },
    },
    iconSizeMedium: {
      "& > *:first-child": {
        width: "90px",
        paddingBlock: "1px",
      },
    },
    addressBar: {
      width: "100% !important",
      ["@media (max-width: 400px)"]: {
        width: "90% !important",
      },
      //hide input arrows
      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
        {
          "-webkit-appearance": "none !important",
          margin: "0px !important",
        },
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
    },
    tabParent: {
        marginTop: "20px !important",
      
    },
    cardTitle: {
      display: "flex",
      justifyContent: "center",
      ["@media (max-width: 412px)"]: {
        justifyContent: "center",
      },
    },
    regNoDiv: {
      width: "50%",
      ["@media (max-width: 412px)"]: {
        width: "37% !important",
      },
    },
    formButton: {
      fontFamily: "Montserrat !important",
      fontWeight: "600 !important",
      width: "320px",
      height: "40px",
      color: "#252F40 !important",
      padding: "12px 20px !important",
      fontSize: "18px !important",
      border: "1px solid #FEDD17",
      color: "#000000 !important",
      lineHeight: "24px",
      textTransform: "capitalize !important",
      boxShadow: "6px 6px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",
      ["@media (max-width: 450px)"]: {
        marginTop: "10px",
        width: "100%",
        height: "40px",
        fontFamily: "Montserrat !important",
        fontWeight: "400 !important",
        fontStyle: "normal",
        fontSize: "12px !important",
        lineHeight: "24px",
        fontSize: "15px !important",
        background: "#ffffff !important",
        border: "1px solid #D9D9D9",
        color: "#000000 !important",
        textTransform: "capitalize !important",
        borderRadius: "6px !important",
        ["@media (max-width: 450px)"]: {
          width: "90vw",
        },
      },
    },
    formButtonAdd: {
      fontFamily: "Montserrat !important",
      fontWeight: "600 !important",
      background: "#FEDD17 !important",
      fontSize: "14px !important",
      color: "#252F40 !important",
      width: "300px",
      height: "40px",
      textTransform: "capitalize !important",
      boxShadow: "6px 6px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px !important",
      border: "0 !important",
      "&:hover": {
        backgroundColor: "yellow !important",
        boxShadow: "none",
      },
      ["@media (max-width: 600px)"]: {
        width: "300px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "90vw",
      },
    },
  })
);
