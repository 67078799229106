export const AppRoutes = {
  //auth
  AUTH: "/auth",
  // admin
  ADMIN: "/administrator",
  // App Routes
  DASH: "/",
};

export const EndPoint = {
  // Dashboard
  DASH: "/dashboard",
  //  USER MANAGEMENT
  USER_MANAGEMENT: "/users",

  // DEALER MANAGEMENT
  DEALER_MANAGEMENT: "/dealers",
  DEALER_FORM: "/dealerForm",
  // SALES PERSON MANAGEMENT
  SALES_MANAGEMENT: "/sales",
  // INVENTORY MANAGEMENT
  INVENTORY_MANAGEMENT: "category/brand/inventoryProduct",
  // ORDER MANAGEMENT
  ORDER_MANAGEMENT: "asigntooficeadvisor/asigntodeal/asigntoemergency",
  ORDERS: "/orders",
  // ASSIGN OFFICE ADVISOR
  ASSIGN_OFFICE_ADVISOR: "/asigntooficeadvisor",
  // ASSIGN TO DEALER / TECHINICIAN
  ASSIGN_TO_DEALER: "/asigntodealer/technician",
  // ASSIGN TO EMERGENCY
  ASSIGN_TO_EMERGENCY: "/asigntoemergencyrequest",
  // USERCREDENTIAL MANAGEMENT
  USERCREDENTIAL_MANAGEMENT: "/usercredential",
  // USER ROLE MANAGEMENT
  USER_ROLE_MANAGEMENT: "/userrole",
  // OFFER MANAGEMENT
  OFFER_MANAGEMENT: "/offer",
  // REVIEW MANAGEMENT
  REVIEW_MANAGEMENT: "/review",
  // FINANCE MANAGEMENT
  FINANCE_MANAGEMENT: "/finance",
  // VEHICLE MANGEMENT
  VEHICLE_MANAGEMENT: "/vehicle",
  // REPORTING
  REPORTING: "/reporting",
  REPORTING_VIEW: "/reporting/view/:name/:id",

  //USER DETAILS
  USER_DETAILS: "/administrator/usersDetails/:id",
  //USER CAR DETAILS
  USER_CAR_DETAILS: "/users/usersDetails/userCarDetails/",
  //USER ORDER DETAILS
  USER_ORDER_DETAILS: "/users/usersDetails/userOrderDetails/",

  //DEALER DETAILS
  DEALER_DETAILS: "/administrator/dealers",
  // DEALER DOCUMENT
  // DEALER_DOC : '/administrator/dealerDetails/dealersViewDocuments/',

  //INVENTORY DEALER/TECHNICIAN VIEW DETAILS
  INVENTORY_ADD_PRODUCT: "/addProduct",
  INVENTORY_EDIT_PRODUCT: "/:id/editProduct",
  //INVENTORY PRODUCT FORM
  INVENTORY_PRODUCT_FORM: "/inventry/inventoryProductForm",

  //CATEGORY
  CATEGORY_LIST: "/category",
  SUB_CATEGORY_LIST: "/category/:id/:name",
  //BRAND
  BRAND_LIST: "/brand",
  // ADD BRAND
  ADD_BRAND: "/addbrand",
  //INVENTORY PRODUCT
  INVENTORY_PRODUCT: "/inventoryProduct",
  //SALES USER DETAILS
  SALES_USER_DETAILS: "/sales/salesUserDetails/:id",
  // SALES DEALER / TECHNICIAN DETAILS
  SALES_DEALER_TECHNICIAN_DETAILS:
    "/sales/salesUserDetails/salesDealerTechnicianDetails/:id",
  // SALES PERSON DETAILS
  SALES_PERSON_DETAILS: "/sales/salesPersonDetails/:id",

  //ASSIGN ADVISOR ORDER DETAILS
  ORDER_DETAILS: "/asigntooficeadvisor/assignorderdetails/:id",

  //LOGIN & LOGOUT
  LOGOUT: "/logout",
  LOGIN: "/login",

  //FORGET PASSSWORD
  FORGET_PASSWORD: "/forgetpasword",

  WALLET_MANAGEMENT:"/wallet"
};
