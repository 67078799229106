import React, { useEffect, useState } from "react";
import useTable from "../../sharedFeatures/useTable";
import {
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Box,
  Grid,
} from "@material-ui/core";
import { BsSearch } from "react-icons/bs";
import { AiOutlineStar } from "react-icons/ai";
import HeadCells from "../../common/Headcells";
import { TXT_REVIEWMANAGEMENT } from "../../utilitis/header";
import makeStyles from "../../sharedFeatures/sharedClasses";
import { Link } from "react-router-dom";
import financeStyles from "../FinanceManagement/financeStyles";
import DropDown from "../../common/Controls/ListingDropdown";
import Controls from "../../common/Controls/Controls";
import HeadBar from "../../components/HeadBar/HeadBar";
import { Pagination } from "@material-ui/lab";
import CustomLoader from "../../features/CustomLoader";
import PageDropDown from "../../common/Controls/PageDropDown";
import reviewQuery from "../../data/reviews/reviewQuery";
import usePagination from "../../common/Controls/usePagination";
import Comment from "./Comment";
const Review = () => {

  const [search, setSearch] = useState();
  // const [trySearch, setTrySearch] = useState("");
  const [currentpageNo, setcurrentpageNo] = useState(1);
  // const [pageItems, setPageItems] = useState(10);
    const [detailsOpen, setDetailsOpen] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const styles = makeStyles();
  const customStyle = financeStyles();
  const searchUser = (e) => {
    setSearch(e.target.value);
  };


  const handleDetailsOpen = (review) => {
    setSelectedReview(review);
    setDetailsOpen(true);
  };

   const handleDetailsClose = () => {
    setDetailsOpen(false);
  };

  const searchParams = new URLSearchParams(window.location.search);
  const searchp = searchParams.get("search");
  const itemsno = searchParams.get("itemsno");

  let page = searchParams.get("page");
  const [trySearch, setTrySearch] = useState(searchp !== null ? searchp : "");
  const [pageItems, setPageItems] = useState(itemsno !== null ? itemsno : 10);
  const qry = {
    searchQry: trySearch,
    page:page !== null ? page : currentpageNo,
    perPageItems: pageItems,
  }; 


  const { data: reviewData, refetch,isLoading: reviewLoading } =
  reviewQuery.getReviewList(qry);
  const { TblContainer, TblHead } = useTable(HeadCells.userReview);
  useEffect(()=>{
    refetch()
  },[])

  const { currentData, currentPage, maxPage , setSearchParams } = usePagination(
    reviewData !== undefined && reviewData,
    pageItems
  );


  const handlePageItems = (e) => {
    searchParams.set("page", "1");
    setSearchParams(searchParams);
    setPageItems(e.target.value);

  };


  //if last page and perpage changes go to first page
  React.useEffect(() => {
    if (currentpageNo > maxPage) {
      setcurrentpageNo(maxPage);
    }
  }, [pageItems]);

  React.useEffect(() => {
    setcurrentpageNo(currentPage);
  }, [currentPage]);


  function convertTimestamp(timestamp) {
    const dateObj = new Date(timestamp);
    
    // Get day, month, year
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = dateObj.getFullYear();
    
    // Get hours and minutes
    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    
    // Concatenate the parts to form the desired format
    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;
    
    return formattedDate;
  }



  return (
    <>
      <HeadBar title={TXT_REVIEWMANAGEMENT} />
      <div className={customStyle.divStyle}>
        <div className={customStyle.searchBoxParent}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={8}>
              <Controls.StyledSearch
                placeholder="Search"
                className={styles.searchBar}
                name="usersearch"
                value={trySearch}
                onChange={(e) => {
                  searchParams.set("page", "1");
                  setSearchParams(searchParams);
                  setTrySearch(e.target.value);
                }}
                icon={<BsSearch style={{ marginLeft: "5px" }} />}
              />
            </Grid>

            {/* <Grid item xs={12} sm={6} md={4}>
              <Grid container alignItems="center">
                <Grid item xs={2} md={3}>
                  <label
                    style={{
                      width: "6vw",
                      fontFamily: "Montserrat",
                      fontWeight: 700,
                      fontSize: "14px",
                    }}
                  >
                    Filter By
                  </label>
                </Grid>
                <Grid item xs={10} md={9}>
                  <DropDown
                    value={"Date"}
                    style={{
                      height: "40px",
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #D2D6DA",
                      borderRadius: "6px",
                    }}
                    items={[
                      { key: 1, value: "11/8/2022" },
                      { key: 2, value: "10/3/2022" },
                    ]}
                  /> 
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
        </div>
{/* {console.log(data.results)} */}
        <Box className={styles.tableBox}>
          <TblContainer>
            <TblHead />
            {reviewLoading && (
              <TableRow>
                <TableCell colSpan={8}>
                  <CustomLoader />
                </TableCell>
              </TableRow>
            )}
            <TableBody>
              {reviewData &&
                reviewData.data &&
                reviewData.data.map((item) => {
                return (
                  <TableRow key={item.order_id.id}>
                    {/* <TableCell align="center">{item.sl_no}</TableCell> */}
                    <TableCell align="center">{item.created_by.first_name}</TableCell>
                    <TableCell align="center">{item.order_id.order_no}</TableCell>
                    <TableCell align="center">{convertTimestamp(item.reviewed_at)}</TableCell>
                    <TableCell align="center">
                      <Link
                          to=""
                          className={styles.linkColor}
                          onClick={() => handleDetailsOpen(item.review_text)}
                          style={{ cursor: "pointer" }}
                        >
                          View
                        </Link>
                    </TableCell>
                    <TableCell align="center">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {item.rate}
                        <IconButton disabled={true}>
                          <AiOutlineStar className={styles.iconColor} />
                        </IconButton>
                      </Box>
                    </TableCell>

                    {/* <TableCell>
                      <div className={styles.tableCell}>
                        <div>
                          <Link to="" className={styles.linkColor}>
                            View
                          </Link>
                        </div>

                        <div>
                          <Link to="" className={styles.linkColorTwo}>
                            Archive
                          </Link>
                        </div>
                      </div>
                    </TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          </TblContainer>
        </Box>

        <div className="pagination">
          <div className="paginationStyle">
            <PageDropDown
             value={pageItems} 
              style={{ width: "90px", height: "34px", borderRadius: "55px" }}
              handlePageItems={handlePageItems}
              items={[
                { key: 1, value: 10 },
                { key: 2, value: 20 },
              ]}
            />
            <span className="paginationPage">Per Page</span>
          </div>

          <Pagination
            count={maxPage}
            className={styles.paginationCompStyle}
            page={Number(page) || currentPage}
            onChange={currentData}
            variant="outlined"
          />
        </div>
         {detailsOpen ? (
          <Comment
            open={detailsOpen}
            handleClose={handleDetailsClose}
            title="Comment"
            commentText={selectedReview}
          />
        ) : null}
      </div>
    </>
  );
};

export default Review;
