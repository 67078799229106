import * as React from "react";
import { Box, Modal, Grid, IconButton, Typography } from "@material-ui/core";
import Controls from "../../common/Controls/Controls";
import IconButs from "../../common/Controls/IconButs";
import useSalesPersonQuery from "../../data/sales/useSalesPersonQuery";
import CustomLoader from "../../features/CustomLoader";
import OrderManagmentStyle from "../Assign_Office_Advisor/OrderManagmentStyle";
import InventoryManagmentStyle from "../InventoryManagement/InventoryManagmentStyle";
import { AiOutlineClose } from "react-icons/ai";
import salesStyle from "./salesStyle";
import "./Sales.css";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";

const SalesAddressDetails = ({ open, handleClose, title, id }) => {
  const orderStyle = OrderManagmentStyle();
  const inventoryStyle = InventoryManagmentStyle();
  const salesStyles = salesStyle();

  const { isLoading, data: salesPerson, error, isError } =
    useSalesPersonQuery.salesPerson_By_Id(id);
  useEffect(() => {
    if (error &&
      error.response &&
      error.response.data &&
      error.response.data.detail) {
      toast.warn(error &&
        error.response &&
        error.response.data &&
        error.response.data.detail)
      handleClose(), {
        hideProgressBar: true,
        autoClose: 2200,
      }
    }
  }, [isError])
  return (
    <>
      <Modal open={open}>
        <div
          className="salesAddress"
          style={{ height: isLoading ? "350px" : "550px" }}
        >
          <Box className={orderStyle.assignlinkbox}>
            <Box></Box>
            <Typography className={salesStyles.assignTitle}>{title}</Typography>
            <Box className={orderStyle.assignlinkclose}>
              <IconButton onClick={handleClose}>
                <AiOutlineClose />
              </IconButton>
            </Box>
          </Box>

          {!isLoading ? (
            <div className={salesStyles.salesAddressParent}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                  <Typography className={inventoryStyle.categoryLabel}>
                    Full Name
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Controls.InputField
                    placeholder="Name"
                    className={salesStyles.inputTextBar}
                    value={`${salesPerson && salesPerson.first_name} ${salesPerson && salesPerson.last_name} `}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography className={inventoryStyle.categoryLabel}>
                    Locality
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Controls.InputField
                    placeholder="Locality"
                    className={salesStyles.inputTextBar}
                    value={
                      salesPerson &&
                      salesPerson.address &&
                      salesPerson.address.locality
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography className={inventoryStyle.categoryLabel}>
                    Address Line 1
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Controls.TextArea
                    height={'75px'}
                    padding={'10px'}
                    maxRows={4}
                    fontSize={'14px'}
                    placeholder="Line 1"
                    borderRadius={'10px'}
                    className={salesStyles.inputTextBar}
                    value={
                      salesPerson &&
                      salesPerson.address &&
                      salesPerson.address.address_line_one
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography className={inventoryStyle.categoryLabel}>
                    Address Line 2
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Controls.TextArea
                    height={'75px'}
                    padding={'10px'}
                    maxRows={4}
                    fontSize={'14px'}
                    placeholder="Line 2"
                    borderRadius={'10px'}
                    className={salesStyles.inputTextBar}
                    value={
                      salesPerson &&
                      salesPerson.address &&
                      salesPerson.address.address_line_two
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography className={inventoryStyle.categoryLabel}>
                    City
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Controls.InputField
                    placeholder="city"
                    className={salesStyles.inputTextBar}
                    value={
                      salesPerson &&
                      salesPerson.address &&
                      salesPerson.address.city
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography className={inventoryStyle.categoryLabel}>
                    ZIP/PostalCode
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Controls.InputField
                    placeholder="post"
                    className={salesStyles.inputTextBar}
                    value={
                      salesPerson &&
                      salesPerson.address &&
                      salesPerson.address.pincode
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography className={inventoryStyle.categoryLabel}>
                    Country
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Controls.InputField
                    className={salesStyles.inputTextBar}
                    name="salesManagers"
                    value={
                      salesPerson &&
                      salesPerson.address &&
                      salesPerson.address.country
                    }
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: { xs: "inline", sm: "flex" },
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <IconButs
                      onClick={handleClose}
                      ibtname={"OK"}
                      className={inventoryStyle.formButtonAdd}
                      variant="outlined"
                    />
                  </Box>
                </Grid>
              </Grid>
            </div>
          ) : (
            <CustomLoader />
          )}
        </div>
      </Modal>
    </>
  );
};

export default SalesAddressDetails;
