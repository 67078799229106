import React from "react";
import OrderManagmentStyle from "./OrderManagmentStyle";
import orderStyles from "./orderStyles";
import { Box, IconButton, Typography } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { AiOutlineClose } from "react-icons/ai";
import Controls from "../../common/Controls/Controls";
import IconButs from "../../common/Controls/IconButs";
import { useState } from "react";
import useAdvisor from "../../data/orderManegemnet/orderChangeQuery";
import { toast, ToastContainer } from "react-toastify";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useEffect } from "react";
import './ChangeStatusModal.css'

const ChangeStatusModal = ({
  open,
  handleClose,
  title,
  orderDetials,
  data
}) => {
  const orderStyle = OrderManagmentStyle();
  const dealerStyle = orderStyles();
  const [checked, setChecked] = useState('');
  const [isSpinning, setIsSpinning] = useState(false);
  const [disableButton, setDisableButton] = useState(true);


  const { mutateAsync: changeStatus, isLoading } = useAdvisor.useChangeStatus();
  const handleChangeStatus = () => {
    setDisableButton(true);
    setIsSpinning(true);
    changeStatus({
      orderId: orderDetials && orderDetials.id,
      status: checked,
    })
      .then((response) => {
        toast.success("Status changed successfully", {
          hideProgressBar: true,
          autoClose: 2200,
          icon: false, 
          style: {
            backgroundColor: 'green', 
            color: 'white',           
          },
        });
        handleClose(false);
      })
      .catch((error) => {
        toast.error("Something went wrong", {
          hideProgressBar: true,
          autoClose: 2200,
        });
      });
  };

  useEffect(() => {
    if (data.status == 4 && data.payment_status == 0) {
      setDisableButton(true);
    }
  }, []); // empty dependency array

  const isStatusShown = (section) => {

    if (section === 'advisor') {
      if (data && data.advisor_id !== null && data && data.status !== 3) {
        return true
      } else {
        return false
      }
    }
    if (section === 'inProgress') {
      if (data && data.status === 3) {
        return true
      } else {
        return false
      }
    }
    if (section === 'completed') {
      if (data && data.payment_status === 1 && data && data.status === 4) {
        return true
      } else {
        return false
      }
    }

  }

  return (
    <div>
      <Modal open={open} onClose={handleClose} centered={true}>
        <div className={dealerStyle.statusModal}>
          <Box className="status-main-container">
            <Box className={orderStyle.assignlinkbox}>
              <Box></Box>
              <Typography className={dealerStyle.assignTitle}>
                {title}
              </Typography>
              <Box className={orderStyle.assignlinkclose}>
                <IconButton onClick={() => handleClose(false)}>
                  <AiOutlineClose />
                </IconButton>
              </Box>
            </Box>
            <div className={dealerStyle.statusSection}>
              <div className="status-container">
                <div className="status-sub">

                  {data.status == 4 && data.payment_status == 0 && <div className="status">
                    <p>Payment is pending.</p>
                  </div>}

                  {isStatusShown('advisor') && data.status !== 2 && data.status !== 4 && <div className="status">
                    <p>Confirmed</p>
                    <Controls.radioButton
                      value={"2"}
                      checked={checked === "2" && true}
                      handleChecked={(e) => {
                        setChecked(e.target.value);
                        setDisableButton(false)
                      }}

                    />
                  </div>}
                  {isStatusShown('inProgress') && <div className="status">
                    <p>Completed</p>
                    <Controls.radioButton
                      value={"4"}
                      checked={checked === "4" && true}
                      handleChecked={(e) => {
                        setChecked(e.target.value);
                        setDisableButton(false)
                      }}

                    />
                  </div>}
                </div>

                <div className="status-sub">
                  {isStatusShown('completed') && <div className="status">
                    <p>Delivered</p>
                    <Controls.radioButton
                      value={"5"}
                      checked={checked === "5" && true}
                      handleChecked={(e) => {
                        setChecked(e.target.value);
                        setDisableButton(false)
                      }}

                    />
                  </div>}
                  {isStatusShown('advisor') && data.status !== 4 && <div className="status">
                    <p>Cancelled</p>
                    <Controls.radioButton
                      value={"6"}
                      checked={checked === "6" && true}
                      handleChecked={(e) => {
                        setChecked(e.target.value);
                        setDisableButton(false)
                      }}

                    />
                  </div>}
                </div>
              </div>
              <div>
                <IconButs
                  onClick={() => handleChangeStatus()}
                  ibtname={isSpinning ? <CircularProgress size={24} /> : 'Change Status'}
                  className={dealerStyle.assignBtn}
                  disabled={disableButton === true ? true : false}
                />
              </div>
            </div>
          </Box>
        </div>
      </Modal>
    </div>
  );
};

export default ChangeStatusModal;
