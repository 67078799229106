import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  ThemeProvider,
  createTheme,
} from "@material-ui/core";
import useStyles from "./dashboardStyles";
import { Progress } from "antd";
import {
  useProductData,
  usequickUpdate,
} from "../../data/dashboard/dashboardQuery";

const QuickUpdates = ({ filter }) => {
  const dashStyles = useStyles();

  const theme = createTheme({
    palette: {
      success: {
        main: "#34D834",
      },
    },
  });

  const { data } = usequickUpdate({
    filter_type: filter.time !== "" ? filter.time : "",
    date_range:
      filter.start_date !== "" &&
      filter.start_date !== undefined &&
      filter.end_date !== "" &&
      filter.end_date !== undefined
        ? `${filter.start_date},${filter.end_date}`
        : "",
  });
  const { data: productData } = useProductData({
    filter_type: filter.time !== "" ? filter.time : "",
    date_range:
      filter.start_date !== "" &&
      filter.start_date !== undefined &&
      filter.end_date !== "" &&
      filter.end_date !== undefined
        ? `${filter.start_date},${filter.end_date}`
        : "",
  });

  const productPieData =
    productData &&
    productData.data &&
    productData.data.data &&
    productData.data.data.map((e) => {
      return e.name;
    });

  const filterdDAta = () => {
    return (
      data &&
      data.data &&
      data.data.data &&
      data.data.data.filter((e) => productPieData?.includes(e.category))
    );
  };

  const updateData = (type) =>
    data &&
    data.data &&
    data.data.data &&
    data.data.data.filter((e) => {
      if (type === e.category) {
        return e;
      }
    });
  const [updateType, setUpdateType] = useState("");

  useEffect(() => {
    setUpdateType(
      data &&
        data.data &&
        data.data.data &&
        data.data.data &&
        filterdDAta()[0] &&
        filterdDAta()[0].category
    );
  }, [data]);

  return (
    <ThemeProvider theme={theme}>
      <Box className={dashStyles.quickUpdatesParent}>
        <span className={dashStyles.quickUpdatesHeader}>Quick Updates</span>
        <h6 className={dashStyles.quickUpdatesSubHeader}>
          Top Selling Products
        </h6>
        <Box className={dashStyles.quickUpdatesRadialProgressParent}>
          <Progress
            type="circle"
            percent={
              updateData(updateType) &&
              updateData(updateType)[0] &&
              updateData(updateType)[0].percentage
            }
            width={150}
            
            format={(percent) => {
              return (
                <>
                  <span
                    style={{
                      color: "#85DE85",
                      fontStyle: "normal",
                      fontFamily: "Montserrat",
                      fontWeight: "700",
                      fontSize: "24px",
                    }}
                  >
                    {percent}%
                  </span>
                  <br />
                  <span
                    style={{
                      color: "#444445",
                      fontStyle: "normal",
                      fontFamily: "Montserrat",
                      fontWeight: "600",
                      fontSize: "10px",
                    }}
                  >
                    Sale Status
                  </span>
                </>
              );
            }}
            status="normal"
            strokeColor="#34D834"
            trailColor="#EEEEEE"
          />
        </Box>
        <h6 className={dashStyles.quickUpdatesSubHeaderTwo}>
          Product Categories {updateType}
        </h6>
        <FormControl style={{width:'100%'}}>
        { updateType !== undefined  &&  <RadioGroup
            value={updateType !== undefined ? updateType : 'Battery'}
            name="radio-buttons-group"
            onChange={(e) => setUpdateType(e.target.value)}
          >
            {data &&
              data.data &&
              data.data.data &&
              data.data.data &&
              filterdDAta().map((e, i) => {
                return (
                  <div 
                    className={dashStyles.quickUpdatesRadioParent}
                    style={{ padding: "0 60px 10px 20px", fontSize:'14px !important' }}
                  >
                    <FormControlLabel
                      value={e.category}
                      control={<Radio color="success" />}
                      label={e.category} 
                      className={dashStyles.radioButtonsText}
                      //onChange={(e) => setUpdateType(e.target.value)}
                    />
                  </div>
                );
              })}
          </RadioGroup>}
        </FormControl>
      </Box>
    </ThemeProvider>
  );
};

export default QuickUpdates;
