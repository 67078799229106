import { useMutation, useQuery } from "react-query";
import { advidorList, downloadOrderPDF, multipleDealerList, ordersList, singleDealerList, singleOrder } from "./orderUrls";
import {toast } from "react-toastify";

const get_order_List = (data) => {
  return useQuery(
    ["getOrdersList", data],
    () => ordersList(data),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
    }
  );
};
const get_advisor_List = (data) => {

  return useQuery(
    ["getAdvisorList", data],
    () => advidorList(data),
    {
      // staleTime: 30000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,

    }
  );
};

// const get_single_dealer_list = (qry) => {
//   console.log(qry)
//   // return useQuery(["singleDealer", qry], () => singleDealerList(qry), {
//   //   staleTime: 30000,
//   //   keepPreviousData: true,
//   //   refetchOnWindowFocus: false,
//   //   poll: 500,
//   // });
// }

const get_single_dealer_list = (data) => {
  return useQuery(
    ["singleDealer", data],
    () => singleDealerList(data),
    {
      staleTime: 30000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,

    }
  );
};
const get_multiple_dealer_list = (data) => {

  return useQuery(
    ["multipleDealer", data],
    () => multipleDealerList(data),
    {
      // staleTime: 30000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,

    }
  );
};

const get_order_Detials = (data) => {

  return useQuery(
    ["getAdvisorList", data],
    () => singleOrder(data),
    {
      staleTime: 30000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,

    }
  );
};

const get_download_inspectionPDF = () => {
  return useMutation((todoId) => downloadOrderPDF(todoId), {
    onError: (err) => {
      toast.error("Failed to download the PDF", {
        hideProgressBar: true,
        autoClose: 2200,
      });
    },
    onSettled: async (data) => {
      if (data?.[0]?.inspection_url || data?.[0]?.inspection_url != null) {
        const pdfUrl = data[0].inspection_url;
        window.open(pdfUrl, "_blank");
      }else{
        toast.error("Inspection report not found", {
          hideProgressBar: true,
          autoClose: 2200,
        });
      }
    }
  })
};

export const useOrderQuery = {
  get_order_List,
  get_advisor_List,
  get_order_Detials,
  get_single_dealer_list,
  get_multiple_dealer_list,
  get_download_inspectionPDF
}