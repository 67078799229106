import { Box, Grid } from "@material-ui/core";
import React from "react";
import useStyles from "./dashboardStyles";
import SalesAreaWise from "./SalesAreaWise";
import TopCatSales from "./TopCatSales";
import {
  useProductData,
  useareaData,
} from "../../data/dashboard/dashboardQuery";
import { keyConverter } from "./utils/utils";

const Sales = ({ filter }) => {
  const dashStyles = useStyles();

  const { data: productData } = useProductData({
    filter_type: filter.time !== "" ? filter.time : "",
    date_range:
      filter.start_date !== "" &&
      filter.start_date !== undefined &&
      filter.end_date !== "" &&
      filter.end_date !== undefined
        ? `${filter.start_date},${filter.end_date}`
        : "",
  });

  const { data: areaData } = useareaData({
    filter_type: filter.time !== "" ? filter.time : "",
    date_range:
      filter.start_date !== "" &&
      filter.start_date !== undefined &&
      filter.end_date !== "" &&
      filter.end_date !== undefined
        ? `${filter.start_date},${filter.end_date}`
        : "",
  });
  const productPieData =
    productData &&
    productData.data &&
    productData.data.data &&
    productData.data.data.map((e) => {
      return {
        name: e.name,
        value: e.count,
      };
    });



  const areaChartData =
    areaData &&
    areaData.data &&
    areaData.data.data &&
    areaData.data.data.map((e) => {
      return {
        name: keyConverter(e, filter),
        mumbai: e["y-axis"].Mumbai,
        navi_Mumbai: e["y-axis"].Navi_Mumbai,
        thane: e["y-axis"].Thane,
      };
    });

  return (
    <Box className={dashStyles.salesSectionParent}>
      <Grid container spacing={2}>
        <Grid item md={6} sm={12}>
          <SalesAreaWise
            data={areaChartData}
            total={areaData && areaData.data && areaData.data.total}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <TopCatSales
            data={productPieData}
            total={productData && productData.data && productData.data.total}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Sales;
