import React, { useState, useEffect } from "react";
import {
  Modal, IconButton, Grid, Box, Typography, CircularProgress, TextField,
  InputAdornment,
  Card,
  CardContent
} from "@material-ui/core";
import { AiOutlineClose } from "react-icons/ai";
import { useParams } from "react-router-dom";
import Controls from "../../common/Controls/Controls";
import IconButs from "../../common/Controls/IconButs";
import categoryBrandStyles from "./InventoryManagmentStyle";
import useCategoryData from "../../data/inventory/useCategoryData";
import OrderManagmentStyle from "../Assign_Office_Advisor/OrderManagmentStyle";
import salesStyle from "../SalesPersonManagement/salesStyle";
import makeStyles from "../../sharedFeatures/sharedClasses";
import {
  FORM_BUTTON_MANAGEMENT,
  FORMONE_BUTTON_MANAGEMENT,
  ADD_CATEGORY,
} from "../../utilitis/header";
import { BsSearch } from "react-icons/bs";
import sample_img from '../../images/sample_add_ons.png'
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { yellow } from "@material-ui/core/colors";
import closeSquare from '../../images/close_square.svg'
import Checkbox from '@mui/material/Checkbox';
import productQuery from "../../data/inventory/useProductList";
import { debounce } from "lodash";
import Search_Icon from '../../images/search_icon_add_ons.svg'

const AddCategory = ({
  open,
  handleClose,
  title,
  modalTitle,
  values,
  handleInputChange,
  setValues,
  submitData,
  id,
  nameError,
  setNameError,
  otherChargeError,
  setOtherChargeError,
  typeError,
  setTypeError,
  serviceTypeError,
  setServiceTypeError,
  timeslotDayError,
  setTimeslotDayError,
  timeslotNightError,
  setTimeslotNightError,
  setProcessingTimeError,
  processingTimeError,
  handleCheckBox,
  selectedItems,
  setSelectedItems
}) => {
  const customStyle = makeStyles();
  const categoryStyle = categoryBrandStyles();
  const orderStyle = OrderManagmentStyle();
  const salesStyles = salesStyle();
  const params = useParams();

  const categoryType = [
    { id: 1, name: "Product" },
    { id: 2, name: "Service" },
  ];

  const partType = [
    { id: 1, name: "Exterior" },
    { id: 2, name: "Interior" },
  ];

  const serviceType = [
    { id: 0, name: "Not Applicable" },
    { id: 1, name: "Service at Home" },
    { id: 2, name: "Nearest Drop" },
    { id: 3, name: "Service at Home Or Nearest Drop" },
  ]

  const { data: catData, isSuccess: catDataSuccess } = isNaN(id)
    ? ""
    : params.name
      ? useCategoryData.useSubcategoryList(id)
      : useCategoryData.useCategoryList(id);

  //Select add-ons
  const [searchText, setSearchText] = useState('');
  const [results, setResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState({ searchQry: '' });

  const {
    data: productListAddons,
    isLoading: addOnsisLoading,
    isFetching: addOnsisFetching,
    refetch: ProductSelectAddonsRefetch,
  } = productQuery.ProductList(searchQuery);

  useEffect(() => {
    setSelectedItems(catData?.addons)
  }, [catData?.addons])

  // Update search query and refetch data on search text change
  useEffect(() => {
    const debouncedFetch = debounce(() => {
      if (searchText === '') {
        setResults([]);
      } else {
        setSearchQuery({ searchQry: searchText });
        ProductSelectAddonsRefetch()
        setResults(productListAddons?.data);
      }
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [searchText, productListAddons]);

  // Add or remove item from selected list
  const toggleSelectItem = (item) => {
    setSelectedItems((prevSelected) => {
      if (prevSelected?.some((selected) => selected?.id === item?.id)) {
        return prevSelected?.filter((selected) => selected?.id !== item?.id);
      } else {
        return [...prevSelected, item];
      }
    });
  };

  useEffect(() => {
    setSelectedItems([])
  }, [])

  // console.log("poiwerndfv", selectedItems)

  // Remove item from selected items
  const handleRemoveSelectedItem = (id) => {
    setSelectedItems((prevSelected) =>
      prevSelected?.filter((item) => item?.id !== id)
    );
  };

  useEffect(() => {
    if (catDataSuccess) {
      let time_slots_day = '';
      let time_slots_night = '';

      if (catData.time_slots === null || catData.time_slots === undefined) {
        time_slots_day = '8:00-19:59';
        time_slots_night = '20:00-7:59';
      } else {
        time_slots_day = catData.time_slots.hasOwnProperty('day') ? catData.time_slots.day : '8:00-19:59';
        time_slots_night = catData.time_slots.hasOwnProperty('night') ? catData.time_slots.night : '20:00-7:59';
      }
      setValues({
        ...values,
        name: catData && catData.name,
        usp: catData && catData.usp,
        other_charges: catData && catData.other_charges,
        type: catData && catData.type,
        part_type: catData && catData.part_type,
        service_type: catData && catData.service_type,
        night_service: catData && catData.night_service,
        time_slots_day: time_slots_day,
        time_slots_night: time_slots_night,
        display: catData && catData.display,
        processing_time: catData && catData.processing_time,
      });
    }
  }, [catData, catDataSuccess]);

  const handleChange = (e) => {
    let targetName = e.target.name;

    let targetValue = e.target.value;

    if (targetName === "type") {
      handleInputChange(e);
      setTypeError("");
    }
    if (targetName === "name") {
      if (/^[a-z ,.&'-]+$/i.test(targetValue)) {
        handleInputChange(e);
        setNameError("");
      } else {
        setValues({
          ...values,
          name: targetValue.substring(0, targetValue.length - 1),
        });
      }
    }
    if (targetName === "other_charges") {
      handleInputChange(e);
      setOtherChargeError("");
    }

    const isValid = /^[0-9]*$/.test(targetValue);

    if (targetName === "processing_time" && isValid) {
      handleInputChange(e);
      setProcessingTimeError("");
    }

    if (targetName === "part_type") {
      handleInputChange(e);
    }
    if (targetName === "service_type") {
      handleInputChange(e);
      setServiceTypeError("");
    }
    if (targetName === "night_service") {
      handleCheckBox(e);
    }
    if (targetName === "display") {
      handleCheckBox(e);
    }
    if (targetName === "time_slots_day") {
      handleInputChange(e);
      setTimeslotDayError("");
    }
    if (targetName === "time_slots_night") {
      handleInputChange(e);
      setTimeslotNightError("");
    }
  };

  return (
    <>
      <Modal open={open}>

        <div className="modalWrap">
          <Box>
            <Box className={orderStyle.assignlinkbox}>
              <Box></Box>
              <Typography className={salesStyles.assignTitle}>
                {title}
              </Typography>
              <Box className={orderStyle.assignlinkclose}>
                <IconButton onClick={handleClose}>
                  <AiOutlineClose />
                </IconButton>
              </Box>
            </Box>



            {/* <Box className={categoryStyle.boxStyle}>
          <form style={{ width: "100%" }}>
            <Grid>
              <div
                // className={customStyle.subHeaderParent}
                style={{
                  backgroundColor: "#FEDD17",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ marginTop: "5px" }}>
                  <Sub data={title } />
                </Box>
              </div> */}

            {/* <div className={categoryStyle.formStyle}>
                <Grid
                  container
                  alignItems="center"
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                > */}
            <div style={{ width: "100%", padding: "10px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography className={categoryStyle.categoryLabel}>
                    Type
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  style={{ marginTop: "15px" }}
                >
                  <Controls.SelectBox
                    className={categoryStyle.basicSelectBox}
                    name="type"
                    error={typeError}
                    label="Category Type"
                    value={values.type}
                    options={categoryType}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography className={categoryStyle.categoryLabel}>
                    {params.name
                      ? "Name of the Sub Category"
                      : "Name of the Category"}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Controls.InputField
                    className={categoryStyle.formTextBar}
                    disabled={false}
                    name="name"
                    type={"text"}
                    value={values.name}
                    onChange={handleChange}
                    error={nameError}
                    inputProps={{ maxLength: 100 }}
                    placeholder="Name"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography className={categoryStyle.categoryLabel}>
                    USP
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Controls.TextArea
                    className={categoryStyle.textArea}
                    name="usp"
                    value={values.usp}
                    placeholder={'USP'}
                    onChange={handleInputChange}
                    // error={errors.usp}
                    maxLength="250"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography className={categoryStyle.categoryLabel}>
                    Other Charges
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Controls.InputField
                    className={categoryStyle.formTextBar}
                    disabled={false}
                    placeholder={'Other Charges'}

                    name="other_charges"
                    value={values.other_charges}
                    onChange={handleChange}
                    // error={otherChargeError}
                    type="text"
                    inputProps={{ maxLength: 75 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography className={categoryStyle.categoryLabel}>
                    Part Type
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <Controls.SelectBox
                    className={categoryStyle.basicSelectBox}
                    name="part_type"
                    label="Part Type"
                    value={values.part_type}
                    options={partType}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography className={categoryStyle.categoryLabel}>
                    Service Type
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <Controls.SelectBox
                    className={categoryStyle.basicSelectBox}
                    name="service_type"
                    label="Service Type"
                    value={values.service_type}
                    error={serviceTypeError}
                    options={serviceType}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography className={categoryStyle.categoryLabel}>
                    Available at Night
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <Controls.CheckBoxLabel
                    checked={values && values.night_service}
                    name="night_service"
                    value={values.night_service}
                    onChange={handleChange}
                    className={categoryStyle.chechBox}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography className={categoryStyle.categoryLabel}>
                    Display
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Controls.CheckBoxLabel
                    checked={values && values.display}
                    name="display"
                    value={values.display}
                    onChange={handleChange}
                    className={categoryStyle.chechBox}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography className={categoryStyle.categoryLabel}>
                    Day slot
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Controls.InputField
                    className={categoryStyle.formTextBar}
                    disabled={false}
                    name="time_slots_day"
                    placeholder={'Day Slot'}

                    value={values.time_slots_day}
                    onChange={handleChange}
                    error={timeslotDayError}
                    type="text"
                    inputProps={{ maxLength: 75 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography className={categoryStyle.categoryLabel}>
                    Night slot
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Controls.InputField
                    className={categoryStyle.formTextBar}
                    disabled={false}
                    name="time_slots_night"
                    value={values.time_slots_night}
                    onChange={handleChange}
                    error={timeslotNightError}
                    type="text"
                    placeholder={'Night Slot'}
                    inputProps={{ maxLength: 75 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography className={categoryStyle.categoryLabel}>
                    ETA
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Controls.InputField
                    className={categoryStyle.formTextBar}
                    disabled={false}
                    name="processing_time"
                    value={values.processing_time}
                    onChange={handleChange}
                    error={processingTimeError}
                    type="text"
                    inputProps={{ maxLength: 5 }}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} md={12} style={{ marginRight: "30px" }}>
                <Grid container alignItems="flex-start">
                  <Grid item xs={12} sm={2}>
                    <Typography className={categoryStyle.label}>
                      Select Add-ons
                    </Typography>
                  </Grid>
                  {/* Search Bar and Results Section */}
                  <Grid
                    item
                    xs={12}
                    sm={10}
                  >
                    <TextField
                      placeholder="Select Add-ons"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      // onChange={(e) => handleSearch(e)}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          addOnsisLoading || addOnsisFetching ? <CircularProgress position="end" style={{ marginRight: "5px" }} size={24} /> :
                            <InputAdornment position="end">
                              <img src={Search_Icon} style={{ marginRight: "5px" }} />
                            </InputAdornment>
                        ),
                      }}
                    />

                    {/* Results Cards */}
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxHeight: results?.length > 5 ? '400px' : 'auto',
                        overflowY: results?.length > 5 ? 'auto' : 'visible',
                        '&::-webkit-scrollbar': {
                          width: '4px',
                          height: '40px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          borderRadius: '4px 0px 0px 0px',
                          backgroundColor: '#444445',
                          opacity: 0,
                        },
                        '&::-webkit-scrollbar-track': {
                          backgroundColor: 'transparent',
                          gap: '0px',
                        },
                      }}>
                      {results?.map((item) => (
                        <Box
                          key={item.id}
                          sx={{
                            width: '100%',
                            // height: 60,
                            border: "1px solid #D2D6DA",
                            // borderRadius: "5px",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            // marginBottom: "10px",
                          }}
                        >
                          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: 3, padding: "8px" }}>
                            <Typography style={{ fontSize: '14px' }}>{item?.title}</Typography>
                            <Typography style={{ fontSize: '12px', color: "#989898" }}>{`₹ ${item?.mrp}`}</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", gap: 5 }}>
                              <Typography style={{ fontSize: '13px', color: "#989898" }}>{item?.brand_id?.category_id?.parent_id?.name}</Typography>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                <Box
                                  component="span"
                                  sx={{
                                    display: 'inline-block',
                                    width: '6px',
                                    height: '6px',
                                    backgroundColor: "#E5E5E5",
                                    borderRadius: '50%',
                                  }}
                                />
                                <Typography style={{ fontSize: '13px', color: "#989898" }}>{item?.brand_id?.category_id?.name}</Typography>
                              </Box>
                            </Box>
                          </Box>

                          <Checkbox
                            checked={selectedItems?.some((selected) => selected?.id === item.id)}
                            onChange={() => toggleSelectItem(item)}
                            sx={{
                              color: "#444445",
                              '&.Mui-checked': {
                                color: yellow[600],
                              },
                            }}
                          />
                        </Box>
                      ))}
                    </Box>

                    {/* Selected Items Section */}
                    {selectedItems && selectedItems?.length > 0 &&
                      <Grid item xs={12} style={{ marginTop: searchText ? "20px" : "0px" }}>
                        <Typography style={{ fontSize: '14px', color: "#989898" }}>Selected Items</Typography>
                        <Grid container spacing={2} mt={1}>
                          {selectedItems?.map((item) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  padding: '5px 2px',
                                  position: 'relative',
                                  border: '1px solid #ccc',
                                  borderRadius: '8px',
                                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                                  width: '100%',
                                  height: '100%',
                                  minWidth: '100px',
                                }}
                              >
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: 3, padding: "8px" }}>
                                  <Typography style={{ fontSize: '14px' }}>{item?.addons_product_id ? item?.addons_product_id?.title : item?.title}</Typography>
                                  <Typography style={{ fontSize: '12px', color: "#989898" }}>{`₹ ${item?.addons_product_id ? item?.addons_product_id?.mrp : item?.mrp}`}</Typography>
                                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", gap: 5 }}>
                                    <Typography style={{ fontSize: '13px', color: "#989898" }}>{item?.addons_product_id ? item?.addons_product_id?.brand_id?.category_id?.parent_id?.name : item?.brand_id?.category_id?.parent_id?.name}</Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                      <Box
                                        component="span"
                                        sx={{
                                          display: 'inline-block',
                                          width: '6px',
                                          height: '6px',
                                          backgroundColor: "#E5E5E5",
                                          borderRadius: '50%',
                                        }}
                                      />
                                      <Typography style={{ fontSize: '13px', color: "#989898" }}>{item?.addons_product_id ? item?.addons_product_id?.brand_id?.category_id?.name : item?.brand_id?.category_id?.name}</Typography>
                                    </Box>
                                  </Box>
                                  <img
                                    src={closeSquare}
                                    onClick={() => handleRemoveSelectedItem(item.id)}
                                    style={{
                                      position: 'absolute',
                                      top: "5px",
                                      right: 5,
                                      cursor: "pointer"
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={12}>
                <Grid container>
                  <Grid xs={12} sm={6}>
                    <IconButs
                      ibtname={
                        typeof id == "number"
                          ? ADD_CATEGORY
                          : FORM_BUTTON_MANAGEMENT
                      }
                      className={categoryStyle.formButtonAdd}
                      onClick={submitData}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <IconButs
                      onClick={handleClose}
                      ibtname={FORMONE_BUTTON_MANAGEMENT}
                      className={categoryStyle.cancelButton}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
            {/* {messageOpen ? (
                <>
                  <div>
                    <Controls.MessageBox message={messageContent} />
                  </div>
                </>
              ) : (
                ""
              )} */}
            {/* </Grid> */}
            {/* </form> */}
          </Box>
        </div>
      </Modal>
    </>
  );
};

export default AddCategory;
