import React, { useEffect, useState } from "react";
import useTable from "../../sharedFeatures/useTable";
import { useMutation } from "react-query";
import {
  Paper,
  IconButton,
  Grid,
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
} from "@material-ui/core";
import { AiOutlinePlus } from "react-icons/ai";
import makeStyles from "../../sharedFeatures/sharedClasses";
import HeadCells from "../../common/Headcells";
import {
  ICON_BUTTON_ADD_ITEM,
  SUBMIT,
  TXT_REQUEST_FROM_TECHNICIAN,
  TXT_ORDER_ITEMS,
} from "../../utilitis/header";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import IconButs from "../../common/Controls/IconButs";
import Controls from "../../common/Controls/Controls";
import OrderManagmentStyle from "./OrderManagmentStyle";
import { useOrderQuery } from "../../data/orderManegemnet/orderQuery";
import CustomLoader from "../../features/CustomLoader";
import { toast, ToastContainer } from "react-toastify";
import { Popover } from "@mui/material";
import axios from "axios";
import DataService from "../../DataService/DataService";
import moment from "moment";
import "./order.css";
import listStyles from "../DealerManagement/listStyle";
import Autocomplete from "@mui/material/Autocomplete";
import productQuery from "../../data/inventory/useProductList";
import {
  findBasePriceAndGSTAndNetAmount,
  calculateDiscountPercentage,
  discountedItemPriceAndGST,
} from "../../utilitis/productPriceCalculator";
import useAddOrder from "../../data/orderManegemnet/useAddOrder";
import useAddProduct from "../../data/inventory/useAddProduct";
import useList from "../../data/inventory/useList";
import { FindLink } from "../../sharedFeatures/FindLink";
import Sub from "../../common/Subheader/Sub";
import CircularProgress from '@mui/material/CircularProgress';

const AdditionalProducts = (data, loadingCompleted) => {
  const { TblContainer, TblHead } = useTable(HeadCells.orderItems);
  const viewStyle = makeStyles();
  const orderStyle = OrderManagmentStyle();
  const customStyle = listStyles();
  const [item, setItem] = useState(false);
  const [search, setSearch] = useState("");
  const [choices, setChoices] = useState("");
  const [selectValue, setSelectValue] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState("");
  const [quantityError, setQuantityError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [addNew, setAddNew] = useState("");
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [hsn, setHsn] = useState("");
  const [hsnError, setHsnError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [gst, setGst] = useState("");
  const [gstError, setGstError] = useState("");
  const [series, setSeries] = useState("");
  const [seriesError, setSeriesError] = useState("");
  const [catName, setCatName] = useState("");
  const [brandId, setBrandId] = useState("");
  const [loading, setLoading] = useState(false)

  const qry = {
    searchQry: search,
    page: 1,
    selected: "",
    perPageItems: 30,
  };

  const {
    data: productList,
    isLoading,
    isFetching,
    isError,
  } = productQuery.ProductList(qry);

  const handleTextChange = (event) => {
    if (event.target.value.length > 1) {
      setSearch(event.target.value);
    }
  };
  const navigate = useNavigate();
  let link = FindLink(
    `/administrator/asigntooficeadvisor/assignorderdetails/${data.data.data.id}`
  );
  let imgFolder =
    process.env.REACT_APP_MODE == "development" ||
      process.env.REACT_APP_MODE == "qa"
      ? "qa/"
      : "";

  const { mutateAsync: editOrder, isSuccess: orderEditSuccess } =
    useAddOrder.edit_order();
  const { mutateAsync: addProduct, isSuccess: productAddSuccess } =
    useAddProduct.add_product();
  const {
    dat: brandByCategoryName,
    isSuccess: brandsListSuccess,
    refetch,
  } = useList.brand_by_category_name(catName);

  const submitData = async (e) => {
    let err = false;
    let totalItemsPriceBeforeGST = 0;
    let discountPercentage = 0;
    let productId = 0;
    let brand_id = 0;
    setLoading(true)

    if (addNew) {
      if (title.length < 3) {
        //title need min 3 characters
        setTitleError("Required min 3 characters.");
        err = true;
      }
      if (series.length < 2) {
        //series need min 3 characters
        setSeriesError("Required min 2 characters.");
        err = true;
      }
      if (/^[0-9]*$/.test(hsn) && hsn.length >= 4 && hsn.length <= 8) {
        setHsn(hsn);
        setHsnError("");
      } else {
        // setHsn("");
        setHsnError("HSN must be between 4 and 8 digits.");
      }

      // if (hsn.length < 4 && hsn.length > 8) {
      //   setHsnError("HSN must be between 4 and 8 digits.");
      // }

      // if (hsn.length != 8) {//hsn 8 characters
      //   setHsnError('HSN required 8 digits.')
      //   err = true;
      // }
      if (description.length < 3) {
        //description need min 3 characters
        setDescriptionError("Required min 3 characters.");
        err = true;
      }

      if (gst < 1 || gst > 50) {
        //gst range within 1-50
        setGstError("Between 1 - 50");
        err = true;
      }
      if (quantity < 1 || quantity > 1000) {
        // quantity must be between 1 and 999
        setQuantityError("Between 1 - 1000");
        err = true;
      }

      if (price < 2 || price > 200000) {
        // price must be between 1 and 1,000,000
        setPriceError("Between 2 - 200000");
        err = true;
      }
    }

    console.log('error', err)

    if (err) {
      setLoading(false)
    }


    if (err) {
      return false;
    }

    let discount_amount = data.data.data.discount_amount;
    //let discount_amount = 100;
    let grandTotalWithDiscount = 0;
    let grandTotalWithoutDiscount = 0;

    //get existing products from the order and
    const orderItem = data.data.data.order_item.map((item) => ({
      unit_price: item.unit_price,
      unit_gst: item.gst_amount,
      gst_percent: item.gst_percent,
      discount_percent: item.discount_percent,
      discount: item.discount_amount,
      item_total: item.total,
      withCouponTotalGstAmount: item.coupon_gst,
      service_details: item.service_details,
      image: item.product_image,
      status: item.status,
      order_id: item.order_id.id,
      product_id: item.product_id.id,
      dealer_id: item.dealer_id && item.dealer_id.id ? item.dealer_id.id : null,
      gst_perc: item.product_id.gst_perc,
      technician_id: item.technician_id,
      user_id: item.user_id && item.user_id.id ? item.user_id.id : null,
      updated_by:
        item.updated_by && item.updated_by.id ? item.updated_by.id : null,
      shop_name: item.shop_name,
      qty: item.qty,
    }));

    //console.log(selectValue, 'selected values');
    //console.log('order items', orderItem)

    if (addNew) {
      //add new product

      if (!brandId) {
        try {
          const brandData = await refetch();
          if (brandData.isSuccess) {
            const data = brandData.data;
            brand_id = data.data[0].id;
            // setLoading(false)
            setBrandId(brand_id);
          } else {
            navigate(link);
            toast.error("Failed to connect to API", {
              hideProgressBar: true,
              autoClose: 2200,
            });
          }
        } catch (error) {
          // Handle errors if the query or refetch encounters an issue
          navigate(link);
          toast.error(error, {
            hideProgressBar: true,
            autoClose: 2200,
          });
        }
      }

      //add new product api call
      try {
        const res = await addProduct({
          title: title,
          series: series,
          mrp: parseFloat(price),
          rcp: parseFloat(price),
          dealer_landing_price: parseFloat(price),
          customer_landing_price: parseFloat(price),
          gst_perc: parseFloat(gst),
          margin: 1,
          description: description,
          // warranty: {
          //   warranty: "1 year",
          // },
          vehicle_category: "All",
          hsn_code: hsn,
          brand_id: brand_id,
          created_by: localStorage.getItem("userId"),
          product_from_order: true,
        });

        if (res.status === 200) {
          productId = res && res.data && res.data.data && res.data.data.id;
          setLoading(false)
        }
      } catch (error) {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.detail
        ) {
          navigate(link);
        }
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.item_code
        ) {
          toast.error(error.response.data.error.item_code[0], {
            hideProgressBar: true,
            autoClose: 2200,
          });
        }
      }
    }

    //add new items to the existing order
    orderItem.push({
      unit_price: price,
      gst_perc: selectValue ? selectValue.gst_perc : gst,
      unit_gst: 0,
      gst_percent: selectValue ? selectValue.gst_perc : gst,
      discount_percent: 0,
      discount: 0,
      item_total: price * quantity,
      withCouponTotalGstAmount: 0,
      service_details: orderItem[0].service_details,
      image: selectValue ? selectValue.product_image : "",
      status: "",
      order_id: orderItem[0].order_id,
      product_id: selectValue ? selectValue.pk : productId,
      dealer_id: orderItem[0].dealer_id,
      technician_id: orderItem[0].technician_id,
      user_id: orderItem[0].user_id,
      updated_by: localStorage.getItem("userId"),
      shop_name: orderItem[0].shop_name,
      qty: quantity,
    });

    //recalculate total, gst
    let items = [];
    orderItem.forEach((item, index) => {
      const [itemPriceBeforeGST, gstAmount, netAmount] =
        findBasePriceAndGSTAndNetAmount(
          item.unit_price,
          item.qty,
          item.gst_perc
        );
      totalItemsPriceBeforeGST += netAmount;
      grandTotalWithoutDiscount += item.item_total;

      item.itemPriceBeforeGST = itemPriceBeforeGST;
      item.unit_gst = gstAmount * item.qty;
      items.push(item);

      // Check if it's the last iteration
      const isLastIteration = index === orderItem.length - 1;

      // Calculate percent of discount if discount is applied
      if (isLastIteration && discount_amount > 0) {
        discountPercentage = calculateDiscountPercentage(
          totalItemsPriceBeforeGST,
          discount_amount
        );
      }
    });

    //console.log(items, discount_amount);

    let discountArr = items;

    // run discount applied logic for each item
    if (discount_amount > 0) {
      items = [];
      discountArr.forEach((item, index) => {
        let [itemPriceAfterDiscount, discountedItemTotalPrice, gstAmountTotal] =
          discountedItemPriceAndGST(
            item.itemPriceBeforeGST,
            discountPercentage,
            item.gst_perc,
            item.qty
          );
        let totalAmount = discountedItemTotalPrice + gstAmountTotal;
        grandTotalWithDiscount += totalAmount;

        delete item.itemPriceBeforeGST;
        delete item.gst_perc;

        item.withCouponTotalGstAmount = gstAmountTotal * item.qty;
        items.push(item);
      });
    }

    // console.log('orderArr fode', items);
    // console.log('grandTotalWithDiscount', grandTotalWithDiscount);
    // console.log('grandTotalWithoutDiscount', grandTotalWithoutDiscount);

    //send the order items to order api
    editOrder({
      id: orderItem[0].order_id,
      grand_total: grandTotalWithoutDiscount,
      grand_total_after_discount: grandTotalWithDiscount,
      payment_status: 0,
      items,
    })
      .then((res) => {
        toast.success("Order Updated", {
          hideProgressBar: true,
          autoClose: 2200,
        });
        navigate(link);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.detail
        ) {
          toast.warn(err.response.data.detail, {
            hideProgressBar: true,
            autoClose: 2200,
          });
          navigate(link);
        } else if (err.response && err.response.status !== 500) {
          toast.error("Something went wrong", {
            hideProgressBar: true,
            autoClose: 2200,
          });
        }
      });
  };

  useEffect(() => {
    if (productList && productList.data) {
      const newChoices = productList.data.map((item) => ({
        name: `${item.title} INR ${item.customer_landing_price}`,
        label: item.brand_id.name,
        pk: item.id,
        description: item.description,
        series: item.series,
        hsn_code: item.hsn_code,
        customer_landing_price: item.customer_landing_price,
        gst_perc: item.gst_perc,
        product_image:
          Array.isArray(item.images) && item.images.length > 1
            ? `${item.images[1]}${item.images[0][0]}`
            : "",
      }));

      // Update the state with the new choices
      setChoices(newChoices);
    }
  }, [productList]);

  console.log("checkItem", selectValue)

  return (
    // Section Technician request
    <Grid container spacing={2} alignItems="center">
      {data &&
        data.data &&
        data.data.data &&
        data.data.data.order_additions &&
        data.data.data.order_additions.length > 0 ? (
        <Grid item xs={12} sm={12}>
          <Grid item xs={12} sm={12}>
            <Grid container alignItems="center">
              <Grid item xs={12} sm={12}>
                <Sub data={TXT_REQUEST_FROM_TECHNICIAN} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <div
              style={{
                border: "1px solid #000",
                padding: "10px",
                backgroundColor: "#FFFFFF",
              }}
            >
              <Grid item xs={12} sm={8}>
                <Grid container alignItems="center">
                  {data.data.data.order_additions.map(
                    (orderAddition, index) => (
                      <Grid item xs={12} sm={12}>
                        <Typography className={orderStyle.viewLabelSmall}>
                          {orderAddition.text_comments}
                        </Typography>
                      </Grid>
                    )
                  )}
                </Grid>
              </Grid>
              {data &&
                data.data &&
                data.data.data &&
                data.data.data.order_additions &&
                data.data.data.order_additions.length > 0 ? (
                <Grid container spacing={2}>
                  {data.data.data.order_additions.map(
                    (orderAddition, index) => (
                      <Grid item key={index} xs={12} sm={4}>
                        <Grid container alignItems="center">
                          <Grid item xs={12} sm={12}>
                            <Typography>
                              <audio
                                src={`${orderAddition.presigned_base_url}${orderAddition.voice_comments}`}
                                controls
                              />
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  )}
                </Grid>
              ) : (
                ""
              )}
            </div>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
      {/* Section Technician request complete */}

      {/* Section Autocomplete */}

      <Grid item xs={12} sm={8}>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={4}>
            <Sub data={TXT_ORDER_ITEMS} />
          </Grid>
        </Grid>
      </Grid>
      {data &&
        data.data &&
        data.data.data &&
        (data.data.data.status === 1 ||
          data.data.data.status === 2 ||
          data.data.data.status === 3) ? (
        <Grid item xs={12} sm={4}>
          <Grid container alignItems="right">
            <Grid item xs={12} sm={12}>
              <Box sx={{ display: { sm: "inline", md: "none" } }}>
                <IconButs
                  ibtname={ICON_BUTTON_ADD_ITEM}
                  fullWidth
                  className={customStyle.dmSalesButtonOne}
                  onClick={() => setItem(true)}
                />
              </Box>
              <Box sx={{ display: { xs: "none", md: "inline" } }}>
                <IconButs
                  ibtname={ICON_BUTTON_ADD_ITEM}
                  icon={<AiOutlinePlus />}
                  fullWidth
                  className={customStyle.dmSalesButtonOne}
                  onClick={() => setItem(true)}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
      {item ? (
        <Grid item xs={12} sm={12}>
          <Autocomplete
            onChange={(e, newValue) => {
              setQuantityError("");
              setPriceError("");
              setTitleError("");
              setHsnError("");
              setDescriptionError("");
              setGstError("");
              setQuantity(1);
              setTitle("");
              setHsn("");
              setDescription("");
              setGst("");
              setAddNew("");
              setSeriesError("");
              setSelectValue("");

              if (newValue) {
                setPrice(newValue.customer_landing_price);
                setSelectValue(newValue);
              }
            }}
            id="product-autocomplete"
            options={choices}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => {
              return (
                <Grid
                  container
                  {...props}
                  justifyContent="space-between"
                  spacing={2}
                  p={4}
                >
                  <Grid item xs={8}>
                    {option.name}{" "}
                  </Grid>
                </Grid>
              );
            }}
            renderInput={(params) => {
              return (
                <>
                  <TextField
                    {...params}
                    size="small"
                    placeholder="Type and select an item"
                    value={search}
                    onChange={handleTextChange}
                  />
                </>
              );
            }}
            PaperComponent={({ children }) => {
              return (
                <Paper>
                  {children}
                  <Button
                    color="primary"
                    fullWidth
                    sx={{ justifyContent: "flex-start", pl: 2 }}
                    onMouseDown={() => {
                      setAddNew(true);
                      setSelectValue("");
                      setPrice("");
                      setQuantity(1);
                      setSearch("");
                      setTitleError("");
                      setHsnError("");
                      setDescriptionError("");
                      setPriceError("");
                      setGstError("");
                      setSeriesError("");
                      setCatName("Parts");
                    }}
                  >
                    + Add New
                  </Button>
                </Paper>
              );
            }}
          />
        </Grid>
      ) : (
        ""
      )}
      {
        // When Product is selected
        selectValue || addNew ? (
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={3}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                  <Typography className={orderStyle.viewlabel}>
                    Title
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Controls.InputField
                    value={
                      selectValue && selectValue.name
                        ? selectValue.name
                        : title
                    }
                    variant="outlined"
                    className={orderStyle.inputTextBar}
                    readOnly={selectValue ? true : false}
                    onChange={(event) => {
                      setTitleError("");
                      setTitle(event.target.value);
                    }}
                    error={titleError}
                    onKeyDown={() => setTitleError("")}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                  <Typography className={orderStyle.viewlabel}>
                    Series
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Controls.InputField
                    value={
                      selectValue && selectValue.series
                        ? selectValue.series
                        : series
                    }
                    variant="outlined"
                    className={orderStyle.inputTextBar}
                    readOnly={selectValue ? true : false}
                    onChange={(event) => {
                      setSeriesError("");
                      setSeries(event.target.value);
                    }}
                    error={seriesError}
                    onKeyDown={() => setSeriesError("")}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                  <Typography className={orderStyle.viewlabel}>
                    HSN/SAC Code
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Controls.InputField
                    value={
                      selectValue && selectValue.hsn_code
                        ? selectValue.hsn_code
                        : hsn
                    }
                    type='number'
                    variant="outlined"
                    className={orderStyle.inputTextBar}
                    readOnly={selectValue ? true : false}
                    onChange={(event) => {
                      setHsnError("");
                      setHsn(event.target.value);
                      // const isValid = /^[0-9]*$/.test(event.target.value);
                      // if (isValid) {
                      //   setHsnError("");
                      //   setHsn(parseInt(event.target.value));
                      // }
                    }}
                    error={hsnError}
                    onKeyDown={() => setHsnError("")}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                  <Typography className={orderStyle.viewlabel}>
                    Quantity
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Controls.InputField
                    name="quantity"
                    type="number"
                    value={quantity}
                    variant="outlined"
                    className={orderStyle.inputTextBar}
                    onChange={(event) => {
                      const isValid = /^[0-9]*$/.test(event.target.value);
                      if (isValid) {
                        setQuantityError("");
                        setQuantity(parseInt(event.target.value));
                      }
                    }}
                    error={quantityError}
                    onKeyDown={() => setQuantityError("")}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                  <Typography className={orderStyle.viewlabel}>
                    Description
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Controls.InputField
                    value={
                      selectValue && selectValue.description
                        ? selectValue.description
                        : description
                    }
                    variant="outlined"
                    className={orderStyle.inputTextBar}
                    readOnly={selectValue ? true : false}
                    onChange={(event) => {
                      setDescriptionError("");
                      setDescription(event.target.value);
                    }}
                    error={descriptionError}
                    onKeyDown={() => setDescriptionError("")}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                  <Typography className={orderStyle.viewlabel}>
                    Price
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Controls.InputField
                    type="text"
                    variant="outlined"
                    className={orderStyle.inputTextBar}
                    value={price}
                    onChange={(e) => {
                      const value = e.target.value;
                      const regex = /^[0-9]*\.?[0-9]*$/;
                      if (regex.test(value)) {
                        setPriceError("");
                        setPrice(value);
                      }
                    }}
                    error={priceError}
                    onKeyDown={() => setPriceError("")}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                  <Typography className={orderStyle.viewlabel}>GST</Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Controls.InputField
                    type="number"
                    value={
                      selectValue && selectValue.gst_perc
                        ? selectValue.gst_perc
                        : gst
                    }
                    variant="outlined"
                    className={orderStyle.inputTextBar}
                    readOnly={selectValue ? true : false}
                    // onChange={(event) => {
                    //   const isValid = /^[0-9]*$/.test(event.target.value);
                    //   if (isValid) {
                    // setGstError("");
                    // setGst(parseFloat(event.target.value));
                    //   }
                    // }}
                    onChange={(e) => {
                      const value = e.target.value;
                      const regex = /^[0-9]*\.?[0-9]*$/;
                      if (regex.test(value)) {
                        setGstError("");
                        setGst(value);
                      }
                    }}
                    error={gstError}
                    onKeyDown={() => setGstError("")}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={2}></Grid>
                <Grid item xs={12} sm={10}>
                  <IconButs
                    ibtname={loading ? <CircularProgress
                      size={24}
                      sx={{
                        color: 'black',
                        position: 'absolute'
                      }}
                    /> : SUBMIT}
                    className={viewStyle.formButtons}
                    variant="outlined"
                    onClick={submitData}
                    disabled={loading ? true : false}
                  />

                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          ""
        )
      }
      {/* End of Autocomplete   */}

      {/* Start of order items listing    */}
      <Grid item xs={12} sm={12}>
        <Box className={viewStyle.tableBox}>
          <TblContainer>
            <Table sx={{ minWidth: 750 }} aria-label="simple table">
              <TblHead />
              <TableBody>
                {!loadingCompleted ? (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <CustomLoader />
                    </TableCell>
                  </TableRow>
                ) : (
                  data &&
                  data.data &&
                  data.data.data &&
                  data.data.data.order_item.map((item, index, array) => {
                    return (
                      <TableRow key={item.id}>
                        <TableCell align="center">
                          {item.product_id.item_code}
                        </TableCell>
                        <TableCell align="center">
                          {item.product_id.title}
                        </TableCell>
                        {item.product_id.brand_id.category_id.parent_id && (
                          <TableCell align="center">
                            {
                              item.product_id.brand_id.category_id.parent_id
                                .name
                            }
                          </TableCell>
                        )}
                        <TableCell align="center">
                          {item.product_id.brand_id.category_id.name}
                        </TableCell>
                        <TableCell align="center">
                          {item.product_id.brand_id.name}
                        </TableCell>
                        <TableCell align="center">
                          {item.product_id.series}
                        </TableCell>
                        <TableCell align="center">
                          {item.product_id.model}
                        </TableCell>
                        <TableCell align="center">{item.unit_price}</TableCell>
                        <TableCell align="center">{item.qty}</TableCell>
                        <TableCell align="center">
                          {item.discount_amount % 1 !== 0
                            ? item.discount_amount.toFixed(2)
                            : item.discount_amount}
                        </TableCell>
                        <TableCell align="center">
                          {item.gst_amount % 1 !== 0
                            ? item.gst_amount.toFixed(2)
                            : item.gst_amount}
                        </TableCell>
                        <TableCell align="right">{item.total}</TableCell>
                      </TableRow>
                    );
                  })
                )}
                {data.data.data.order_item.length > 0 && (
                  <TableRow>
                    <TableCell colSpan={9} align="right">
                      <strong>Grand Total</strong>
                    </TableCell>
                    <TableCell colSpan={2} align="right">
                      <strong>
                        INR{" "}
                        {data &&
                          data.data &&
                          data.data.data &&
                          data.data.data.grand_total_after_discount > 0
                          ? data.data.data.grand_total_after_discount
                          : data.data.data.grand_total}
                      </strong>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TblContainer>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AdditionalProducts;
