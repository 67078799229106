export function findBasePriceAndGSTAndNetAmount(
    itemPriceWithGST,
    quantity,
    gstPercentage,
    ) {
    // Calculate item price before GST
    let itemPriceBeforeGST = parseFloat(
        (itemPriceWithGST / (1 + gstPercentage / 100)).toFixed(4),
    );
    return [
        itemPriceBeforeGST,
        parseFloat((itemPriceWithGST - itemPriceBeforeGST).toFixed(4)),
        parseFloat((itemPriceBeforeGST * quantity).toFixed(4)),
    ];
}

export function calculateDiscountPercentage(totalItemsPriceBeforeGST, discount) {
    let discountPercentage = parseFloat(
      ((discount * 100) / totalItemsPriceBeforeGST).toFixed(4),
    );
    return discountPercentage;
}

export function discountedItemPriceAndGST(
    itemPriceBeforeGST,
    discountPercentage,
    gstPercentage,
    quantity,
  ) {
    let itemPriceAfterDiscount =
      itemPriceBeforeGST * (1 - discountPercentage / 100);
    let gstAmountOnDiscountedPrice =
      itemPriceAfterDiscount * (gstPercentage / 100);
    return [
      parseFloat(itemPriceAfterDiscount.toFixed(4)),
      parseFloat((itemPriceAfterDiscount * quantity).toFixed(4)),
      parseFloat((gstAmountOnDiscountedPrice * quantity).toFixed(4)),
    ];
}