import * as React from "react";
import Box from "@material-ui/core/Box";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import makeStyles from "../../sharedFeatures/sharedClasses";
import { AiOutlineClose } from "react-icons/ai";
import IconButton from "@material-ui/core/IconButton";
import Controls from "../../common/Controls/Controls";
import OrderManagmentStyle from "./OrderManagmentStyle";
import moment from "moment";
// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   fontFamily: "Montserrat",
//   transform: "translate(-50%, -50%)",
//   width: 900,
//   bgcolor: "#ffffff",
//   boxShadow: 24,
//   borderRadius: "10px",
//   ["@media (max-width: 600px)"]: {
//     width:"90vw",
//     height:"90vh",
//     overflow:"hidden scroll"
//   },
// };

const AssignLinkedOrders = ({ open, onModalClose, title, data }) => {
  const customStyle = makeStyles();
  const orderStyle = OrderManagmentStyle();

  return (
    <>
      <Modal open={open} onClose={onModalClose}>
        <Box className={orderStyle.modalStyle}>
          <Box className={orderStyle.assignlinkbox}>
            <Box></Box>
            <Typography className={orderStyle.assignTitle}>{title}</Typography>
            <Box className={orderStyle.assignlinkclose}>
              <IconButton onClick={() => onModalClose(false)}>
                <AiOutlineClose />
              </IconButton>
            </Box>
          </Box>
          <main>
            <form>
              {data &&
                data.map((item, index) => (
                  <>
                    <Box
                      sx={{
                        width: "100%",
                        border: "1px solid #D9D9D9",
                        borderRadius: "8px",
                        padding: "15px",
                        marginTop: "10px",
                      }}
                      key={index}
                    >
                      <div style={{ marginBottom: "20px", fontSize: "16px" }}>
                        Item {index + 1}
                      </div>
                      <Grid container spacing={2} alignItems="flex-start">
                        <Grid item xs={12} sm={6}>
                          <Grid container alignItems="center">
                            <Grid item xs={12} sm={4}>
                              <Typography className={orderStyle.viewlabel}>
                                Item Code
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                              <Controls.InputField
                                name={"customerName"}
                                value={item.product_id.item_code}
                                className={orderStyle.inputTextBar}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Grid container alignItems="center">
                            <Grid item xs={12} sm={4}>
                              <Typography className={orderStyle.viewlabel}>
                                Item Title
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                              <Controls.InputField
                                name={"customerName"}
                                value={item.product_id.title}
                                className={orderStyle.inputTextBar}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Grid container alignItems="center">
                            <Grid item xs={12} sm={4}>
                              <Typography className={orderStyle.viewlabel}>
                                Category Name
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                              <Controls.InputField
                                name={"vehicelModel"}
                                value={
                                  item.product_id.brand_id.category_id.name
                                }
                                className={orderStyle.inputTextBar}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Grid container alignItems="center">
                            <Grid item xs={12} sm={4}>
                              <Typography className={orderStyle.viewlabel}>
                                Brand
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                              <Controls.InputField
                                name={"mobileNumber"}
                                value={item.product_id.brand_id.name}
                                className={orderStyle.inputTextBar}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Grid container alignItems="center">
                            <Grid item xs={12} sm={4}>
                              <Typography className={orderStyle.viewlabel}>
                                Series
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                              <Controls.InputField
                                name={"oderData"}
                                value={item.product_id.series}
                                className={orderStyle.inputTextBar}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Grid container alignItems="center">
                            <Grid item xs={12} sm={4}>
                              <Typography className={orderStyle.viewlabel}>
                                Model
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                              <Controls.InputField
                                name={"services"}
                                value={
                                  item.product_id.model
                                    ? item.product_id.model
                                    : "No data"
                                }
                                className={orderStyle.inputTextBar}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Grid container alignItems="center">
                            <Grid item xs={12} sm={4}>
                              <Typography className={orderStyle.viewlabel}>
                                Quantity
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                              <Controls.InputField
                                name={"slot"}
                                value={item.qty}
                                className={orderStyle.inputTextBar}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Grid container alignItems="center">
                            <Grid item xs={12} sm={4}>
                              <Typography className={orderStyle.viewlabel}>
                                Price
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                              <Controls.InputField
                                name={"status"}
                                value={"INR " + item.unit_price}
                                className={orderStyle.inputTextBar}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Grid container alignItems="center">
                            <Grid item xs={12} sm={4}>
                              <Typography className={orderStyle.viewlabel}>
                                GST Percentage
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                              <Controls.InputField
                                name={"serviceName"}
                                value={item.product_id.gst_perc + " %"}
                                className={orderStyle.inputTextBar}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Grid container alignItems="center">
                            <Grid item xs={12} sm={4}>
                              <Typography className={orderStyle.viewlabel}>
                                GST Amount
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                              <Controls.InputField
                                name={"serviceName"}
                                value={"INR " + Math.round(item.gst_amount)}
                                className={orderStyle.inputTextBar}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Grid container alignItems="center">
                            <Grid item xs={12} sm={4}>
                              <Typography className={orderStyle.viewlabel}>
                                Total Amount
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                              <Controls.InputField
                                name={"serviceName"}
                                value={"INR " + item.total}
                                className={orderStyle.inputTextBar}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Grid container alignItems="center">
                            <Grid item xs={12} sm={4}>
                              <Typography className={orderStyle.viewlabel}>
                                Dealer
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                              <Controls.InputField
                                name={"serviceName"}
                                value={
                                  item.shop_name
                                    ? item.shop_name
                                    : "Not assigned"
                                }
                                className={orderStyle.inputTextBar}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                ))}
            </form>
          </main>
        </Box>
      </Modal>
    </>
  );
};

export default AssignLinkedOrders;
