import { useMutation, useQueryClient } from "react-query";
import { addCategory, deleteSubCategory, editCategory } from "./inventoryUrls";

const add_cat = (id) => {
  const queryClient = useQueryClient();
  return useMutation((category) => addCategory(category), {
    onMutate: async (newCategory) => {
      if (id) {
        await queryClient.cancelQueries("subcategoryListByCategory");
        
        const prev = queryClient.getQueryData("subcategoryListByCategory");
        return {
          prev,
          newCategory,
        };
      } else {
        await queryClient.cancelQueries("categoryList");

        const prev = queryClient.getQueryData("categoryList");
        return {
          prev,
          newCategory,
        };
      }
    },
    onSuccess: (data) => {
      return data;
    },
    onSettled: () => {
      if (id) {
        queryClient.invalidateQueries("subcategoryListByCategory"); //refetch the collection on the background
      } else {
        queryClient.invalidateQueries("categoryList"); //refetch the collection on the background
      }
    },
    onError: (err, _, context) => {
      if (id) {
        queryClient.setQueryData("subcategoryListByCategory", context.prev); //rollback the cache to the previous state
      } else {
        queryClient.setQueryData("categoryList", context.prev); //rollback the cache to the previous state
      }
      return err;
    },
  });
};

const edit_cat = (value) => {
  const queryClient = useQueryClient();
  if (value) {
    return useMutation((subCategory) => editCategory(subCategory), {
      onMutate: async (subCategory) => {
        await queryClient.cancelQueries("subcategoryListByCategory");
        await queryClient.cancelQueries([
          "categoryData",
          subCategory.parent_id.id,
        ]);
        const prev = queryClient.getQueryData("subcategoryListByCategory");
        return {
          prev,
          subCategory,
        };
      },
      onSettled: () => {
        queryClient.invalidateQueries("subcategoryListByCategory");
      },
      onError: (err, _, context) => {
        queryClient.setQueryData("subcategoryListByCategory", context.prev);
      },
    });
  } else {
    return useMutation((category) => editCategory(category), {
      onMutate: async (category) => {
        await queryClient.cancelQueries("categoryList"); //cancel any in-flight or pending query to the `category` key
        await queryClient.cancelQueries(["categoryList", category.id]);

        const prev = queryClient.getQueryData("categoryList", category.id);
        queryClient.setQueryData(["categoryList", category.id], category); //add the new todo to the data
        // also update the whole list to enable smooth navigation
        queryClient.setQueryData("category", (old = []) => {
          const index = old.findIndex((item) => item.id === category.id);
          return [
            ...old.slice(0, index),
            category,
            ...old.slice(index + 1, old.length),
          ];
        });
        return {
          prev,
          category,
        };
      },
      onSettled: () => {
        queryClient.invalidateQueries("categoryList"); //refetch the collection on the background
      },
      onError: (err, _, context) => {
        queryClient.setQueryData("categoryList", context.prev); //rollback the cache to the previous state
      },
    });
  }
};

const delete_cat = () => {
  const queryClient = useQueryClient();
  return useMutation((todoId) => deleteSubCategory(todoId), {
    onMutate: async (todo) => {
      await queryClient.cancelQueries("categoryList");
      const prev = await queryClient.getQueryData("categoryList");

      queryClient.setQueryData("categoryList", (old = []) => {});

      return {
        prev,
        todo,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("categoryList");
      queryClient.invalidateQueries("subcategoryListByCategory");
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("categoryList", context.prev);
    },
  });
};

const useAddCategory = {
  add_cat,
  delete_cat,
  edit_cat,
};

export default useAddCategory;
